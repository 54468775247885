.tabs-wrap {
	background: #f6f6f6;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center; }


.music-tabs-items {
	flex-wrap: wrap;
	display: flex; }

.tabs-item {
	flex: 0 0 25%;
	padding: 0 10px;
	max-width: 25%;
	-webkit-box-flex: 0; }

.tabs-item-two {
	flex: 0 0 33.33%;
	padding: 0 10px;
	max-width: 33.33%;
	-webkit-box-flex: 0; }

.music-tabs {
	background-size: cover;
	background-position: center center; }

.tab-pd-left {
	padding: 80px 80px 60px 135px;
	background-size: cover; }

.tab-pd-right {
	padding: 17px 135px 0 80px; }

.music-tab {
	position: relative;
	border: 1px solid #b7b7b7;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 20px;

	&:before {
		position: absolute;
		content: '';
		left: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.6); }

	img {
		width: 100%;
		height: auto; }

	> a {
		position: absolute;
		right: 20px;
		top: 15px;
		font-size: 16px;
		font-weight: 500;
		color: #fff;
		font-weight: 500;
		font-family: $font_heading;

		span {
			color: $color_primary; } }

	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 0 20px 15px 20px;
		width: 100%;

		h3 {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 0;

			a {
				color: #fff;

				&:hover {
					color: $color_primary; } } }

		.ratting {
			list-style: none;

			li {
				display: inline-block;
				color: $color_primary;
				font-size: 14px; } } }

	&.music-tab-two {
		background: #fff;
		border-radius: 4px;
		padding: 29px 20px;
		position: relative;
		min-height: 145px;

		&:before {
			display: none; }

		.bg-icon {
			font-size: 50px;
			font-family: Flaticon;
			position: absolute;
			bottom: 47px;
			right: 15px;

			i {
				font-size: 60px;
				opacity: 0.09; } }

		i {
			font-size: 22px; }

		.content {
			position: relative;
			padding: 0;

			h3 {
				color: #444;
				font-family: $font_heading;
				font-size: 20px;
				font-weight: 700;

				a {
					position: relative;
					color: #444;
					right: auto;
					top: auto;

					&:hover {
						color: $color_primary; } } }

			.ratting {

				li {
					i {
						font-size: 14px; } } } }

		.view-btn {
			position: relative;
			color: #444;
			right: auto;

			&:hover {
				color: $color_primary; } } } }

@media (max-width: 1600px) {

	.tab-pd-right {
		padding: 30px 80px; }

	.tab-pd-left {
		padding-left: 70px; } }

@media (min-width: 1280px) and (max-width: 1600px) {

	.tabs-item {
		flex: 0 0 33.33%;
		max-width: 33.33%; }

	.tabs-item-two {
		flex: 0 0 50%;
		max-width: 50%; } }

@media (min-width: 992px) and (max-width: 1279px) {
	.tabs-item, .tabs-item-two {
		flex: 0 0 50%;
		max-width: 50%; } }

@media (max-width: 768px) {
	.music-tabs-items {
		padding: 50px 5% 30px; }

	.tabs-item {
		flex: 0 0 33.33%;
		max-width: 33.33%; }

	.tabs-item-two {
		flex: 0 0 50%;
		max-width: 50%; } }

@media (max-width: 600px) {
	.tabs-item {
		flex: 0 0 50%;
		max-width: 50%; } }

@media (max-width: 500px) {
	.tabs-item-two {
		flex: 0 0 100%;
		max-width: 100%; }

	.music-tab.music-tab-two .content h3 {
		font-size: 15px;
		font-weight: 400; } }
