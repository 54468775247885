
$font_primary: 'Roboto', sans-serif;
$font_heading: 'Changa', sans-serif;


/* Primary Color */
$color_primary: #e43a90;
$color_secondary: #494848;
$color_three: #f60038;

