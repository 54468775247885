.page-header {
	padding: 280px 0 30px;
	background-position: center;
	background-size: cover;

	&.event-header {
		padding: 300px 0 30px;

		.page-header-title {
			padding-bottom: 150px; } }

	.page-header-title {
		padding-bottom: 40px;

		h2 {
			color: #fff;
			font-size: 50px;
			font-weight: 600;
			text-transform: uppercase; }

		h3 {
			color: #fff;
			font-size: 24px;
			font-weight: 500;
			text-transform: uppercase; }

		&.event-page-header {
			h2 {
				font-size: 36px; }

			h3 {
				font-size: 24px;
				margin-bottom: 20px; } } }



	.live-ticket-count {
		margin-bottom: 50px;
		text-align: center;

		.countdown {
			margin: 0 auto; } } }

.page-header .tim-btn.tim-btn-bgt {
	border: 1px solid #e4e4e4;
	color: #fff; }

.page-header .tim-btn.tim-btn-bgt:hover {
	color: $color_primary; }



.breadcrumbs {
	a, span {
		color: #fff;
		font-weight: 500;
		font-size: 20px;
		text-transform: uppercase; }

	a {
		color: 	#b4b4b4;

		&:hover {
			color: $color_primary; } } }

@media (max-width: 992px) {
	.page-header {
		padding-top: 155px !important; } }


@media (max-width: 768px) {
	.page-header {
		.page-header-title {
			h2 {
				font-size: 40px;
				line-height: 40px; } }

		.live-ticket-count {
			margin-bottom: 20px;
			text-align: center; } } }
