
.mag-header-inner {


	h2 {
		font-size: 60px;
		color: #fff;
		span {
			color: #db9a0a; } } }

.mag-bn {
		position: relative;
		width: auto;
		z-index: 1;
		padding: 13px 35px;
		text-align: center;
		display: inline-block;
		margin-left: 15px;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: skewX(-15deg);
			background: #313130;
			z-index: -1; }
		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 3%;
			width: 94%;
			height: 100%;
			-webkit-transform: skewX(-15deg);
			-ms-transform: skewX(-15deg);
			transform: skewX(-15deg);
			z-index: -1;
			border-left: 7px solid #fff;
			border-right: 7px solid #fff; }

		p {
			font-size: 24px;
			color: #2e2e2e;
			font-weight: 500;
			margin: 0;
			text-transform: uppercase;

			span {
				color: #fff; } } }

.mag-header-inner {
	max-width: 1350px;
	margin: 0 auto; }


.mag-social {
	ul {
		padding: 0;
		list-style: none;
		li {
			display: inline;

			a {} } } }
.mheader-top {
	span {
		float: left; }
	p {
		float: left; }
	.mag-social {
		float: right; } }

.mheader-top {
	display: block;
	overflow: hidden;
	background: #1d1d1d;
	padding: 16px 15px 16px;
	border-radius: 5px 5px 0px 0px; }

.mag-header-inner .mag-bn::before {
	background:  #dc9c0a; }



.mheader-top span {
	float: left;
	color: #fff;
	background: #dc9c0a;
	padding: 6px 13px;
	overflow: hidden;
	display: block;
	border-radius: 5px 0px 0px 5px;
	margin-right: 15px; }

.mheader-top p {
	float: left;
	padding-top: 6px;
	margin: 0px;
	color: #adadad;
	font-size: 14px; }

.mag-social ul li a {
	display: inline-block;
	height: 35px;
	width: 35px;
	margin-right: 8px;
	text-align: center;
	color: #fff;
	border-radius: 50px;
	line-height: 35px; }

.mag-social ul li a.fb {
	background: #071662; }
.mag-social ul li a.tt {
	background: #4d91e5; }
.mag-social ul li a.ins {
	background: #9d1973; }
.mag-social ul li a.gp {
	background: #cd0630; }

.mag-header-inner img {
	max-width: 100%; }

.headr-post {
	width: 25%;
	float: left;
	position: relative;
	z-index: 1; }

.mheader-post {
	display: block;
	overflow: hidden; }


.hp-content {
	position: absolute;
	bottom: 0;
	left: 16px;
	z-index: 999; }

.hp-content p a {
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 24px; }

.hp-content a {
	color: #da9a0a;
	font-size: 18px;
	font-weight: 400; }

.hp-gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	background: linear-gradient(to bottom, #000, transparent);
	background: -webkit-linear-gradient(bottom, #000, transparent);
	opacity: 0.7;
	z-index: 1;
	top: 0; }


.magazine-tab-area .tab-content {
	overflow: hidden;
	width: 100%; }

.page-header.magazine-header {
	padding-bottom: 0; }


