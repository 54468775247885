#pricing {
	padding: 100px 0; }

.pricing-table {
	text-align: center;
	// background-image: url(../../media/background/pricing.svg)
	background-repeat: no-repeat;
	padding: 60px 0;
	background-size: cover;
	position: relative;
	max-width: 365px;
	margin: 0 auto 30px;

	.pricbg, .pricbg-bottom {
		position: absolute;
		filter: drop-shadow(-1px -11px 5px rgba(0, 0, 0, 0.1));

		path {
			fill: #fff; } }

	.pricbg {
		top: 0;
		left: 0;

		path {
			fill: #f1f1f1;
			transition: all 0.3s ease-in-out; } }

	.pricbg-bottom {
		bottom: 0;
		left: 0;
		z-index: -1;
		filter: drop-shadow(0px 8px 5px rgba(0, 0, 0, 0.1)); }

	.pricing-head {
		padding-bottom: 33px;
		position: relative;

		.price-title {
			font-size: 36px;
			font-weight: 600;
			margin-bottom: 0; } }

	.price-inner {
		box-shadow: 0 -15px 20px 0 rgba(0,0,0,0.1); }

	.price {
		font-size: 55px;
		font-weight: 600;
		color: $color_primary;
		line-height: 55px;
		padding: 40px 0 20px; }

	.price-feature {
		margin: 0;
		list-style: none;
		padding: 0 70px 40px;

		li {
			line-height: 40px;
			border-bottom: 1px dashed #d7d7d7; } }

	.tim-btn {
		padding: 12px 30px;
		background: transparent;
		border: 1px solid #d7d7d7;
		border-radius: 5px;
		text-transform: uppercase;
		color: #696868;
		font-size: 20px;
		display: inline-block;

		&:hover {
			background: $color_primary;
			color: #fff;
			border-color: $color_primary; } }

	&:hover {
		.pricbg {

			path {
				fill: $color_primary; } }

		.pricing-head {

			.price-title {
				color: #fff; } } } }

@media (max-width: 1400px) {
	.pricing-table {
		.pricing-head {
			padding-bottom: 18px; } } }


@media (min-width: 992px) and (max-width: 1280px) {
	.pricing-table {
		padding: 45px 0;

		.pricing-head {
			padding-bottom: 0;

			.price-title {
				font-size: 28px; } }

		.price-feature {
			padding: 0 30px 20px;

			.pricing-table {} }

		.price {
			padding: 50px 0 10px;
			font-size: 45px;
			margin-bottom: 0; } }

	.container.con-pd {
		padding: 0 15px; } }

@media (min-width: 768px) and (max-width: 991px) {

	.pricing-table {
		padding: 50px 0;

		.pricing-head {
			padding-bottom: 0;

			.price-title {
				font-size: 30px; } }

		.price {
			font-size: 45px;
			padding: 50px 0 0; }

		.price-feature {
			padding: 0 30px 20px; }

		.tim-btn {
			padding: 8px 25px;
			font-size: 16px; } } }

@media (max-width: 1400px) {

		#pricing {
			padding: 50px 0; } }

@media (max-width: 767px) {

	#pricing .container.con-pd {
		padding: 0; } }


