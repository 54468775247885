

.single-social-widget {
	width: 100%;
	background: #242e8a;
	height: 70px;
	margin-bottom: 10px; }

.single-social-widget a {
	float: left;
	background: #19227a;
	color: #fff;
	display: block;
	width: 60px;
	height: 70px;
	text-align: center;
	line-height: 70px;
	font-size: 27px; }

.single-social-widget p {
	color: #fff;
	margin: 0;
	padding-left: 80px;
	padding-top: 17px;
	font-family: $font_heading;
	font-size: 16px;
	line-height: 16px; }

.single-social-widget span {
	color: #fff;
	margin: 0;
	padding-left: 81px;
	display: block;
	font-family: $font_heading;
	font-size: 16px; }

.single-social-widget p:first-child {
	padding-top: 10px; }

.facebook-widget {
	background: #242e8a;
	a {
		background: #19227a; } }


.twitter-widget {
	background: #2196f0;
	a {
		background: #1487df; } }
.pint-widget {
	background: #cf0f21;
	a {
		background: #b30919; } }

.magazine-post-widget > a {
	float: left; }

.mag-post-content {
	position: relative;
	display: block;
	overflow: hidden; }

.mag-post-content h2 a {
	font-size: 18px;
	color: #363636; }

.magazine-post-widget {
	overflow: hidden;
	padding-bottom: 20px;
	margin-bottom: 20px;
	border-bottom: 1px dashed #ddd; }

.mag-post-content h2 {
	line-height: 17px;
	padding: 5px 0 5px 20px;
	margin-bottom: 0; }

.mag-post-content span {
	line-height: 17px;
	padding: 5px 20px; }

.mag-post-content span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }

.mag-show-content h2 {
	line-height: 17px;
	padding: 5px 20px;
	margin-bottom: 0; }

.mag-show-content span {
	line-height: 16px;
	padding: 5px 20px;
	font-size: 16px; }

.mag-show-content span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }

.mag-show-content > a {
	color: #dc9c0a;
	float: right;
	margin-right: 50px; }


.mag-show-content h2 a {
	font-size: 20px;
	color: #363636; }


.magazine-show-widget {
	margin-bottom: 20px;
	box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11); }

.mag-show-widget .magazine-show-widget:last-child {
	margin-bottom: 0px; }


.magazine-tab-area .magazine-show-widget {
	margin-bottom: 30px; }


.mag-show-content {
	background: #fff;
	padding: 18px 0 27px; }

.magazine-widget .mag-bn::before {

	background: #313130; }

.magazine-widget .mag-bn p {
	color: #fff;
	font-weight: 700; }

.magazine-sidebar {
	padding-top: 60px; }

.magazine-widget .mag-bn {
	margin-bottom: 30px; }

.magazine-widget {
	margin-bottom: 60px; }

.mag-tag-widget {
	ul li {
		position: relative;
		width: auto;
		z-index: 1;
		text-align: center;
		display: inline-block;
		margin-left: 15px;
		margin-bottom: 15px;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			-webkit-transform: skewX(-20deg);
			-ms-transform: skewX(-20deg);
			transform: skewX(-20deg);
			background: #313130;
			z-index: -1; }

		a {
			color: #fff;
			padding: 12px 35px;
			display: block;
			text-transform: uppercase;
			font-weight: 500; } } }


.mag-tag-widget ul li:hover::before {

	background: #ddb013;
	transition: all 0.5s ease; }

.mag-twitter-widget {
	.single-twitter {
		border-bottom: 1px dashed #ddd;
		margin-top: 1rem;
		p {
			font-size: 16px;
			color: #525252;
			margin-top: 10px; }
		.twt-top {
			i {
				color: #313130;
				font-size: 27px;
				margin-right: 18px;
				margin-top: -1px;
				float: left;
				font-style: italic; }
			span {
				color: #ddb013; } } } }


.magazine-blog {
	padding-top: 60px;
	.mag-bn {
		p {
			color: #fff;
			font-weight: 700; } } }



.magazine-post {
	position: relative;
	z-index: 1;
	margin-bottom: 30px;
	overflow: hidden; }

.magazine-post:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out; }

.magazine-post img {
	transition: all 0.3s ease-in-out; }

.magazine-show-widget a {
	display: block;
	overflow: hidden; }


.magazine-show-widget:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out; }

.magazine-show-widget img {
	transition: all 0.3s ease-in-out; }

.mags-post-content {
	position: absolute;
	bottom: 6px;
	left: 24px;
	z-index: 999; }

.mags-post-content h4 a {
	color: #fff;
	font-size: 32px;
	font-weight: 500;
	text-transform: uppercase;
	line-height: 42px;
	padding-right: 105px;
	display: inline-block; }

.mags-post-content > a {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	background: #da9a0a;
	border-radius: 3px;
	padding: 8px 18px;
	display: inline-block;
	margin-bottom: 13px;
	font-family: $font_heading; }


.mag-post-gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	background: linear-gradient(to bottom, #000, transparent);
	background: -webkit-linear-gradient(bottom, #000, transparent);
	opacity: 0.7;
	z-index: 1;
	top: 0; }

.mags-post-content p {
	font-size: 16px;
	color: #fff;
	font-family: $font_heading; }

.mags-post-content p a {
	color: #ddb013; }


.mid .mags-post-content h4 a {
	font-size: 24px;
	line-height: 32px;
	padding-right: 0; }

.mid .mags-post-content > a {
	font-size: 15px;
	margin-bottom: 7px;
	padding: 5px 13px; }

.mid .mags-post-content h4 {
	margin-bottom: 0; }

.magazine-post.mid:first-child {
	margin-bottom: 20px; }

.magazine-post-small {
	overflow: hidden;
	margin-bottom: 22px;
	box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11); }

.magazine-post-small:last-child {
	margin-bottom: 0; }



.magazine-post-small > a {
	float: left; }

.mag-post-content-small {
	position: relative;
	display: block;
	overflow: hidden; }


.mag-post-content-small h2 a {
	font-size: 18px;
	color: #363636; }

.mag-post-content-small h2 a:hover {
	color: #da9a0a; }

.mag-post-content-small h2 {
	line-height: 17px;
	padding: 5px 0 5px 20px;
	margin-bottom: 0; }

.mag-post-content-small span {
	line-height: 17px;
	padding: 5px 20px; }

.mag-post-content-small span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }


.magazine-blog .mag-bn {
	margin-bottom: 30px; }


.magazine-tab-area {
	.mag-bn {
		float: left;
		margin-bottom: 0; } }


.magazine-tab-btn {
	float: right;
	margin-top: 7px;
	.nav-item {
		a {
			font-size: 18px;
			color: #474646;
			font-family: $font_heading; } } }

.magazine-tab-btn .nav-item a:hover,.magazine-tab-btn .nav-item a.active,.mag-post-content h2 a:hover,.mag-show-content h2 a:hover,
.magazine-post-small-video .mag-post-content-small h2 a:hover,.single-concert .sin-concert-content h4 a:hover,.magazine-next-cons-content h2 a:hover,
.mag-hot-post h2 a:hover,.concer-sin-content h2 a:hover {


	color:  #da9a0a; }


.magazine-tab-area .tab-content .tab-pane {
	padding: 30px 15px 0; }




.magazine-artist-info .aritist-music .icon {
	float: none;
	margin-right: 0;
	text-align: center; }

.magazine-artist-info .aritist-music .content {
	margin-left: 0;
	text-align: center; }

.magazine-artist-info .aritist-music .icon i {
	color: #fff;
	font-size: 40px;
	line-height: 50px; }

.magazine-artist-info .aritist-music .content p {
	color: #fff;
	font-size: 30px;
	margin: 0;
	font-weight: 600;
	font-family: $font_heading; }

.magazine-artist-info .aritist-music .content span {
	font-size: 20px;
	color: #fff;
	font-family: "Changa", sans-serif; }

.magazine-artist-info {
	position: absolute;
	top: 0;
	height: 100%;
	background: rgba(58, 57, 58, 0.67);
	width: 102px;
	z-index: 6;
	padding-top: 20px; }

.magazine-artist {
	position: relative;
	margin-bottom: 30px; }

.magazine-artist::before {
	position: absolute;
	content: '';
	height: 100%;
	width: 100%;
	background: rgba(216, 198, 0, 0.4);
	opacity: 0;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out; }

.magazine-artist:hover:before {
	opacity: 1; }


.mag-artist-content {
	position: absolute;
	left: 0;
	bottom: 31px;
	left: 131px;
	z-index: 6;
	p {
		color: #fff;
		font-size: 24px;
		font-weight: 500;
		font-family: $font_heading;
		margin-bottom: 0; }
	span {
		color: #fff;
		font-size: 16px;
		font-weight: 500;
		font-family: $font_heading; } }

.mag-gradient {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	background: -webkit-gradient(linear, left top, left bottom, from(#000), to(transparent));
	background: -o-linear-gradient(top, #000, transparent);
	background: linear-gradient(to bottom, #000, transparent);
	background: -webkit-linear-gradient(bottom, #000, transparent);
	opacity: 0.7;
	z-index: 1;
	top: 0; }

.aritist-music {
	margin-bottom: 20px; }

.magazine-artist:hover .magazine-artist-info {
	background: rgba(220, 156, 10, 0.67);
	transition: all 0.5s ease; }

.magazine-video {
	position: relative;
	margin-bottom: 30px; }

.magazine-video .popup-video-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	color: #d21d20;
	font-size: 96px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	outline: 0; }

.magazine-video .popup-video-btn::after {
	content: '';
	background: #fff;
	width: 46px;
	height: 46px;
	top: 50%;
	left: 50%;
	position: absolute;
	z-index: -1;
	transform: translate(-50%,-50%); }




.magazine-post-small-video {
	overflow: hidden;
	margin-bottom: 18px;
	box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
	position: relative; }

.magazine-post-small-video:last-child {
	margin-bottom: 0; }


.magazine-post-small-video > img {
	float: left; }

.magazine-post-small-video .mag-post-content-small {
	position: relative;
	display: block;
	overflow: hidden; }


.magazine-post-small-video .mag-post-content-small h2 a {
	font-size: 18px;
	color: #363636; }

.magazine-post-small-video .mag-post-content-small h2 {
	line-height: 17px;
	padding: 5px 0 5px 20px;
	margin-bottom: 0; }

.magazine-post-small-video .mag-post-content-small span {
	line-height: 17px;
	padding: 5px 20px; }

.magazine-post-small-video .mag-post-content-small span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }


.magazine-post-small-video .popup-video-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	color: #d21d20;
	font-size: 25px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	outline: 0; }

.mag-post-vid-img {
	float: left;
	position: relative; }


.magazine-post-small-video .popup-video-btn::after {
	content: '';
	background: #fff;
	width: 10px;
	height: 13px;
	top: 8px;
	left: 9px;
	position: absolute;
	z-index: -1;
	transition: all 0.3s ease-in-out; }

.single-concert {
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease;
	> a.tag {
		position: absolute;
		top: 25px;
		left: 25px;
		background: #dc9c0a;
		border-radius: 3px;
		padding: 3px 10px;
		color: #fff; }

	.sin-concert-content {
		span {
			color: #707070;
			font-size: 16px;
			font-family: $font_heading;
			a {
				color: #ddb80a; } }
		h4 a {
			font-family: $font_heading;
			font-size: 22px;
			color: #353535; }
		p {
			font-size: 14px;
			color: #363636; } } }

.single-concert a.imag {
	display: inline-block;
	overflow: hidden;
	transition: all 0.3s ease-in-out; }
.single-concert a.imag img {
	transition: all 0.3s ease-in-out; }

.single-concert:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out; }


.sin-concert-content {
	padding: 30px 15px 30px 25px; }



.area-wrap {
	box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
	margin-bottom: 30px; }


.concert-single img {
	float: left; }

.concer-sin-content {
	position: relative;
	display: block;
	overflow: hidden; }

.concer-sin-content h2 a {
	font-size: 18px;
	color: #363636;
	font-family: $font_heading; }

.concert-single {
	overflow: hidden;
	padding-bottom: 20px;
	padding: 27px 15px 27px 25px;
	border-top: 1px dashed #ddd; }

.concer-sin-content h2 {
	line-height: 17px;
	padding: 0 0 0 20px;
	margin-bottom: 0; }

.concer-sin-content span {
	line-height: 17px;
	padding: 5px 20px;
	font-size: 14px;
	color: #ddb80a; }

.concer-sin-content span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }

.concer-sin-content > a {
	background: #8a2467;
	color: #fff;
	padding: 3px 11px;
	border-radius: 5px;
	margin-top: 4px;
	display: inline;
	font-size: 14px;
	text-transform: capitalize;
	margin-left: 18px; }


.magazine-next-cons-content h2 {
	line-height: 17px;
	padding: 5px 20px;
	margin-bottom: 0; }

.magazine-next-cons-content span {
	line-height: 16px;
	padding: 5px 20px;
	font-size: 16px; }

.magazine-next-cons-content span i {
	color: #dc9c0a;
	font-size: 14px;
	margin-right: 5px; }

.magazine-next-cons-content > a {
	color: #dc9c0a;
	float: right;
	margin-right: 50px; }


.magazine-next-cons-content h2 a {
	font-size: 20px;
	color: #363636; }

.magazine-next-cons-content {
	background: #fff;
	padding: 12px 0 12px; }

.magazine-next-cons > a {
		display: inline-block;
		overflow: hidden; }

.magazine-next-cons img {
	transition: all 0.3s ease-in-out; }

.magazine-next-cons:hover img {
	transform: scale(1.1);
	transition: all 0.3s ease-in-out; }

.mag-hot-post-long {
	box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
	overflow: hidden;
	margin-bottom: 30px;
	img {
		float: left;
		width: 50%; } }

.mag-hot-post-long.img-fullwidth > img {
	width: 100%; }

.mag-hot-post {
	.mhp-icon {
		i {
			color: #fff;
			background: #2a2a2a;
			padding: 7px;
			border-radius: 51px;
			width: 32px;
			height: 32px;
			text-align: center;
			padding-left: 11px; }

		> a {
			background: #dc9c0a;
			color: #fff;
			padding: 3px 11px;
			border-radius: 5px;
			margin-top: 4px;
			display: inline;
			font-size: 14px;
			text-transform: capitalize;
			margin-left: 10px; } }
	h2 {
		margin-top: 20px;
		line-height: 32px;
		a {
			font-size: 22px;
			color: #363636;
			font-family: $font_heading;
			line-height: 32px; } }

	p {
		font-size: 16px;
		color: #363636;
		font-family: $font_heading; }


	.mhp-footer {
		img {
			width: 32px;
			height: 32px;
			border-radius: 50px;
			margin-right: 20px; }
		span {
			font-size: 16px;
			color:  #696969;
			font-family: $font_heading; }

		i {
			color: #ddb80a;
			background: none; } } }

.mag-hot-post {
	overflow: hidden;
	padding-top: 35px;
	padding-left: 25px;
	padding-right: 15px; }

.mhp-footer {
	border-top: 1px dashed #ddd;
	padding-top: 24px;
	margin-right: 30px;
	padding-bottom: 23px; }
.bg-two {
	background: #e43a90!important; }


@media (min-width: 1200px) and (max-width: 1500px) {
	.magazine-post-widget > a {
		float: left;
		width: 84px; }

	.mag-post-content h2 {
		line-height: 15px;
		padding: 0px 0 5px 20px; }

	.magazine-widget .mag-bn p {
		color: #fff;
		font-weight: 700;
		font-size: 18px; }
	.mag-tag-widget ul li a {
		color: #fff;
		padding: 4px 11px;
		display: block;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 14px; }

	.hp-content p a {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px; }

	.mag-show-content h2 a {
		font-size: 16px; }

	.mag-show-content {
		padding: 9px 0 17px; }

	.mag-show-content span {
		font-size: 14px; }

	.mags-post-content > a {
		font-size: 16px;
		padding: 2px 8px; }

	.mags-post-content h4 a {
		font-size: 20px;

		line-height: 28px;
		padding-right: 50px; }

	.magazine-post.mid .mags-post-content {
		left: 10px; }

	.mid .mags-post-content > a {
		font-size: 15px;
		padding: 4px 10px; }

	.mid .mags-post-content h4 a {
		font-size: 18px;
		line-height: 28px; }

	.magazine-post-small > a {
		display: none; }

	.mag-post-content-small h2 a {
		font-size: 16px; }

	.tab-content .mag-show-content > a {
		margin-right: 18px; }

	.tab-content .mag-show-content h2 {
		padding: 5px 8px; }

	.tab-content .mag-show-content span {
		padding: 5px 9px; }

	.magazine-artist-info .aritist-music .content span {
		font-size: 16px; }


	.magazine-artist-info .aritist-music .content p {
		font-size: 22px;
		line-height: 20px; }

	.magazine-artist-info .aritist-music .icon i {

		font-size: 17px;
		line-height: 12px; }

	.aritist-music {
		margin-bottom: 4px; }

	.mag-artist-content {
		left: 115px; }

	.mag-post-vid-img {
		display: none; }

	.magazine-post-small-video .mag-post-content-small {
		padding-bottom: 4px; }

	.concert-single img {
		display: none; }

	.single-concert .sin-concert-content span {
		font-size: 13px; }

	.single-concert .sin-concert-content h4 a {
		font-size: 16px;
		line-height: 16px; }

	.sin-concert-content {
		padding: 14px 10px 0px 15px; }

	.concert-single {
		padding: 13px 12px 10px 1px; }

	.concer-sin-content > a {
		padding: 2px 8px;
		font-size: 12px; }
	.magazine-next-cons-content h2 a {
		font-size: 16px; }

	.magazine-next-cons-content span {
		font-size: 14px; }

	.concer-sin-content h2 {
		line-height: 16px; }
	.concer-sin-content h2 a {
		font-size: 16px; }

	.magazine-next-cons {
		margin-bottom: 20px; }
	.magazine-post-small {
		margin-bottom: 15px;
		padding-bottom: 5px; }
	.magazine-post-small-video .mag-post-content-small h2 a {
		font-size: 16px; }

	.mag-hot-post-long.big-one img {
		display: none; }

	.mag-hot-post h2 {
		margin-top: 10px;
		line-height: 1.2rem; }

	.mag-hot-post h2 a {
		font-size: 16px;
		line-height: 16px; }

	.mag-hot-post .mhp-icon i {
		padding: 4px;
		width: 21px;
		height: 21px;
		padding-left: 7px;
		font-size: 13px; }
	.mag-hot-post .mhp-footer img {
		display: none; }

	.mhp-footer {
		padding-top: 10px;
		padding-bottom: 15px; }
	.mag-post-content h2 a {
		font-size: 16px; }
	.mag-twitter-widget .single-twitter p {
		font-size: 14px;
		line-height: 26px; }

	.magazine-widget {
		margin-bottom: 30px; }

	.magazine-post-small-video .mag-post-content-small span {
		padding: 5px 14px;
		font-size: 14px; }

	.magazine-post-small-video .mag-post-content-small h2 {
		padding: 5px 0 5px 12px; }

	.mag-post-content-small span {
		font-size: 14px; } }





@media (min-width: 992px) and (max-width: 1199px) {

	.mheader-top p {
		padding-top: 0px;
		width: 60%;
		line-height: 21px; }
	.hp-content p {
		line-height: 20px; }
	.hp-content p a {
		font-size: 15px; }

	.hp-content a {
		font-size: 14px; }
	.hp-content {
		top: 50px; }
	.magazine-post-small img {
		width: 93px; }
	.mag-show-content > a {
		margin-right: 20px; }
	.mag-show-content h2 a {
		font-size: 16px; }
	.mag-show-content span {
		font-size: 14px; }
	.mag-show-content {
		padding: 5px 0 10px; }
	.magazine-artist-info .aritist-music .content span {
		font-size: 16px; }


	.magazine-artist-info .aritist-music .content p {
		font-size: 22px;
		line-height: 20px; }

	.magazine-artist-info .aritist-music .icon i {

		font-size: 17px;
		line-height: 12px; }

	.aritist-music {
		margin-bottom: 4px; }
	.magazine-artist-info {
		width: 80px; }
	.mag-artist-content {
		left: 90px; }
	.mag-bn p {
		font-size: 20px; }
	.mag-bn {
		padding: 8px 33px; }
	.magazine-post-small-video:last-child {
		display: none; }
	.mag-post-vid-img img {
		width: 102px; }
	.concert-single img {
		display: none; }
	.single-concert .sin-concert-content span {
		font-size: 14px; }
	.single-concert .sin-concert-content h4 a {
		font-size: 17px; }
	.concert-single {
		padding: 13px 15px 8px 0px; }
	.sin-concert-content {
		padding: 14px 15px 0px 20px; }

	.magazine-next-cons-content span {
		font-size: 14px; }
	.magazine-next-cons-content h2 a {
		font-size: 17px; }

	.magazine-widget {
		width: 50%;
		float: left;
		padding-right: 15px; } }


@media (min-width: 768px) and (max-width: 991px) {

	.mheader-top p {
		font-size: 13px;
		width: 50%;
		margin-top: -8px;
		padding-top: 0; }
	.headr-post {
		width: 50%; }

	.magazine-artist-info .aritist-music .icon i {
		font-size: 30px; }
	.magazine-artist-info .aritist-music .content p {
		font-size: 26px; }
	.aritist-music {
		margin-bottom: 0; }
	.magazine-artist-info {
		padding-top: 10px; }

	.magazine-next-cons {
		width: 50%;
		float: left;
		padding: 15px; }
	.magazine-post-small-video:last-child {
		margin-bottom: 30px; }
	.magazine-widget {
		width: 50%;
		float: left;
		padding-right: 15px; } }

@media (min-width: 576px) and (max-width: 767px) {
	.mheader-top p {
		font-size: 14px;
		width: 72%;
		line-height: 24px; }
	.mheader-top span {
		padding: 3px 7px;
		border-radius: 5px 0px 0px 5px;
		margin-right: 11px;
		margin-top: 7px; }
	.mheader-top .mag-social {
		float: none;
		text-align: center;
		margin-top: 14px;
		display: inline-block;
		width: 100%; }
	.headr-post {
		width: 50%; }
	.hp-content p a {
		font-size: 16px; }
	.hp-content p {
		line-height: 24px; }

	.hp-content {
		top: 85px; }

	.magazine-artist-info .aritist-music .content span {
		font-size: 16px; }


	.magazine-artist-info .aritist-music .content p {
		font-size: 22px;
		line-height: 20px; }

	.magazine-artist-info .aritist-music .icon i {

		font-size: 17px;
		line-height: 12px; }

	.aritist-music {
		margin-bottom: 4px; }
	.magazine-artist-info {
		width: 80px; }
	.mag-artist-content {
		left: 90px; }
	.magazine-artist-info {
		padding-top: 10px; }
	.magazine-post-small-video:last-child {
		margin-bottom: 30px; }
	.mag-hot-post-long img {
		float: none;
		width: 100%; }
	.mag-show-widget .magazine-show-widget:last-child {
		margin-bottom: 30px; }
	.magazine-sidebar .magazine-show-widget {
		width: 45%;
		margin: 0 2%;
		margin-bottom: 30px;
		float: left; }
	.magazine-next-cons {
		width: 50%;
		float: left;
		padding: 0 15px;
		margin-bottom: 15px; }
	.magazine-post-small:last-child {
		margin-bottom: 22px; }
	.magazine-post.mid {
		width: 50%;
		float: left; } }





@media (max-width: 575px) {

	.magazine-artist-info .aritist-music .content span {
		font-size: 16px; }
	.magazine-artist-info .aritist-music .content p {
		font-size: 22px;
		line-height: 20px; }
	.magazine-artist-info .aritist-music .icon i {
		font-size: 17px;
		line-height: 12px; }
	.aritist-music {
		margin-bottom: 4px; }
	.magazine-artist-info {
		width: 80px; }
	.mag-artist-content {
		left: 90px; }
	.magazine-artist-info {
		padding-top: 35px; }
	.headr-post {
		width: 50%; }
	.hp-content p a {
		font-size: 16px; }
	.hp-content p {
		line-height: 24px; }

	.hp-content {
		top: 75px; }
	.magazine-post-small:last-child {
		margin-bottom: 30px; }
	.magazine-post-small-video:last-child {
		margin-bottom: 30px; }
	.mag-hot-post-long img {
		float: none;
		width: 100%; }
	.mag-show-content h2 {
		padding: 5px 11px; }
	.mag-show-content span {
		font-size: 13px;
		padding: 5px 11px; }

	.mag-show-content > a {
		margin-right: 23px; }
	.mag-header-inner h2 {
		font-size: 45px;
		line-height: 50px;
		margin-top: 9px; } }


@media (min-width: 420px) and (max-width: 575px) {

	.magazine-next-cons {
		width: 80%;
		margin: 0 auto; }
	.mags-post-content {
		bottom: 0px;
		left: 13px; }
	.concert-single img {
		width: 20%; } }



@media (max-width: 460px) {
	.hp-content {
		top: 7px; } }























