#blog-grid {
	background: #f8f8f8;

	&.section-padding {
		padding-bottom: 90px; } }

.blog-post-grid {
	background: #FFF;
	max-width: 400px;
	margin: 0 auto 30px;

	img {
		max-width: 100%;
		height: auto; }

	.entry-date {
		font-size: 28px;
		font-weight: 700;
		color: #fff;
		background: $color_primary;
		padding: 10px;
		text-align: center;
		height: 80px;
		width: 80px;
		display: inline-block;
		transform: translate(-10px, -90px);
		position: absolute;
		font-family: $font_heading;
		text-transform: uppercase;

		span {
			font-size: 16px;
			font-style: normal; } }

	.share-link {
		float: left;
		list-style: none;
		text-align: center;
		padding: 0 20px;

		li {
			a {
				color: #363636;
				font-size: 17px;
				line-height: 28px; } } }

	.entry-content {
		padding: 30px 25px;

		.entry-header {
			.entry-title {
				font-size: 20px;
				font-weight: 500;
				line-height: 26px;
				margin-bottom: 10px;

				a {
					color: #363636;

					&:hover {
						color: $color_primary; } } } }

		.read-btn {
			font-family: $font_heading;
			color: #434343;
			font-size: 16px;
			line-height: 18px;
			font-weight: 500;
			position: relative;
			display: inline-block;
			padding: 8px 15px 8px 0;

			&:hover {
				color: $color_primary; } } }

	.entry-meta {
		border-top: 1px solid #e4e4e4;
		padding: 10px 20px;

		a {
			color: #5d5a5a;
			font-size: 16px;

			i {
				margin-right: 5px;
				color: #5d5a5a; }

			span {
				color: #5d5a5a; }

			&:hover {
				color: $color_primary; } }

		.comments {
			margin-left: 30px;
			position: relative;
			float: right; } }

	.post-type-icon {
	    height: 50px;
	    width: 50px;
	    text-align: center;
	    display: block;
	    position: relative;
	    z-index: 111;
	    font-size: 20px;
	    font-weight: 700;
	    color: #fff;
	    line-height: 48px;
	    transform: translate(7px, -70px);
	    position: absolute;
	    background: $color_primary;

	    span {
	        display: block;
	        font-size: 16px; }

	    &:before, &:after {
	    	display: none; } } }


.post-type-icon {
	height: 65px;
	width: 65px;
	background-color: transparent;
	transform: translate(27px, -50px);
	font-size: 25px;
	line-height: 60px;
	display: inline-block;
	text-align: center;
	color: #fff;
	position: absolute;
	z-index: 11;

	&:before {
		position: absolute;
		content: '';
		height: 65px;
		width: 65px;
		background: #e43a90;
		left: 0;
		top: 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		border-radius: 4px;
		z-index: -1; }

	&:after {
		position: absolute;
		content: '';
		border: 2px solid #fff;
		height: 100%;
		width: 100%;
		border-radius: 4px;
		left: 0;
		top: 0;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg); } }

/* Blog List */
.blog-posts {
	padding: 100px 0; }

.blog-post {
	border-radius: 20px;
	background: #fff;
	box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
	margin-bottom: 30px;
	overflow: hidden;

	.post-thumbnail {
		a {
			display: block;

			img {
				max-width: 100%;
				border-top-left-radius: 20px;
				border-top-right-radius: 20px; } } }


	&.blog-grid {
		.entry-content-wrapper {
			padding: 25px 30px; } }

	.tim-btn-bgt {
		&.read-more-btn {
			border-color: #e4e4e4;
			color: #363636;

			&:hover {
				color: $color_primary; } } } }


.entry-content-wrapper {
	padding: 30px;
	position: relative;
	background: #fff;

	ul {
		margin: 0 0 20px;
		padding: 0;
		list-style: none;
		display: block;
		clear: both;

		li {
			float: left;
			width: 50%;
			padding-left: 20px;
			position: relative;
			line-height: 30px;

			&:before {
				position: absolute;
				content: '';
				left: 0;
				top: 9px;
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #464646; } } }

	.entry-meta {
		ul.meta {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				margin-right: 5px;
				font-family: $font_heading;
				width: auto;
				padding-left: 0;
				float: none;

				&:before {
					display: none; }

				a {
					color: #717171;

					&:hover {
						color: $color_primary; } }

				.author {

					a {
						color: $color_primary; } } } } }

	.entry-header {
		.entry-title {
			font-size: 26px;
			font-weight: 500;

			a {
				color: #363636;

				&:hover {
					color: $color_primary; } } } }
	.blog-footer {
		border-top: 1px dashed #e0e0e0;
		padding-top: 30px;
		margin-top: 80px;

		.tag {
			a {
				padding: 0 12px;
				background: #363636;
				font-size: 15px;
				line-height: 28px;
				border-radius: 4px;
				display: inline-block;
				color: #fff;
				margin-right: 5px;

				&:hover {
					background: $color_primary;
					color: #fff; } } } }

	.entry-content {
		margin-bottom: 30px; } }

.post-on {
	position: absolute;
	height: 65px;
	width: 65px;
	text-align: center;
	transform: translate(27px, -49px);
	padding: 12px 0;
	line-height: 20px;

	&:before {
		position: absolute;
		content: '';
		background: rgba($color_primary,0.9);
		border: 3px solid #e2dfe2;
		border-radius: 4px;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		transform: rotate(-45deg); }


	a {
		font-size: 24px;
		font-weight: 700;
		text-align: center;
		font-family: $font_heading;
		height: 65px;
		width: 65px;
		z-index: 1;
		position: relative;
		color: #fff;

		span {
			display: block;
			font-size: 15px; } } }


ul.shared-link {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline-block;
	clear: inherit;

	li {
		height: 40px;
		width: 40px !important;
		position: relative;
		z-index: 1;
		text-align: center;
		line-height: 40px;
		float: none;
		width: auto;
		padding-left: 0;
		display: inline-block;
		margin-right: 15px;

		&:before {
			content: '';
			position: absolute;
			height: 40px;
			width: 40px;
			border: 4px solid #ebebeb;
			z-index: -1;
			left: 0;
			top: 0;
			transform: rotate(-45deg);
			box-shadow: 0px 5px 17.22px 3.78px rgba(180, 180, 180, 0.21);
			border-radius: 4px;
			transition: all 0.3s ease-in-out;
			background-color: transparent; }

		a {
			color: #717171;
			display: block;
			line-height: 40px; }

		&:hover {
			&:before {
				background: $color_primary;
				border-color: $color_primary; }

			a {
				color: #fff; } } } }

blockquote {
	color: #474747;
	background: #f6f6f6;
	padding: 30px 30px 30px 60px;
	font-size: 20px;
	font-style: italic;
	position: relative;
	font-weight: 500;

	.quote-icon {
		position: absolute;
		left: 35px;
		top: 30px;
		color: #c5c5c5; } }


.blog-count-details {
	padding: 7px 0;

	a {
		color: #666;
		margin-right: 15px;
		position: relative;
		font-weight: 600;

		&:after {
			position: absolute;
			content: '';
			height: 12px;
			width: 1px;
			background: #bbb;
			right: -10px;
			top: 5px; }

		&:last-child {
			&:after {
				display: none; } }

		i {
			margin-right: 5px;
			color: $color_primary; } } }


.blog-post-list {
	border-radius: 20px;
	overflow: hidden;
	margin-bottom: 30px; }

/* Comment */
.comments-area {
	ul {
		margin: 0;
		list-style: none;

		ul {
			margin-left: 50px; } } }

.comment-inner {
	background: #fff;
	box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
	border-radius: 25px;
	padding: 60px 40px;
	margin-bottom: 30px; }

.reply-title h3, .comment-reply-title {
	font-size: 26px;
	font-weight: 600;
	color: #3c3c3c;
	margin-bottom: 40px;
	text-align: center;
	text-transform: uppercase; }

ul.comment-list {


	.comment-body {
		position: relative;
		margin-bottom: 40px;

		.comment-author {
			a {
				font-size: 24px;
				color: $color_primary;
				font-weight: 500;
				font-family: $font_heading; }

			.author-thumb {
				height: 110px;
				width: 110px;
				border-radius: 50%;
				border: 6px solid #ffffff;
				box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
				float: left;

				img {
					max-width: 100%;
					border-radius: 50%; } }

			.comment-metadata {
				a {
					font-size: 15px;
					color: #979696; } } }

		.author-name {
			margin-bottom: 10px; }

		.comment-content {
			p {
				margin-bottom: 0; } }

		.author-name, .comment-content {
			margin-left: 130px; }

		.reply {
			position: absolute;
			top: 0;
			right: 0;

			a {
				color: #363636;
				font-weight: 500;
				font-family: $font_heading;
				transition: all 0.3s ease-in-out;
				font-size: 18px;

				i {
					margin: 5px; }

				&:hover {
					color: $color_primary; } } } } }

.comment-respond {
	background: #fff;
	box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
	border-radius: 25px;
	padding: 60px 40px; }

.comment-form {

	&:after {
		content: '';
		clear: both;
		display: block; }
	p.comment-form-comment {
		width: 100%;

		textarea {
			width: 100%;
			background: #fafafa;
			height: 170px;
			border: 1px solid #ededed;
			box-shadow: none;
			border-radius: 25px;
			padding: 20px;
			outline: 0;
			transition: all 0.5s ease-in-out;

			&:hover, &:focus {
				border-color: #a59a9a; } } }
	p {
		width: 33.33%;
		float: left;
		padding-right: 20px;

		input {
			width: 100%;
			height: 50px;
			border-radius: 15px;
			padding: 10px 20px;
			border: 1px solid #ededed;
			outline: 0;
			transition: all 0.5s ease-in-out;

			&:hover, &:focus {
				border-color: #a59a9a; }

			&::placeholder {
				color: #838383; } } }

	.form-submit {
		width: 100%;
		padding-right: 0;
		text-align: center;
		margin-top: 30px;

		.submit {
			border: 1px solid #e4e4e4;
			padding: 10px 20px;
			color: #363636;
			border-radius: 5px;
			font-size: 18px;
			font-weight: 500;
			max-width: 200px;
			text-transform: uppercase;
			font-family: $font_heading;
			transition: all 0.3s ease-in-out;
			cursor: pointer;
			background: transparent;
			&:hover {
				color: $color_primary; } } } }



/* Post Navigation */
.posts-navigation {
	ul {
		margin: 60px 0 0;
		padding: 0;
		list-style: none;

		li {
			height: 35px;
			width: 35px;
			border: 1px solid #e1e1e1;
			border-radius: 3px;
			display: inline-block;
			vertical-align: middle;
			line-height: 34px;

			a {
				font-size: 18px;
				font-weight: 500;
				color: #a5a4a4;
				line-height: 18px; }

			&:hover, &.active {
				a {
					color: $color_primary; } }


			&:first-child, &:last-child {
				height: auto;
				width: auto;
				padding: 7px 20px;
				line-height: 18px; } } } }


@media (min-width: 992px) and (max-width: 1199px) {

	.blog-post-grid {
		max-width: 100%; } }



@media (min-width: 768px) and (max-width: 1024px) {
	.blog-post {
		&.blog-grid {
			.entry-content-wrapper {
				padding: 25px 20px;

				.entry-header {
					.entry-title {
						font-size: 20px; } } } } }

	.widget {
		padding: 20px 15px; } }

@media (max-width: 768px) {
	.blog-post-grid {
		.share-link {
			padding: 0 10px; }

		.entry-content {
			padding: 30px 15px; }

		.entry-meta {
			padding: 10px 15px; } }

	.comment-form {
		p {
			width: 100%;
			padding-right: 0; } }


	.comment-respond {
		margin-bottom: 30px; }

	.widget_popular_entries {

		.widgets-popular-post {
			li {
				max-width: 350px; } } }

	.blog-posts {
		padding: 50px 0; } }



@media (max-width: 500px) {
	.blog-post {
		.post-type-icon {
			height: 50px;
			width: 50px;
			line-height: 48px;
			font-size: 22px;
			transform: translate(24px, -33px);

			&:before {
				height: 50px;
				width: 50px; } } }

	.entry-content-wrapper {
		padding: 30px 20px;

		.blog-footer {
			text-align: center; }

		ul {
			li {
				width: 100%;
				line-height: 28px; } } }

	.blog-count-details {
		&.float-left {
			float: none !important;
			margin-bottom: 10px; } }



	ul {

		&.shared-link {
			position: relative;
			top: auto;
			left: auto;
			margin-bottom: 20px;

			li {
				display: inline-block;
				margin-right: 15px;
				margin-bottom: 0; } } }


	blockquote {
		padding: 30px 30px 30px 45px;

		.quote-icon {
			left: 15px;

			.entry-content-wrapper {} } }

	ul.shared-link {
		margin: 0;

		&.float-right {
			float: none !important; } }

	ul.comment-list {
		.comment-body {
			text-align: center;

			.comment-author {
				.author-thumb {
					float: none;
					margin: 0 auto; } }

			.author-name, .comment-content {
				margin-left: 0; }

			.reply {
				position: static; } } }

	#blog-grid.section-padding {
		padding: 50px 0 20px; }

	#blog-grid {
		&.section-padding {
			padding-bottom: 20px!important; } }


	.posts-navigation ul li {
		margin-top: 15px; } }


