#event-schedule {
	display: flex;
	padding-bottom: 100px; }



.schedule-ticket {
	position: relative;
	max-width: 32%;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;


	img {
		max-width: 100%; }

	.content {
		position: absolute;
		top: 50%;
		left: 20%;
		transform: translateY(-50%);


		p {
			font-size: 18px;
			font-weight: 600;
			font-family: $font_heading;
			color: #fff;
			margin: 0; }

		h3 {
			font-size: 36px;
			font-weight: 600;
			color: #fff;
			text-transform: uppercase;
			margin-bottom: 20px; }

		.tim-btn {
			padding: 10px 30px;
			text-transform: uppercase;
			color: #fff;
			border-color: #fff;

			&:hover {
				color: $color_primary; } } } }

.schedule {
	width: 68%;
	float: left;

	.schedule-item {
		text-align: center;
		padding: 41px 0;
		border: 1px solid #ededed;
		border-left: 0;

		.schedule-thumb {
			height: 125px;
			width: 125px;
			margin: 0 auto;
			border-radius: 50%;
			margin-bottom: 20px;

			img {
				width: 100%;
				border-radius: 50%; } }

		.sch-time {
			font-size: 24px;
			color: #666666;
			font-weight: 600; }

		.band-name {
			font-size: 18px;
			font-weight: 600;
			color: $color_primary;
			position: relative;
			padding-top: 10px;

			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 50%;
				width: 50px;
				height: 2px;
				background: $color_primary;
				transform: translateX(-50%); } }

		p {
			color: #747373;
			margin: 0; } } }

@media (min-width: 1280px) and (max-width: 1440px) {
	.schedule {
		.schedule-item {
			p {
				font-size: 14px; } } }

	.schedule-ticket {

		.content {
			left: 10%; } } }

@media (min-width: 1024px) and (max-width: 1439px) {

	.schedule-ticket {

		.content {
			left: 10%; } } }

@media (max-width: 1024px) {
	#event-schedule {
		display: block;
		background: #f3f3f3; }

	.schedule {
		width: 100%; }

	.schedule-ticket {
		display: block;
		max-width: 100%;
		margin: 0 auto; } }

@media (max-width: 768px) {
	#event-schedule {
		padding-bottom: 50px; } }

@media (max-width: 350px) {

	.schedule-ticket .content {
		top: 7px;
		left: 19px;
		-webkit-transform: translateY(0);
		-ms-transform: translateY(0);
		transform: translateY(0); }

	.schedule-ticket .content h3 {
		font-size: 25px;
		margin-bottom: 8px; } }


@media (max-width: 500px) {
	.schedule-ticket {
		.content {
			h3 {
				font-size: 30px; } } } }
