.show-archive {
	padding-bottom: 50px; }


.show-archive-wrapper {
	width: 78%;
	float: right;
	margin-top: -140px;
	div {
		padding: 0; } }

.video-btn-three {
	height: 60px;
	width: 60px;
	border: 2px solid #f60038;
	border-radius: 4px;
	color: #f60038;
	line-height: 65px;
	display: block;
	text-align: center; }


.video-btn-three i {
	margin-left: 3px;
	color:  #f60038;
	font-size: 30px;
	transition: all .8s cubic-bezier(0,1.09,1,1); }

.video-btn-three:hover i {
	color: #fff;
	transition: all .8s cubic-bezier(0,1.09,1,1); }


.single-show-archive {
	position: relative;
	> a {
		color: #fff;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate3d(-14px, 55px, 0);
		font-size: 21px;
		transition: all .8s cubic-bezier(0,1.09,1,1);
		opacity: 0; }
	.video-player {
		position: absolute;
		left: 0px;
		bottom: 40px;
		transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
		transform: translate3d(-25px,0, 0);
		.text {
			position: absolute;
			left: 80px;
			top: 2px;
			width: 255px;
			p {
				font-size: 20px;
				color: #fff;
				margin: 0;
				font-family: $font_heading; }
			span {
				font-size: 14px;
				color: #fff;
				font-family: $font_heading; } } }
	&:hover {
		> a {
			transform: translate3d(-14px, -14px, 0);
			transition: all .8s cubic-bezier(0,1.09,1,1);
			opacity: 1;
			delay: .5s; } } }


.video-btn-shade {
	height: 60px;
	width: 60px;
	border: 2px solid #000;
	border-radius: 4px;
	color: #f60038;
	line-height: 65px;
	display: block;
	text-align: center;
	position: absolute;
	left: -7px;
	top: 7px;
	z-index: -1;
	filter: blur(2.6px);
	opacity: 0.4;
	i {
		color: #000;
		font-size: 25px; } }

.single-show-archive::before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: #f60038;
	opacity: 0;
	transition-duration: 0.7s; }

.single-show-archive:hover:before {
	opacity: .8;
	transition-duration: 0.7s; }

.single-show-archive:hover .video-player {
	left: 0;
	transform: translate3d(40px,0, 0);
	transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); }

.single-show-archive.video-archive:before {
	display: none; }


@media (min-width: 768px) and (max-width: 991px) {
	.show-archive-wrapper {
		width: 90%;
		float: right;
		margin-top: 0; } }


@media (max-width: 767px) {
	.show-archive-wrapper {
		width: 100%;
		float: right;
		margin-top: -15px; }

	.single-show-archive .video-player {
		transform: translate3d(15px, 0, 0); }
	.single-show-archive:hover .video-player {

		transform: translate3d(20px, 0, 0); }

	.show-archive {
		padding-top: 50px; } }

