#tranding-album, #tranding-album-two {
	padding: 120px 0 90px; }

#tranding-album-two {
	padding: 120px 0 70px; }

.album-box {
	position: relative;
	margin-bottom: 30px;
	overflow: hidden;
	max-width: 530px;

	.box-thumb {
		&:before {
			position: absolute;
			top: 0;
			left: -75%;
			z-index: 2;
			display: block;
			content: '';
			width: 50%;
			height: 100%;
			background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
			-webkit-transform: skewX(-25deg);
			transform: skewX(-25deg); } }

	img {
		max-width: 100%;
		transition: all 0.3s ease-in-out;
		transform-origin: 100% 100%; }

	.album-details {
		background: rgba(0,0,0,0.9);
		position: absolute;
		bottom: 0;
		padding: 22px 40px;
		width: 100%;
		transition: all 0.3s ease-in-out;
		z-index: 222;

		.content {
			display: inline-block;
			float: left;

			p {
				color: #fff;
				font-size: 16px;
				line-height: 16px;
				margin-top: 8px;
				margin-bottom: 0; }

			.album-name {
				font-size: 20px;
				font-weight: 700;
				position: relative;
				padding-bottom: 8px;
				line-height: 24px;
				margin: 0;
				transition: all 0.3s ease-in-out;

				a {
					color: #fff;

					&:hover {
						color: rgba(255,255,255,0.8); } }

				&:before,
				&:after {
					position: absolute;
					content: '';
					bottom: 0;
					left: 0;
					background: $color_primary;
					transition: all 0.3s ease-in-out; }

				&:before {
					width: 100px;
					height: 1px; }

				&:after {
					width: 30px;
					height: 3px;
					bottom: -1px;
					left: 15px; } } }

		.icon {
			text-align: right;
			height: 50px;
			position: relative;
			// border-left: 2px solid $color_primary
			margin-top: 3px;
			transition: all 0.3s ease-in-out;
			padding-left: 15px;

			i {
				font-size: 50px;
				line-height: 42px;
				color: $color_primary;
				transition: all 0.3s ease-in-out; } } }

	&:hover {
		.box-thumb {
			&:before {
				-webkit-animation: shine .75s;
				animation: shine .75s; } }


		img {
			transform: scale(1.05); }

		.album-details {
			background: rgba($color_primary, 0.97);

			.content {
				.album-name {
					&:before,
					&:after {
						background: #fff; } } }

			.icon {
				border-color: #fff;

				i {
					color: #fff; } } } }

	&.album-box-two {
		.box-thumb {
			position: relative;
			box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
			border-radius: 4px;
			overflow: hidden;
			img {
				width: 100%; }

			.icon {
				position: absolute;
				bottom: 0;
				right: 0;
				height: 65px;
				width: 65px;
				line-height: 65px;
				background: rgba(255,255,255,0.5);
				text-align: center;

				i {
					font-size: 30px;
					color: #fff; } } }

		.album-details {
			position: static;
			background: transparent !important;
			padding: 20px 0;

			.content {
				h3 {
					a {
						color: #444;

						&:hover {
							color: $color_primary; } }

					&:before,
					&:after {
						background: #000; } }

				p {
					color: #444; } } }


		&:hover {
			background: transparent;

			.content {
				.album-name {
					&:before,
					&:after {
						background: $color_primary !important; } }

				p {
					color: #444; } } } } }

.tab-top-music {
	background: url(../../media/background/13.jpg); }

.hover14 figure::before {
	position: absolute;
	top: 0;
	left: -75%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg); }

.hover14 figure:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s; }

@-webkit-keyframes shine {
	100% {
		left: 125%; } }


@keyframes shine {
	100% {
		left: 125%; } }




@media (max-width: 1280px) {
	.album-box {
		.album-details {
			padding: 15px 30px;

			.icon {
				i {
					font-size: 40px; } } } } }

@media (max-width: 768px) {
	#tranding-album-two {
		padding: 50px 0 20px; }

	#tranding-album {
		padding: 50px 0 20px; } }



@media (min-width: 500px) and (max-width: 768px) {
	.album-box {
		.album-details {
			padding: 10px 15px;

			.content {
				.album-name {
					font-size: 18px;
					font-weight: 600; }

				p {
					font-size: 14px; } }

			.icon {
				height: 35px;
				margin-top: 10px;
				padding-left: 10px;

				i {
					font-size: 35px;
					line-height: 30px; } } } } }

@media (max-width: 500px) {

	.album-box .album-details .content .album-name {
		font-size: 17px;
		font-weight: 400; } }
