.header-four {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;
	.user-login {
		float: right;
		margin-top: 27px;
		> li {
			&:after {
				display: none; } }
		li {
			> a {
				color: #fff;

				i {
					color: #fff; }

				&:hover {
					color: $color_primary; } }

			&.cart-count {
				.badge {
					color: #fff; } } } }

	.header-inner {
		background: transparent; }
	.nav {
		display: inline-block;
		float: right;
		margin-right: 50px; } }


.header-four .user-login .search-input-wrapper {
	position: absolute;
	right: 5px;
	top: 80px;
	background: $color_primary;
	height: 0;
	width: 320px;
	overflow: hidden;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	opacity: 1;
	z-index: 1; }

.header-four .user-login .search-input-wrapper.active {
	height: 60px; }

.header-four .user-login .search-input-wrapper input {
	display: block;
	border: 1px solid $color_primary;
	border-radius: 2px;
	padding: 5px;
	width: 300px;
	margin-left: 10px;
	margin-top: 10px; }


/* Top Header Right With Mini Cart */
.header-four {
	.user-login {
		list-style: none;

		> li {
			display: inline-block;
			position: relative;
			margin-right: 15px;
			font-family: $font_heading;
			padding: 10px 0; }
		li {
			&.cart-count {
				position: relative;

				.badge {
					position: absolute;
					top: 10px;
					left: 8px;
					background: #ffff;
					border-radius: 50%;
					line-height: 16px;
					width: 17px;
					font-size: 11px;
					height: 17px;
					display: block;
					padding: 0;
					font-weight: 500;
					text-align: center;
					color: $color_primary; }

				.cart-overview {
					opacity: 0;
					visibility: hidden;
					z-index: 101;
					line-height: 1.8;
					position: absolute;
					background: #FFF;
					text-align: left;
					width: 350px;
					padding: 15px;
					margin-top: 0;
					right: 0;
					top: 140%;
					transition: all 0.3s ease-in-out;

					.cart-item {
						margin-bottom: 10px;
						display: block;
						position: relative;
						margin-right: 0;
						padding: 0;

						.product-thumbnail {
							margin-right: 1.3em;
							float: left;
							padding: 0 !important;
							display: inline-block;
							width: 70px;

							img {
								max-width: 100%; } }


						.product-details {
							position: relative;
							margin-left: 70px;

							.product-title {
								font-size: 16px;
								font-weight: 600;
								color: #000;
								display: block;
								text-decoration: none;


								&:hover {
									color: $color_primary; } }

							.product-remove {
								position: absolute;
								right: 0;
								top: 42%;
								height: 18px;
								width: 18px;
								font-size: 6px;
								background: #3e3e3e;
								color: #FFF;
								border-radius: 50%;
								text-align: center;
								line-height: 18px;
								color: #f3f3f3;
								transition: all 0.3s ease-in-out;

								&:hover {
									background: #444;
									color: #FFF; } }

							.product-quantity {
								display: inline-block; } } }

					.cart-subtotal {
						display: block;
						padding: 15px 0;
						border-top: 1px solid #dcdcdc;
						border-bottom: 1px solid #dcdcdc;
						margin-top: 15px;
						font-size: 14px;
						font-weight: 600;
						color: #000;
						text-transform: uppercase;

						.amount {
							float: right; } }

					.cart-actions {
						display: block;
						padding-top: 20px;
						.view-cart, .checkout {
							padding: 13px 25px !important;
							text-decoration: none;
							border-radius: 30px;
							transition: all 0.3s ease-in-out;
							text-transform: uppercase;
							font-size: 16px !important;
							display: inline-block !important;
							letter-spacing: 1px;
							font-weight: 600; } } }

				.view-cart {
					color: #363636;
					border: 1px solid #363636;

					&:hover {
						background: $color_primary;
						color: #fff !important;
						border-color: $color_primary; } }

				.checkout {
					background: $color_primary;
					color: #FFF !important;
					float: right;
					border: 1px solid $color_primary;

					&:hover {
						background: darken($color_primary, 10%); } } }
			&:hover {
				.cart-overview {
					opacity: 1 !important;
					top: 100% !important;
					visibility: visible !important; } }

			&:last-child {
				margin-right: 0;

				&:after {
					display: none; } }


			a {
				font-size: 15px;
				color: #fff;
				font-weight: 500;
				display: inline-block;
				line-height: 1;

				i {
					margin-right: 10px;
					color: #fff; } }

			&:hover {
				a {
					color: #b9b9b9;

					i {
						color: #fff; } } } } } }

.hide-topbar.headroom--pinned .user-login li a i {
	color: #363636; }
.hide-topbar.headroom--pinned  .user-login li a {
	color: #363636; }
#main-header-menu-4 {
	list-style: none;

	> li {
		position: relative;

		a {
			position: relative;
			display: inline-block;
			font-family: $font_heading;
			i {
				font-size: 13px;
				margin-left: 5px; } }



		&.menu-item-has-children {
			.sub-menu {
				text-align: left;
				position: absolute;
				padding: 10px 20px;
				width: 240px;
				background: rgba(0,0,0,0.95);
				top: 100%;
				transform: translateY(-10px);
				left: auto;
				margin: 0;
				border-top: 1px solid $color_primary;
				opacity: 0;
				visibility: hidden;
				box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
				transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
				height: auto;

				li {
					display: block;
					padding: 5px 0;
					margin: 0;

					&:last-child {
						border-bottom: 0; }

					a {
						color: #fff;
						padding: 3px 0;
						font-size: 16px;

						&:hover, &.current_page {
							color: $color_primary; } } }



				&.mega-menu {
					width: 100%;
					padding: 20px 30px;

					> li {
						width: 25%;
						float: left;

						h3 {
							font-size: 20px;
							font-weight: 600;
							color: #fff;
							margin-left: 15px; } }

					li {

						.sub-menu {
							background-color: transparent;
							border-top-color: transparent;
							float: left;
							padding: 0 15px;



							li {
								display: block;
								a {
									display: block;
									padding: 3px 0; } } }

						&:not(:last-child) {
							.sub-menu {
								border-right: 1px solid rgba(255,255,255,0.1) !important; } } } } }

			&:hover {

				.sub-menu {
					opacity: 1;
					transform: translateY(0);
					visibility: visible;
					z-index: 20;
					transition-delay: 0s, 0s, 0.3s; } } } }

	li {
		display: inline-block;
		margin: 0 20px;
		padding: 37px 0;


		a {
			color: #fff;
			text-transform: uppercase;
			font-size: 17px; } }

	> li.active {

		&:before {
			left: -6px; }

		&:after {
			right: -5px; } }

	> li.active {

		&:before, &:after {
			opacity: 1; } } }



.headroom--pinned {
	-ms-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	animation-name: headerSlideDown;

	&.hide-topbar {
		.header-inner {
			background: #FFF;
			box-shadow: 0 10px 30px rgba(0,0,0,0.05);

			#main-header-menu-4 {
				li {
					&:after, &:before {
						background: $color_primary; }
					a {
						color: #363636;

						&:hover {
							color: $color_primary; } }

					&.menu-item-has-children {
						.sub-menu {
							background: #fff; } } }

				li.active {
					> a {
						color: $color_primary; } } }

			.nav {
				.head-btn {
					border-color: #d8d1d1;
					color: #363636;

					&:hover {
						color: #fff;
						border-color: $color_primary; } } } } } }





@media (max-width: 1440px) {

	#main-header-menu-4 {
		li {
			margin: 0 14px; } } }

@media (max-width: 1199px) {

	#main-header-menu-4 > li::before {
		top: 28px; }

	#main-header-menu-4 > li::after {
		bottom: 28px; }

	#main-header-menu-4 {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } } }


@media (max-width: 1024px) {
	#main-header-menu-4 {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } } }

@media (min-width: 992px) and (max-width: 1199px) {
	.header-four .user-login {
		margin-top: 16px; } }





/*--------------------------------------------------------------
  ##   Offsetmenu
  --------------------------------------------------------------*/


.offset-menu {
	position: fixed;
	right: -380px;
	top: 0;
	width: 370px;
	background: rgba(21, 30, 64, 0.97);
	height: 100%;
	z-index: 999999;
	padding: 40px;
	transition: all .3s;
	.offset-social {
		margin-top: 40px;
		span {
			font-size: 20px;
			color: #fff;
			font-family: $font_heading;
			margin-bottom: 10px;
			display: inline-block; }
		ul {
			li {
				display: inline-block;
				margin-right: 10px;
				a {
					color: #fff;
					font-size: 15px; } } } } }

.offset-menu.open {
	right: 0;
	transition: all .3s; }


.offset-menu {
	> a {
		margin-bottom: 25px;
		display: block;
		position: absolute;
		right: 30px;
		top: 17px; }
	.footer-about {
		.footer-logo {
			max-width: 120px;
			margin-bottom: 30px;
			width: 100%;
			display: inline-block;
			margin-bottom: 15px;

			img {
				width: 100%; } }
		> p {
			color: #c8c6c6;
			line-height: 25px;
			margin-bottom: 25px; } } }
.offset-menu {
	.footer-contact {
		.contact-details {
			i {
				float: left;
				width: 30px;
				height: 40px;
				line-height: 26px;
				color: $color_primary; }


			p {
				color: #fff;
				font-size: 16px;
				margin-bottom: 5px;
				margin-left: 30px; } } }
	h3 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 22px; } }



.offset-menu {
	.footer-about {
		border-bottom: 1px solid #26325c;
		padding-bottom: 30px;
		margin-bottom: 35px; }
	.footer-blog-inner {
		.footer-blog {
			border-bottom: 1px solid #26325c;
			margin-bottom: 20px;
			padding-bottom: 20px;

			&:last-child {
				border-bottom: 0; }

			.fea-image {
				float: left;
				position: relative;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 30px;
					color: #fff; } }

			.content {
				margin-left: 116px;
				padding-top: 5px;

				h3 {
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 0;

					a {
						color: #fff;
						font-size: 16px;

						&:hover {
							color: $color_primary; } } }

				.meta {
					color: $color_primary;
					font-size: 14px;

					&:hover {
						color: $color_primary; } } } } } }



