#sale-album {
	background: #f7f7f7; }


.gallery-tab {
	.swiper-slide {
		.slider-tab {
			float: right; } } }

.slider-content {
	h3 {
		font-size: 24px;
		font-weight: 500; }

	p {
		font-size: 16px; }


	ul {
		margin-bottom: 20px;
		list-style: none;

		li {
			line-height: 30px;
			font-size: 16px;
			position: relative;
			padding-left: 35px;
			margin-bottom: 7px;

			&:before {
				position: absolute;
				content: "\f105";
				font: normal normal normal 14px/1 FontAwesome;
				left: 0;
				top: 5px;
				color: $color_primary;
				border: 1px solid #cecece;
				height: 20px;
				width: 20px;
				text-align: center;
				line-height: 18px;
				border-radius: 50%;
				padding-left: 2px; } } } }

.sale-tabs {
	position: relative;
	max-width: 1072px;
	margin: 0 auto;
	text-align: center;

	li {
		width: 25%;

		a {
			color: #5b5b5b;
			font-size: 16px;
			margin-bottom: 20px;
			margin-right: 30px;
			position: relative;
			font-family: $font_heading;

			&:before {
				position: absolute;
				content: '';
				height: 15px;
				width: 15px;
				border: 1px solid  #cdcdcd;
				background: #fff;
				border-radius: 50%;
				left: 50%;
				bottom: -7px;
				transform: translate(-50%); }

			&.active {
				&:after {
					position: absolute;
					content: '';
					height: 9px;
					width: 9px;
					background: $color_primary;
					border-radius: 50%;
					left: 50%;
					bottom: -4px;
					transform: translate(-50%); } } } } }


/* Tab Nav */
.tabnav-wrap {
	position: relative;

	&:before {
		content: '';
		width: 100%;
		height: 1px;
		background: #cdcdcd;
		position: absolute;
		bottom: 20px; } }

.tab-content {
	.tab-pane {
		padding: 50px 15px 0;

		&.active {
			animation: fadeIn .75s ease forwards; } } }


.tim-music-player {
	width: 100%;
	margin: 0; }

.tim-music-player .player {
	width: 100%;
	float: left; }



/* Track Info */
.tim-music-player {
	.track-info {
		p {
			font-family: $font_heading;
			color: #363636;
			&.title {
				margin: 0;
				font-size: 20px;
				font-weight: 600; }

			&.artist-outer {
				font-size: 16px; } } }

	.player {
		.album-cover {
			img {
				max-width: 100%; } } }

	.album-cover {
		position: relative;

		.track-info {
			position: absolute;
			left: 15px;
			bottom: 60px;

			.title {
				color: #fff;
				font-family: $font_heading;
				font-size: 18px;
				line-height: 18px;
				font-weight: 500;
				margin-bottom: 0; }

			.artist-outer {
				color: #fff; } }

		.player-controls {
			position: absolute;
			width: 100%;
			height: 60px;
			bottom: 0;

			> div {
				cursor: pointer;
				display: -webkit-flex;
				display: -moz-flex;
				display: -ms-flex;
				display: -o-flex;
				display: flex;
				justify-content: center;
				align-items: center; }

			.main {

				.play-control {
					height: 35px;
					width: 35px;
					line-height: 32px;
					text-align: center;
					border: 1px solid #fff;
					border-radius: 50%;
					margin-right: 5px; }

				.jp-stop {
					height: 25px;
					width: 25px;
					text-align: center;
					line-height: 22px;
					border-radius: 50%;
					border: 1px solid #fff;
					margin-right: 10px;

					&:before {
						content: "\f04d";
						color: #fff;
						font-size: 12px;
						font: normal normal normal 14px/1 FontAwesome; } } }

			.jp-time-holder {
				margin-right: 10px; }

			.jp-duration {
				margin-right: 10px;
				color: #fff; } } }

	.progress-wrapper {
		height: 9px;
		max-width: 140px;
		width: 100%;
		// background: transparent url(../img/player-progress.png) repeat-x 0 0 scroll
		margin: 7px 10px 5px 0;
		border-radius: 2px;
		-moz-border-radius: 2px;
		-webkit-border-radius: 2px; }


	.progress {
		height: 9px;
		width: 135px;
		cursor: pointer;
		background-color: rgba(255, 255, 255, 0.7);
		background-image: none;
		border-radius: 2px;
		-webkit-border-radius: 2px; }

	.elapsed {
		height: 9px;
		border-radius: 2px;
		-moz-border-radius: 2px;
		-webkit-border-radius: 2px;
		width: 60%;
		background: $color_primary; }

	.description {
		clear: both;
		margin: 30px 0 0 0;
		font-size: 12px;
		color: #999;
		text-shadow: 0 1px 1px rgba(0, 0, 0, .6); }

	.description.showing {
		margin: 30px 0; }

	.player {
		width: 40%;
		float: left;
		padding-right: 30px; }

	.tracklist {
		.title {
			max-width: 60%;
			width: 100%;
			display: inline-block;
			padding: 0 0 0 14px;
			cursor: pointer; } }

	.jp-volume-controls button {
		display: block;
		position: absolute;
		overflow: hidden;
		text-indent: -9999px;
		border: none;
		cursor: pointer; }

	.jp-volume-bar {
		overflow: hidden;
		background: #fff;
		width: 46px;
		height: 5px;
		cursor: pointer; }

	.jp-volume-bar-value {
		width: 0;
		height: 5px;
		background: $color_primary;
		display: block; }

	.jp-mute {
		width: 20px;
		height: 20px;
		line-height: 18px;
		position: relative;

		&:before {
			content: "\f028";
			font: normal normal normal 14px/1 FontAwesome;
			color: #fff;
			font-size: 12px; } }

	.jp-current-time {
		color: #fff;
		font-size: 12px;
		line-height: 18px; } }

.jp-state-muted {
	.jp-unmute {
		width: 20px;
		height: 20px;
		line-height: 18px;
		position: relative;
		&:after {
			content: "\f026";
			font: normal normal normal 14px/1 FontAwesome;
			color: #fff;
			font-size: 12px; } } }

.tim-music-player .rating {
	height: 16px;
	margin: 20px 0 20px 0; }


.tim-music-player .rating-star {
	width: 15px;
	height: 16px;
	display: block;
	float: left;
	cursor: pointer; }

.tim-music-player .rating-star:before {
	font-family: FontAwesome;
	content: "\f005";
	font-size: 15px;
	color: #333; }

.tim-music-player .rating-bar:before {
	font-family: FontAwesome;
	content: "\f005";
	font-size: 11px !important;
	color: #333; }

.hover:before,
.on:before {
	color: #FFFF00 !important; }



/* Player */
.tim-music-player {}


.progress {
	float: left; }


.tim-music-player {
	.previous, .next {
		color: #fff;
		&:hover {
			&:before {
				color: $color_primary; } } } }

div.jp-seeking-bg {
	background: url("../img/jplayer_seeking.gif") !important; }


.tim-music-player {
	.play {
		color: #fff; }

	.play {
		&:before {
			content: "\f102"; } }

	.pause {
		&:before {
			content: "\f101"; } }

	.play, .pause {
		&:before {
			font-family: tim-icon;
			font-size: 14px;
			color: #f5f5f5;
			margin-left: 1px; } } }



.tim-music-player .pause {
	color: #fff; }




/** Track List **/
.tim-music-player {
	.tracklist {
		padding: 0;
		border: 1px solid #ededed;
		overflow: hidden; } }

.tim-music-player {
	.tracklist {
		ol {
			margin: 0;
			padding: 0;
			overflow:hidden {} } } }

.tim-music-player {
	.tracklist {
		li {
			padding: 11px 0 11px 20px;
			list-style-position: inside;
			position: relative;
			border-bottom: 1px solid #ededed;
			transition: all 0.2s ease-in-out;
			font-size: 18px;
			font-family: $font_heading; } } }

.tim-music-player {
	.tracklist {
		li {
			&:hover {
				color: #fff;
				background: $color_primary; } } } }

.tim-music-player {
	li {
		&:last-child {
			border-bottom: 0;
			box-shadow: none;
			-webkit-box-shadow: none; } } }

.tim-music-player .show-more-button li:last-child {
	background: transparent url(../img/tracklist-item-bg.png) repeat-x bottom left scroll; }

.tim-music-player {}



.tim-music-player li:nth-child(1n + 10) .title {
	padding-left: 7px; }


.tim-music-player li.playing {
	color: #fff !important;
	background: $color_primary; }

.tim-music-player {
	.tracklist {
		.rating {
			margin-left: 30px;
			width: 66px;
			display: block;
			position: absolute;
			right: 92px;
			top: -15px; } } }

.tim-music-player {
	.rating-bar {
		width: 13px;
		display: block;
		float: left;
		cursor: pointer; } }

.tim-music-player {
	.buy {

		height: 18px;
		display: inline-block;
		font-size: 16px;
		text-align: center;
		line-height: 18px;
		width: 8%;
		color: #363636; }


	.video {
		color: #363636;
		width: 16%;
		text-align: center;
		display: block; }

	li {
		&.track:hover .buy,
		.playing .buy, &.track:hover .video {
			color: #fff; } } }

.tim-music-player .buy.not-active {
	display:none {} }

.tim-music-player {
	.more {
		display:none {}
		font-size: 14px;
		padding: 5px 0;
		cursor: pointer;
		text-align: center;
		background: #fff;
		position: relative; } }

.tim-music-player .more:hover {
	color: #fff;
	background: $color_primary; }

.tim-music-player .show-more-button .more {
	display:block {} }

.tim-music-player .more,
.tim-music-player .album-cover .highlight,
.tim-music-player .tracklist li,
.tim-music-player .buy {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease; }

.tim-music-player {
	.tracklist {
		li {
			span {
				display: inline-block; } } } }


.tim-music-player .player:after {
	clear: both;
	content: ' ';
	display: block;
	font-size: 0;
	line-height: 0;
	visibility: hidden;
	width: 0;
	height: 0; }


.music-single .tracklist {
	width: 94%;
	margin-top: 20px; }

.music-single {
	.player {
		width: 100%;
		float: none;
		display: block; } }

.music-single {
	.track-info {
		width: 67%; }

	.player-controls {
		width: 67%; }

	.player-controls {
		div {
			width: 79%; } } }

.wide-mp3 {
	.player-controls {
		width: 54%; }

	.progress-wrapper {
		width: 173px; } }


.jp-volume-controls {}

/* Best Music 2018 */
#the-sorcerers-wrath-container {
	margin-top: 30px;

	.jp-audio {
		max-width: 100%;
		margin-bottom: 60px; }

	.jp-controls {
		float: left;
		width: 95px;
		min-height: 42px;
		margin-bottom: 30px;

		a {
			float: left;
			display: inline-block;
			width: 40px;
			height: 40px;
			line-height: 36px;
			text-align: center;
			border: 2px solid #434343;
			border-radius: 50%;
			color: #434343;
			outline: 0;

			i {
				font-size: 16px; }

			&.jp-play {
				height: 50px;
				width: 50px;
				margin-right: 5px;
				margin-top: -5px;

				i {
					font-size: 22px;
					line-height: 46px;
					margin-left: 3px; } }

			&:hover {
				background: transparent; } } }

	.jp-seek-bar {
		background: #ededed;
		cursor: pointer; }

	.jp-play-bar {
		background: $color_primary; }

	.jp-middle {
		position: relative;
		width: -webkit-calc( 100% - 95px );
		width:         calc( 100% - 95px );
		min-height: 42px;
		float: left;
		padding: 0 0 0 22px; }

	.jp-progress {
		width: 100%;
		height: 8px;
		margin-bottom: 4px; }

	.jp-seek-bar {
		width: 0;
		height: 100%; }

	.jp-play-bar {
		width: 0;
		height: 8px;
		position: relative; }

	.jp-title {
		font-weight: 700;
		line-height: 100%;
		margin-bottom: 6px; }



	.jp-current-time {
		float: left; }

	.jp-duration {
		float: right; }


	.jp-volume-max {
		display: block;
		width: 24px;
		min-height: 42px;
		float: left;
		font-size: 14px;
		text-align: center;
		padding-top: 8px; }

	.jp-volume-bar {
		width: 59%;
		height: 8px;
		float: left;
		margin-top: 17px; }

	.jp-volume-bar-value {
		width: 0;
		height: 8px;
		position: relative; }


	.jp-playlist {
		clear: both;

		ul {
			margin: 0;
			padding: 0;
			list-style: none;
			border: 1px solid #ededed;

			li {
				position: relative;
				border-bottom: 1px solid #ededed;

				&:last-child {
					border-bottom: 0; }

				a {
					display: block;
					position: relative;
					min-height: 50px;
					font-weight: 500;
					line-height: 50px;
					margin-bottom: 1px;
					padding: 0 20px;
					color: #494949;
					font-family: $font_heading;
					font-size: 16px; }

				&.jp-free-media {
					position: absolute;
					display: inline;
					top: 0;
					right: 20px;
					font-size: 65%;
					font-weight: normal;
					letter-spacing: 4px;
					text-transform: uppercase;
					z-index: 1;

					a {
						height: auto;
						margin: 0;
						padding: 0;
						display: inline;
						font-weight: normal;

						&:hover {
							box-shadow: none; } } }

				&:hover, &.jp-playlist-current {
					background: $color_primary;

					a {
						outline: 0;
						color: #fff; } } } } }

	.track-byline {
		font-weight: normal; } }

.jp-controls a {
	color: $color_primary; }

.jp-state-playing {
	.jp-play {
		i:before {
			content: "\f04c"; } } }


/* Bast Sale Album */

.best-sale-album-cover {
	width: 45%;
	float: left;

	img {
		max-width: 100%; } }

.music-playlist-two {
	margin-left: 47%;

	.hit-plalist {
		.sm2_button {
			width: 10%; }

		.song-name {
			width: 60%; } } }


@media (max-width: 1280px) {
	.tim-music-player {
		.progress-wrapper {
			width: 110px; } } }


@media (min-width: 991px) and (max-width: 1279px) {
	.tim-music-player {
		.tracklist {
			.title {
				width: 55%; } }

		.player {
			padding-right: 10px; } }

	.slider-content {
		p {
			font-size: 14px; }

		ul {
			li {
				font-size: 14px; } } } }



@media (max-width: 1024px) {
	.tim-music-player {
		.tracklist {
			.title {
				width: 55%; } } } }

@media (max-width: 768px) {
	.tim-music-player {
		.player {
			max-width: 400px;
			float: none;
			width: 100%;
			margin: 0 auto 30px; } }

	.best-sale-album-cover {
		width: 100%;
		float: none; }

	.music-playlist-two {
		margin-left: 0px; }

	.hit-plalist li span {
		width: 15%; }

	.hit-plalist li .song-name {
		width: 50%; }

	.hit-plalist li .video-song {
		width: 14%; }

	.music-playlist-two .hit-plalist .song-name {
		width: 47%; } }




@media (max-width: 500px) {
	.tim-music-player {
		.tracklist {
			.title {
				width: 52%; }

			li {
				padding: 10px 0 11px 8px;

				span {
					font-size: 14px; } } } } }


@media (min-width: 768px) and (max-width: 991px) {
	.slider-content ul li {
		font-size: 15px;
		float: left;
		padding-right: 7px;
		width: 50%; }

	.best-sale-album {
		margin-top: 30px; } }
