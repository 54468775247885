.widget {
	background: #fff;
	padding: 40px 30px;
	text-align: center;
	box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
	border-radius: 15px;
	margin-bottom: 60px;

	&.widget-shop {
		text-align: left !important;
		background-color: transparent;
		box-shadow: none;
		padding: 0;

		.widget-title-shop {
			font-size: 26px;
			text-transform: uppercase;
			font-weight: 600;
			color: #363636;
			margin-bottom: 20px;
			font-family: $font_heading;

			span {
				color: $color_primary; } } }

	.widget-title {
		font-size: 26px;
		color: #3c3c3c;
		padding: 7px 0;
		border-radius: 5px;
		transition: all 0.3s ease-in-out;
		margin-bottom: 30px;
		font-family: $font_heading;
		position: relative;

		&:before , &:after {
			position: absolute;
			content: '';
			bottom: 0;
			background: #e43a90;
			left: 50%;
			-webkit-transform: translateX(-50%);
			-ms-transform: translateX(-50%);
			transform: translateX(-50%); }

		&:before {
			width: 60px;
			height: 1px; }

		&:after {
			height: 3px;
			bottom: -1px;
			width: 25px;
			left: 50%; }

		span {
			color: $color_primary; } }

	p {
		color: #676767; }

	&:hover {
		.widget-title {} }


	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;

		li {
			line-height: 45px;
			border-bottom: 1px dashed #e0e0e0;

			&:last-child {
				border-bottom: 0; }

			a {
				color: #585858;
				font-size: 16px;
				position: relative;
				display: block;
				font-family: $font_heading;
				text-transform: uppercase;

				span {
					position: absolute;
					right: 0; }

				&:hover {
					color: $color_primary; } } } } }


/* Widget Newsletter */
.widgit_newsletter {
	position: relative;

	&:after {
		position: absolute;
		content: '';
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		background-image: url(../../media/background/12.jpg);
		max-width: 250px;
		width: 100%;
		height: 213px;
		z-index: 1; }

	.newsletter-form {
		border-radius: 5px;
		overflow: hidden;
		position: relative;
		z-index: 222;
		background: rgba(255,255,255,0.7);

		input {
			height: 60px;
			border: 0;
			border: 1px solid #dcdcdc;
			border-bottom: 0;
			border-radius: 4px 4px 0  0;
			background: transparent;

			&::placeholder {
				color: #a2a2a2; } }

		.tim-btn {
			width: 100%;
			display: block;
			border-radius: 0;
			padding: 14px 0;
			text-align: center;
			background: #e4e4e4;
			color: #363636;
			text-transform: uppercase;

			&:hover {
				background: $color_primary; } } }

	p {
		color: #676767;
		margin-bottom: 25px;
		position: relative;
		z-index: 22; } }


/* Widget About Me */
.ab-thumbnails {
	margin-bottom: 20px;
	img {
		max-width: 100%;
		height: auto; } }

/* Popular Post */
.widgets-popular-post {

	li {
		margin-bottom: 10px;
		padding-bottom: 10px;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0; }

		.content {
			float: left;
			max-width: 65%;

			.entry-meta {
				font-size: 15px;
				font-weight: 500;
				line-height: 26px;

				&.cat {
					a {
						color: $color_primary; } } }
			h3 {
				font-size: 18px; } }

		.feature-img {
			float: right;
			width: 35%;
			display: block;

			img {
				max-width: 100%; } } } }

/* Product Price Filter */
#slider-range {
	background-color: #363636; }

#range-slider {
	p {
		line-height: 1.6875em;
		color: #363636;
		margin-bottom: 20px;
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;

		#amount {
			border: 0;
			background-color: transparent; } } }


.ui-slider-horizontal {
	height: 4px;
	.ui-slider-range {
		background-color: $color_primary; }

	.ui-slider-handle {
		top: -10px; } }

.ui-widget {
	&.ui-widget-content {
		border: 0; } }


.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	background: $color_primary; }


.ui-slider {
	.ui-slider-handle {
		box-shadow: inset 0px 6px 3.68px 0.32px rgba(0, 0, 0, 0.18);
		border-radius: 50%;
		height: 24px;
		width: 24px;
		background: $color_primary;
		border: 0;
		outline: 0; } }

.fil-btn {
	padding: 10px 25px;
	color: #363636;
	border: 1px solid #e4e4e4;
	text-transform: capitalize;
	font-weight: 600;
	display: inline-block;
	margin-top: 30px;
	font-size: 17px;
	line-height: 20px;
	border-radius: 4px;

	&:hover {
		color: $color_primary; } }


/* Best Sales */
.best-sales {
	.sales-item {
		display: inline-block;
		position: relative;
		margin: 5px;
		width: 29%;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: rgba($color_primary, 0.9);
			opacity: 0;
			transition: all 0.3s ease-in-out; }

		img {
			max-width: 100%; }

		.content {
			position: absolute;
			width: 100%;
			text-align: center;
			top: 15px;
			left: 0;
			opacity: 0;
			transition: all 0.3s ease-in-out;

			a {
				color: #696969;
				position: relative;
				z-index: 2;
				text-align: center;
				line-height: 28px;
				height: 30px;
				width: 30px;
				display: inline-block;
				margin-bottom: 10px;

				i {
					font-size: 14px;
					margin-left: -2px; }

				&:before {
					position: absolute;
					content: '';
					height: 30px;
					width: 30px;
					background: #fff;
					border-radius: 4px;
					transform: rotate(-45deg);
					z-index: -1;
					top: 0;
					left: 0; }

				&:hover {
					color: $color_primary; } }

			.star-rating {
				margin: 0 auto;
				text-align: center;
				width: 4.7em;

				&:before {
					font-size: 14px; }

				span {
					&:before {
						color: #fff;
						font-size: 14px; } } }


			.price {
				font-size: 20px;
				color: #fff;
				font-weight: 600;
				font-family: $font_heading;
				line-height: 20px;

				span {
					line-height: 20px; } } }

		&:hover {
			&:before {
				opacity: 1; }

			.content {
				opacity: 1; } } } }

@media (min-width: 768px) and (max-width: 1320px) {
	.best-sales {
		.sales-item {
			width: 44%; } } }


@media (max-width: 767px) {
	.best-sales {
		.sales-item {
			width: auto; } } }

/* Adds */
.adds-thumbnails {
	a {
		display: block;

		img {
			max-width: 100%; } } }





@media (min-width: 768px) and (max-width: 1280px) {
	.widget {
		padding: 20px 15px;

		ul {
			li {
				a {
					font-size: 14px; } } } }

	#range-slider p {
		font-size: 15px; } }


@media (max-width: 768px) {

	.widgit_newsletter {
		&:after {
			bottom: 10px; } } }
