#footer {
	background: #1b1b1b;

	.footer-inner {
		padding: 80px 0 40px; } }

.footer-about {
	.footer-logo {
		max-width: 120px;
		margin-bottom: 30px;
		width: 100%;
		display: inline-block;

		img {
			width: 100%; } }
	> p {
		color: #c8c6c6;
		line-height: 25px;
		margin-bottom: 20px; } }

.footer-contact {
	.contact-details {
		i {
			float: left;
			width: 30px;
			height: 40px;
			line-height: 26px; }


		p {
			color: #fff;
			font-size: 16px;
			margin-bottom: 5px;
			margin-left: 30px; } } }

.footer-title {
	color: #fff;
	font-size: 30px;
	font-weight: 500;
	text-transform: uppercase;
	margin-bottom: 30px;
	margin-top: 20px; }

/* Footer Latest Post */
.footer-blog-inner {
	.footer-blog {
		border-bottom: 1px solid #4c4c4c;
		margin-bottom: 20px;
		padding-bottom: 20px;

		&:last-child {
			border-bottom: 0; }

		.fea-image {
			float: left;
			position: relative;

			i {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				font-size: 30px;
				color: #fff; } }

		.content {
			margin-left: 140px;

			h3 {
				font-size: 15px;
				line-height: 24px;

				a {
					color: #fff;

					&:hover {
						color: $color_primary; } } }

			.meta {
				color: #b4b3b3;

				&:hover {
					color: $color_primary; } } } } }

/* Tags */
.tagcloud {
	a {
		display: inline-block;
		padding: 10px 28px;
		font-size: 16px;
		color: #fff;
		background: #636363;
		margin: 0 5px 10px 0;
		font-family: $font_heading;

		&:hover {
			background: $color_primary; } } }

/* Widget Newsletter */
.footer-newsletter {

	p {
		color: #fff; }

	#widget-newsletter {
		display: flex;
		input {
			width: 100%;
			height: 45px;
			background: #fff;
			padding: 10px 20px;
			outline: 0;
			border: 0;

			&::placeholder {
				color: #afafaf; } }

		.submit {
			background: $color_primary;
			height: 45px;
			line-height: 45px;
			text-align: center;
			border: 0;
			padding: 0 17px;
			color: #fff;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				background: #444; } } } }

/*  Instagram Widget */
.instagram-feed {
	a {
		max-width: 120px;
		width: 100%;
		display: inline-block;
		margin-bottom: 10px;
		padding-right: 5px;

		img {
			max-width: 100%; } } }

/* Footer Social Link */
.footer-social-link {
	height: 60px;
	li {
		display: inline-block;
		width: 25%;
		background: #000;
		margin-left: -4px;
		text-align: center;
		padding: 16px 0;
		position: relative;
		z-index: 1;
		overflow: hidden;

		&:before {
			position: absolute;
			content: '';
			height: 61px;
			width: 100%;
			top: -61px;
			background: $color_primary;
			z-index: 0;
			left: 0;
			transition: all 0.3s ease-in-out; }

		a {
			display: block;
			font-size: 18px;
			font-weight: 500;
			color: #fff;
			position: relative;
			z-index: 2; }

		&.fb-bg {
			background: #4868ab; }

		&.yotube-bg {
			background: #ff2500; }

		&.tw-bg {
			background: #1da2f2; }

		&.pin-bg {
			background: #bd1a1c; }

		&:hover {
			&:before {
				top: 0; } } } }


.copyright-text {
	padding: 28px 0;

	p {
		margin: 0;
		color: #fff;
		font-family: $font_heading;

		a {
			color: $color_primary; } } }

@media (min-width: 1200px) and (max-width: 1450px) {

	.tagcloud {
		a {
			padding: 10px 23px; } }

	.footer-newsletter #widget-newsletter input {
		width: 85%; } }




@media (max-width: 576px) {
	.footer-social-link {
		li {
			padding: 12px 0;

			a {
				font-size: 16px; } } }

	.copyright-text {
		p {
			text-align: center; } }

	#footer .footer-inner {
		padding: 50px 0 40px; }

	.footer-newsletter #widget-newsletter input {
		width: 83%; } }


@media (min-width: 768px) and (max-width: 991px) {
	.instagram-feed a {
		max-width: 107px; }

	.tagcloud a {
		padding: 10px 20px; } }

