#recent-performance {
	padding: 100px 0;
	background: #f8f8f8; }


.performace {
	position: relative;
	transition: all 0.3s ease-in-out;
	overflow: hidden;
	max-width: 400px;
	margin: 0 auto 30px;

	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: rgba($color_primary, 0.58);
		opacity: 0;
		transition: all 0.3s ease-in-out;
		z-index: 1; }

	.per-thumb {

		img {
			max-width: 100%;
			transition: all 0.3s ease-in-out; } }

	.content {
		position: absolute;
		text-align: center;
		bottom: 30px;
		width: 78%;
		left: 50%;
		transform: translateX(-50%);
		background: rgba(60,60,60,0.85);
		padding: 15px 0;
		transition: all 0.3s ease-in-out;
		z-index: 2;

		&:before {
			position: absolute;
			content: '';
			left: -7px;
			right: -7px;
			top: -7px;
			bottom: -7px;
			border: 3px solid rgba(60,60,60,0.85);
			transition: all 0.3s ease-in-out; }

		h3 {
			color: #fff;
			font-size: 24px;
			font-weight: 600;
			line-height: 26px; }

		h5 {
			font-size: 16px;
			color: #fff; } }

	&:hover {
		&:before {
			opacity: 1; }

		.per-thumb {
			img {
				transform: scale(1.05); } }

		.content {
			background: rgba(255,255,255, 0.8);

			h3, h5 {
				color: $color_primary; }

			&:before {
				border-color: rgba(255,255,255, 0.8); } } } }


@media (max-width: 1400px) {
	#recent-performance {
		padding: 50px 0; } }
