#logo-carousel {
	padding: 65px 0; }

.tim-logo-carousel {
	.swiper-wrapper {
		display: flex;
		align-items: center; }

	.brand-logo {
		text-align: center;
		img {
			opacity: 0.4;
			transition: all 0.3s ease-in-out; }

		&:hover {
			img {
				opacity: 1; } } } }

@media (max-width: 768px) {

	#logo-carousel {
		padding: 50px 0; } }
