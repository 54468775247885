.artist-profile {
	box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.22);
	padding: 65px 40px;

	&.artist-profile-details {
		padding: 65px 40px 40px; }

	.profoile-image {
		float: left;
		max-width: 250px;
		text-align: center;

		img {
			max-width: 100%;
			border-radius: 50%; }

		.artist-social-link {
			margin-top: 10px;

			li {
				margin-right: 5px;
				height: auto;
				width: auto;
				border: 0;
				a {
					color: #868686;
					height: auto;
					width: auto;
					border: 0;

					&:hover {
						color: $color_primary;
						border: 0; } } } } }

	.content {
		margin-left: 280px;

		h3 {
			font-size: 34px;
			font-weight: 500;

			a {
				color: #363636;

				&:hover {
					color: $color_primary; } } }

		span {
			display: block;
			margin-bottom: 10px;
			color: #828282; }
		p {
			margin-bottom: 30px; }

		.tim-btn {
			font-size: 16px;
			text-transform: uppercase;
			padding: 7px 20px; } }
	.recent-concert {
		margin-top: 35px;

		.title {
			font-size: 26px;
			font-weight: 500;
			margin-bottom: 12px; }

		p {
			margin-bottom: 30px; } }

	.video-inner {
		position: relative;
		.popup-video-btn {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 50px;
			width: 50px;
			color: #fff;
			border: 2px solid #fff;
			text-align: center;
			line-height: 46px;
			border-radius: 50%;
			font-size: 20px;
			transform: translate(-50%, -50%);
			outline: 0; } } }

.artist-profile {
	margin-bottom: 30px;

	.video-inner {
		img {
			max-width: 100%; } } }



