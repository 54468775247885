

.gmap3-area {
    height: 425px; }

.sin-cont-info {
    text-align: center;
    color: #525252;
    height: 410px;
    box-shadow: 0px 3px 9px 0px rgba(108, 108, 108, 0.35); }

.sin-cont-info i {
    font-size: 45px;
    color: #3a3a3a;
    padding-bottom: 6px;
    display: inline-block; }

.sin-cont-info h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase; }

.sin-cont-info p {
    font-size: 15px;
    font-weight: 700;
    padding-bottom: 0;
    margin-bottom: 0; }

.sin-cont-info a {
    font-size: 15px;
    font-weight: 400;
    color: #797878; }

.no-pad {
    padding: 0px; }

.center-wrap {
    margin: 0 auto; }

.office-location {
    background: #2c2c2c;
    position: relative;
    z-index: 6; }

.office-location h3 {
    color: #fff; }

.office-location p {
    color: #fff; }

.office-location a {
    color: #fff; }

.sin-cont-info.office-location i {
    color: #fff; }

.office-location::before {
	content: '\f041';
	position: absolute;
	left: 0;
	font-family: Fontawesome;
	font-size: 250px;
	top: 44%;
	left: 50%;
	z-index: -1;
	transform: translate(-50%, -25%); }


.con-bottom-inner h4 {
    font-size: 30px;
    color: #3c3c3c; }

.con-bottom-inner .per-social ul li {
	display: inline;
	margin: 0 10px; }


.con-bottom-inner h4 span {
    font-size: 30px;
    color: #f18636; }

.con-bottom-inner {
    text-align: center; }

.con-bottom-inner h4 {
    font-size: 30px;
    color: #3c3c3c;
    padding-bottom: 15px; }

.con-bottom-inner .per-social {
    padding-bottom: 30px; }

.con-bottom-inner .per-social li {
    margin: 0 10px; }

.con-bottom-inner .per-social ul li a i {
    text-shadow: none; }

.con-bottom-inner .per-social ul li a {
    color: #3c3c3c; }

.con-bottom-inner .per-social ul li a:hover {
    color: #e43a90; }

.con-page-form {
    padding-top: 32px;
    display: inline-block; }

.con-page-form textarea {
    width: 100%;
    border: 1px solid #e7e6e6;
    border-radius: 4px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
    padding: 15px;
    height: 180px; }

.con-page-form input {
    width: 49%;
    float: left;
    border: 1px solid #e7e6e6;
    box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
    border-radius: 4px;
    height: 55px;
    padding: 0px 20px; }

.Stock {
    border-width: 1px;
    border-color: rgb(231, 230, 230);
    border-style: solid;
    border-radius: 12px;

    position: absolute;
    left: 550px;
    top: 1323px;
    width: 818px;
    height: 178px;
    z-index: 64; }

.mar-r {
    margin-right: 2%; }

.con-page-form input[type="submit"] {
	background-color: transparent;
	color: #363636;
	padding: 16px 40px;
	border-color: #e7e6e6;
	border-radius: 4px;
	cursor: pointer;
	width: auto;
	margin-top: 10px;
	text-transform: uppercase;
	margin-top: 30px;
	-webkit-box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
	box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
	height: 55px;
	line-height: 0; }


.con-page-form input[type="submit"]:hover {
	color: #e43a90; }

#googleMap {
    height: 500px; }

@media only screen and (max-width: 575px) {
	.con-page-form input {
		width: 100%;
		margin-bottom: 20px; }

	.con-page-form input[type="submit"] {
		margin-top: 0; } }


