#store-pagination {
	text-align: left;
	left: 35px;
	bottom: 40px;
	.swiper-pagination-bullet {
		width: 9px;
		height: 9px;
		background: #d7d7d7;
		border-radius: 0;
		opacity: 1; }
	.swiper-pagination-bullet-active {
		background: #f60038;
		opacity: 1; } }

.brand-shop-carousel {
	box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1); }



.soundcloud-wrapper {
	padding: 40px;
	box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
	border: solid 1px #f8f8f8;
	iframe {
		height: 120px;
		width: 100%; }
	h6 {
		font-size: 20px;
		color: #000;
		padding-top: 13px;
		padding-bottom: 17px; }
	footer {
		border-top: 1px solid #ebebeb;
		padding-top: 5px;
		overflow: hidden;
		a {
			font-size: 14px;
			color: #959595;
			letter-spacing: 1.7px;
			float: left;
			font-weight: 500;
			font-family: "Changa", sans-serif;
			i {
				color: red;
				width: 100%;
				display: inline;
				float: left;
				transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
				margin-left: 0; } }
		p {
			font-size: 14px;
			color: #494848;
			float: right;
			span {
				color: #949494; } } } }

.soundcloud-wrapper footer a:hover i {
	margin-left: 15px;
	transition: all 0.5s cubic-bezier(0, 1.09, 1, 1); }


@media (min-width: 992px) and (max-width: 1400px) {

	.soundcloud-wrapper {
		padding: 20px 30px; } }


@media (min-width: 768px) and (max-width: 991px) {

	.soundcloud-wrapper h6 {
		font-size: 17px;
		padding-top: 13px;
		padding-bottom: 10px; }

	.soundcloud-wrapper {
		padding: 20px; } }


@media (min-width: 320px) and (max-width: 767px) {
	.brand-shop-carousel {
		max-width: 435px;
		margin: 0 auto;
		margin-bottom: 100px; } }

@media (min-width: 320px) and (max-width: 575px) {
	.soundcloud-wrapper {
		padding: 15px; } }


