.tabs-filter-button {
	margin: 0;
	padding: 0;
	list-style: none;
	background: #313131;
	padding: 37px 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	text-align: center;

	li {
		position: relative;
		padding-left: 20px;

		&:after {
			content: '';
			position: absolute;
			right: -60px;
			top: 6px;
			height: 16px;
			width: 2px;
			background: #4e4e4e; }


		&:last-child {
			&:after {
				display: none; } }

		a {
			color: #fff;
			font-size: 18px;
			font-weight: 600;
			font-family: $font_heading;

			&:hover {
				color: $color_primary; } }
		&.current {
			a {
				color: $color_primary; } } } }


.tab-details {
	.details {
		margin: 0;
		padding: 30px 40px;
		list-style: none;
		box-shadow: 0px 5px 21px 0px rgba(142, 142, 142, 0.23);
		background: rgba(255,255,255,0.5);


		li {
			font-family: $font_heading;
			font-size: 16px;
			font-weight: 500;
			color: #585757;
			position: relative;
			padding-bottom: 5px;
			display: inline-block;
			text-align: left;
			width: 16%;

			&:before, &:after {
				content: '';
				position: absolute;
				background: #adadad; }

			&:before {
				bottom: 0;
				width: 25%;
				height: 1px;
				left: 25px;
				transform: translateX(-50%); }

			&:after {
				left: 16px;
				width: 15px;
				height: 4px;
				bottom: -1px; } } }

	&.tab-dark {
		ul {
			background: #313131;

			li {
				color: #fff; } } } }

.tabs-filter-button li {}
.songs-details {
	margin: 0;
	padding: 0;
	list-style: none;
	li {
		display: inline-block;
		width: 16%;
		text-align: left;
		font-size: 16px;
		font-weight: 500;
		font-family: $font_heading;

		a {
			color: #585757;

			&:hover {
				color: $color_primary; } }

		span {
			color: $color_primary; }

		i {
			margin-right: 5px; } } }

.tabs-filter {
	margin: 0;
	padding: 0;
	list-style: none; }

.tab-filter-wraper {
	background-image: url(../../media/background/13.jpg);
	padding-bottom: 30px;

	&.top-music-wrap {
		box-shadow: 0px 5px 21px 0px rgba(184, 184, 184, 0.23); } }

.tim-isotope-3 {
	padding: 0 40px; }

.tim-songs-items {
	&.grid-item {
		width: 100%;
		padding: 20px 0;
		border-bottom: 1px solid #d1d1d1;

		&:last-child {
			border-bottom: 0; } }

	&.tranding-song {
		padding: 16px 0;
		border-bottom: 1px solid #d1d1d1;

		&:before {
			clear: both;
			content: '';
			display: block; } } }


/* Top Music */
#top-music {
	padding: 120px 0;
	background: #fbfbfb; }


.circle-player {
	background: #f5f5f5;
	padding: 25px; }


#jp_container_1 {
	.jp-type-playlist {
		background: #dea23c;
		padding: 80px; }
	.jp-gui {
		margin: 0 auto;
		height: 410px;
		width: 410px;
		border-radius: 50%;
		background: #FFF;
		padding: 80px;
		position: relative;

		.jp-controls {
			background: #dea23c;
			border-radius: 50%;
			text-align: center;
			height: 100%;
			width: 100%;
			padding: 65px 0;


			button {
				border: 0;
				background: transparent; } }

		.jp-next, .jp-previous {
			position: absolute;
			top: 50%;
			transform: translateY(-50%); }

		.jp-next {
			right: 25px; }

		.jp-previous {
			left: 25px; }

		.jp-volume-controls {
			border-radius: 50%;
			display: -webkit-flex;
			display: -moz-flex;
			display: -ms-flex;
			display: -o-flex;
			display: flex;
			justify-content: center;
			align-items: center;

			.jp-volume-bar {
				height: 5px;
				background: #fff;
				width: 70px;

				.jp-volume-bar-value {
					height: 5px;
					background: #4c4c4c; } }

			.jp-mute {
				margin-right: 5px; } }

		.jp-progress {
			width: 150px;
			margin: 0 auto;

			.jp-seek-bar {
				height: 5px;
				background: #fff;

				.jp-play-bar {
					height: 5px;
					background: #4c4c4c; } }

			.jp-current-time {
				float: left; }

			.jp-duration {
				float: right; } } } }

.tab-filter-wrapper {
	padding: 0 40px; }


@media (max-width: 1200px) {
	.tabs-filter-button {
		li {
			&:after {
				right: -44px; } } } }

@media (max-width: 1200px) {
	.tabs-filter-button {
		li {
			&:after {
				right: -30px; } } } }

@media (max-width: 1199px) {

	.tab-pd-left,.tab-pd-right {
		padding: 70px 35px; } }

@media (max-width: 768px) {

	.tabs-filter-button {
		li {
			width: 25%;

			&:after {
				right: -17px; } } }

	.tim-isotope-3 {
		padding: 0; }

	.tab-details {
		.details {
			display: none; } }



	.songs-details {
		li {
			display: block;
			width: 100%;
			float: right;
			padding: 15px;
			border-bottom: 1px solid #b1b1b1;

			&:last-child {
				border-bottom: 0; }

			&:before {
				width: 30%;
				float: left;
				font-weight: 600;
				text-align: left; }

			&:nth-of-type(1) {
				&:before {
					content: "Artist"; } }

			&:nth-of-type(2) {
				&:before {
					content: "Title"; } }

			&:nth-of-type(3) {
				&:before {
					content: "Type"; } }

			&:nth-of-type(4) {
				&:before {
					content: "Rating"; } }

			&:nth-of-type(5) {
				&:before {
					content: "Published"; } }

			&:nth-of-type(6) {
				&:before {
					content: "View"; } } } }

	.tim-songs-items {
		&.tranding-song {} }


	.tim-songs-items {
		&.grid-item {
			padding: 15px 0;
			border-bottom: 2px solid #000; } }

	.tim-songs-items {
		&.tranding-song {
			padding: 0;
			border-bottom: 2px solid #211f1f; } }

	.tabs-filter-button {
		li {
			&:nth-last-child(-n+4) {
				&:after {} } } }

	.tab-filter-wrapper {
		padding: 0;
		overflow: hidden; }

	#top-music {
		padding: 50px 0; } }

@media (max-width: 575px) {

	.tabs-filter-button {
		li {
			width: 50%; } }

	.tab-pd-left, .tab-pd-right {
		padding: 30px 15px 15px; } }


/*--------------------------------------------------------------
  ##  Tab single page
  --------------------------------------------------------------*/


  /*--------------------   Tab single page header  --------------*/



.header-search {
	width: 670px;
	margin: 0 auto;
	text-align: left;
	padding-bottom: 170px;
	h2 {
		color: #fff;
		font-size: 50px;
		font-weight: 600;
		text-transform: uppercase; }

	input {
		border: 1px solid #fff;
		border-radius: 5px;
		width: 40%;
		background: rgba(255,255,255,.09);
		padding-left: 15px;
		height: 47px;
		color: #fff;
		margin-right: 9px; }
	select {
		border: 1px solid #fff;
		width: 40%;
		height: 47px;
		-moz-appearance: none;
		-webkit-appearance: none;
		-o-appearance: none;
		appearance: none;
		color: #7b7b7b;
		padding-left: 15px;
		overflow: hidden;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;
		padding-right: 25px;
		background: rgba(255,255,255,.09);
		border-radius: 5px;
		margin-right: 9px;
		background-image: url("../../assets/img/arrow.png") no-repeat scroll 94% 47%; }
	.tim-cart-btn {
		background: #e43a90;
		padding: 10px 23px;
		color: #FFF;
		border-radius: 6px;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		font-size: 18px;
		font-weight: 500;
		border: 0;
		font-family: "Changa", sans-serif;
		cursor: pointer; } }


.page-header.tab-header {
	padding: 330px 0 30px;
	background-position: center;
	background-size: cover {



  /*--------------------   Tab single page Inner  --------------*/ } }

.tab-left {
  	.tab-img {
  		box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
  		border: 14px solid #fff; }
  	h6 {
  		font-size: 30px;
  		color: #585757;
  		margin-top: 30px; }
  	p {
  		font-size: 18px;
  		color: #585757;
  		a {
  			color: #f84196; } } }

.tab-right {
	padding-top: 170px;
	padding-left: 50px;
	padding-right: 110px;
	h6 {
		font-size: 20px;
		color: #fff;
		font-family: $font_heading;
		position: relative;
		padding-bottom: 20px;
		margin-bottom: 30px; }
	p {
		font-size: 16px;
		color: #fff; }
	ul {
		margin-top: 42px;
		position: relative;
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 38px;
			background: #e39ac8;
			width: 100%;
			height: 1px; }
		li {
			display: inline-block;
			text-align: left;
			padding-right: 27px;
			font-family: "Changa", sans-serif;
			font-weight: 400;
			color: #fff;
			font-size: 18px;
			i {
				margin-right: 5px; }
			span {
				display: block;
				font-family: $font_heading;
				font-weight: 400;
				font-size: 15px;
				padding-top: 24px;
				color: #fff; } }
		&:last-child {
			padding-right: 15px; } } }


.tab-right::after {
	content: '';
	width: 720px;
	height: 500px;
	position: absolute;
	left: -200px;
	top: 100px;
	z-index: -1;
	background-image: -moz-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%) {
    background-image: -webkit-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%);
    background-image: -ms-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%);
    box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09); } }



.tab-right h6::before, .tab-right h6::after {
	position: absolute;
	content: '';
	bottom: 0;
	background: #fff; }



.tab-right h6::before {
	width: 80px;
	height: 1px; }

.tab-right h6::after {
	height: 3px;
	bottom: -1px;
	width: 30px;
	left: 0; }


.tab-right ul li {
	display: inline-block;
	text-align: left;
	padding-right: 27px;
	font-family: "Changa", sans-serif;
	font-weight: 400;
	color: #fff;
	font-size: 18px; }

.tab-right ul li:last-child {
	padding-right: 0; }

.single-tab-details {
	text-align: left;
	.section-title-small h2 {
		font-size: 22px;
		line-height: 26px;
		color: #444;
		font-weight: 700;
		text-transform: uppercase;
		padding-bottom: 20px;
		position: relative;
		display: inline-block;
		span {
			color: #e43a90; } }
	.section-title-small h2::before, .section-title-small h2::after {
		position: absolute;
		content: '';
		background: #e43a90; } }


.section-title-small h2::before {
	width: 100px;
	height: 1px;
	left: 0;
	bottom: 0; }



.section-title-small h2::after {
	height: 3px;
	bottom: -1px;
	width: 30px;
	left: 10px; }


.lyrics-note {
	p {
		font-size: 16px;
		font-family: "Changa", sans-serif;
		border-bottom: 1px solid #ddd;
		padding-bottom: 15px;
		span {}
		display: block; }
	ul {
		list-style: none;
		li {
			padding-bottom: 10px;
			font-size: 16px;
			color: #585757;
			font-weight: 500; }
		span {
			font-weight: 400; } } }


.tab-details {
	background: #fcfafb; }

.tab-details .section-title-small {
	padding-bottom: 70px; }

.lyrics-note {
	margin-top: 66px;
	> span {
		font-size: 24px;
		color: #585757;
		font-weight: 600;
		font-family: "Changa", sans-serif;
		margin-bottom: 18px;
		display: inline-block; }
	ul {
		margin-top: 20px;
		li {} } }


.header_player.tab_page {
	position: relative;
	background-image: -moz-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%) {
  	background-image: -webkit-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%);
  	background-image: -ms-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%); }
	border: 12px solid #fff;
	box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09); }

.header_player.tab_page .current-tracks {
	float: none; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
	font-size: 17px;
	line-height: 37px;
	color: #d20f14;
	margin-left: 6px; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
	height: 40px;
	width: 40px;
	padding-left: 2px;
	background: #fff; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls {
	width: 140px;
	margin-top: -6px; }


.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
	width: 125px;
	margin-left: 20px; }

.header_player.tab_page .current-tracks::after {
	display: none; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
	padding: 0px 0 28px; }

.header_player.tab_page #nowPlaying .track-name {
	font-size: 20px; }

.header_player.tab_page #nowPlaying {
	padding: 21px 0 40px 15px; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress {
	border: 1px dashed #ec89c3; }
.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
	background: #fff; }

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress {
	background-color: transparent; }

.tab-video {
	position: relative;
	margin-top: 80px; }


.tab-video .popup-video-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	text-align: center;
	color: #d21d20;
	font-size: 96px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	outline: 0; }

.tab-video .popup-video-btn::after {
	content: '';
	background: #fff;
	width: 46px;
	height: 46px;
	top: 50%;
	left: 50%;
	position: absolute;
	z-index: -1;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%); }

.related-album-single .section-title h2::before {
	left: 13px;
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
	width: 100px; }
.related-album-single .section-title h2::after {
	height: 3px;
	bottom: -1px;
	width: 30px;
	left: 13px; }


.related-album-wrapper {
	list-style: none;
	padding: 0; }

.single-related-album {
	box-shadow: 0px 1px 29.75px 5.25px rgba(23, 28, 62, 0.1);
	list-style: none;
	margin: 30px 8px;
	display: inline-block;


	.single-related-prod-bottom {
		font-family: $font_heading;
		padding: 15px 15px;
		width: 100%;
		overflow: hidden;
		.left {
			float: left;
			display: inline-block;
			a {
				font-size: 16px;
				color: #333333;
				font-weight: 600; }
			p {
				font-size: 16px;
				color: #585757;
				line-height: 16px; } }

		.play-bottom {
			float: right;
			width: 46px;
			height: 46px;
			background: #fae4ee;
			text-align: center;
			border-radius: 50px;
			line-height: 48px;
			position: relative;

			i {
				color: #fff;
				width: 36px;
				height: 36px;
				background: #333333;
				border-radius: 50px;
				line-height: 36px;
				transition: all 0.15s ease-in-out; } } }
	&:hover {
		.left {
			a {
				color: #f84196; } }
		.play-bottom {

			i {
				background:  #f84196;
				transition: all 0.15s ease-in-out; } } } }

.tab_page #playlist-toggle {
	display: none; }

.header_player.tab_page .player-container {
	padding-bottom: 30px; }



@media (min-width: 992px) and (max-width: 1199px) {
	.tab-right::after {
		width: 520px;
		height: 380px;
		left: -30px;
		top: 68px; }
	.tab-right {
		padding-top: 102px;
		padding-left: 15px;
		padding-right: 30px; }
	.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
		padding: 22px 0; } }



@media (min-width: 768px) and (max-width: 991px) {


	.tab-right {
		padding-top: 50px;
		padding-left: 50px;
		padding-right: 110px;
		background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
		padding-bottom: 50px; }

	.tab-right::after {
		display: none; }
	.header_player.tab_page .player-container {
		max-width: 600px;
		margin-top: 32px; }
	.header_player.tab_page {
		margin-top: 50px; }
	.tab-video {
		margin-top: 70px; }
	.tab-left .tab-img {
		text-align: center; }
	.header_player.tab_page {
		margin-top: 132px; }
	.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
		display: none; } }


@media (min-width: 576px) and (max-width: 767px) {
	.tab-right {
		padding-top: 50px;
		padding-left: 50px;
		padding-right: 110px;
		background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
		padding-bottom: 50px; }

	.tab-right::after {
		display: none; }
	.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
		padding: 20px 0 40px; }
	.tab-details .section-title-small {
		padding-bottom: 20px; }
	.header_player.tab_page {
		margin-top: 25px; }

	.tab-video {
		margin-top: 55px; }

	.header_player.tab_page .player-container {
		padding-bottom: 11px; } }


@media (min-width: 320px) and (max-width: 575px) {
	.tab-right {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
		background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
		padding-bottom: 20px; }


	.tab-right::after {
		display: none; }
	.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
		padding: 20px 0 40px; }
	.tab-details .section-title-small {
		padding-bottom: 20px; }
	.header_player.tab_page {
		margin-top: 25px; }

	.tab-video {
		margin-top: 55px; }
	.header_player.tab_page .player-container {
		padding-bottom: 6px; }
	.header_player.tab_page {
		border: none; }
	.header_player.tab_page .tim-container {
		padding: 0; }
	.tab-right ul::after {
		display: none; }
	.tab-right ul li span {
		padding-top: 10px; }
	.tab-right ul li {
		margin-bottom: 30px; } }











