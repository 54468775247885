/*!
  Theme Name: Music Studio
  Theme URI: http://www.themeim.com/demo/music-studio/
  Author: ThemeIm
  Author URI: http://www.themeim.com/
  Description: Music Studio HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: music, musician, singer, audio
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Tranding Album
    ## Artist
    ## Upcomiong Concerts
    ## Ticket
    ## Ultimate Tabs
    ## Intro Video
    ## Blog
    ## Widgets
    ## Logo Carousel
    ## lesson
    ## About
    ## Event About
    ## Sale Album
    ## Album
    ## Artist Profiles
    ## Featured Album
    ## Newsletter
    ## Featured Album
    ## Sticky Audio Player
    ## Event Schedule
    ## Recent Performance
    ## Pricing
    ## Tabs
    ## Gallery
    ## Tabs
    ## Page Header
    ## Woocommerce
    ## Page Header
    ## woocommers
    ## Footer

  --------------------------------------------------------------*/



/*--------------------------------------------------------------
  ##  Google Web Fonts
  --------------------------------------------------------------*/


@import url('https://fonts.googleapis.com/css?family=Changa:200,300,400,500,600,700,800');

@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');



@import "base/variable";
@import "base/mixins";
@import "base/base";
@import "sections/elements";

