.artist-lineup {
	background: #111111;
	.swiper-button-prev {
	    background-image: none;
	    left: 0px;
	    right: auto;
	    height: 107%;
	    background-size: cover;
	    top: 7px;
	    width: 200px;
	    background: linear-gradient(90deg, rgba(17,17,17,1) 0%, rgba(17,17,17,1) 13%, rgba(255,255,255,0) 87%, rgba(255,255,255,0) 100%); }

	.swiper-button-next {
		background-image: none;
		right: 0;
		left: auto;
		height: 107%;
		background-size: cover;
		top: 7px;
		width: 200px;
		background: linear-gradient(-90deg, #111111 0%, #111111 13%, rgba(255, 255, 255, 0) 87%, rgba(255, 255, 255, 0) 100%); } }



.artist-lineup .swiper-container {
	margin-top: 65px; }


.artist-single {
	position: relative;
	z-index: 1;
	overflow: hidden;
	&:before {
		content: "";
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(246,0,56,1) 100%);
		position: absolute;
		left: 0;
		top: 27px;
		width: 100%;
		height: 100%;
		z-index: 1;
		background-repeat: repeat-x;
		opacity: 0;
		transition: all 0.3s; }
	img {
		-webkit-transition: opacity 1s, -webkit-transform 1s;
		transition: opacity 1s, transform 1s;
		width: 100%; }

	.artist-single-content {
		position: absolute;
		left: 31px;
		bottom: 15px;
		z-index: 5;
		ul {
			border-bottom: 1px solid #f60038;
			display: inline-block;
			padding-right: 25px;
			padding-bottom: 6px;
			margin-bottom: 10px;
			-webkit-transform: translate3d(0,40px,0);
			transform: translate3d(0,40px,0);
			opacity: 0;
			-webkit-transition-delay: 0.03s;
			transition-delay: 0.03s;
			-webkit-transition-duration: 0.25s;
			transition-duration: 0.25s;
			transition-opacity: 0.15s;
			li {
				display: inline-block;
				margin-right: 12px;

				a {
					color: #fff;
					&:hover {
						color: #000; } } } }
		h6 {
			color: #fff;
			font-size: 20px;
			font-weight: 400;
			line-height: 20px;
			-webkit-transform: translate3d(0,40px,0);
			transform: translate3d(0,40px,0);
			-webkit-transition-delay: 0.03s;
			transition-delay: 0.03s;
			-webkit-transition-duration: 0.25s;
			transition-duration: 0.25s;
			transition-opacity: 0.15s;
			opacity: 0; }
		p {
			font-size: 14px;
			font-family: $font_primary;
			color: #fff;
			line-height: 14px;
			-webkit-transform: translate3d(0,40px,0);
			transform: translate3d(0,40px,0);
			-webkit-transition-delay: 0.05s;
			transition-delay: 0.05s;
			-webkit-transition-duration: 0.35s;
			transition-duration: 0.35s;
			opacity: 0; } } }

.artist-single:hover:before {
	opacity: 1;
	transition: all 0.3s; }
.artist-single:hover {
	ul {
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);

		-webkit-transition: -webkit-transform 0.35s;
		transition: transform 0.35s;
		opacity: 1; }


	h6 {
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		-webkit-transition-delay: 0.03s;
		transition-delay: 0.03s;
		-webkit-transition-duration: 0.25s;
		transition-duration: 0.25s;
		transition-opacity: 0.15s;
		opacity: 1; }
	p {
		-webkit-transform: translate3d(0,0,0);
		transform: translate3d(0,0,0);
		-webkit-transition-delay: 0.05s;
		transition-delay: 0.05s;
		-webkit-transition-duration: 0.35s;
		transition-duration: 0.35s;
		opacity: 1; }
	img {
		-webkit-transform: scale3d(1.1,1.1,1) rotate(2deg);
		transform: scale3d(1.1,1.1,1) rotate(2deg); } }







@media (min-width: 991px) and (max-width: 1300px) {
	.section-title.style-four p {
		padding: 0px 15%; } }



@media (max-width: 991px) {

	.artist-single .artist-single-content {
		left: 17px;
		bottom: 10px; }

	.artist-lineup .swiper-button-next {
		background-repeat: no-repeat;
		background-image: url("../../assets/img/car-arrowr.png");
		right: 0;
		height: 30px;
		top: 50%;
		background-size: contain;
		width: 30px;
		background-position: center; }

	.artist-lineup .swiper-button-prev {
		background-image: url("../../assets/img/car-arrowl.png");
		left: 0;
		right: auto;
		height: 30px;
		background-size: contain;
		top: 50%;
		width: 30px;
		background-repeat: no-repeat; } }



@media (min-width: 320px) and (max-width: 767px) {


	.artist-lineup .swiper-container {
		margin-top: 30px; } }
