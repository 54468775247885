.partners-area {
	padding: 60px 0 16px; }

.partner-swipper .swiper-button-next {
	background-image: url("../img/right-arrow.png");
	background-size: auto; }



.partner-swipper .swiper-button-prev {
	background-image: url("../img/left-arrow.png");
	background-size: auto; }

.partner-swipper {
	margin-top: 50px;
	.swiper-slide {
		text-align: center; } }


.partner-swipper .swiper-button-next,.partner-swipper .swiper-button-prev {
	top: 60%;
	opacity: 0;
	transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); }

.partner-swipper:hover .swiper-button-next,.partner-swipper:hover .swiper-button-prev {
	opacity: 1;
	transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); }
