/*--------------------------------------------------------------
  ##   Header Five css
  --------------------------------------------------------------*/

.header-magic-line {
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 999;
	.user-login {
		float: right;
		margin-top: 18px;
		> li {
			&:after {
				display: none; } }
		li {
			> a {
				color: #fff;

				i {
					color: #fff; }

				&:hover {
					color: $color_primary; } }

			&.cart-count {
				.badge {
					color: #fff; } } } }

	.header-inner {
		background: transparent; }
	.nav {
		padding-left: 0;
		float: right;
		margin-right: 50px; }
	#site-logo {
		line-height: 80px; } }



.header-magic-line .user-login .search-input-wrapper {
	position: absolute;
	right: 5px;
	top: 80px;
	background: #f60038;
	height: 0;
	width: 320px;
	overflow: hidden;
	-o-transition: all 0.3s;
	-webkit-transition: all 0.3s;
	transition: all 0.3s;
	opacity: 1;
	z-index: 1; }

.header-magic-line .user-login .search-input-wrapper.active {
	height: 60px; }

.header-magic-line .user-login .search-input-wrapper input {
	display: block;
	border: 1px solid $color_primary;
	border-radius: 2px;
	padding: 5px;
	width: 300px;
	margin-left: 10px;
	margin-top: 10px; }


.header-magic-line {
	.user-login {
		list-style: none;

		> li {
			display: inline-block;
			position: relative;
			margin-right: 15px;
			font-family: $font_heading;
			padding: 10px 0; }
		li {
			&.cart-count {
				position: relative;

				.badge {
					position: absolute;
					top: 10px;
					left: 8px;
					background: #ffff;
					border-radius: 50%;
					line-height: 16px;
					width: 17px;
					font-size: 11px;
					height: 17px;
					display: block;
					padding: 0;
					font-weight: 500;
					text-align: center;
					color: $color_primary; }

				.cart-overview {
					opacity: 0;
					visibility: hidden;
					z-index: 101;
					line-height: 1.8;
					position: absolute;
					background: #FFF;
					text-align: left;
					width: 350px;
					padding: 15px;
					margin-top: 0;
					right: 0;
					top: 100px;
					transition: all 0.3s ease-in-out;

					.cart-item {
						margin-bottom: 10px;
						display: block;
						position: relative;
						margin-right: 0;
						padding: 0;

						.product-thumbnail {
							margin-right: 1.3em;
							float: left;
							padding: 0 !important;
							display: inline-block;
							width: 70px;

							img {
								max-width: 100%; } }


						.product-details {
							position: relative;
							margin-left: 70px;

							.product-title {
								font-size: 16px;
								font-weight: 600;
								color: #000;
								display: block;
								text-decoration: none;


								&:hover {
									color: #f60038; } }

							.product-remove {
								position: absolute;
								right: 0;
								top: 42%;
								height: 18px;
								width: 18px;
								font-size: 6px;
								background: #3e3e3e;
								color: #FFF;
								border-radius: 50%;
								text-align: center;
								line-height: 18px;
								color: #f3f3f3;
								transition: all 0.3s ease-in-out;

								&:hover {
									background: #444;
									color: #FFF; } }

							.product-quantity {
								display: inline-block; } } }

					.cart-subtotal {
						display: block;
						padding: 15px 0;
						border-top: 1px solid #dcdcdc;
						border-bottom: 1px solid #dcdcdc;
						margin-top: 15px;
						font-size: 14px;
						font-weight: 600;
						color: #000;
						text-transform: uppercase;

						.amount {
							float: right; } }

					.cart-actions {
						display: block;
						padding-top: 20px;
						.view-cart, .checkout {
							padding: 13px 25px !important;
							text-decoration: none;
							border-radius: 30px;
							transition: all 0.3s ease-in-out;
							text-transform: uppercase;
							font-size: 16px !important;
							display: inline-block !important;
							letter-spacing: 1px;
							font-weight: 600; } } }

				.view-cart {
					color: #363636;
					border: 1px solid #363636;

					&:hover {
						background: #f60038;
						color: #fff !important;
						border-color: #f60038; } }

				.checkout {
					background: #f60038;
					color: #FFF !important;
					float: right;
					border: 1px solid #f60038;

					&:hover {
						background: darken($color_primary, 10%); } } }
			&:hover {
				.cart-overview {
					opacity: 1 !important;
					top: 66px !important;
					visibility: visible !important; } }

			&:last-child {
				margin-right: 0;

				&:after {
					display: none; } }
			a {
				font-size: 15px;
				color: #fff;
				font-weight: 500;
				display: inline-block;
				line-height: 1;

				i {
					margin-right: 10px;
					color: #fff; } } } } }



.hide-topbar.headroom--pinned .header-magic-line .user-login li a {
	color:  #363636; }
.hide-topbar.headroom--pinned .header-magic-line .user-login li a i {
	color:  #363636; }


.headroom--pinned {
	-ms-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	animation-name: headerSlideDown;

	&.hide-topbar {
		.header-inner {
			background: #FFF;
			box-shadow: 0 10px 30px rgba(0,0,0,0.05);

			#header-menu-magic-line {
				li {
					&:after, &:before {
						background: $color_primary; }
					a {
						color: #363636; }


					&.menu-item-has-children {
						.sub-menu {
							background: #fff; } } }

				li.active {
					> a {
						color: $color_primary; } } }

			.nav {
				.head-btn {
					border-color: #d8d1d1;
					color: #363636;

					&:hover {
						color: #fff;
						border-color: $color_primary; } } } } } }

#header-menu-magic-line {
	list-style: none;
	float: right;
	width: 100%;
	position: relative;

	> li {

		a {
			position: relative;
			display: inline-block;
			font-family: $font_heading;
			i {
				font-size: 13px;
				margin-left: 5px; } }



		&.menu-item-has-children {
			.sub-menu {
				text-align: left;
				position: absolute;
				padding: 10px 20px;
				width: 240px;
				background: rgba(0,0,0,0.95);
				top: 104%;
				transform: translateY(-10px);
				left: auto;
				margin: 0;
				opacity: 0;
				visibility: hidden;
				box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
				transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
				height: auto;

				li {
					display: block;
					padding: 5px 0;
					margin: 0;

					&:last-child {
						border-bottom: 0; }

					a {
						color: #fff;
						padding: 3px 0;
						font-size: 16px;

						&:hover, &.current_page {
							color: #f60038; } } }



				&.mega-menu {
					width: 100%;
					padding: 20px 30px;

					> li {
						width: 25%;
						float: left;

						h3 {
							font-size: 20px;
							font-weight: 600;
							color: #fff;
							margin-left: 15px; } }

					li {

						.sub-menu {
							background-color: transparent;
							border-top-color: transparent;
							float: left;
							padding: 0 15px;



							li {
								display: block;
								a {
									display: block;
									padding: 3px 0; } } }

						&:not(:last-child) {
							.sub-menu {
								border-right: 1px solid rgba(255,255,255,0.1) !important; } } } } }

			&:hover {

				.sub-menu {
					opacity: 1;
					transform: translateY(0);
					visibility: visible;
					z-index: 20;
					transition-delay: 0s, 0s, 0.3s; } } } }

	li {
		display: inline-block;
		margin: 0 20px;
		padding: 28px 0;


		a {
			color: #fff;
			text-transform: uppercase;
			font-size: 17px; } }

	> li.active {

		&:before {
			left: -6px; }

		&:after {
			right: -5px; } }

	> li.active {

		&:before, &:after {
			opacity: 1; } } }

.headroom--pinned {
	-ms-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0);
	position: fixed;
	-webkit-animation-name: headerSlideDown;
	animation-name: headerSlideDown; }

.headroom--pinned.hide-topbar.header-magic-line .header-inner {
	background: #000; }
.headroom--pinned.hide-topbar.header-magic-line .header-magic-line-inner {
	border-bottom: none; }

.headroom--pinned.hide-topbar.header-magic-line .header-inner #header-menu-magic-line li a {
	color: #fff; }

.hide-topbar.headroom--pinned.header-magic-line .user-login li a i {
	color: #fff; }

.headroom--pinned.hide-topbar.header-magic-line .header-inner #header-menu-magic-line li.menu-item-has-children .sub-menu {
	background: #000; }



.header-magic-line-inner {
	border-bottom: 1px solid #f60038; }

.headroom--pinned.hide-topbar .header-magic-line-inner {
	border-bottom: 1px solid #fff; }


#magic-line {
	position: absolute!important;
	bottom: -2px; left: 0;
	width: 100px;
	height: 4px;
	background: #f60038;
	padding: 0!important;
	border-radius: 5px; }

.header_player.style-fullwidth {
	position: relative;
	height: 80px;
	background: transparent;
	background: #111111;
	height: 100px;
	padding: 22px 0 0;
	height: 158px; }



.header_player.style-fullwidth {
	#main_player {
		display: none; }
	.current-tracks::after {
		display: none; } }

.header_player.style-fullwidth #nowPlaying {
	padding: 0 0 21px 15px;
	margin-top: -7px; }

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls {
	width: 80px;
	background: #252525;
	position: absolute;
	height: 80px;
	margin-top: 0;
	top: 0;
	left: 0; }


.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface {
	padding: 26px 0 13px 110px; }

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
	border: 0px solid #fff; }

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
	max-width: 60%;
	height: 6px; }

.header_player.style-fullwidth #nowPlaying .track-name {
	font-size: 16px;
	font-family: $font_primary;
	text-transform: uppercase; }

.header_player.style-fullwidth .current-tracks {
	width: 277px; }


.header_player.style-fullwidth .jp-audio .jp-type-playlist {
	height: 80px; }

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
	background: #f60038; }
.header_player.style-fullwidth .jp-audio .jp-volume-bar .jp-volume-bar-value {
	background: #f60038; }


.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress,.header_player.style-fullwidth .header_player .jp-audio .jp-volume-bar {
	background-color: #323232; }

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
	width: 140px;
	margin-left: 19px; }

.header_player.style-fullwidth button#playlist-toggle {
	float: right;
	margin-right: 25px; }

.header_player.style-fullwidth .jp-audio #playlist-toggle i {
	color: #f60038; }

.player-container-fullwidth {
	box-shadow: 0px 17px 15.5px 5.5px rgba(0, 0, 0, 0.32);
	background: #181818;
	margin: 0 6.5%; }


.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
	font-size: 29px;
	line-height: 80px;
	color: #f60038;
	margin-left: 6px; }

.header_player.style-fullwidth #nowPlaying {
	color: #424242;
	font-size: 14px; }


.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
	background: #d31014; }
.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
	background: #d31014; }



.banner-five {
	height: 840px;
	background-position: center;
	background-size: cover;
	position: relative;
	overflow: hidden; }

.banner-five::after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	background: url(../../media/background/brushbg.png);
	height: 280px;
	bottom: -48px;
	width: 100%; }


.banner-five {
	.baneer-five-content {
		position: absolute;
		top: 50%;
		left: 50%;
		text-align: center;
		-webkit-transform: translate(-50%, -25%);
		-ms-transform: translate(-50%, -25%);
		transform: translate(-50%, -25%);
		width: 100%;
		z-index: 99;

		h3 {
			color: #fff;
			font-size: 30px;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 3px;
			margin-top: 126px;
			font-family: $font_primary;
			position: relative; }
		.tim-slide-btn {
			border: 2px dashed #ff6c00;
			color: #ff6c00;
			margin-top: 75px;
			padding: 15px 60px;
			&:hover {
				background: transparent; } } } }


@media (max-width: 1440px) {

	#header-menu-magic-line {
		li {
			margin: 0 14px; } } }

@media (max-width: 1199px) {

	#header-menu-magic-line > li::before {
		top: 28px; }

	#header-menu-magic-line > li::after {
		bottom: 28px; }

	#header-menu-magic-line {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } } }

@media (max-width: 1024px) {
	#header-menu-magic-line {
		li {
			padding: 25px 0;
			margin: 0 10px;

			a {
				font-size: 14px; } } } }




/*--------------------------------------------------------------
  ##   Offsetmenu two
  --------------------------------------------------------------*/


.offset-menu-two {
	position: fixed;
	right: -380px;
	top: 0;
	width: 359px;
	background: rgba(0, 0, 0, 0.70);
	background-image: url("../../media/background/offset-menu-bg.png");
	background-position: top;
	background-repeat: no-repeat;
	background-size: auto;
	height: 100%;
	z-index: 999999;
	padding: 40px;
	transition: all .5s cubic-bezier(0, 1.09, 1, 1);
	-webkit-transition: all .5s cubic-bezier(0, 1.09, 1, 1);
	padding-top: 415px;
	.offset-social-two {
		margin-top: 40px;
		span {
			font-size: 20px;
			color: #fff;
			font-family: $font_heading;
			margin-bottom: 10px;
			display: inline-block; }
		ul {
			li {
				display: inline-block;
				border: 1px solid #f60038;
				width: 33px;
				height: 33px;
				text-align: center;
				transition: all 0.15s ease-in-out;
				a {
					color: #f60038;
					font-size: 15px;
					display: block;
					height: 100%;
					line-height: 29px; } } }
		> a {
			padding-bottom: 20px;
			border-bottom: 1px solid #f60038;
			margin-bottom: 50px;
			display: block;
			position: relative;
			&:after {
				content: '';
				position: absolute;
				left: 0;
				bottom: -2px;
				width: 30px;
				height: 3px;
				background: #f60038; } } } }
.offset-menu-two.open {
	right: 0;
	transition: all .5s cubic-bezier(0, 1.09, 1, 1); }


.offset-menu-two {
	> a {
		margin-bottom: 25px;
		display: block;
		position: absolute;
		right: 30px;
		top: 17px; }
	.footer-about {
		.footer-logo {
			max-width: 120px;
			margin-bottom: 30px;
			width: 100%;
			display: inline-block;
			margin-bottom: 15px;

			img {
				width: 100%; } }
		> p {
			color: #c8c6c6;
			line-height: 25px;
			margin-bottom: 25px; } } }
.offset-menu-two {
	.footer-contact {
		.contact-details {
			i {
				float: left;
				width: 30px;
				height: 40px;
				line-height: 26px;
				color: #f60038; }


			p {
				color: #fff;
				font-size: 16px;
				margin-bottom: 5px;
				margin-left: 30px; } } }
	h3 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 22px; } }



.offset-menu-two {
	.footer-about {
		padding-bottom: 30px;
		margin-bottom: 35px; }
	.footer-blog-inner {
		.footer-blog {
			border-bottom: 1px solid #26325c;
			margin-bottom: 20px;
			padding-bottom: 20px;

			&:last-child {
				border-bottom: 0; }

			.fea-image {
				float: left;
				position: relative;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 30px;
					color: #fff; } }

			.content {
				margin-left: 116px;
				padding-top: 5px;

				h3 {
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 0;

					a {
						color: #fff;
						font-size: 16px;

						&:hover {
							color: $color_primary; } } }

				.meta {
					color: $color_primary;
					font-size: 14px;

					&:hover {
						color: $color_primary; } } } } } }


.offset-menu-two .offset-social-two ul li a:hover {
	color: #fff; }

.offset-menu-two .offset-social-two ul li:hover {
	border: 1px solid #fff; }

/*--------------------------------------------------------------
  ##   Banner five
  --------------------------------------------------------------*/


.banner-five {
	figure {
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  height: 100vh;
	  width: 100vw;
	  padding-top: 250px;
	  text-align: center; } }
.banner-five {
	svg {
	  width: 90vw;
	  width: 700px; } }

.banner-five {
	svg > * {
	  fill: #1C1C1C;
	  stroke: #1C1C1C;
	  stroke-width: 0.85px; } }

#canvas {
	width: 100%;
	position: absolute;
	left: 100px;
	opacity: .4;
	-webkit-transform: rotate(45deg) scale(1.5);
	-ms-transform: rotate(45deg) scale(1.5);
	transform: rotate(45deg) scale(1.5);
	height: 1300px;
	bottom: -333px; }
.banner-five .baneer-five-content h3::after {
	position: absolute;
	content: '';
	left: 50%;
	top: 5px;
	height: 1px;
	width: 36%;
	background: rgb(255,255,255);
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(246,0,56,1) 14%, rgba(246,0,56,1) 85%, rgba(255,255,255,0) 100%);
	transform: translateX(-50%); }


.banner-five .baneer-five-content h3::before {
	position: absolute;
	content: '';
	left: 50%;
	bottom: 5px;
	height: 1px;
	width: 36%;
	background: rgb(255,255,255);
	background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(246,0,56,1) 14%, rgba(246,0,56,1) 85%, rgba(255,255,255,0) 100%);
	transform: translateX(-50%); }




/*--------------- Banner five text animation ------------*/
.sp-container {
	position: fixed;
	top: -135px;
	left: 0px;
	width: 100%;
	height: 100%;
	z-index: 0; }


.sp-content {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: 1000; }

.sp-container h2 {
	position: absolute;
	top: 50%;
	line-height: 100px;
	height: 90px;
	margin-top: -50px;
	font-size: 180px;
	width: 100%;
	text-align: center;
	color: transparent;
	text-shadow: 0px 0px 1px #110006;



	font-family: 'intro_inlineregular'; }

.sp-container h2.frame-1 {

	font-size: 180px;
	-webkit-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
	-moz-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
	-ms-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation-duration: 20s; }
.sp-container h2.frame-2 {

	-webkit-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
	-moz-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
	-ms-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation-duration: 20s; }

.sp-container h2.frame-3 {

	font-size: 180px;
	-webkit-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
	-moz-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
	-ms-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation-duration: 20s; }


.sp-container h2.frame-4 {
	font-size: 180px;

	-webkit-animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
	-moz-animation: animation-4  cubic-bezier(0, 1.09, 1, 1) infinite;
	-ms-animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
	animation-duration: 20s; }


.loader  *:after {
  animation-play-state: paused !important; }


.paralax {
	position: absolute;
	left: 0;
	top: -20px;
	width: 100%;
	height: 100%;
	overflow: hidden; }

















@keyframes animation-1 {
    0% {
    	opacity: .5;
    	transform: scale(1.3);
    	text-shadow: 0px 0px 40px #000;
    	color: transparent; }
    5% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    20% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    30% {
    	opacity: 0;
    	transform: scale(0);
    	text-shadow: 0px 0px 50px #000;
    	color: transparent; }
    100% {
    	opacity: 0;
    	transform: scale(0); } }


@keyframes animation-2 {
    0% {
    	opacity: 0; }
    20% {
    	opacity: 0;
    	transform: scale(1.3);
    	text-shadow: 0px 0px 40px #000;
    	color: transparent; }

    30% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    40% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    60% {
    	opacity: 0;
    	transform: scale(0);
    	text-shadow: 0px 0px 50px #000;
    	color: transparent; }
    100% {
    	opacity: 0; } }

@keyframes animation-3 {
    0% {
    	opacity: 0; }
    40% {
    	opacity: 0;
    	transform: scale(1.3);
    	text-shadow: 0px 0px 40px #000;
    	color: transparent; }
    60% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    70% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    80% {
    	opacity: 0;
    	transform: scale(0);
    	text-shadow: 0px 0px 50px #000;
    	color: transparent; }
    100% {
    	opacity: 0; } }

@keyframes animation-4 {
    0% {
    	opacity: 0; }
    70% {
    	opacity: 0;
    	transform: scale(1.3);
    	text-shadow: 0px 0px 40px #000;
    	color: transparent; }
    80% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    95% {
    	opacity: .8;
    	transform: scale(1);
    	text-shadow: 0px 0px 0px #000;
    	color: #000; }
    100% {
    	opacity: 0;
    	transform: scale(.5);
    	text-shadow: 0px 0px 50px #000;
    	color: transparent; } }


@media (min-width: 1501px) and (max-width: 1700px) {
	.header_player.style-fullwidth .current-tracks {
		width: 200px; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		max-width: 55%; } }



@media (min-width: 1200px) and (max-width: 1500px) {
	.header_player.style-fullwidth .current-tracks {
		width: 200px; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		max-width: 45%; } }




@media (min-width: 1200px) and (max-width: 1400px) {

	.sp-container h2.frame-2, .sp-container h2.frame-3,
	.sp-container h2.frame-4 {
		font-size: 120px; }

	.banner-five .baneer-five-content h3::after {
		width: 55%; }


	.banner-five .baneer-five-content h3::before {
		width: 55%; } }


@media (min-width: 992px) and (max-width: 1199px) {

	.sp-container h2.frame-2, .sp-container h2.frame-3,
	.sp-container h2.frame-4 {
		font-size: 100px; }

	.banner-five .baneer-five-content h3::after {
		width: 70%; }


	.banner-five .baneer-five-content h3::before {
		width: 70%; }

	.header_player.style-fullwidth .current-tracks {
		width: 200px; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		max-width: 36%; } }

@media (min-width: 768px) and (max-width: 991px) {

	.sp-container h2.frame-2, .sp-container h2.frame-3,
	.sp-container h2.frame-4 {
		font-size: 80px; }
	.sp-container h2.frame-1 {
		font-size: 130px; }

	.banner-five .baneer-five-content h3::after {
		width: 70%; }


	.banner-five .baneer-five-content h3::before {
		width: 70%; }

	#canvas {
		width: 100%;
		left: 0;
		height: 800px;
		bottom: 0; }
	.paralax {
		top: 77px; } }



@media (min-width: 576px) and (max-width: 767px) {

	.sp-container h2.frame-2, .sp-container h2.frame-3,
	.sp-container h2.frame-4 {
		font-size: 66px; }
	.sp-container h2.frame-1 {
		font-size: 66px; }

	#canvas {
		left: -24px;
		transform: rotate(35deg) scale(1.5); }
	.banner-five {
		height: 760px; }

	.paralax {
		top: 22%; }


	.banner-five .baneer-five-content h3::after {
		width: 77%; }


	.banner-five .baneer-five-content h3::before {
		width: 77%; } }





@media (min-width: 320px) and (max-width: 575px) {

	.sp-container h2.frame-2, .sp-container h2.frame-3,
	.sp-container h2.frame-4 {
		font-size: 30px; }
	.sp-container h2.frame-1 {
		font-size: 30px; }

	#canvas {
		left: -124px;
		transform: rotate(35deg) scale(1.5); }
	.banner-five {
		height: 660px; }
	.banner-five .baneer-five-content h3 {
		font-size: 14px;
		margin-top: 40px; }
	.banner-five .baneer-five-content h3::before ,
	.banner-five .baneer-five-content h3::after {
		display: none; }

	.banner-five .baneer-five-content .tim-slide-btn {
		padding: 7px 17px;
		font-size: 14px;
		max-width: 104px;
		margin-top: 35px; }

	.banner-five::after {
		height: 63px;
		bottom: -23px;
		background-size: contain;
		background-repeat: no-repeat; }
	.banner-five {
		height: 460px; }
	.sp-container {
		top: -28px; }

	.paralax {
		left: -72px;
		top: 25%; }
	#canvas {
		transform: rotate(0deg) scale(1.5);
		height: 400px;
		top: 0;
		left: 0; }

	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
		height: 75px;
		width: 60px; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls {
		width: 60px; }

	.header_player.style-fullwidth .jp-audio .jp-type-playlist .vel-wrap {
		display: none; }

	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		width: 100%; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface {
		padding: 26px 0 13px 80px; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-duration {
		display: none; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
		font-size: 22px; } }

@media (min-width: 400px) and (max-width: 575px) {

	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		width: 40%; }
	.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
		width: 80px;
		display: block; } }










