#video-intro {
	padding: 250px 0;
	background-size: cover;
	background-position: center;
	background-attachment: fixed; }

.intro-video {
	text-align: center; }

.video-btn {
	height: 85px;
	width: 85px;
	border: 2px solid #fff;
	border-radius: 50%;
	color: #fff;
	line-height: 82px;
	display: block;
	margin: 0 auto;
	font-size: 26px;
	margin-bottom: 20px;

	i {
		margin-left: 3px;
		color: #fff; }

	&:hover {
		background: $color_primary;
		border-color: $color_primary;


		i {
			color: #fff; } } }

.intro-video {
	h2 {
		font-size: 70px;
		font-weight: 600;
		color: #fff;
		margin: 0;
		letter-spacing: 5px; }
	h5 {
		font-size: 20px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 20px; }
	p {
		font-size: 18px;
		color: #fff;
		margin-bottom: 0px;
		font-family: $font_heading;
		font-weight: 400; }


	span {
		display: block;
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		font-family: $font_heading; } }


/* Watch Video */
#watch {
	background-size: cover;
	background-position: center;
	background-attachment: fixed;
	padding: 150px 0; }

.watch-inner {
	.tim-btn {
		color: #fff;
		border-color: #fff;
		font-size: 15px;
		text-transform: uppercase;
		font-weight: 500;

		&:hover {
			background: $color_primary;
			color: #fff;
			border-color: $color_primary; }

		&.tim-btn-bg {
			margin-left: 20px;
			background: $color_primary;
			border-color: $color_primary;

			&:hover {
				color: #363636;
				background: #fff;
				border-color: #fff; } } } }


@media (max-width: 768px) {
	#video-intro {
		padding: 150px 0; }

	.intro-video {
		h2 {
			font-size: 30px; }

		p {
			font-size: 18px; } }

	.watch-inner .tim-btn.tim-btn-bg {
		margin-top: 10px;
		margin-left: 10px; } }
