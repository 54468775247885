#event-about {
	padding: 100px 0; }

.event-thumb {
	img {
		max-width: 100%; } }

.event-content {
	h2 {
		font-size: 34px;
		font-weight: 600;
		text-transform: uppercase;
		margin-bottom: 20px;

		span {
			color: $color_primary; }

		em {
			font-style: normal;
			font-weight: 400; } } }

.event-content {

	p {
		margin-bottom: 0; }

	.event-details {
		margin: 30px 0;

		p {
			margin: 0;

			span {
				font-weight: 600;
				font-size: 18px;
				font-family: $font_heading; } } }



	h4 {
		font-size: 18px;
		font-weight: 600; }


	.tim-btn {
		padding: 12px 27px;
		text-transform: uppercase;
		margin-top: 30px;
		font-size: 16px;
		line-height: 20px; } }




@media (max-width: 991px) {
	.event-content {
		margin-top: 20px; } }

@media (max-width: 768px) {
	#event-about {
		padding: 50px 0; }

	.event-content {
		h2 {
			font-size: 26px; } } }
