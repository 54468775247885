#footer-3 {
	background-image: url(../../media/background/footer-bg.jpg);
	background-size: 100%;
	background-postion: center;
	padding: 260px 0 180px;
	background-repeat: no-repeat;
	background-size: cover;
	.section-title.style-four {
		margin-bottom: 20px;
		h2 {
			color: #fff; } } }

.footer-feed {

	ul {
		li {
			float: left;
			width: 12.5%;
			position: relative;
			a {
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					background: #000;
					opacity: .5;
					top: 0;
					left: 0;
					transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); }
				&:hover:before {
					opacity: 0;
					transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); } } } }
	padding-bottom: 40px;
	overflow: hidden;
	border-bottom: 1px solid #000; }

.footer-three-bottom {
	padding-top: 50px;
	border-top: 1px solid #1e1e1e;
	.footer-three-left {
		width: 60%;
		float: left;
		> a {
			padding-bottom: 30px;
			display: inline-block; }
		p {
			font-size: 16px;
			letter-spacing: 0.4px;
			line-height: 1.56;
			font-weight: 300;
			color: #6d6d6d; } }


	.footer-three-right {
		float: right;
		width: 40%;
		text-align: right; }
	.footer-social-three {
		ul {
			li {
				display: inline-block;
				margin-left: 11px;
				a {
					color: #5e5e5e;
					font-size: 14px;
					&:hover {
						color: #f60038; } } } } }

	.footer-three-menu {
		padding-bottom: 20px;
		li {
			line-height: 22px;
			a {
				color: #f60038;
				font-size: 14px;
				font-weight: 500;
				letter-spacing: 2.8px;
				text-decoration: underline;
				font-family: "Changa", sans-serif;
				text-transform: uppercase;
				&:hover {
					color: #fff; } } } } }


@media (min-width: 576px) and (max-width: 991px) {
	#footer-3 {
		padding: 150px 0 80px; } }


@media (min-width: 320px) and (max-width: 575px) {

	#footer-3 {
		padding: 150px 0 80px; }

	.footer-three-bottom .footer-three-right {
		width: 100%;
		text-align: left; }

	.footer-three-bottom .footer-three-left {
		width: 100%; } }

