.three-d-album {
	padding: 55px 0 0; }

.single-3d {
	flex: 0 0 16%;
	max-width: 16%;
	padding: 15px;
	img {
		box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.22), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
		transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
		z-index: 1;
		position: relative; } }

.threed-container-wrapper {
	perspective: 2950px; }

.threed-container-inner {
	transform: translate3d(-11%, -25%, 0) scale(1) rotateX(45deg) rotateY(-5deg) rotateZ(25deg);
	display: flex;
	flex-wrap: wrap; }

.three-d-album-width {
	width: 122%;
	margin-top: 300px; }



.single-3d:hover img {
	transform: scale(1.5);
	box-shadow: 0px 36px 67.9px 2.1px rgba(0, 0, 0, 0.50), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
	transition: all .6s ease-in-out;
	position: relative;
	z-index: 5; }

.three-d-album {
	background-image: url(../../media/background/play_bg.png);
	background-position: left 93%;
	background-repeat: no-repeat;
	background-size: auto;
	.more-album {
		position: absolute;
		right: 350px;
		margin-top: 54px;
		a {
			font-size: 14px;
			color: #959595;
			font-family: $font_heading;
			font-weight: 500;
			&:hover {
				i {
					margin-left: 20px;
					transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); } }
			i {
				color: #f60038;
				display: block;
				transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
				margin-left: 0; } } } }



@media (min-width: 992px) and (max-width: 1200px) {
	.single-3d {
		padding: 5px; } }


@media (min-width: 768px) and (max-width: 992px) {

	.threed-container-inner {
		transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }

	.single-3d {
		width: 20%;
		padding: 7px;
		flex: 0 0 20%;
		max-width: 20%; }
	.three-d-album-width {
		width: 100%;
		margin-top: 170px;
		padding: 0 21px; } }

@media (min-width: 768px) and (max-width: 991px) {
	.single-3d.empty-space {
		display: none; }
	.three-d-album {
		padding-bottom: 180px; } }



@media (min-width: 576px) and (max-width: 776px) {

	.threed-container-inner {
		transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }

	.single-3d {
		width: 25%;
		padding: 7px;
		flex: 0 0 25%;
		max-width: 25%; }
	.three-d-album-width {
		width: 100%;
		margin-top: 154px;
		padding: 0 15px; }
	.empty-space {
		display: none; }
	.banner-five .baneer-five-content h3 {
		font-size: 22px;
		margin-top: 53px; }
	.banner-five .baneer-five-content .tim-slide-btn {
		margin-top: 20px;
		padding: 12px 35px; }
	.three-d-album .more-album {
		margin-top: 0; } }





@media (min-width: 320px) and (max-width: 575px) {

	.threed-container-inner {
		transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg); }

	.three-d-album-width {
		width: 100%;
		margin-top: 170px;
		padding: 0 15px; }

	.three-d-album {
		background-image: none; }

	.single-3d {
		-ms-flex: 0 0 46%;
		flex: 0 0 46%;
		max-width: 46%;
		overflow: hidden;
		padding: 0;
		margin: 5px; }

	.empty-space {
		display: none; }
	.three-d-album-width {
		margin-top: 100px; }
	.three-d-album .more-album {
		left: 10px;
		margin-top: 0;
		right: 0; } }


