.single-show-ticket {

	transition: all .2s;
	border: solid 6px transparent;
	padding: 28px 17px 13px;
	margin-bottom: 16px;
	h6 {
		font-size: 30px;
		color: #000;
		line-height: 16px;
		margin-bottom: 0;
		line-height: 34px;
		font-weight: 700; }
	h5 {
		font-size: 20px;
		color: #000;
		font-weight: 400; }
	p {
		font-size: 14px;
		color: #000; }
	a {
		font-size: 14px;
		letter-spacing: 2.8px;
		color: #000;
		border-radius: 4px;
		text-align: center;
		font-weight: 500;
		z-index: 99;
		position: relative;
		height: 60px;
		text-align: center;
		width: 100%;
		display: block;
		overflow: hidden;
		padding-top: 17px;
		&:hover {
			color: #f60038; } } }


.single-show-ticket:hover {
	box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
	transition: all .5s;
	border: solid 6px #f60038;
	h6 {
		color: #f60038; }

	h5 {
		color:  #f60038; } }



.path {
	fill: transparent;
	stroke: #f60038;
	stroke-miterlimit: 10;
	stroke-width: 2px;
	opacity: 0; }


.st0 {
	fill: transparent;
	stroke: #ddd;
	stroke-miterlimit: 10;
	stroke-width: 2px; }

.a_hover:hover .path {
	stroke-dasharray: 1000;
	animation: dash 2s linear;
	opacity: 1;
	stroke-width: 2px;
	stroke: #f60038; }

.a_hover {
	position: relative;
	margin-top: 0;
	width: 144px;
	svg {
		position: absolute;
		left: 0;
		top: 0; } }

.a_hover i {
	position: absolute;
	bottom: 0;
	left: 5px;
	color: #f60038;
	font-size: 13px;
	opacity: 0; }

.a_hover:hover i {
	left: 24px;
	transition-duration: 1s;

	transition-delay: .5s;
	opacity: 1; }

@keyframes dash {
	from {
		stroke-dashoffset: 1000; }

	to {
		stroke-dashoffset: 0; } }



.date-time {
	width: 72%;
	position: relative;
	&:after {
		content: '';
		position: absolute;
		right: 0;
		background: #d7d7d7;
		height: 85px;
		width: 1px;
		top: -13px; } }


@media (min-width: 768px) and (max-width: 991px) {
	.date-time {
		width: 90%; } }



@media (max-width: 767px) {
	.a_hover {
		margin: 0 auto; } }

@media (min-width: 576px) and (max-width: 767px) {
	.single-show-ticket p {
		font-size: 15px; }
	.date-time {
		width: 100%; } }




@media (max-width: 767px) {
	.date-time::after {
		display: none; }
	.a_hover {
		float: left;
		margin-left: -6px; }

	.single-show-ticket {
		border: solid 1px transparent;
		margin-bottom: 40px;
		box-shadow: 0px -1px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75); }
	.single-show-ticket:hover {
		border: solid 1px #f60038;
		box-shadow: 0px -1px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75); }
	.single-show-ticket h5 {
		color: #f60038; } }



