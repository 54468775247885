#about {}

.about-details {
	img {
		max-width: 100%;
		height: auto;
		margin-bottom: 20px;
 }		// box-shadow: 0px 20px 12px 4px rgba(0, 0, 0, 0.1)

	h3 {
		font-size: 34px;
		font-weight: 500; }


	h4 {
		font-size: 18px;
		font-weight: 500;
		margin-bottom: 30px; }

	p {
		margin-bottom: 50px; } }



.artist-details-two {
	.artist-name {
		font-size: 36px;
		font-weight: 700;
		color: #fff; }

	.band-name {
		font-size: 18px;
		font-weight: 400;
		color: $color_primary;
		margin-bottom: 40px;

		span {
			color: #fff; } }

	.details {
		margin-bottom: 20px;

		p {
			color: #fff;
			margin-bottom: 50px; } } }

.artist-music-inner {
	padding: 20px 0;
	border-top: 2px solid #d9d9d9;
	display: flex;
	align-items: center;

	.aritist-music {
		padding: 0 30px;
		border-right: 2px solid #d9d9d9;
		-webkit-box-flex: 0;
		-ms-flex: 0 0 25%;
		flex: 0 0 25%;
		max-width: 25%;

		.icon {
			float: left;
			margin-right: 20px;

			i {
				color: $color_primary;
				font-size: 50px;
				line-height: 50px; } }

		.content {
			margin-left: 70px;

			p {
				color: $color_secondary;
				font-size: 30px;
				margin: 0;
				font-weight: 600;
				font-family: $font_heading;
				margin-bottom: 5px; }

			span {
				font-size: 20px;
				color: $color_secondary;
				font-family: $font_heading; } }


		&:last-child {
			border-right: 0; }

		&:first-child {
			padding-left: 0; } }

	&.artist-music-inner-two {
		border-top: 2px dashed #717171;
		border-bottom: 2px dashed #717171;
		margin-bottom: 40px;

		.aritist-music {
			border-color: #333;
			padding: 0 15px;

			.icon {
				margin-right: 0; }

			.content {
				margin-left: 60px;

				p, span {
					color: #fff; }

				span {
					display: block;
					line-height: 20px; } } } } }


.album-cover-img {
	position: relative;

	img {
		max-width: 100%;
		margin-bottom: 30px; }

	.popup-video-btn {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 50px;
		width: 50px;
		border: 2px solid #fff;
		text-align: center;
		border-radius: 50%;
		line-height: 46px;
		color: #fff;
		font-size: 20px;
		transform: translate(-50%, -50%);
		outline: 0; } }

.artist-social-link {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		display: inline-block;
		margin-right: 5px;

		a {
			display: inline-block;
			height: 40px;
			width: 40px;
			color: #fff;
			border: 1px dashed #fff;
			border-radius: 4px;
			text-align: center;
			line-height: 38px;

			&:hover {
				border-color: $color_primary;
				color: $color_primary; } } } }

/* Music Playlist One */
.hit-plalist {
	margin: 0;
	padding: 0;
	list-style: none;
	border: 1px solid #f5f5f5;

	li {
		padding: 16px 0;
		border-bottom: 1px solid #f5f5f5;
		display: -webkit-flex;
		display: -moz-flex;
		display: -ms-flex;
		display: -o-flex;
		display: flex;
		flex-wrap: wrap;

		&:last-child {
			border-bottom: 0; }

		.sm2_button {
			width: 7%;
			display: inline-block;
			font-family: $font_heading;
			color: #363636;
			font-size: 18px;
			text-align: center; }


		.song-name {
			width: 63%;
			text-align: left;
			display: inline-block; }

		span {
			width: 10%;
			display: inline-block;
			text-align: center;
			font-family: $font_heading; }


		.video-song, .song-download {
			width: 10%;
			display: inline-block;
			text-align: center;
			color: #363636;

			&:hover {
				color: $color_primary; } }


		&.active {
			background: $color_primary;

			.sm2_button, .video-song, .song-download, span {
				color: #fff; }

			.video-song, .song-download {
				&:hover {
					color: darken($color_primary,30%); } } } } }


/* About Two */

.artist-about {

	.artist-name {
		font-size: 34px;
		font-weight: 500;
		margin-top: 0;

		a {
			color: #363636;

			&:hover {
				color: $color_primary; } } }

	h6 {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 20px; }
	span {
		display: block;
		margin-bottom: 10px;
		color: #828282; }

	.tim-btn {
		padding: 7px 20px;
		font-size: 16px;
		margin-bottom: 20px;
		position: absolute;
		right: 30px;
		top: 0; }

	.content {
		padding-bottom: 15px;
		border-bottom: 1px solid #ebebeb;
		margin-bottom: 20px; }

	.alb-title {
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 20px; } }


.alb-single {
	display: flex;
	flex-wrap: wrap;

	.single-items {
		display: inline-block;
		width: 20%;
		padding-right: 5px;

		img {
			max-width: 100%; } } }

.album-feature {
	position: relative;

	img {
		max-width: 100%; }

	.artist-music-inner {
		position: absolute;
		width: 100%;
		bottom: 0;
		left: 0;
		background: rgba($color_primary, 0.8);
		border-top: 0;
		padding-left: 30px;

		.aritist-music {
			border-right: 1px solid rgba(255,255,255,0.5);


			.icon {
				i {
					color: #fff; } }

			.content {
				p, span {
					color: #fff; } } } } }


.sm2_button {
	height: 30px;
	width: 30px;
	position: relative;
	background: transparent;

	i {
		&:after {
				content: "\f04b";
				font: normal normal normal 14px/1 FontAwesome; } }

	&.sm2_playing {
		i {


			&:after {
				content: "\f04c";
				font: normal normal normal 14px/1 FontAwesome; } } } }


.artist-about.pt_70 .tim-btn {
	top: 70px; }



@media (max-width: 1600px) {
	.artist-about {
		margin-top: 0; } }

@media (max-width: 1440px) {
	.alb-single {
		margin-bottom: 20px; }

	.artist-music-inner {
		.aritist-music {
			padding: 0 15px;

			.content {
				margin-left: 55px;
				p {
					font-size: 26px; }
				span {
					font-size: 14px;
					display: block;
					line-height: 16px; } }

			.icon {
				margin-right: 0;
				i {
					font-size: 40px; } } } } }

@media (min-width: 768px) and (max-width: 1200px) {
	.artist-music-inner {
		.aritist-music {
			padding: 0 5px;

			.icon {
				i {
					font-size: 30px; } }

			.content {
				margin-left: 35px;

				p {
					margin: 0; } } } }

	.artist-about.pt_70 {
		padding-top: 0; }

	.artist-about.pt_70 .tim-btn {
		top: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
	.artist-about .artist-name {
		font-size: 26px; } }


@media (min-width: 768px) and (max-width: 1024px) {
	.artist-music-inner {
		&.artist-music-inner-two {

			.aritist-music {
				.content {
					margin-left: 40px; } } } }

	.artist-details-two {
		.band-name {
			margin-bottom: 20px; }

		.details {

			p {
				margin-bottom: 20px; } } } }

@media (min-width: 1024px) and (max-width: 1440px) {
	.artist-profile {
		.profoile-image {
			max-width: 190px !important; } }

	.artist-profile {
		padding: 30px 25px;

		.content {
			margin-left: 210px !important; } } }

@media (max-width: 767px) {
	.artist-profile {
		.profoile-image {
			float: none !important;
			margin: 0 auto; }

		.content {
			margin-left: 0 !important;
			text-align: center; } } }


@media (max-width: 991px) {
	.album-feature {
		margin-bottom: 30px; }

	.artist-about {
		&.pl__30 {
			padding-left: 0 !important; } } }

@media (min-width: 768px) and (max-width: 991px) {

	.artist-music-inner {
		.aritist-music {
			border-right: 0; } } }

@media (max-width: 768px) {
	.artist-music-inner {
		// margin-bottom: 20px

		.aritist-music {
			padding: 0 10px; } }
	.album-feature {
		.artist-music-inner {
			.aritist-music {
				border-right: 0; } } } }

@media (max-width: 580px) {

	.album-cover-img {
		margin: 0 auto 20px; }

	.artist-music-inner {
		display: block;

		.aritist-music {
			border-right: 0;
			padding-left: 0;
			width: 50%;
			float: left;
			margin-bottom: 10px;
			flex: 0 0 50%;
			max-width: 50%;

			.content {
				margin-left: 30px;

				p {
					font-size: 21px; } }

			.icon {
				i {
					font-size: 20px;
					line-height: 30px; } } } }

	.artist-about .tim-btn {
		position: relative;
		right: 0;
		top: 5px; }

	.artist-about.pt_70 .tim-btn {
		top: 6px; } }


