
.video-btn-four {
	height: 60px;
	width: 60px;
	border: 2px solid #f60038;
	border-radius: 4px;
	color: #f60038;
	line-height: 65px;
	display: block;
	text-align: center; }


.video-btn-four i {
	margin-left: 3px;
	color:  #f60038;
	font-size: 30px;
	transition: all 0.5s cubic-bezier(0, 1.09, 1, 1); }
.video-btn-four:hover i {
	color: #fff;
	transition: all 0.5s cubic-bezier(0, 1.09, 1, 1); }




.spotlight-inner {
	.video-player-three {
		position: absolute;
		right: 40px;
		bottom: 40px;
		-webkit-transition: all 1s ease-in;
		-o-transition: all 1s ease-in;
		transition: all 1s ease-in;
		-webkit-transform: translate3d(-25px, 0, 0);
		transform: translate3d(0px, 0, 0);
		.text {
			position: absolute;
			right: 76px;
			top: 2px;
			width: 255px;
			text-align: right;
			p {
				font-size: 20px;
				color: #fff;
				margin: 0;
				font-family: $font_heading; }
			span {
				font-size: 14px;
				color: #fff;
				font-family: $font_heading; } } } }


.video-player-three .video-btn-shade {
	height: 60px;
	width: 60px;
	border: 2px solid #000;
	border-radius: 4px;
	color: #f60038;
	line-height: 65px;
	display: block;
	text-align: center;
	position: absolute;
	left: -7px;
	top: 7px;
	z-index: -1;
	filter: blur(2.6px);
	opacity: 0.4;
	i {
		color: #000;
		font-size: 25px; } }


.spotlight-inn:hover .video-player-three {
	left: 0;
	transform: translate3d(40px,0, 0);
	transition: all 1s ease-in; }

.grid-60 {
	width: 60%;
	float: left;
	padding: 0 15px; }

.grid-40 {
	width: 40%;
	float: left;
	padding: 0 15px; }

.spotlight-inner {
	position: relative;
	box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1); }

.subscribe-two {
	position: relative;
	box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
	padding: 50px 40px;
	background: #fff;
	input {
		background: #f7f7f7;
		width: 100%;
		border: 0px solid;
		height: 60px;
		padding-left: 19px;
		margin-bottom: 19px; }
	button {
		border: 2px solid #000;
		border-radius: 4px;
		color: #000;
		padding: 7px 33px;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 2.8px;
		cursor: pointer;
		transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); } }

.subscribe-two button:hover {
	border: 2px solid #f60038;
	color: #f60038;
	transition: all 0.8s cubic-bezier(0, 1.09, 1, 1); }


.subscribe-two .section-title {
	margin-bottom: 21px; }


.wide_line_bg {
	position: relative;
	background-image: url(../../media/background/play_bg_right.png);
	background-position: right 93%;
	background-repeat: no-repeat;
	background-size: auto;
	&:after {
		position: absolute;
		content: '';
		left: -8%;
		top: 28%;
		width: 117%;
		height: 325px;
		z-index: -1;
		background-repeat: no-repeat;
		background: #f60038;
		transform: rotate(160deg); } }



@media (min-width: 992px) and (max-width: 1199px) {

	.wide_line_bg::after {
		left: -8%;
		top: 40%;
		width: 117%;
		height: 230px; } }



@media (min-width: 768px) and (max-width: 1300px) {

	.subscribe-two {
		padding: 25px 25px; }
	.subscribe-two .section-title.style-five p {
		width: auto; } }


@media (min-width: 768px) and (max-width: 991px) {
	.wide_line_bg::after {
		display: none; }
	.wide_line_bg {
		display: none; }
	.section-title.style-five p {
		width: auto; } }



@media (min-width: 320px) and (max-width: 767px) {
	.subscribe-two {
		padding: 30px; }
	.section-title.style-five p {
		width: auto; }

	.grid-60, .grid-40 {
		width: 100%; }
	.spotlight-inner {
		margin-bottom: 30px; }
	.wide_line_bg::after {
		display: none; }
	.wide_line_bg {
		background-image: none; } }

