#ticket {
	background-size: cover;
	background-position: center center;
	padding: 120px 0 120px; }

.live-ticket {
	h2 {
		font-size: 40px;
		color: #fff;
		line-height: 46px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: 10px;
		position: relative;
		margin-bottom: 0px;
		padding-bottom: 20px;

		span {
			color: $color_primary; }

		&:after {
			position: absolute;
			content: '';
			background-image: url(../img/dm2l.png);
			height: 15px;
			max-width: 200px;
			background-repeat: no-repeat;
			background-size: contain;
			width: 100%;
			left: 0;
			bottom: -8px;
			display: none; } }

	h4 {
		font-size: 18px;
		color: #fff;
		font-weight: 600;
		margin-bottom: 18px;

		span {
			color: $color_primary;
			font-weight: 700; } }


	p {
		color: #fff;
		line-height: 26px;
		font-size: 16px;
		margin: 0; }

	.tim-btn {
		color: #fff;
		border-color: #fff;

		&:hover {
			color: #fff;
			background: $color_primary;
			border-color: $color_primary; } } }

.ticket-thumb {
	img {
		max-width: 100%;
		max-width: 100%;
		grid-column: 1 / span 1;
		clip-path: polygon(0 0, 0 100%, 93% 100%, 100% 0); } }

.live-ticket-count {
	margin: 45px 0 45px;

	.countdown {
		max-width: 500px;

		.CountdownContent {
			display: inline-block;
			height: 93px;
			width: 104px;
			font-size: 36px;
			font-weight: 600;
			color: #fff;
			font-family: $font_heading;
			text-align: center;
			position: relative;
			background-image: url("../../media/background/1.png");
			background-size: contain;
			margin-right: 20px;
			background-repeat: no-repeat;
			padding: 24px 0;

			.CountdownLabel {
				display: block;
				text-align: center;
				font-size: 16px;
				font-family: $font_primary; } } } }



@media (min-width: 1200px) and (max-width: 1440px) {
	.live-ticket {
		h2 {
			font-size: 30px;
			line-height: 35px; }

		h4 {
			margin-bottom: 10px; }

		p {
			br {
				display: none; } }


		.tim-btn {
			padding: 8px 25px; } }

	.live-ticket-count {
		margin: 25px 0 15px;

		.countdown {
			.CountdownContent {
				width: 85px;
				font-size: 30px;
				padding: 13px 0;

				.CountdownLabel {
					font-size: 12px; } } } } }


@media (min-width: 991px) and (max-width: 1200px) {
	.live-ticket {
		h2 {
			font-size: 25px;
			line-height: 25px;
			margin-bottom: 20px; }


		h4 {
			margin-bottom: 10px; }

		p {
			font-size: 14px;
			line-height: 22px;

			br {
				display: none; } }


		.tim-btn {
			padding: 5px 22px; } }

	.live-ticket-count {
		margin: 20px 0 15px;

		.countdown {
			.CountdownContent {
				width: 70px;
				font-size: 25px;
				padding: 12px 0;
				height: 62px;
				line-height: 20px;

				.CountdownLabel {
					font-size: 12px; } } } } }

@media (max-width: 992px) {

	.live-ticket {
		padding: 30px; }

	.ticket-thumb {
		img {
		    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0); } } }

@media (max-width: 768px) {
	.live-ticket {

		padding: 30px 15px;
		text-align: center;

		h2 {
			font-size: 35px;
			line-height: 40px; }
		p {
			br {
				display: none; } } }

	.live-ticket-count {

		.countdown {
			max-width: 100%;


			.CountdownContent {
				height: 90px;
				width: 100px;
				font-size: 25px;
				padding: 18px 0;
				margin-right: 10px;
				background-size: cover;
				margin-bottom: 15px;

				.CountdownLabel {
					font-size: 16px; } } } }

	#ticket {
		padding: 50px 0 50px; } }

@media (max-width: 500px) {

	.live-ticket {
		h2 {
			font-size: 30px; }

		h4 {
			font-size: 16px; } } }

