#album {
	padding: 120px 0; }

#album-two {
	padding: 120px 0 110px; }

.album-filter-button {
	margin: 0 auto 50px;
	padding: 0;
	list-style: none;
	max-width: 1000px;
	text-align: center;
	position: relative;

	&:after {
		position: absolute;
		content: '';
		height: 1px;
		border-bottom: 1px dashed #dedede;
		width: 90%;
		top: 50%;
		z-index: -1;
		left: 27px; }


	li {
		display: inline-block;
		height: 60px;
		width: 60px;
		line-height: 58px;
		text-align: center;
		border: 1px dashed #dedede;
		border-radius: 50%;
		margin-left: 160px;
		background: #fff;

		a {
			display: block;
			text-transform: uppercase;
			color: #363636;
			font-weight: 500; }

		&:first-child {
			margin-left: 0 !important; }


		&:hover, &.current {
			background: $color_primary;
			border-color: $color_primary;
			border-style: solid;

			a {
				color: #fff; } } }

	&.album-filter-button-two {
		max-width: 760px;

		&:after {
			width: 90%;
			left: 0; }

		li {
			height: auto;
			width: auto;
			line-height: 20px;
			padding: 10px 15px;
			border-radius: 8px;
			border: 1px dashed #dedede;
			margin-left: 90px;
			transition: all 0.3s ease-in-out;

			&:hover, &.current {
				background: #fff;
				border-color: $color_primary;
				border-style: solid;
				border: 1px dashed $color_primary;

				a {
					color: $color_primary; } } } } }


.tim-album-items {
	margin: 0;
	padding: 0;
	list-style: none;

	.grid-item {
		width: 24.5%;
		padding: 15px;

		&:before {
			left: 15px;
			top: 15px;
			right: 15px;
			bottom: 15px; } } }

.tim-isotope {
	position: relative;
	&.tim-isotope-1 {
		.tim-album-item {
			display: inline-block;
			text-align: center;
			border-radius: 8px;
			position: relative;
			position: relative;
			overflow: hidden;

			img {
				border-radius: 8px;
				box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
				max-width: 100%;
				height: auto; }

			&:before {
				position: absolute;
				content: '';
				left: 15px;
				top: 15px;
				right: 15px;
				bottom: 15px;
				background: rgba(0,0,0, 0.7);
				padding: 10px;
				border-radius: 8px;
				opacity: 0;
				transition: all 0.3s ease-in-out; }

			.album_details_wrap {
				position: absolute;
				left: 0;
				bottom: 45px;
				width: 100%;
				visibility: hidden;
				transition: all 0.3s ease-in-out;
				opacity: 0;

				h4 {
					font-size: 24px;
					font-weight: 500;
					color: #fff;
					padding-bottom: 15px;
					margin-bottom: 15px;
					position: relative;

					&:before, &:after {
						content: '';
						position: absolute;
						background: #fff;
						left: 50%;
						transform: translateX(-50%);
						transition: all 0.3s ease-in-out; }

					&:before {
						width: 30px;
						height: 1px;
						left: 50%;
						bottom: 0; }

					&:after {
						width: 30px;
						height: 5px;
						bottom: -2px; } }


				h5 {
					font-size: 15px;
					font-weight: 500;
					color: #fff;
					margin-bottom: 10px; }

				.tim-btn {
					color: #fff;
					padding: 5px 30px;
					text-transform: uppercase;
					font-size: 16px;
					margin-top: 15px;

					&:hover {
						background: darken($color_primary, 10%); } } }

			&:hover {
				&:before {
					opacity: 1; }
				.album_details_wrap {
					visibility: visible;
					opacity: 1;

					h4 {
						&:before {
							width: 100px; } } } } } }
	&.tim-isotope-2 {
		.tim-album-item {
			position: relative;

			&:before {
				position: absolute;
				content: '';
				left: 15px;
				bottom: 15px;
				right: 15px;
				top: 15px;
				border-radius: 8px;
				background: rgba($color_primary,0.58);
				opacity: 0;
				transition: all 0.3s ease-in-out;
				z-index: 1; }

			.tim-isotope-grid__img {
				border-radius: 8px;
				overflow: hidden;

				img {
					width: 100%;
					border-radius: 8px;
					box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
					transition: all 0.3s ease-in-out; } }

			.popup-modal {
				position: absolute;
				top: 40px;
				left: 40px;
				color: #fff;
				opacity: 0;
				visibility: hidden;
				z-index: 2;
				transform: scale(0);
				transition: all 0.3s ease-in-out; }

			.gallery_details_wrap {
				text-align: left;
				position: absolute;
				left: 40px;
				bottom: 40px;
				z-index: 2;

				.gallery-info {
					opacity: 0;
					visibility: hidden;

					h4 {
						font-size: 26px;
						font-weight: 700;
						color: #fff;
						margin: 0;
						text-transform: uppercase;
						transform: translateY(-15px);
						transition: all 0.3s ease-in-out;
						transition-delay: 100ms;
						opacity: 0; }

					h5 {
						font-size: 15px;
						color: #fff;
						margin: 0;
						transform: translateY(-15px);
						transition: all 0.3s ease-in-out;
						opacity: 0; } } }


			&:hover {
				&:before {
					opacity: 1; }

				.tim-isotope-grid__img {
					img {
						transform: scale(1.1); } }

				.popup-modal, .gallery-info {
					visibility: visible;
					opacity: 1; }

				.popup-modal {
					transform: scale(1); }

				.gallery-info {
					h4, h5 {
						transform: translateY(0);
						opacity: 1; } } } } } }

.grid-item, .grid-sizer {
	width: 25%; }


/* Exclusive Album */
.exclusive-album-items {
	padding: 0;
	.album-item {
		position: relative;
		margin: 0 5px 10px;


		&:before {
			position: absolute;
			content: '';
			height: 100%;
			width: 100%;
			background: rgba($color_primary, 0.5);
			opacity: 0;
			transition: all 0.3s ease-in-out; }

		.album-image {
			img {
				max-width: 100%; }



			.exse-album-name {
				position: absolute;
				top: 0;
				left: 0;

				.icon {
					height: 40px;
					width: 45px;
					line-height: 40px;
					background: $color_primary;
					text-align: center;
					margin-right: 5px;
					display: inline-block;
					color: #fff;
					font-size: 22px;
					vertical-align: top; }

				h4 {
					color: #fff;
					background: $color_primary;
					font-size: 20px;
					font-weight: 600;
					padding: 10px 20px;
					display: inline-block;
					line-height: 20px;
					margin: 0;
					vertical-align: top;
					text-transform: uppercase; }

				&.exse-album-bottom {
					top: auto;
					left: auto;
					bottom: 0;
					right: 0; } } }

		.content {
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 10px 20px;

			h4 {
				font-size: 26px;
				color: #fff;
				font-weight: 700;
				text-transform: uppercase;
				margin: 0;
				line-height: 26px;
				transform: translateY(15px);
				transition: all 0.3s ease-in-out;
				opacity: 0; }

			span {
				font-size: 15px;
				color: #fff;
				text-transform: uppercase;
				transform: translateY(15px);
				transition: all 0.3s ease-in-out;
				transition-delay: 100ms;
				opacity: 0;
				display: inline-block; }

			.icon {
				position: absolute;
				right: 20px;
				top: 23px;
				opacity: 0;
				transition: all 0.3s ease-in-out;

				a {
					color: #fff;
					font-size: 25px;

					&:hover {
						color: $color_primary; } } } }

		&:hover {
			&:before {
				opacity: 1; }

			h4, span {
				transform: translateY(0);
				opacity: 1; }

			.icon {
				opacity: 1; } } } }




@media (max-width: 1280px) {
	.album-filter-button {
		max-width: 600px;

		li {
			margin-left: 70px; }

		&.album-filter-button-two {
			max-width: 760px; }

		&:after {
			width: 90%;
			left: 0; } }

	.tim-album-items {
		.grid-item, .grid-sizer {
			width: 33%; } } }

@media (max-width: 1024px) {
	.album-filter-button {
		max-width: 600px;

		&.album-filter-button-two {
			li {
				margin-left: 60px; }

			&:after {
				left: 30px;
				transform: translateX(0);
				width: 90%; } } } }


@media (max-width: 992px) {
	.album-filter-button {
		max-width: 450px;

		li {
			height: 50px;
			width: 50px;
			line-height: 46px;
			margin-left: 45px; } } }

@media (max-width: 768px) {

	#album {
		padding: 50px 0; }

	.tim-album-items {
		.grid-item, .grid-sizer {
			width: 50%; } }

	.album-filter-button {
		&.album-filter-button-two {
			max-width: 425px;

			&:after {
				display: none; }
			li {
				padding: 8px 12px;
				margin-left: 25px;

				a {
					font-size: 13px; } } } }

	#album-two {
		padding: 60px 0 50px; }

	.exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
		top: 0;
		left: 0; }

	#gallery .section-title h2 {
		margin-bottom: 0; }

	#gallery .album-filter-button {
		margin: 0 auto 20px; }


	.album-filter-button {
		margin: 0 auto 20px; } }



@media (max-width: 580px) {

	.album-filter-button {
		max-width: 330px;

		&:after {
			left: 40px;
			width: 76%; }

		li {
			margin-left: 9px;
			height: 40px;
			width: 40px;
			line-height: 37px;

			a {
				font-size: 10px; } }

		&.album-filter-button-two {
			max-width: 500px; } } }

@media (max-width: 500px) {
	.tim-album-items {
		.grid-item {
			width: 100%; } }

	.album-filter-button {
		&.album-filter-button-two {
			li {
				margin-left: 10px;
				margin-bottom: 10px; } } } }






/*--------------- Single Album  ----------------*/


.single-album-info {
	box-shadow: 0px 1px 17.85px 3.15px rgba(23, 28, 62, 0.05);
	.padding-remove {
		padding: 0; }
	.single-album-details {
		padding: 70px 90px 70px 60px;
		.details-top {
			border-bottom: 2px solid #f8f8f8;
			padding-bottom: 20px;
			position: relative;
			padding-bottom: 10px;
			&:after {
				content: '';
				position: absolute;
				width: 200px;
				height: 2px;
				background: #f2adc3;
				bottom: -2px;
				left: 0; } }


		h6 {
			font-size: 26px;
			color: #333333;
			font-weight: 600; }

		p {
			font-size: 16px;
			color: #585757;
			font-family: $font_heading; }
		ul {
			padding-top: 46px;
			width: 79%;
			padding-bottom: 60px;
			font-family: $font_heading;
			li {
				list-style: none;
				font-size: 16px;
				color: #585757;
				font-weight: 500;
				margin-bottom: 12px;
				span {
					font-weight: 400;
					float: right;
					width: 250px; } } }

		.single-album-description {
			h6 {
				font-size: 20px;
				color: #333333;
				position: relative;
				padding-bottom: 11px;
				margin-bottom: 25px;
				&:after {
					position: absolute;
					content: '';
					bottom: 0;
					background: #979797;
					width: 100px;
					height: 2px;
					left: 0; } }

			p {
				font-size: 16px;
				color: #585757;
				font-family: $font_heading; } }

		.single-album-price {
			margin-top: 43px;
			.left {
				float: left;
				display: inline-block;
				margin-right: 55px;
				span {
					font-size: 20px;
					color: $color_primary;
					font-weight: 600;
					font-family: $font_heading; }

				p {
					color: #333333;
					font-size: 16px;
					font-family: $font_heading;
					text-transform: uppercase;
					margin-bottom: 0; } }
			a {
				padding: 9px 29px;
				font-size: 16px;
				font-weight: 500;
				text-transform: uppercase;
				margin-right: 30px;
				border-radius: 4px;
				font-family: $font_heading;
				background: #e43a90;
				color: #fff;
				border: transparent;
				display: inline-block; } } } }


.album-info-wrapper {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 50%;
		background: #edf5fb;
		height: 100%; } }


.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
	display: block!important;
	position: relative;
	bottom: 100px;
	right: 0;
	width: 100%;
	overflow-y: hidden;
	overflow-x: hidden;
	background: #fff;
	padding: 15px;
	margin-top: 163px; }

.single-album-player {
	.current-tracks {
		z-index: 5; }

	.header_player .jp-audio .jp-type-playlist .jp-interface {
		padding: 36px 0;
		border: 5px solid #ebebeb;
		background-image: -moz-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%) {
	  	background-image: -webkit-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%);
	  	background-image: -ms-linear-gradient( 90deg, rgb(238,101,143) 0%, rgb(163,60,191) 100%);
	  	box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09); } }

	.header_player {
		background: transparent;
		position: relative; }
	.header_player .player-container {
		max-width: 100%; }
	.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
		color: #333333;
		font-family: $font_heading; }
	.artist-name {
		font-size: 14px; } }



.single-album-player #main_player {
	width: 0 !important; }


.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
	padding-top: 34px;
	padding-bottom: 60px; }


.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
	font-size: 20px;
	line-height: 37px;
	color: #e43a90;
	margin-left: 8px; }

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
	height: 40px;
	width: 40px;
	padding-left: 2px;
	background: #fff; }

.single-album-player .header_player #nowPlaying {
	padding: 30px 0 0 38px; }
.single-album-player .current-tracks::after {
	top: 23px; }

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
	padding-top: 8px; }
.single-album-player .header_player #nowPlaying .track-name {
	font-size: 18px; }


.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
	height: auto; }

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
	background: transparent; }
.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
	display: block;
	border-bottom: 1px solid #ebebeb; }
.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
	background: transparent; }

.single-album-player .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover > div > a {
	color: $color_primary;
	font-family: "Changa", sans-serif; }
.single-album-player .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
	margin-left: 6px; }

.single-album-player .music-playlist-one {
	width: 100%; }

.single-album-player #playlist-toggle {
	display: none; }


.related-album-single .swiper-slide {
	text-align: center; }



@media (min-width: 992px) and (max-width: 1199px) {

	.single-album-info .single-album-details {
		padding: 70px 10px 70px 30px; }
	.single-album-info .single-album-details ul li span {
		width: 187px; }
	.album-info-wrapper::before {
		display: none; }
	.single-album-player .current-tracks {
		display: block; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
		padding-top: 3px; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
		bottom: 145px; }
	.single-album-player .current-tracks::after {
		right: 60px; } }



@media (min-width: 768px) and (max-width: 991px) {

	.single-album-info .single-album-details {
		padding: 70px 10px 70px 30px; }
	.single-album-info .single-album-details ul li span {
		width: 187px; }
	.album-info-wrapper::before {
		display: none; }
	.single-album-info .single-album-details ul li {
		font-size: 14px; }

	.single-album-info .single-album-details ul {
		width: 100%; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
		padding-top: 35px; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
		padding-top: 2px; }
	.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		max-width: 30%; }
	.single-album-player .current-tracks {
		display: block;
		width: 220px; } }

@media (min-width: 320px) and (max-width: 767px) {

	.single-album-info .single-album-details {
		padding: 70px 10px 70px 30px; }
	.single-album-info .single-album-details ul li span {
		width: 187px; }
	.album-info-wrapper::before {
		display: none; }
	.single-album-info .single-album-details ul li {
		font-size: 14px; }

	.single-album-info .single-album-details ul {
		width: 100%; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
		padding-top: 35px; }
	.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
		padding-top: 2px; }
	.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
		max-width: 55%; } }

@media (min-width: 320px) and (max-width: 575px) {
	.single-album-info .single-album-details .single-album-price .left {
		margin-right: 26px; }
	.single-album-info .single-album-details ul li span {
		width: 160px; }
	.related-album-single .section-title {
		margin-bottom: 0; } }





