#lesson {
	padding: 120px 0 90px; }

.lesson-box {
	position: relative;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
	max-width: 400px;
	margin: 0 auto 30px;

	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0, 0.4);
		opacity: 0;
		transition: all 0.3s ease-in-out;
		z-index: 11; }

	img {
		border-radius: 8px;
		vertical-align: top;
		-webkit-transform-origin: 100% 100%;
		-ms-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
		transition: all 0.3s ease-in-out;
		max-width: 100%; }

	i {
		color: #fff;
		font-size: 30px;
		position: absolute;
		left: 30px;
		top: 30px;
		z-index: 22; }


	.content {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: 20px;
		width: 100%;
		text-align: center;
		z-index: 22;

		h3 {
			color: #fff;
			font-weight: 700;
			margin: 0;
			transition: all 0.3s ease-in-out;


			a {
				color: #fff;

				&:hover {
					color: $color_primary; } } }

		p {
			color: #c5c5c5;
			margin-bottom: 0;
			transition: all 0.3s ease-in-out;
			transition-delay: 0.05s; } }

	&:hover {
		&:before {
			opacity: 1; }

		.content {
			h3, p {
				transform: translateY(-50px); } }

		img {
			-webkit-transform: scale(1.1);
			transform: scale(1.1); } } }



@media (max-width: 767px) {

	#lesson {
		padding: 50px 0 20px; } }
