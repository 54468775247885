#product {
	&.section-padding {
		padding-bottom: 65px; } }


.product-top-menu {
	margin-bottom: 30px;
	overflow: hidden;
	width: 100%;

	span {
		font-family: $font_heading;
		margin-right: 5px; }

	#product-show {
		padding: 0 22px 0 6px;
		margin-right: 20px; }

	.product-select {
		border: 1px solid #ebebeb;
		background: transparent;
		font-family: $font_heading;
		font-size: 16px;
		line-height: 16px;
		color: #7a7979;
		outline: 0;
		padding: 0 10px; }

	.product-menu-wrapper {
		width: 450px;
		float: right; } }


.tim-product-btn-vw {
	input {
		display: none; }

	label {
		cursor: pointer; } }

/* Product */
ul {
	&.products {
		li {
			&.product {
				text-align: left;

				.product-thumb {
					position: relative;
					overflow: hidden;
					margin: 0 auto 20px;

					img {
						max-width: 100%;
						margin-bottom: 0; }

					.new {
						position: absolute;
						top: 10px;
						right: -10px;
						background: $color_primary;
						color: #fff;
						font-size: 16px;
						font-weight: 500;
						padding: 4px 17px; }

					&:before {
						position: absolute;
						content: '';
						background: rgba($color_primary,0.7);
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						opacity: 0;
						transition: all 0.3s ease-in-out; }

					.add_to_cart_button {
						padding: 8px 25px;
						// background: $color_primary
						border: 1px solid #fff;
						font-family: $font_heading;
						color: #fff;
						text-transform: uppercase;
						font-weight: 500;
						font-size: 16px;
						display: inline-block;
						position: absolute;
						bottom: -100%;
						left: 50%;
						transform: translateX(-50%);
						margin: 0;
						border-radius: 4px;
						transition: all 0.5s ease-in-out;


						&:hover {
							background: #434343;
							color: #fff;
							border-color: #434343; } }

					&:hover {
						.add_to_cart_button {
							bottom: 20px; }

						&:before {
							opacity: 1; } } }

				.woocommerce-loop-product__title {

					font-family: $font-heading;
					color: #363636;
					font-size: 24px;
					font-weight: 500;
					display: inline-block;
					transition: all 0.3s ease-in-out;
					margin-bottom: 0;

					a {
						font-family: $font-heading;
						color: #363636;
						font-size: 24px;
						font-weight: 500;
						display: inline-block;
						transition: all 0.3s ease-in-out;
						margin-bottom: 0;

						&:hover {
							color: $color_primary; } }

					&:hover {
						color: $color_primary; } }

				.price {
					display: block;
					float: right;
					margin: 0;

					.woocommerce-Price-amount {
						font-size: 24px;
						font-weight: 500;
						color: $color_primary;
						font-family: $font-heading; } } }


			&.product-two {
				text-align: center;

				.woocommerce-loop-product__title {
					font-size: 22px; }


				.star-rating {
					float: right; }


				.product-thumb {
					position: relative;

					&:before {
						position: absolute;
						content: '';
						background: rgba(0,0,0,0.3); }

					.product-details {
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%,-50%);
						padding: 10px;
						background: #fff;
						box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.08);
						border-radius: 5px;
						width: 160px;
						opacity: 0;
						visibility: visible;
						transition: all 0.3s ease-in-out;

						a {
							width: 40px;
							display: inline-block;
							text-align: center;

							i {
								color: #636363;
								transition: all 0.3s ease-in-out;
								font-size: 20px; }

							&:nth-child(2) {
								border-right: 1px solid #d1d1d1;
								border-left: 1px solid #d1d1d1; }

							&:hover {

								i {
									color: $color_primary; } } } }
					.new {
						left: 0;
						right: auto;
						font-family: $font_heading;

						&.sale {
							right: 0;
							left: auto;
							background: #d2d40b; } }

					&:hover {
						.product-details {
							opacity: 1; }

						.share-link {
							opacity: 1;
							visibility: visible;

							li {
								transform: scale(1); } } } }
				.star-rating {
					font-size: 14px;
					span {
						&:before {
							font-size: 14px;
							color: $color_primary; } } }
				.price {
					float: none;

					ins {
						margin-left: 0;
						text-decoration: none; }

					del {
						.woocommerce-Price-amount {
							font-size: 18px;
							color: #898989;
							margin-left: 10px; } } }

				.product-description {
					display: none; }

				.share-link {
					position: absolute;
					bottom: 50px;
					text-align: center;
					width: 100%;
					list-style: none;
					opacity: 0;
					visibility: hidden;

					li {
						display: inline-block;
						height: 35px;
						width: 35px;
						border-radius: 4px;
						position: relative;
						margin-right: 10px;
						text-align: center;
						line-height: 35px;
						transform: scale(0);
						transition: all 0.3s ease-in-out;

						&:before {
							position: absolute;
							height: 30px;
							width: 30px;
							background: #fff;
							content: '';
							transform: rotate(45deg);
							z-index: 0;
							left: 3px;
							box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.23);
							border-radius: 4px;
							transition: all 265ms ease-in; }

						a {
							display: block;
							position: relative;
							z-index: 1;
							line-height: 30px; }

						&:nth-child(2) {
							transition-delay: 0.01s; }

						&:nth-child(3) {
							transition-delay: 0.05s; }

						&:nth-child(3) {
							transition-delay: 0.07s; }

						&:nth-child(4) {
							transition-delay: 0.09s; }

						&:hover {
							&:before {
								transform: rotate(0deg);
								border-radius: 50%; } }

						&.fa-col {
							a {
								color: #4868ab; } }

						&.tw-col {
							a {
								color: #1da2f2; } }

						&.ins-col {
							a {
								color: #f23751; } }

						&.dri-col {
							a {
								color: #ee1370; } } } }


				.ajax_add_to_cart {
					border-radius: 10px;
					padding: 10px 18px;
					position: relative;
					font-size: 16px;

					&:before {
						position: absolute;
						content: '';
						left: 20px;
						height: 20px;
						width: 2px;
						background: rgba(255,255,255, 0.5);
						top: 12px;
						left: 43px; }


					i {
						margin-right: 20px; } } } } } }

/* Product 3 Column */
.site-main {
	.columns-3 {
		ul {
			&.products {
				li {
					&.product {
						width: 33.33%;
						padding: 0 15px;
						margin: 0 0 40px; } } }

			&.tim-product-view--list {
				li.product-two {
					width: 98% !important;
					text-align: left;
					border: 1px solid #ebebeb;
					padding: 0;
					display: flex;
					align-items: center;

					.product-thumb {
						max-width: 31%;
						margin-bottom: 0; }


					.product-details-content {
						padding: 0 20px;
						width: 69%;

						.woocommerce-loop-product__title {
							font-size: 30px;
							margin-bottom: 20px; }

						.product-description {
							display: block; }

						.price {
							margin-bottom: 20px; }

						p {
							margin-bottom: 30px;
							font-size: 16px; }

						.add_to_cart_button {
							display: inline-block;
							padding: 15px 35px;
							border: 1px solid #e4e4e4;
							color: #363636;
							font-size: 18px;
							line-height: 18px;
							border-radius: 4px;
							font-family: $font_heading;
							font-weight: 500;

							i {
								margin-right: 10px; }

							&:hover {
								background: $color_primary;
								border-color: $color_primary;
								color: #fff; } } } } } } } }

/* Product 4 Column */
.site-main {
	.columns-4 {
		ul {
			&.products {
				li {

					&.product {
						margin-right: 0;
						width: 25%;
						padding: 0 15px;
						margin-bottom: 50px; } } } } } }

.quickview-slider {
	swiper-slide {
		img {
			width: 100%; } }

	.slider-nav {
		.slick-slide {
			padding: 0 3px; } } }

.product-cart {
	margin: 40px 0 60px;

	.quantity {
		position: relative;
		background: #FFF;
		box-shadow: 0 2px 20px rgba(0,0,0,0.09);
		width: 90px;
		height: 50px;
		border: none;
		border-radius: 5px;
		float: left;
		padding: 11px 12px;
		margin-right: 10px;

		.minus, .plus {
			cursor: pointer;
			color: #363636;
			font-size: 12px;

			&:focus {
				background: transparent;
				color: #363636; } }

		input {
			width: 51%;
			margin: 0 auto;
			border: none;
			padding: 0;
			text-align: center;
			font-size: 16px; } }

	.tim-cart-btn {
		background: $color_primary;
		padding: 10px 17px;
		color: #FFF;
		border-radius: 4px;
		transition: all 0.3s ease-in-out;
		font-size: 18px;
		font-weight: 500;
		border: 0;
		font-family: $font_heading;
		cursor: pointer;

		i {
			margin-right: 5px; }

		&:hover {
			background: #333;
			color: #FFF; } } }

.product-details {
	.close-menu {
		right: 15px;
		top: 0px;
		padding: 0;
		height: auto;
		width: auto;
		line-height: 1;
		cursor: pointer; }

	.product-title {
		font-size: 26px; }

	.product-sub-title {
		font-size: 20px; }

	.price {
		margin-bottom: 30px;

		ins {
			margin-right: 10px;
			text-decoration: none; }

		ins, del {
			font-size: 20px;
			font-weight: 600; } }

	.woocommerce-product-details__short-description {
		margin-bottom: 30px; } }

.product_meta {
	.sku_wrapper {
		margin: 10px 0; }

	.posted_in {
		margin: 10px 0; }

	.tagcloud {
		a {
			font-size: 14px;
			padding: 3px 15px;
			border-radius: 10px; } }

	.posted_in {
		a {
			color: #333;
			font-size: 14px;
			margin: 3px;
			&:hover {
				color: $color_primary; } } } }

.woocommerce-product-rating {
	.star-rating {
		display: inline-block;
		margin-right: 10px;
		font-size: 14px;
		height: 19px;

		&:before {
			font-size: 14px; } } }

.share-wrap {
	margin-top: 20px;
	h3 {
		font-size: 18px;
		display: inline-block;
		margin-right: 10px; } }

.product-share-link {
	margin: 0;
	padding: 0;
	list-style: none;
	display: inline-block;

	li {
		display: inline-block;
		margin-right: 5px;

		a {
			display: block;
			height: 35px;
			width: 35px;
			border-radius: 4px;
			text-align: center;
			line-height: 35px;
			color: #fff;
			transition: all .3s ease-in-out;

			&.facebook-bg {
				background: #305fb3; }

			&.twitter-bg {
				background: #5eaade; }

			&.google-plus-bg {
				background: #d7482a; }

			&.pinterest-bg {
				background: #bd081c; }

			&:hover {
				background: $color_primary; } } } }

/* Shop Page */
.shop {
	padding: 100px 0; }

.single-product {
	padding: 100px 0 60px; }

.single-product-details {
	.product-title {
		font-size: 32px;
		line-height: 30px;
		margin-bottom: 20px; }

	.price {
		margin-bottom: 50px;
		ins {
			margin-right: 10px;
			text-decoration: none; }

		ins, del {
			font-size: 26px;
			font-weight: 600; } }

	.star-rating {
		span {
			&:before {
				color: $color_primary; } } }

	.review-link {
		color: #363636; }

	.woocommerce-product-details__short-description {
		margin-bottom: 30px; } }

/* Quick View */
.quickview-wrapper {
	position: fixed;
	max-width: 760px;
	border-radius: 5px;
	top: 50%;
	// left: 0
	// right: 0
	// bottom: 0
	opacity: 0;
	visibility: hidden;
	backface-visibility: hidden;
	display: inline-block;
	transition: all 0.3s ease-in-out;
	margin: 0 auto;
	overflow-x: hidden;
	overflow-y: auto;

	.quickview-content {
		background: #fff;
		padding: 15px;
		transition: all 0.5s ease-in-out; }

	&.open {
		visibility: visible;
		transform: translate(0, -50%);
		opacity: 1;

		.quickview-content {
			transform: translateY(0); } } }

.quickview-slider {
	.slider-for, .slider-nav, .slider-for1, .slider-nav1 {
		.slick-slide {
			outline: 0;
			img {
				max-width: 100%; } } }


	.slider-for, .slider-for1 {
		margin-bottom: 15px; }

	.slider-nav, .slider-nav1 {
		.slick-slide {
			cursor: pointer; } } }

/* Product Single */
.product-tab-wrapper {
	.product-tabs {
		margin: 50px 0 30px;
		list-style: none;
		text-align: center;
		border-top: 1px solid #ededed;
		text-align: center;
		display: inherit;


		li {
			display: inline-block;

			a {
				padding: 10px 20px;
				text-decoration: none;
				color: #444;
				display: inline-block;
				transition: all 0.3s ease-in-out;
				border-top: 1px solid transparent;
				font-weight: 600;

				&:hover, &.active {
					color: $color_primary;
					border-color: $color_primary; } } } }
	.tab-content {
		.tab-pane {
			padding: 0; } } }

.comments-title {
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 20px; }

.review-list {
	margin: 0;
	padding: 0;
	list-style: none;

	.review-comment {
		margin-bottom: 20px;
		.user-avatar {
			height: 70px;
			width: 70px;
			float: left;
			border-radius: 50%;
			overflow: hidden;

			img {
				width: 100%;
				height: auto; } }

		.comment-content {
			margin-left: 90px;

			.name {
				font-size: 16px;
				font-weight: 700;
				margin: 0; }

			.star-rating {
				span {
					&:before {
						color: $color_primary; } } }

			.comment-meta {
				margin-bottom: 10px;
				.star-rating {
					li {
						font-size: 12px;
						margin-right: 0; } }

				.post-date {
					font-size: 12px;
					color: #a7a7a7; } } } } }

.rating-title {
	font-size: 22px;
	font-weight: 600; }

.review-form {

	textarea, input {
		border: 1px solid #c9c9c9;
		border-radius: 5px;
		outline: none;
		font-size: 14px;
		width: 100%;
		padding: 10px; }

	textarea {
		height: 150px; }

	input {
		height: 50px;
		margin-top: 20px; }

	.gp-btn {
		max-width: 250px;
		margin: 30px 0 0 15px;
		display: inline-block;
		width: 100%;
		text-align: center;
		background: transparent;
		color: #343434;
		border-radius: 4px;
		padding: 12px 0px;
		font-weight: 500;
		-webkit-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
		border: none;
		outline: none;
		cursor: pointer;
		text-transform: uppercase;
		border: 1px solid #c9c9c9;

		&:hover {
			background: $color_primary;
			color: #fff;
			border: 1px solid $color_primary; } } }

.shop_attributes {
	width: 100%;

	tbody {
		border: 1px solid #dcdcdc;
		tr {
			height: 40px;
			border-bottom: 1px solid #dcdcdc;


			th {
				width: 30%;
				border-right: 1px solid #dcdcdc;
				padding-left: 20px; }

			td {
				padding-left: 20px;
				p {
					margin-bottom: 0; } } } } }

.related-product {
	padding-top: 30px;
	margin-top: 30px;
	border-top: 1px solid #dcdcdc;

	.product-title {
		font-size: 24px;
		font-weight: 600;
		margin-bottom: 30px;
		color: #363636; } }

/* Rating Star Widgets Style */
.rating {
	margin-bottom: 20px;

	li {

		cursor: default;
		display: inline-block;
		font-size: 20px;
		list-style-type: none; } }

.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
	color: #a5a5a5; }


.rating:hover .star,
.rating.vote-cast .star {
	color: $color_primary; }


/* Color Checkbox */
.color-checkboxes {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-bottom: 30px;

	h4 {
		margin-right: 10px;
		font-size: 18px;
		font-weight: 600; }

	.col-Blue-label {
		background: #2196f3; }

	.col-Green-label {
		background: #8bc34a; }

	.col-Yellow-label {
		background: #fdd835; }

	.col-Orange-label {
		background: #ff9800; }

	.col-Red-label {
		background: #f44336; }

	.col-Black-label {
		background: #222222; } }

.color-checkbox {
	width: 20px;
	height: 20px;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	margin-right: 7px;
	position: relative;
	border-radius: 16px;
	-webkit-transition: -webkit-transform 0.1s linear;
	transition: -webkit-transform 0.1s linear;
	transition: transform 0.1s linear;
	transition: transform 0.1s linear, -webkit-transform 0.1s linear;
	cursor: pointer;

	&.bordered {
		-webkit-box-shadow: 0 0 0 2px #f0f0f0 inset;
		box-shadow: 0 0 0 2px #f0f0f0 inset;

		&:after {
			border-color: black; } }

	&:after {
		content: '';
		display: block;
		width: 6px;
		height: 10px;
		position: absolute;
		top: 3px;
		left: 7px;
		border: 2px solid white;
		border-width: 0 2px 2px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		opacity: 0;
		-webkit-transition: opacity 0.1s;
		-o-transition: opacity 0.1s;
		transition: opacity 0.1s;
		text-align: center; } }

.color-checkbox:hover,
.color-checkbox__input:focus + .color-checkbox {
	-webkit-transform: scale(1.2);
	transform: scale(1.2); }

.color-checkbox__input:checked + .color-checkbox:after {
	opacity: 1; }

.color-checkbox__input {
	visibility: hidden;
	width: 0;
	pointer-events: none;
	position: absolute; }

/* Product Size */
.options__item {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	margin-top: 10px;
	align-items: center;

	h4 {
		font-size: 18px;
		font-weight: 600;
		margin-right: 10px;
		margin-bottom: 0; }
	span {
		display: inline-block;
		height: 30px;
		width: 30px;
		border: 1px solid #363636;
		color: #363636;
		border-radius: 3px;
		text-align: center;
		left: 30px;
		margin-right: 5px;
		cursor: pointer;
		font-weight: 700;
		transition: all 0.3s ease-in-out;

		&:hover, &.active {
			background: $color_primary;
			color: #fff;
			border-color: $color_primary; } } }


/* Cart */
#cart {
    padding: 100px 0;
    background: #f7f7f7; }

.woocommerce {
    table {
        &.shop_table {
            margin-bottom: 60px;
            border: none;
            border-collapse: collapse;
            background: #fff;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 15px 34px rgba(0, 0, 0, 0.09);
            margin: 0 -1px 24px 0;
            text-align: left;
            width: 100%;

            th {
                border: 0;
                text-transform: uppercase;
                position: relative; }

            td {
                padding: 6px 12px;
                border: 0;
                text-transform: uppercase;
                position: relative; }

            thead {
                tr {
                    background: #f7f7f7;

                    th {
                        border-bottom: 1px solid #f7f7f7;
                        background: #e9e9e9;
                        font-size: 15px;
                        font-weight: 700;
                        line-height: 28px;
                        letter-spacing: 0.1em;
                        padding: 15px 30px;
                        text-align: center; } } }

            tbody {
                tr {
                    border-bottom: 1px solid #f7f7f7;

                    td {
                        &.product-item {
                            a {
                                color: #202020;
                                text-decoration: none;
                                font-weight: 600;

                                &.remove {
                                    display: inline-block;
                                    vertical-align: middle;
                                    width: 40px;
                                    height: 40px;
                                    font-size: 16px;
                                    line-height: 40px;
                                    text-align: center;
                                    background: #e9e9e9;
                                    transition: all 0.4s ease-in-out;
                                    position: absolute;
                                    left: 20px;
                                    top: 20px;
                                    border-radius: 100%;
                                    color: red !important;
                                    text-decoration: none;
                                    border: 0; } }

                            img {
                                width: 70px;
                                height: 70px;
                                margin-left: 70px;
                                margin-right: 15px;
                                border-radius: 3px; } }

                        &.product-price {
                            line-height: 28px;
                            letter-spacing: 0.1em;
                            text-align: center;
                            border: 1px solid #f7f7f7; }

                        &.actions {
                            padding: 20px;
                            .coupon {
                                float: left;
                                input {
                                    margin-bottom: 0;
                                    &.input-text {
                                        margin-right: 10px;
                                        max-width: 200px;
                                        padding: 6px 15px;
                                        border: 2px solid #f7f7f7;
                                        border-radius: 25px;
                                        font-size: 14px; }

                                    &.button {
                                        background: #333;
                                        color: #FFF;

                                        &:hover {
                                            background: #191919;
                                            color: #FFF; } } } }

                            .button {
                                box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18); } } } } } } }

    .cart-collaterals {
        .cart_totals {
            .grand-total {
                .total-wrap {
                    padding: 10px 20px;
                    background: #fff;
                    border-radius: 15px;

                    > ul {
                        margin: 0;
                        padding: 0;

                        > li {
                            list-style: none;
                            padding: 10px 0;
                            border-bottom: 1px solid #f7f7f7;

                            .title {
                                display: inline-block;
                                width: 59%;
                                font-size: 12px;
                                font-weight: 900;
                                line-height: 30px;
                                letter-spacing: 0.1em;
                                text-transform: uppercase; }

                            span[data-title="Subtotal"],  span[data-title="Total"] {
                                display: inline-block;
                                width: 39%;
                                font-size: 14px;
                                font-weight: 600;
                                font-style: italic;
                                line-height: 24px;
                                letter-spacing: 0.1em;
                                text-align: right; } } } } } } }

    .grand-total {
        h4 {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
            color: #333;
            margin-bottom: 20px; } }


    .wc-proceed-to-checkout {
        padding-top: 20px;
        .gp-btn {
            padding: 12px 35px;
            color: #FFF;
            font-size: 14px;
            display: inline-block;
            margin: auto;
            float: none;
            text-align: center;
            border-radius: 25px;
            background: $color_primary;
            font-size: 14px;
            border: none;
            box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18); } } }


.text-active, .active > .text, .active > .auto .text {
    display: none !important; }

.active>.text-active, .active>.auto .text-active {
    display: inline-block !important; }

/* Product Responsive */

@media (min-width: 1200px) {
	.quickview-wrapper {
		min-height: 600px; } }

@media (max-width: 1380px) {
	.site-main {
		.columns-3 {
			ul {

				&.tim-product-view--list {
					li.product-two {

						.product-thumb {
							max-width: 45%; }



						.product-details-content {
							width: 55%;

							p {
								font-size: 14px;
								line-height: 22px;
								margin-bottom: 20px; } }

						.product-details-content {
							.add_to_cart_button {
								padding: 12px 20px; } } } } } } } }

@media (max-width: 1280px) {
	.site-main {
		.columns-4 {
			ul {
				&.products {
					li {
						&.product {
							width: 33.33%; } } } } } }

	.site-main {
		.columns-3 {
			ul.products {
				width: 100%;
				li.product {
					width: 50%;
					float: left; } } } } }

@media (max-width: 1024px) {
	.site-main {
		.columns-3 {
			ul {
				&.tim-product-view--list {
					li.product-two {
						.product-thumb {
							float: none; }
						.product-details-content {
							margin-left: 0; } } } } } } }



@media (max-width: 992px) {
	.site-main {
		.columns-4 {
			ul {
				&.products {
					li {
						&.product {
							width: 50%;
							float: left; } } } } } }

	.site-main {
		.columns-3 {
			ul.tim-product-view--list {
				li.product-two {
					.product-details-content {
						.woocommerce-loop-product__title {
							margin-bottom: 5px;
							font-size: 22px;
							margin-bottom: 10px; }

						.price {
							margin-bottom: 5px; }

						p {
							margin-bottom: 5px;
							margin-bottom: 15px; } } } } } }

	.widget.widget-shop .widget-title-shop {
		font-size: 20px; }

	#range-slider p #amount {
		width: 104px; }

	.fil-btn {
		padding: 6px 12px;
		font-size: 15px; } }




@media (max-width: 576px) {

	.quickview-wrapper {
		width: 60%;

		&.open {
			    transform: translate(0, 3%); } }

	.product-details {
		margin-top: 20px; } }


@media (max-width: 576px) {
	.single-product-details {
		.product-title {
			margin-top: 20px; } }

	ul.products li.product .product-thumb .add_to_cart_button {
		padding: 8px 11px; }

	.product-top-menu .product-menu-wrapper {
		width: auto;
		float: none; } }


@media (max-width: 500px) {
	.site-main {
		.columns-4 {
			ul {
				&.products {
					li {
						&.product {
							width: 100%; } } } } } }

	.site-main {
		.columns-3 {
			ul.products {
				width: 100%;
				li.product {
					width: 100%; } } } }
	.site-main {
		.columns-3 {
			ul.tim-product-view--list {
				li.product-two {
					display: inherit;
					width: 300px !important;
					margin: 0 auto 30px;
					float: none;

					.product-thumb {
						max-width: 100%; }

					.product-details-content {
						width: 100%;
						padding: 30px 20px 35px; } } } } }

	.quickview-wrapper {
		width: 90%; }

	.product-top-menu span:first-child {
		display: none; }

	.product-top-menu #product-show {
		display: none; }

	.product-top-menu .product-select {
		width: 110px; }

	.shop.site-main {
		padding: 50px 0; }

	.color-checkboxes h4 {
		font-size: 16px; } }



@media (min-width: 501px) and (max-width: 575px) {

	ul.products li.product .woocommerce-loop-product__title a {
		font-size: 21px; }

	ul.products li.product .price .woocommerce-Price-amount {
		font-size: 21px; }

	ul.products li.product .product-thumb .add_to_cart_button {
		font-size: 12px; } }



@media (max-width: 767px) {

	#product.section-padding {
		padding-bottom: 0px; }

	.quickview-wrapper {
		top: 5%; }

	.single-product {
		padding: 50px 0 50px; }

	.woocommerce-product-rating.float-right {
		float: left !important;
		margin-bottom: 17px; }

	.single-product-details .product-title {
		font-size: 23px; }

	.single-product-details .price {
		margin-bottom: 27px;
		display: block;
		overflow: hidden;
		width: 100%; }

	.single-product-details .price ins, .single-product-details .price del {
		font-size: 20px;
		font-weight: 600; } }


