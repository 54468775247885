.latest-relese {
	background-image: url(../../media/background/3.png);
	background-postion: top;
	background-size: 100%;
	background-repeat: no-repeat;


	.section-title {
		margin-bottom: 95px; } }


.latest-album-left {

	.cover-img {
		float: left;
		box-shadow: 0px 50px 108.9px 12.1px rgba(0, 0, 0, 1); }

	.albun-details {
		overflow: hidden;
		padding-left: 30px;
		h6 {
			font-size: 20px;
			color: #fff;
			font-weight: 400;
			margin-bottom: 26px;
			line-height: 11px; }

		p {
			font-size: 14px;
			color: #565656;
			font-family: "Roboto", sans-serif;
			line-height: 21px;
			span {
				color: $color_three; } } } }


.latest-album-right {
	padding-left: 128px;
	h6 {
		font-size: 20px;
		color: #fff;
		font-weight: 400;
		margin-bottom: 26px;
		line-height: 11px; } }


.latest-album-btn {
	.sm2_button {
		color: #f60038;
		border: 2px solid #f60038;
		width: 58px;
		height: 58px;
		display: block;
		text-align: center;
		line-height: 60px;
		border-radius: 3px;
		i:after {
			font-size: 27px; } } }
.latest-album-btn .sm2_button:hover {
	color: #fff; }

.player-main {
	text-align: center; }



.record-player {
	position: relative;
	margin-top: 70px;
	width: 691px;
	margin: 0 auto;
	margin-top: 60px;
	.player-main-shade {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		height: 691px;
		width: 691px;
		-webkit-box-shadow: 15px 13px 17px 5px rgba(0,0,0,0.48);
		-moz-box-shadow: 15px 13px 17px 5px rgba(0,0,0,0.48);
		box-shadow: 15px 13px 17px 5px rgba(0,0,0,0.48);
		border-radius: 100%;
		box-shadow: 0px 17px 36.86px 1.14px rgba(0, 0, 0, 0.45); }
	.record-key {
		position: absolute;
		top: -24px;
		right: -189px; } }




@keyframes color_change {
	0% {
		transform: rotate(0deg); }

	100% {
		transform: rotate(360deg); } }


.player-main {
	animation: color_change 5s linear infinite;
	transition: all 2s;
	animation-play-state: paused; }


.player-main img {
	transform: rotate(0deg);
	transition: all 2s; }

.player-main.active {
	animation-play-state: running; }

.record-player .record-key {
	top: -24px;
	right: -189px;
	transform: rotate(0deg);
	transform-origin: 149px 147px;
	transition: all 1s; }

.record-player .record-key.active {
	transform: rotate(15deg);
	transform-origin: 149px 147px;
	transition: all 1s; }





@keyframes move {
  100% {
    transform: translate3d(300px, 100px,0px); } }

.bubble-wrap {
	position: absolute;
	top: 36%;
	left: 10%;
	-webkit-transform: rotate(39deg);
	-ms-transform: rotate(39deg);
	transform: rotate(47deg);
	z-index: -1;
	opacity: 0;
	transition: all 0.3s; }

.bubble-wrap-right {
	position: absolute;
	top: 53%;
	right: 46%;
	-webkit-transform: rotate(39deg);
	-ms-transform: rotate(39deg);
	transform: rotate(9deg);
	z-index: -1;
	opacity: 0; }

@keyframes bub_opacity {
	0% {
		opacity: 1; }

	100% {
		opacity: 0; } }





.bubble {

	position: absolute {
  	opacity: 0; }
	animation: move 3s infinite reverse;
	background-image: url(../../media/music/1.png);
	background-repeat: no-repeat;
	background-size: contain; }


.bubble-wrap.active, .bubble-wrap-right.active {
	opacity: 0.2;
	transition: all 0.3s; }

.bubble.icon-1 {
	background-image: url(../../media/music/1.png); }

.bubble.icon-2 {
	background-image: url(../../media/music/2.png); }

.bubble.icon-3 {
	background-image: url(../../media/music/3.png); }

.bubble.icon-4 {
	background-image: url(../../media/music/4.png); }

.bubble.icon-5 {
	background-image: url(../../media/music/5.png); }

@for $i from 1 through 500 {
  .bubble:nth-child(#{$i}) {
     $size: random(30)+px;
     height: $size;
     width: $size;
     animation-delay: -$i * .9s;
     transform: translate3d( (random(1000) * 1px),  (random(1000) * 1px), (random(2000) * 1px)); } }




@media (min-width: 768px) and (max-width: 1199px) {
	.record-player .record-key {
		top: -24px;
		right: -117px;
		width: 169px;
		transform-origin: 95px 95px; }

	.record-player .player-main-shade {
		height: 400px;
		width: 400px; }
	.record-player {
		width: 400px; }

	.bubble-wrap {
		left: 21%; }

	.bubble-wrap-right {
		right: 62%; }

	.record-player .record-key.active {
		transform-origin: 95px 95px; } }



@media (min-width: 768px) and (max-width: 991px) {

	.latest-album-right {
		padding-left: 0; }
	.latest-relese .section-title {
		margin-bottom: 30px; }
	.latest-album-left .albun-details p {
		font-size: 12px;
		line-height: 19px; }
	.latest-album-left .albun-details h6 {
		margin-bottom: 15px; } }


@media (min-width: 576px) and (max-width: 767px) {
	.record-player .record-key {
		top: -24px;
		right: -117px;
		width: 169px;
		transform-origin: 95px 95px; }

	.record-player .player-main-shade {
		height: 380px;
		width: 380px; }
	.record-player {
		width: 380px; }

	.bubble-wrap {
		left: 21%; }

	.bubble-wrap-right {
		right: 62%; }

	.record-player .record-key.active {
		transform-origin: 95px 95px; }

	.latest-album-right {
		padding-left: 0; }
	.latest-relese .section-title {
		margin-bottom: 30px; }
	.latest-album-left .albun-details p {
		font-size: 12px;
		line-height: 19px; }
	.latest-album-left .albun-details h6 {
		margin-bottom: 15px; }

	.cover-img {
		display: none; }
	.bubble-wrap,.bubble-wrap-right {
		display: none; }
	.latest-relese {
		background-size: cover;
		background-position: 45% -124px; } }





@media (min-width: 320px) and (max-width: 575px) {
	.record-player .record-key {
		top: -4px;
		right: -65px;
		width: 80px;
		transform-origin: 45px 45px; }

	.record-player .player-main-shade {
		height: 200px;
		width: 200px; }
	.record-player {
		width: 200px; }

	.bubble-wrap {
		left: 21%; }

	.bubble-wrap-right {
		right: 62%; }

	.record-player .record-key.active {
		transform-origin: 45px 45px; }

	.latest-album-right {
		padding-left: 0; }
	.latest-relese .section-title {
		margin-bottom: 30px; }
	.latest-album-left .albun-details p {
		font-size: 12px;
		line-height: 19px; }
	.latest-album-left .albun-details h6 {
		margin-bottom: 15px; }

	.cover-img {
		display: none; }
	.bubble-wrap,.bubble-wrap-right {
		display: none; }
	.latest-relese {
		background-size: cover;
		background-position: 55% -85px; }
	.latest-album-left .albun-details {
		padding-left: 0;
		margin-bottom: 10px; }
	.record-player {
		margin-top: 10px; }
	.section-title.style-five {
		margin-bottom: 30px; }
	.latest-album-btn .sm2_button {
		width: 42px;
		height: 42px;
		line-height: 47px; } }




