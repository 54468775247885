#footer-2 {
	background: #030825;

	.footer-inner {
		padding: 80px 0 40px; }

	.footer-title {
		color: #fff;
		font-size: 20px;
		font-weight: 500;
		text-transform: uppercase;
		margin-bottom: 70px;
		margin-top: 20px;
		position: relative;
		z-index: 1;
		&:after {
			content: '';
			position: absolute;
			left: 32px;
			top: -5px;
			width: 39px;
			height: 39px;
			background-image: url(../../media/blog/square.png);
			background-repeat: no-repeat;
			z-index: -1; } } }

#footer-2 {
	.footer-about {
		.footer-logo {
			max-width: 120px;
			width: 100%;
			display: inline-block;
			margin-bottom: 66px;
			margin-top: 17px;

			img {
				width: 100%; } }
		> p {
			color: #c8c6c6;
			line-height: 25px;
			margin-bottom: 25px; } } }
#footer-2 {
	.footer-contact {
		.contact-details {
			i {
				float: left;
				width: 30px;
				height: 40px;
				line-height: 26px; }


			p {
				color: #fff;
				font-size: 16px;
				margin-bottom: 5px;
				margin-left: 30px; } } }
	h3 {
		font-size: 20px;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 22px; } }




/* Footer Latest Post */
#footer-2 {
	.footer-blog-inner {
		.footer-blog {
			border-bottom: 1px solid #151a35;
			margin-bottom: 20px;
			padding-bottom: 40px;

			&:last-child {
				border-bottom: 0; }

			.fea-image {
				float: left;
				position: relative;

				i {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					font-size: 30px;
					color: #fff; } }

			.content {
				margin-left: 116px;
				padding-top: 5px;

				h3 {
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 0;

					a {
						color: #fff;
						font-size: 16px;

						&:hover {
							color: $color_primary; } } }

				.meta {
					color: $color_primary;
					font-size: 14px;

					&:hover {
						color: $color_primary; } } } } } }


#footer-2 {
	.footer-menu {
		ul {
			li {
				list-style: none;
				padding-bottom: 10px;
				border-bottom: 1px solid #151a35;
				margin-bottom: 19px;
				position: relative;
				padding-left: 17px;
				&:after {
					content: '';
					position: absolute;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 4px 0 4px 6px;
					border-color: transparent transparent transparent #f84196;
					left: 0;
					top: 9px; }
				a {
					font-size: 16px;
					color: #fff; } } } } }


/* Tags */
#footer-2 {
	.tagcloud {
		a {
			display: inline-block;
			padding: 6px 28px;
			font-size: 16px;
			color: #fff;
			background: transparent;
			margin: 0 5px 10px 0;
			font-family: $font_heading;
			border: 2px solid #262969;
			border-radius: 5px;

			&:hover {
				background: $color_primary;
				border: 2px solid $color_primary; } } } }


/* Widget Newsletter */

#footer-2 {
	.footer-newsletter {

		p {
			color: #fff; }

		#widget-newsletter {
			display: flex;
			input {
				width: 100%;
				height: 45px;
				background: #fff;
				padding: 10px 20px;
				outline: 0;
				border: 0;

				&::placeholder {
					color: #afafaf; } }

			.submit {
				background: $color_primary;
				height: 45px;
				line-height: 45px;
				text-align: center;
				border: 0;
				padding: 0 17px;
				color: #fff;
				cursor: pointer;
				transition: all 0.3s ease-in-out;

				&:hover {
					background: #444; } } } } }

/*  Instagram Widget */

#footer-2 {
	.instagram-feed {
		a {
			max-width: 120px;
			width: 100%;
			display: inline-block;
			margin-bottom: 10px;
			padding-right: 5px;

			img {
				max-width: 100%; } } } }

/* Footer Social Link */

#footer-2 {
	.footer-social-link {
		height: 60px;
		li {
			display: inline-block;
			width: 25%;
			background: #000;
			margin-left: -4px;
			text-align: center;
			padding: 16px 0;
			position: relative;
			z-index: 1;
			overflow: hidden;

			&:before {
				position: absolute;
				content: '';
				height: 61px;
				width: 100%;
				top: -61px;
				background: $color_primary;
				z-index: 0;
				left: 0;
				transition: all 0.3s ease-in-out; }

			a {
				display: block;
				font-size: 18px;
				font-weight: 500;
				color: #fff;
				position: relative;
				z-index: 2; }

			&.fb-bg {
				background: #4868ab; }

			&.yotube-bg {
				background: #ff2500; }

			&.tw-bg {
				background: #1da2f2; }

			&.pin-bg {
				background: #bd1a1c; }

			&:hover {
				&:before {
					top: 0; } } } } }

#footer-2 {
	.copyright-text {
		padding: 28px 0;
		background: #000310;
		p {
			margin: 0;
			color: #fff;
			font-family: $font_heading;
			padding-top: 6px;

			a {
				color: $color_primary; } } }
	.footer-social-2 {
		ul {
			float: right;
			li {
				display: inline;
				margin-left: 5px;
				a {
					color: #fff;
					position: relative;
					background: url(../../media/blog/square.png);
					background-repeat: no-repeat;
					width: 36px;
					height: 40px;
					display: inline-block;
					text-align: center;
					line-height: 41px;
					background-position: 5px center; }
				&:after {
					position: absolute;
					left: 0;
					top: 0; } } } } }

@media (min-width: 1200px) and (max-width: 1450px) {

	.tagcloud {
		a {
			padding: 10px 23px; } }

	.footer-newsletter #widget-newsletter input {
		width: 85%; } }



@media (min-width: 576px) and (max-width: 768px) {

	#footer-2 .tagcloud a {
		padding: 5px 16px; } }

@media (max-width: 576px) {
	.footer-social-link {
		li {
			padding: 12px 0;

			a {
				font-size: 16px; } } }

	.copyright-text {
		p {
			text-align: center; } }

	#footer .footer-inner {
		padding: 50px 0 40px; }

	.footer-newsletter #widget-newsletter input {
		width: 83%; }

	#footer-2 .footer-social-2 ul {
		float: none;
		text-align: center;
		margin-top: 10px; } }



@media (min-width: 768px) and (max-width: 991px) {
	.instagram-feed a {
		max-width: 107px; }

	.tagcloud a {
		padding: 10px 20px; } }


