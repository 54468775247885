#newsletter {
	padding: 90px 0;
	background: #e8e8e8; }


.newsletter {
	h2 {
		font-size: 36px;
		font-weight: 600; }

	h4 {
		font-size: 18px;
		font-family: $font_primary;
		margin-bottom: 0; } }

.section-dark {
	h2, h4 {
		color: #fff; } }


.newsletter-inner {
	margin-top: 7px;
	.newsletter-form {
		display: flex;
		max-width: 550px;
		width: 100%;
		margin: 0 auto;

		input {
			height: 60px;
			background: #fff;
			border: 0;
			border-radius: 0;
			box-shadow: none;
			padding: 15px 20px;

			&::placeholder {
				color: #363636;
				font-family: $font_heading; }

			&:hover, &:focus {
			    outline: 0; } }

		.tim-btn {
			padding: 10px 23px;
			border-radius: 0;
			text-transform: uppercase;
			border: 0;

			&:hover {
				background: darken($color_primary, 5%);
				color: #fff; } } } }

.newsletter-form {
	> button {
		> i {
			display: none; } } }

/* Newsletter Two */

.newsletter-wrap-two {
	position: relative;
	padding: 90px 0;

	&:before {
		position: absolute;
		content: '';
		background: rgba($color_primary, 0.5);
		height: 100%;
		width: 100%;
		top: 0;
		left: 0; }

	.newsletter-inner {
		.newsletter-form {
			input {
				border-top-left-radius: 7px;
				border-bottom-left-radius: 7px; }

			.tim-btn {
				border-top-right-radius: 7px;
				border-bottom-right-radius: 7px; } } } }

@media (max-width: 575px) {
	.newsletter-inner .newsletter-form .tim-btn {
		position: relative;
		left: 50%;
		margin-left: -70px;
		margin-top: 20px; }


	.newsletter-wrap-two .newsletter-inner .newsletter-form input {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0; }

	.newsletter-wrap-two .newsletter-inner .newsletter-form .tim-btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0; }

	.newsletter-inner .newsletter-form .tim-btn {
		padding: 7px 23px; }

	.newsletter-inner .newsletter-form input {
		height: 45px; }

	.newsletter-inner .newsletter-form {
		display: block; } }










@media (max-width: 768px) {
	.newsletter {
		text-align: center;
		margin-bottom: 30px; }

	#newsletter {
		padding: 50px 0; }

	.newsletter h2 {
		font-size: 28px; }

	.newsletter-wrap-two {
		padding: 50px 0; } }



