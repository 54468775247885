#feature-album {
	padding: 100px 0 70px; }

.feature-albums {
	.tim-album-item {
		text-align: center;
		border-radius: 8px;
		position: relative;
		overflow: hidden;
		max-width: 400px;
		margin: 0 auto 30px;

		&:before {
			position: absolute;
			content: '';
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.7);
			padding: 10px;
			border-radius: 8px;
			opacity: 0;
			transition: all 0.3s ease-in-out; }

		.tim-feature-image {
			border-radius: 8px;
			img {
				max-width: 100%;
				border-radius: 8px;
				box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35); } }

		.album_details_wrap {
			position: absolute;
			left: 0;
			bottom: 45px;
			width: 100%;
			visibility: hidden;
			-o-transition: all 0.3s ease-in-out;
			transition: all 0.3s ease-in-out;
			opacity: 0;

			h4 {
				font-size: 24px;
				font-weight: 500;
				color: #fff;
				padding-bottom: 15px;
				margin-bottom: 15px;
				position: relative;

				&:before, &:after {
					content: '';
					position: absolute;
					background: #fff;
					left: 50%;
					transform: translateX(-50%);
					transition: all 0.3s ease-in-out; }


				&:before {
					width: 30px;
					height: 1px;
					left: 50%;
					bottom: 0; }

				&:after {
					width: 30px;
					height: 5px;
					bottom: -2px; } }

			h5 {
				font-size: 15px;
				font-weight: 500;
				color: #fff; }

			.tim-btn {
				color: #fff;
				border-color: #fff;
				padding: 5px 30px;
				font-size: 16px;
				margin-top: 15px;
				text-transform: uppercase;

				&:hover {
					color: #fff;
					background: $color_primary;
					border-color: $color_primary; } } }

		&:hover {
			&:before {
				opacity: 1; }

			.album_details_wrap {
				opacity: 1;
				visibility: visible;
				h4 {
					&:before {
						width: 100px; } } } } } }

@media (max-width: 768px) {
	#feature-album {
		padding: 50px 0; } }
