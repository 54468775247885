.artist-image {
	img {
		border-radius: 10px;
		max-width: 100%;
		height: auto; } }

.artist-details {
	margin-top: 50px;
	.artist-name {
		color: #fff;
		text-transform: uppercase;
		font-weight: 500;
		font-size: 26px; }

	.band-name {
		color: #fff;
		font-size: 15px;
		font-family: $font_primary;
		margin-bottom: 30px; }

	.details {
		h3 {
			color: #fff;
			font-size: 20px;
			font-weight: 500; }

		p {
			color: #fff; }

		.sng {
			margin-top: 20px; } } }


.artist-details-two {
	margin-top: 45px; }


@media (max-width: 1200px) {
	.artist-details {
		margin-top: 0; } }


@media (max-width: 768px) {
	.artist-details {
		margin-top: 30px; }

	.artist-details-two {
		margin-top: 30px; } }
