/*!
  Theme Name: Music Studio
  Theme URI: http://www.themeim.com/demo/music-studio/
  Author: ThemeIm
  Author URI: http://www.themeim.com/
  Description: Music Studio HTML Template
  Version: 1.0.0
  License: GNU General Public License v2 or later
  License URI: http://www.gnu.org/licenses/gpl-2.0.html
  Tags: music, musician, singer, audio
  */
/*--------------------------------------------------------------
  >>> TABLE OF CONTENTS:
  ----------------------------------------------------------------
  # Base
  # Sections
    ## Header
    ## Banner
    ## Tranding Album
    ## Artist
    ## Upcomiong Concerts
    ## Ticket
    ## Ultimate Tabs
    ## Intro Video
    ## Blog
    ## Widgets
    ## Logo Carousel
    ## lesson
    ## About
    ## Event About
    ## Sale Album
    ## Album
    ## Artist Profiles
    ## Featured Album
    ## Newsletter
    ## Featured Album
    ## Sticky Audio Player
    ## Event Schedule
    ## Recent Performance
    ## Pricing
    ## Tabs
    ## Gallery
    ## Tabs
    ## Page Header
    ## Woocommerce
    ## Page Header
    ## woocommers
    ## Footer

  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##  Google Web Fonts
  --------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css?family=Changa:200,300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900");
/* Primary Color */
body {
  font-size: 16px;
  line-height: 28px;
  color: #494848;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

::selection {
  background: #838383;
}

h1, h2, h3, h4, h5, h6, p, span, div {
  -webkit-text-size-adjust: 100%;
  -webkit-text-size-adjust-font-variant-ligatures: none;
  -webkit-text-size-adjust--webkit-font-variant-ligatures: none;
  -webkit-text-size-adjust-text-rendering: optimizeLegibility;
  -webkit-text-size-adjust--moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust-font-smoothing: antialiased;
  -webkit-text-size-adjust--webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust-text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
  -webkit-text-size-adjust-font-size: 100%;
}

/* GLOBAL SETTINGS */
a {
  transition: all 0.15s ease-in-out;
}

a:hover {
  text-decoration: none;
}

ul {
  list-style: none;
}

ul, ol, figure {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.tim-container-box {
  width: 1200px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1600px) {
  .tim-container {
    padding: 0 6.5%;
  }
}

@media (max-width: 1599px) {
  .tim-container {
    padding: 0 5%;
  }
}

#site {
  position: relative;
  overflow: hidden;
}

.container {
  max-width: 1400px;
}

.container.con-pd {
  padding: 0 125px;
}

.section-padding {
  padding: 120px 0;
}

.section-padding-two {
  padding: 90px 0;
}

/* Reset */
.pt__100 {
  padding-top: 100px;
}

.pb__100px {
  padding-bottom: 100px;
}

.pr__30 {
  padding-right: 30px;
}

.pl__30 {
  padding-left: 30px;
}

.ml__15 {
  margin-left: 15px;
}

.pdlr {
  padding: 0 100px;
}

.pt__110 {
  padding-top: 110px;
}

.pt_70 {
  padding-top: 70px;
}

.mar-btn-30 {
  margin-bottom: 30px !important;
}

/* TYPOGRAPHY */
h1, h2, h3, h4, h5, h6 {
  font-family: "Changa", sans-serif;
}

.section-title {
  margin-bottom: 50px;
}

.section-title h2 {
  font-size: 40px;
  line-height: 26px;
  color: #444;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 23px;
  padding-bottom: 25px;
  position: relative;
  display: inline-block;
}

.section-title h2:before, .section-title h2:after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #e43a90;
  left: 50%;
  transform: translateX(-50%);
}

.section-title h2:before {
  width: 200px;
  height: 1px;
}

.section-title h2:after {
  height: 3px;
  bottom: -1px;
  width: 30px;
  left: 50%;
}

.section-title h2 span {
  color: #e43a90;
}

.section-title p {
  color: #494848;
  font-size: 16px;
  margin: 0;
}

.section-title.title-two h2 {
  margin-bottom: 30px;
}

.section-title.title-two h2:before {
  display: none;
}

.section-title.title-two h2:after {
  background-image: url(../../media/background/2.png);
  background: transparent;
  height: 25px;
  max-width: 320px;
  background-repeat: no-repeat;
  width: 100%;
  left: 50%;
  bottom: -20px;
}

.section-title.title-three h2:before {
  background-image: url(../img/dm2.png);
  background-color: transparent;
  height: 15px;
  width: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: -8px;
}

.section-title.title-three h2:after {
  display: none;
}

.section-title.style-four {
  width: 100%;
  text-align: center;
}

.section-title.style-four h2 {
  color: #f60038;
  font-weight: 700;
}

.section-title.style-four h2::before {
  width: 70px;
  height: 1px;
  background: #f60038;
}

.section-title.style-four h2::after {
  background: #f60038;
}

.section-title.style-four p {
  padding: 0px 28%;
  font-size: 18px;
}

.section-title.style-five {
  text-align: left;
  margin-bottom: 90px;
}

.section-title.style-five h2::before {
  width: 70px;
  height: 1px;
  left: 0;
  transform: translate(0);
}

.section-title.style-five h2::after {
  background: #f60038;
  left: 0;
  transform: translate(0);
}

.section-title.style-five p {
  padding: 0;
  width: 350px;
  font-weight: 300;
  font-size: 18px;
}

.section-title.style-five h2 {
  color: #f60038;
  font-weight: 700;
}

.section-title.style-six {
  text-align: left;
  margin-bottom: 12px;
}

.section-title.style-six p {
  width: 350px;
  font-weight: 300;
}

.section-title.style-six h2::before {
  width: 70px;
  height: 1px;
  left: 0;
  transform: translate(0);
}

.section-title.style-six h2::after {
  background: #f60038;
  left: 0;
  transform: translate(0);
}

.section-title.style-six p {
  padding: 0;
  width: 350px;
}

.section-title.style-six h2 {
  color: #f60038;
  font-weight: 700;
}

.section-dark .section-title h2 {
  color: #fff;
}

.section-dark .section-title p {
  color: #fff;
}

.section-dark .section-title.title-three h2:before {
  background-image: url(../img/dm2l.png);
}

/* Button */
.tim-btn {
  padding: 10px 41px;
  border: 1px solid #e4e4e4;
  color: #363636;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  border-radius: 5px;
}

.tim-btn:hover {
  color: #e43a90;
}

.tim-btn.tim-btn-bgt {
  border: 1px solid #e4e4e4;
  color: #363636;
}

.tim-btn.tim-btn-bgt:hover {
  color: #fff;
}

.video-btn-two {
  padding: 13px 37px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 4px;
  vertical-align: middle;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.video-btn-two i {
  margin-right: 20px;
  color: #e43a90;
  vertical-align: middle;
  font-size: 24px;
  position: relative;
}

.video-btn-two i:after {
  position: absolute;
  content: '';
  height: 20px;
  width: 1px;
  background: #7c7d81;
  right: -14px;
  top: 2px;
}

.video-btn-two:hover {
  background: #e43a90;
  border-color: #e43a90;
  color: #fff;
}

.video-btn-two:hover i {
  color: #fff;
}

.video-btn-two:hover i:after {
  background: #fff;
}

.store-btn {
  padding: 10px 25px;
  color: #fff;
  border: 2px solid #fff;
  font-weight: 600;
  border-radius: 4px;
  font-size: 18px;
  vertical-align: top;
  display: inline-block;
}

.store-btn i {
  font-size: 25px;
  margin-right: 7px;
}

.store-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

/* Flat Icons */
@font-face {
  font-family: tim-icon;
  src: url("../fonts/tim-icon.eot");
  src: url("../fonts/tim-icon.eot?#iefix") format("embedded-opentype"), url("../fonts/tim-icon.woff2") format("woff2"), url("../fonts/tim-icon.woff") format("woff"), url("../fonts/tim-icon.ttf") format("truetype"), url("../fonts/tim-icon.svg#tim-icon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: tim-icon;
    src: url("../fonts/tim-icon.svg#tim-icon") format("svg");
  }
}

[class^="tim-"]:before, [class*=" tim-"]:before,
[class^="tim-"]:after, [class*=" tim-"]:after {
  font-family: tim-icon;
  font-style: normal;
}

.Switcher__control {
  background-image: none;
  font-size: 25px;
  outline: 0 !important;
}

/* Flat Icons */
.tim-apple-logo:before {
  content: "\f100";
}

.tim-playstore:before {
  content: "\f101";
}

.tim-technology:before {
  content: "\f102";
}

.tim-photo:before {
  content: "\f103";
}

.tim-photo-camera:before {
  content: "\f104";
}

.tim-cross-out:before {
  content: "\f105";
}

.tim-repeat:before {
  content: "\f106";
}

.tim-favorite:before {
  content: "\f107";
}

.tim-eye:before {
  content: "\f108";
}

.tim-headphones:before {
  content: "\f109";
}

.tim-sound-frecuency:before {
  content: "\f10a";
}

.tim-code-rate:before {
  content: "\f10b";
}

.tim-slide:before {
  content: "\f10c";
}

.tim-music-player-play:before {
  content: "\f10d";
}

.tim-piano-keys:before {
  content: "\f10e";
}

.tim-piano-keyboard:before {
  content: "\f10f";
}

.tim-heart-outline:before {
  content: "\f110";
}

.tim-favorite-heart-button:before {
  content: "\f111";
}

.tim-drum-set-cartoon-variant:before {
  content: "\f112";
}

.tim-music-album-1:before {
  content: "\f113";
}

.tim-electric-guitar-music-instrument:before {
  content: "\f114";
}

.tim-cogwheel:before {
  content: "\f115";
}

.tim-pause-button:before {
  content: "\f116";
}

.tim-woofer-speaker:before {
  content: "\f117";
}

.tim-layers:before {
  content: "\f118";
}

.tim-view:before {
  content: "\f119";
}

.tim-guitar:before {
  content: "\f11a";
}

.tim-commerce:before {
  content: "\f11b";
}

.tim-support:before {
  content: "\f11c";
}

.tim-shopping-cart-1:before {
  content: "\f11d";
}

.tim-music:before {
  content: "\f11e";
}

.tim-newsletter:before {
  content: "\f11f";
}

.tim-tabs:before {
  content: "\f120";
}

.tim-monitor:before {
  content: "\f121";
}

.tim-music-album:before {
  content: "\f122";
}

.tim-shopping-cart:before {
  content: "\f123";
}

.tim-flute:before {
  content: "\f124";
}

.tim-keyboard:before {
  content: "\f125";
}

.tim-arrows:before {
  content: "\f126";
}

.tim-layout:before {
  content: "\f127";
}

.tim-zoom-out:before {
  content: "\f128";
}

.tim-computer:before {
  content: "\f129";
}

.tim-music-1:before {
  content: "\f12a";
}

.loader.is-active {
  background-color: #fff;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.loader-bar-ping-pong::before {
  background-color: #e7e3e3;
  border-radius: 16px;
  display: none;
}

.loader-bar-ping-pong::after, .loader-bar-ping-pong::before {
  content: "";
  height: 2px;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 100px);
}

@media (max-width: 768px) {
  .section-padding {
    padding: 50px 0;
  }
  .section-title h2 {
    font-size: 30px;
  }
  .section-title p br {
    display: none;
  }
  .pt__100 {
    padding-top: 50px;
  }
  .pt__110 {
    padding-top: 50px;
  }
  .section-padding-two {
    padding: 50px 0;
  }
}

@media (max-width: 500px) {
  .col-full-width {
    max-width: 400px;
    margin: 0 auto;
    flex: 0 0 100%;
  }
  .container.con-pd {
    padding: 0;
  }
}

@media (min-width: 320px) and (max-width: 991px) {
  .section-title.style-four p {
    padding: 0px 5%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title.style-four {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .section-title.style-four {
    margin-bottom: 30px;
  }
  .section-title.style-four p {
    padding: 0px 5%;
    font-size: 14px;
    line-height: 24px;
  }
  .section-title.style-five p {
    font-size: 14px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
  ##  Header
  --------------------------------------------------------------*/
.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header .top-header {
  background: rgba(228, 58, 144, 0.65);
}

.header.header-two .top-header {
  position: relative;
  background: #fff;
}

.header.header-two .top-header .skew-bg {
  background: #e43a90;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 70%;
  height: 50px;
}

.header.header-two .top-header .skew-bg:before {
  position: absolute;
  right: -40px;
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 0 0 40px;
  border-color: transparent transparent transparent #e43a90;
}

.header.header-two .top-header .user-login > li:after {
  background: rgba(0, 0, 0, 0.3);
}

.header.header-two .top-header .user-login li > a {
  color: #363636;
}

.header.header-two .top-header .user-login li > a i {
  color: #363636;
}

.header.header-two .top-header .user-login li > a:hover {
  color: #e43a90;
}

.header.header-two .top-header .user-login li.cart-count .badge {
  color: #fff;
}

.header.header-two .header-inner {
  background: rgba(228, 58, 144, 0.2);
}

.site-social-link {
  list-style: none;
  display: inline-block;
  position: relative;
}

.site-social-link li {
  display: inline-block;
  padding: 11px 0;
}

.site-social-link li a {
  display: block;
  color: #fff;
  font-size: 14px;
  margin-right: 15px;
}

.site-social-link li a:hover {
  color: #363636;
}

/* Header style three */
/* Top Header Right With Mini Cart */
.user-login {
  list-style: none;
}

.user-login > li {
  display: inline-block;
  position: relative;
  margin-right: 30px;
  font-family: "Changa", sans-serif;
  padding: 10px 0;
}

.user-login > li:after {
  position: absolute;
  content: '';
  height: 15px;
  width: 2px;
  background: rgba(255, 255, 255, 0.3);
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
}

.user-login li.cart-count {
  position: relative;
}

.user-login li.cart-count .badge {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #363636;
  border-radius: 50%;
  line-height: 16px;
  width: 17px;
  font-size: 11px;
  height: 17px;
  display: block;
  padding: 0;
  font-weight: 500;
  text-align: center;
}

.user-login li.cart-count .cart-overview {
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
  background: #FFF;
  text-align: left;
  width: 350px;
  padding: 15px;
  margin-top: 0;
  right: 0;
  top: 140%;
  transition: all 0.3s ease-in-out;
}

.user-login li.cart-count .cart-overview .cart-item {
  margin-bottom: 10px;
  display: block;
  position: relative;
  margin-right: 0;
  padding: 0;
}

.user-login li.cart-count .cart-overview .cart-item .product-thumbnail {
  margin-right: 1.3em;
  float: left;
  padding: 0 !important;
  display: inline-block;
  width: 70px;
}

.user-login li.cart-count .cart-overview .cart-item .product-thumbnail img {
  max-width: 100%;
}

.user-login li.cart-count .cart-overview .cart-item .product-details {
  position: relative;
  margin-left: 70px;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: block;
  text-decoration: none;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-title:hover {
  color: #e43a90;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-remove {
  position: absolute;
  right: 0;
  top: 42%;
  height: 18px;
  width: 18px;
  font-size: 6px;
  background: #3e3e3e;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  color: #f3f3f3;
  transition: all 0.3s ease-in-out;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-remove:hover {
  background: #444;
  color: #FFF;
}

.user-login li.cart-count .cart-overview .cart-item .product-details .product-quantity {
  display: inline-block;
}

.user-login li.cart-count .cart-overview .cart-subtotal {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.user-login li.cart-count .cart-overview .cart-subtotal .amount {
  float: right;
}

.user-login li.cart-count .cart-overview .cart-actions {
  display: block;
  padding-top: 20px;
}

.user-login li.cart-count .cart-overview .cart-actions .view-cart, .user-login li.cart-count .cart-overview .cart-actions .checkout {
  padding: 13px 25px !important;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 16px !important;
  display: inline-block !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.user-login li.cart-count .view-cart {
  color: #363636;
  border: 1px solid #363636;
}

.user-login li.cart-count .view-cart:hover {
  background: #e43a90;
  color: #fff !important;
  border-color: #e43a90;
}

.user-login li.cart-count .checkout {
  background: #e43a90;
  color: #FFF !important;
  float: right;
  border: 1px solid #e43a90;
}

.user-login li.cart-count .checkout:hover {
  background: #cf1c77;
}

.user-login li:hover .cart-overview {
  opacity: 1 !important;
  top: 100% !important;
  visibility: visible !important;
}

.user-login li:last-child {
  margin-right: 0;
}

.user-login li:last-child:after {
  display: none;
}

.user-login li a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}

.user-login li a i {
  margin-right: 10px;
  color: #fff;
}

.user-login li:hover .badge {
  color: #fff;
}

.user-login li:hover a {
  color: #b9b9b9;
}

.user-login li:hover a i {
  color: #fff;
}

.header-inner {
  background: rgba(228, 58, 144, 0.2);
}

#site-logo {
  line-height: 104px;
}

#site-logo .logo-stickky {
  display: none;
}

.hide-topbar #site-logo .logo-stickky {
  display: block;
}

.hide-topbar #site-logo .logo-main {
  display: none;
}

#main-header-menu {
  list-style: none;
}

#main-header-menu > li {
  position: relative;
}

#main-header-menu > li a {
  position: relative;
  display: inline-block;
  font-family: "Changa", sans-serif;
}

#main-header-menu > li:after, #main-header-menu > li:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 1px;
  background: #fff;
  opacity: 0;
  transition: all 0.5s ease;
}

#main-header-menu > li:before {
  left: 30px;
  top: 35px;
}

#main-header-menu > li:after {
  bottom: 35px;
  right: 30px;
}

#main-header-menu > li:hover:after, #main-header-menu > li:hover:before {
  opacity: 1;
}

#main-header-menu > li:hover:before {
  left: -6px;
}

#main-header-menu > li:hover:after {
  right: -5px;
}

#main-header-menu > li.menu-item-has-children .sub-menu {
  text-align: left;
  position: absolute;
  padding: 10px 20px;
  width: 240px;
  background: rgba(0, 0, 0, 0.95);
  top: 100%;
  transform: translateY(-10px);
  left: auto;
  margin: 0;
  border-top: 1px solid #e43a90;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  height: auto;
}

#main-header-menu > li.menu-item-has-children .sub-menu li {
  display: block;
  padding: 5px 0;
  margin: 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu li a {
  color: #fff;
  padding: 3px 0;
  font-size: 16px;
}

#main-header-menu > li.menu-item-has-children .sub-menu li a:hover, #main-header-menu > li.menu-item-has-children .sub-menu li a.current_page {
  color: #e43a90;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu {
  width: 100%;
  padding: 20px 30px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu > li {
  width: 25%;
  float: left;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu > li h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu {
  background-color: transparent;
  border-top-color: transparent;
  float: left;
  padding: 0 15px;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li {
  display: block;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
}

#main-header-menu > li.menu-item-has-children .sub-menu.mega-menu li:not(:last-child) .sub-menu {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#main-header-menu > li.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  z-index: 20;
  transition-delay: 0s, 0s, 0.3s;
}

#main-header-menu li {
  display: inline-block;
  margin: 0 20px;
  padding: 37px 0;
}

#main-header-menu li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}

#main-header-menu > li.active:before {
  left: -6px;
}

#main-header-menu > li.active:after {
  right: -5px;
}

#main-header-menu > li.active:before, #main-header-menu > li.active:after {
  opacity: 1;
}

.header-inner .nav .head-btn {
  height: 35px;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  font-size: 16px;
  color: #fff;
  padding: 0 25px;
  margin: 33px 0 0 100px;
  text-transform: uppercase;
  box-sizing: border-box;
  display: block;
  float: right;
  line-height: 30px;
  transition: .25s;
  cursor: pointer;
  font-family: "Changa", sans-serif;
}

.header-inner .nav .head-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

/* Mobile Nav */
#nav-toggle {
  margin-right: 0;
  float: right;
  width: 30px;
  padding: 7px 0;
}

#nav-toggle .toggle-inner {
  position: relative;
}

#nav-toggle .toggle-inner > span {
  display: block;
  position: relative;
  margin-bottom: 5px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}

#nav-toggle .toggle-inner > span:nth-child(2), #nav-toggle .toggle-inner > span:nth-child(3) {
  width: 24px;
  margin-left: 6px;
  background-color: #e43a90 !important;
}

#nav-toggle .toggle-inner > span:nth-child(4) {
  margin-bottom: 0;
}

#nav-toggle.active .toggle-inner > span:nth-child(2), #nav-toggle.active .toggle-inner > span:nth-child(3) {
  opacity: 0;
}

#nav-toggle.active .toggle-inner > span:nth-child(1) {
  transform: rotate(45deg) translate(7px, 7px);
}

#nav-toggle.active .toggle-inner > span:nth-child(4) {
  transform: rotate(-45deg) translate(8px, -8px);
}

nav#accordian {
  padding: 20px 30px;
}

#mobile-nav-wrap {
  display: none;
  background: #131313;
  padding: 16px 20px;
}

#mobile-nav-wrap #mobile-logo {
  float: left;
  padding: 5px 0;
}

#mobile-nav-wrap #mobile-logo img {
  width: 60px;
}

#mobile-nav-wrap .user-link {
  padding: 5px 0;
}

#mobile-nav-wrap .user-link li {
  margin-right: 10px;
}

#mobile-nav-wrap.mnav-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 9999;
  animation: headerFixedTop .35s;
}

.mobile-menu-inner {
  display: block;
  width: 100%;
  text-align: center;
  background: #000;
  position: fixed;
  z-index: 9999;
  left: -100%;
  transition: all 0.7s cubic-bezier(0.65, -0.22, 0.35, 1.13);
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  top: 0;
}

.mobile-menu-inner.mnav-fixed {
  position: fixed;
}

.mobile-menu-inner #mobile-logo {
  margin-bottom: 30px;
}

.mobile-menu-inner .mob-nav {
  margin: 0;
  padding: 0;
  text-align: left;
  list-style: none;
}

.accordion-menu {
  width: 100%;
  margin: 20px auto 50px;
  text-align: left;
  border-radius: 4px;
  list-style: none;
}

.accordion-menu ul {
  list-style: none;
}

.accordion-menu > li {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
}

.accordion-menu > li:first-child {
  border-top: 0;
}

.accordion-menu > li.single-link {
  margin: 3px 0;
}

.accordion-menu > li .dropdownlink:before {
  content: "\f105";
  position: absolute;
  right: 0;
  top: 8px;
  font: normal normal normal 14px/1 FontAwesome;
  transition: all 0.3s ease-in-out;
  font-size: 16px;
  height: 30px;
  width: 30px;
  background: rgba(255, 255, 255, 0.2);
  text-align: center;
  line-height: 29px;
}

.accordion-menu > li.open > a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.accordion-menu > li.open .dropdownlink:before {
  content: "\f107";
  transition: all 0.3s ease-in-out;
}

.accordion-menu > li > a {
  font-weight: 500;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  padding: 10px 0;
  color: #fff;
  display: block;
  font-family: "Changa", sans-serif;
}

.accordion-menu .accordion-menu li.open .dropdownlink {
  color: #e43a90;
}

.accordion-menu .accordion-menu li:last-child .dropdownlink {
  border-bottom: 0;
}

.accordion-menu .accordion-menu .fa-angle-down {
  transform: rotate(180deg);
}

.accordion-menu .dropdownlink {
  cursor: pointer;
  color: #fff;
  position: relative;
  transition: all 0.4s ease-out;
}

.accordion-menu .dropdownlink i {
  position: absolute;
  top: 13px;
  left: 16px;
  font-size: 14px;
}

.accordion-menu .dropdownlink .fa-angle-down {
  right: 12px;
  left: auto;
}

.accordion-menu .dropdownlink:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems {
  display: none;
  background: transparent;
}

.accordion-menu .submenuItems li:last-child {
  border-bottom: none;
}

.accordion-menu .submenuItems a {
  display: block;
  color: #FFF;
  transition: all 0.4s ease-out;
  text-decoration: none;
  font-size: 18px;
  padding: 5px 0;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.accordion-menu .submenuItems:hover, .accordion-menu .submenuItems:focus {
  color: #e43a90;
}

#moble-search {
  border: 1px solid #fff;
  display: flex;
  border-radius: 4px;
}

#moble-search input[type="text"] {
  background: transparent;
  height: 45px;
  color: #fff;
  border: 0;
  padding: 0 10px;
  width: 88%;
  outline: 0;
}

#moble-search input[type="text"]::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

#moble-search button[type="submit"] {
  background: transparent;
  border: 0;
  color: #fff;
}

@-webkit-keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes headerFixedTop {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.mask-overlay {
  content: '';
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

.active .mobile-menu-inner {
  left: 0;
}

.close-menu {
  position: absolute;
  right: 20px;
  top: 28px;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  padding: 5px;
}

.close-menu .bar {
  display: block;
  position: relative;
  margin-bottom: 3px;
  width: 100%;
  height: 2px;
  background-color: #000;
  transition: all 0.25s ease-in-out;
}

.close-menu .bar:first-child {
  margin-bottom: 5px;
  transform: rotate(-45deg) translate(-9px, 10px);
}

.close-menu .bar:last-child {
  transform: rotate(45deg) translate(5px, 4px);
}

.user-link li a {
  color: #fff;
}

.user-link li a i {
  margin-right: 5px;
}

.mobile-nav-top-wrap .mob-header-inner {
  padding: 20px;
  background: #fff;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo {
  margin: 0;
  max-width: 120px;
  float: left;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo a {
  display: block;
}

.mobile-nav-top-wrap .mob-header-inner .mobile-logo a img {
  max-width: 100%;
}

.hide-topbar .top-header {
  display: none;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  animation-name: headerSlideDown;
}

.headroom--pinned.hide-topbar .header-inner {
  background: #FFF;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li:after, .headroom--pinned.hide-topbar .header-inner #main-header-menu li:before {
  background: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li a {
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li a:hover {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li.menu-item-has-children .sub-menu {
  background: #fff;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu li.active > a {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn {
  border-color: #d8d1d1;
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn:hover {
  color: #fff;
  border-color: #e43a90;
}

.headroom {
  will-change: transform;
  transition: transform 200ms linear;
  will-change: transform, opacity;
  animation-duration: .5s;
}

.headroom--unpinned {
  transform: translateY(-100%);
  position: fixed;
}

@-webkit-keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }
  100% {
    margin-top: 0;
  }
}

.animated.slideUp {
  -webkit-animation-name: slideUp;
  -moz-animation-name: slideUp;
  -o-animation-name: slideUp;
  animation-name: slideUp;
}

@media (max-width: 1440px) {
  #main-header-menu li {
    margin: 0 14px;
  }
  .header-inner .nav .head-btn {
    margin-left: 25px;
  }
}

@media (max-width: 1199px) {
  #main-header-menu > li::before {
    top: 28px;
  }
  #main-header-menu > li::after {
    bottom: 28px;
  }
  #main-header-menu li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu li a {
    font-size: 14px;
  }
  #site-logo {
    line-height: 75px;
  }
  .header-inner .nav .head-btn {
    margin-top: 22px;
  }
}

@media (max-width: 1024px) {
  #main-header-menu li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu li a {
    font-size: 14px;
  }
  #site-logo {
    max-width: 100px;
  }
  #site-logo a img {
    max-width: 100%;
  }
  .header-inner .nav .head-btn {
    margin-left: 25px;
    margin-top: 22px;
  }
}

@media (max-width: 991px) {
  .header {
    display: none;
  }
  #mobile-nav-wrap {
    display: block;
    width: 100%;
    z-index: 999;
    position: fixed;
  }
}

/* 	#mobile-nav-wrap.headroom--top
		position: relative; */
/*--------------------------------------------------------------
  ##   Backtotop
  --------------------------------------------------------------*/
.backtotop {
  position: fixed;
  bottom: 25px;
  right: 25px;
  border: 2px solid #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 9999;
  -webkit-box-shadow: -4px 1px 7px 0px rgba(84, 84, 84, 0.35);
  box-shadow: -1px 1px 5px 0px rgba(84, 84, 84, 0.35);
}

.backtotop i {
  height: 42px;
  width: 42px;
  line-height: 42px;
  font-size: 20px;
  background: #fff;
  margin: 2px;
  color: #050303;
  text-align: center;
  border-radius: 50%;
}

@media (max-width: 767px) {
  .backtotop i {
    height: 25px;
    width: 25px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
  ##  Header Four
  --------------------------------------------------------------*/
.header-four {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-four .user-login {
  float: right;
  margin-top: 27px;
}

.header-four .user-login > li:after {
  display: none;
}

.header-four .user-login li > a {
  color: #fff;
}

.header-four .user-login li > a i {
  color: #fff;
}

.header-four .user-login li > a:hover {
  color: #e43a90;
}

.header-four .user-login li.cart-count .badge {
  color: #fff;
}

.header-four .header-inner {
  background: transparent;
}

.header-four .nav {
  display: inline-block;
  float: right;
  margin-right: 50px;
}

.header-four .user-login .search-input-wrapper {
  position: absolute;
  right: 5px;
  top: 80px;
  background: #e43a90;
  height: 0;
  width: 320px;
  overflow: hidden;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
  z-index: 1;
}

.header-four .user-login .search-input-wrapper.active {
  height: 60px;
}

.header-four .user-login .search-input-wrapper input {
  display: block;
  border: 1px solid #e43a90;
  border-radius: 2px;
  padding: 5px;
  width: 300px;
  margin-left: 10px;
  margin-top: 10px;
}

/* Top Header Right With Mini Cart */
.header-four .user-login {
  list-style: none;
}

.header-four .user-login > li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  font-family: "Changa", sans-serif;
  padding: 10px 0;
}

.header-four .user-login li.cart-count {
  position: relative;
}

.header-four .user-login li.cart-count .badge {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #ffff;
  border-radius: 50%;
  line-height: 16px;
  width: 17px;
  font-size: 11px;
  height: 17px;
  display: block;
  padding: 0;
  font-weight: 500;
  text-align: center;
  color: #e43a90;
}

.header-four .user-login li.cart-count .cart-overview {
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
  background: #FFF;
  text-align: left;
  width: 350px;
  padding: 15px;
  margin-top: 0;
  right: 0;
  top: 140%;
  transition: all 0.3s ease-in-out;
}

.header-four .user-login li.cart-count .cart-overview .cart-item {
  margin-bottom: 10px;
  display: block;
  position: relative;
  margin-right: 0;
  padding: 0;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-thumbnail {
  margin-right: 1.3em;
  float: left;
  padding: 0 !important;
  display: inline-block;
  width: 70px;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-thumbnail img {
  max-width: 100%;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details {
  position: relative;
  margin-left: 70px;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details .product-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: block;
  text-decoration: none;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details .product-title:hover {
  color: #e43a90;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details .product-remove {
  position: absolute;
  right: 0;
  top: 42%;
  height: 18px;
  width: 18px;
  font-size: 6px;
  background: #3e3e3e;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  color: #f3f3f3;
  transition: all 0.3s ease-in-out;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details .product-remove:hover {
  background: #444;
  color: #FFF;
}

.header-four .user-login li.cart-count .cart-overview .cart-item .product-details .product-quantity {
  display: inline-block;
}

.header-four .user-login li.cart-count .cart-overview .cart-subtotal {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.header-four .user-login li.cart-count .cart-overview .cart-subtotal .amount {
  float: right;
}

.header-four .user-login li.cart-count .cart-overview .cart-actions {
  display: block;
  padding-top: 20px;
}

.header-four .user-login li.cart-count .cart-overview .cart-actions .view-cart, .header-four .user-login li.cart-count .cart-overview .cart-actions .checkout {
  padding: 13px 25px !important;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 16px !important;
  display: inline-block !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.header-four .user-login li.cart-count .view-cart {
  color: #363636;
  border: 1px solid #363636;
}

.header-four .user-login li.cart-count .view-cart:hover {
  background: #e43a90;
  color: #fff !important;
  border-color: #e43a90;
}

.header-four .user-login li.cart-count .checkout {
  background: #e43a90;
  color: #FFF !important;
  float: right;
  border: 1px solid #e43a90;
}

.header-four .user-login li.cart-count .checkout:hover {
  background: #cf1c77;
}

.header-four .user-login li:hover .cart-overview {
  opacity: 1 !important;
  top: 100% !important;
  visibility: visible !important;
}

.header-four .user-login li:last-child {
  margin-right: 0;
}

.header-four .user-login li:last-child:after {
  display: none;
}

.header-four .user-login li a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}

.header-four .user-login li a i {
  margin-right: 10px;
  color: #fff;
}

.header-four .user-login li:hover a {
  color: #b9b9b9;
}

.header-four .user-login li:hover a i {
  color: #fff;
}

.hide-topbar.headroom--pinned .user-login li a i {
  color: #363636;
}

.hide-topbar.headroom--pinned .user-login li a {
  color: #363636;
}

#main-header-menu-4 {
  list-style: none;
}

#main-header-menu-4 > li {
  position: relative;
}

#main-header-menu-4 > li a {
  position: relative;
  display: inline-block;
  font-family: "Changa", sans-serif;
}

#main-header-menu-4 > li a i {
  font-size: 13px;
  margin-left: 5px;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu {
  text-align: left;
  position: absolute;
  padding: 10px 20px;
  width: 240px;
  background: rgba(0, 0, 0, 0.95);
  top: 100%;
  transform: translateY(-10px);
  left: auto;
  margin: 0;
  border-top: 1px solid #e43a90;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  height: auto;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu li {
  display: block;
  padding: 5px 0;
  margin: 0;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu li a {
  color: #fff;
  padding: 3px 0;
  font-size: 16px;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu li a:hover, #main-header-menu-4 > li.menu-item-has-children .sub-menu li a.current_page {
  color: #e43a90;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu {
  width: 100%;
  padding: 20px 30px;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu > li {
  width: 25%;
  float: left;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu > li h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu {
  background-color: transparent;
  border-top-color: transparent;
  float: left;
  padding: 0 15px;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li {
  display: block;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
}

#main-header-menu-4 > li.menu-item-has-children .sub-menu.mega-menu li:not(:last-child) .sub-menu {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#main-header-menu-4 > li.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  z-index: 20;
  transition-delay: 0s, 0s, 0.3s;
}

#main-header-menu-4 li {
  display: inline-block;
  margin: 0 20px;
  padding: 37px 0;
}

#main-header-menu-4 li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}

#main-header-menu-4 > li.active:before {
  left: -6px;
}

#main-header-menu-4 > li.active:after {
  right: -5px;
}

#main-header-menu-4 > li.active:before, #main-header-menu-4 > li.active:after {
  opacity: 1;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  animation-name: headerSlideDown;
}

.headroom--pinned.hide-topbar .header-inner {
  background: #FFF;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li:after, .headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li:before {
  background: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li a {
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li a:hover {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li.menu-item-has-children .sub-menu {
  background: #fff;
}

.headroom--pinned.hide-topbar .header-inner #main-header-menu-4 li.active > a {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn {
  border-color: #d8d1d1;
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn:hover {
  color: #fff;
  border-color: #e43a90;
}

@media (max-width: 1440px) {
  #main-header-menu-4 li {
    margin: 0 14px;
  }
}

@media (max-width: 1199px) {
  #main-header-menu-4 > li::before {
    top: 28px;
  }
  #main-header-menu-4 > li::after {
    bottom: 28px;
  }
  #main-header-menu-4 li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu-4 li a {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  #main-header-menu-4 li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #main-header-menu-4 li a {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .header-four .user-login {
    margin-top: 16px;
  }
}

/*--------------------------------------------------------------
  ##   Offsetmenu
  --------------------------------------------------------------*/
.offset-menu {
  position: fixed;
  right: -380px;
  top: 0;
  width: 370px;
  background: rgba(21, 30, 64, 0.97);
  height: 100%;
  z-index: 999999;
  padding: 40px;
  transition: all .3s;
}

.offset-menu .offset-social {
  margin-top: 40px;
}

.offset-menu .offset-social span {
  font-size: 20px;
  color: #fff;
  font-family: "Changa", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
}

.offset-menu .offset-social ul li {
  display: inline-block;
  margin-right: 10px;
}

.offset-menu .offset-social ul li a {
  color: #fff;
  font-size: 15px;
}

.offset-menu.open {
  right: 0;
  transition: all .3s;
}

.offset-menu > a {
  margin-bottom: 25px;
  display: block;
  position: absolute;
  right: 30px;
  top: 17px;
}

.offset-menu .footer-about .footer-logo {
  max-width: 120px;
  margin-bottom: 30px;
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

.offset-menu .footer-about .footer-logo img {
  width: 100%;
}

.offset-menu .footer-about > p {
  color: #c8c6c6;
  line-height: 25px;
  margin-bottom: 25px;
}

.offset-menu .footer-contact .contact-details i {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 26px;
  color: #e43a90;
}

.offset-menu .footer-contact .contact-details p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
}

.offset-menu h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 22px;
}

.offset-menu .footer-about {
  border-bottom: 1px solid #26325c;
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.offset-menu .footer-blog-inner .footer-blog {
  border-bottom: 1px solid #26325c;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.offset-menu .footer-blog-inner .footer-blog:last-child {
  border-bottom: 0;
}

.offset-menu .footer-blog-inner .footer-blog .fea-image {
  float: left;
  position: relative;
}

.offset-menu .footer-blog-inner .footer-blog .fea-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}

.offset-menu .footer-blog-inner .footer-blog .content {
  margin-left: 116px;
  padding-top: 5px;
}

.offset-menu .footer-blog-inner .footer-blog .content h3 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.offset-menu .footer-blog-inner .footer-blog .content h3 a {
  color: #fff;
  font-size: 16px;
}

.offset-menu .footer-blog-inner .footer-blog .content h3 a:hover {
  color: #e43a90;
}

.offset-menu .footer-blog-inner .footer-blog .content .meta {
  color: #e43a90;
  font-size: 14px;
}

.offset-menu .footer-blog-inner .footer-blog .content .meta:hover {
  color: #e43a90;
}

/*--------------------------------------------------------------
  ##  Header Five
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##   Header Five css
  --------------------------------------------------------------*/
.header-magic-line {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-magic-line .user-login {
  float: right;
  margin-top: 18px;
}

.header-magic-line .user-login > li:after {
  display: none;
}

.header-magic-line .user-login li > a {
  color: #fff;
}

.header-magic-line .user-login li > a i {
  color: #fff;
}

.header-magic-line .user-login li > a:hover {
  color: #e43a90;
}

.header-magic-line .user-login li.cart-count .badge {
  color: #fff;
}

.header-magic-line .header-inner {
  background: transparent;
}

.header-magic-line .nav {
  padding-left: 0;
  float: right;
  margin-right: 50px;
}

.header-magic-line #site-logo {
  line-height: 80px;
}

.header-magic-line .user-login .search-input-wrapper {
  position: absolute;
  right: 5px;
  top: 80px;
  background: #f60038;
  height: 0;
  width: 320px;
  overflow: hidden;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 1;
  z-index: 1;
}

.header-magic-line .user-login .search-input-wrapper.active {
  height: 60px;
}

.header-magic-line .user-login .search-input-wrapper input {
  display: block;
  border: 1px solid #e43a90;
  border-radius: 2px;
  padding: 5px;
  width: 300px;
  margin-left: 10px;
  margin-top: 10px;
}

.header-magic-line .user-login {
  list-style: none;
}

.header-magic-line .user-login > li {
  display: inline-block;
  position: relative;
  margin-right: 15px;
  font-family: "Changa", sans-serif;
  padding: 10px 0;
}

.header-magic-line .user-login li.cart-count {
  position: relative;
}

.header-magic-line .user-login li.cart-count .badge {
  position: absolute;
  top: 10px;
  left: 8px;
  background: #ffff;
  border-radius: 50%;
  line-height: 16px;
  width: 17px;
  font-size: 11px;
  height: 17px;
  display: block;
  padding: 0;
  font-weight: 500;
  text-align: center;
  color: #e43a90;
}

.header-magic-line .user-login li.cart-count .cart-overview {
  opacity: 0;
  visibility: hidden;
  z-index: 101;
  line-height: 1.8;
  position: absolute;
  background: #FFF;
  text-align: left;
  width: 350px;
  padding: 15px;
  margin-top: 0;
  right: 0;
  top: 100px;
  transition: all 0.3s ease-in-out;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item {
  margin-bottom: 10px;
  display: block;
  position: relative;
  margin-right: 0;
  padding: 0;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-thumbnail {
  margin-right: 1.3em;
  float: left;
  padding: 0 !important;
  display: inline-block;
  width: 70px;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-thumbnail img {
  max-width: 100%;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details {
  position: relative;
  margin-left: 70px;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details .product-title {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  display: block;
  text-decoration: none;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details .product-title:hover {
  color: #f60038;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details .product-remove {
  position: absolute;
  right: 0;
  top: 42%;
  height: 18px;
  width: 18px;
  font-size: 6px;
  background: #3e3e3e;
  color: #FFF;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  color: #f3f3f3;
  transition: all 0.3s ease-in-out;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details .product-remove:hover {
  background: #444;
  color: #FFF;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-item .product-details .product-quantity {
  display: inline-block;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-subtotal {
  display: block;
  padding: 15px 0;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  margin-top: 15px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  text-transform: uppercase;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-subtotal .amount {
  float: right;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-actions {
  display: block;
  padding-top: 20px;
}

.header-magic-line .user-login li.cart-count .cart-overview .cart-actions .view-cart, .header-magic-line .user-login li.cart-count .cart-overview .cart-actions .checkout {
  padding: 13px 25px !important;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-size: 16px !important;
  display: inline-block !important;
  letter-spacing: 1px;
  font-weight: 600;
}

.header-magic-line .user-login li.cart-count .view-cart {
  color: #363636;
  border: 1px solid #363636;
}

.header-magic-line .user-login li.cart-count .view-cart:hover {
  background: #f60038;
  color: #fff !important;
  border-color: #f60038;
}

.header-magic-line .user-login li.cart-count .checkout {
  background: #f60038;
  color: #FFF !important;
  float: right;
  border: 1px solid #f60038;
}

.header-magic-line .user-login li.cart-count .checkout:hover {
  background: #cf1c77;
}

.header-magic-line .user-login li:hover .cart-overview {
  opacity: 1 !important;
  top: 66px !important;
  visibility: visible !important;
}

.header-magic-line .user-login li:last-child {
  margin-right: 0;
}

.header-magic-line .user-login li:last-child:after {
  display: none;
}

.header-magic-line .user-login li a {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  line-height: 1;
}

.header-magic-line .user-login li a i {
  margin-right: 10px;
  color: #fff;
}

.hide-topbar.headroom--pinned .header-magic-line .user-login li a {
  color: #363636;
}

.hide-topbar.headroom--pinned .header-magic-line .user-login li a i {
  color: #363636;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  animation-name: headerSlideDown;
}

.headroom--pinned.hide-topbar .header-inner {
  background: #FFF;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
}

.headroom--pinned.hide-topbar .header-inner #header-menu-magic-line li:after, .headroom--pinned.hide-topbar .header-inner #header-menu-magic-line li:before {
  background: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner #header-menu-magic-line li a {
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner #header-menu-magic-line li.menu-item-has-children .sub-menu {
  background: #fff;
}

.headroom--pinned.hide-topbar .header-inner #header-menu-magic-line li.active > a {
  color: #e43a90;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn {
  border-color: #d8d1d1;
  color: #363636;
}

.headroom--pinned.hide-topbar .header-inner .nav .head-btn:hover {
  color: #fff;
  border-color: #e43a90;
}

#header-menu-magic-line {
  list-style: none;
  float: right;
  width: 100%;
  position: relative;
}

#header-menu-magic-line > li a {
  position: relative;
  display: inline-block;
  font-family: "Changa", sans-serif;
}

#header-menu-magic-line > li a i {
  font-size: 13px;
  margin-left: 5px;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu {
  text-align: left;
  position: absolute;
  padding: 10px 20px;
  width: 240px;
  background: rgba(0, 0, 0, 0.95);
  top: 104%;
  transform: translateY(-10px);
  left: auto;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
  height: auto;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu li {
  display: block;
  padding: 5px 0;
  margin: 0;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu li:last-child {
  border-bottom: 0;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu li a {
  color: #fff;
  padding: 3px 0;
  font-size: 16px;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu li a:hover, #header-menu-magic-line > li.menu-item-has-children .sub-menu li a.current_page {
  color: #f60038;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu {
  width: 100%;
  padding: 20px 30px;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu > li {
  width: 25%;
  float: left;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu > li h3 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-left: 15px;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu {
  background-color: transparent;
  border-top-color: transparent;
  float: left;
  padding: 0 15px;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li {
  display: block;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu li .sub-menu li a {
  display: block;
  padding: 3px 0;
}

#header-menu-magic-line > li.menu-item-has-children .sub-menu.mega-menu li:not(:last-child) .sub-menu {
  border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

#header-menu-magic-line > li.menu-item-has-children:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  z-index: 20;
  transition-delay: 0s, 0s, 0.3s;
}

#header-menu-magic-line li {
  display: inline-block;
  margin: 0 20px;
  padding: 28px 0;
}

#header-menu-magic-line li a {
  color: #fff;
  text-transform: uppercase;
  font-size: 17px;
}

#header-menu-magic-line > li.active:before {
  left: -6px;
}

#header-menu-magic-line > li.active:after {
  right: -5px;
}

#header-menu-magic-line > li.active:before, #header-menu-magic-line > li.active:after {
  opacity: 1;
}

.headroom--pinned {
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  transform: translateY(0);
  position: fixed;
  -webkit-animation-name: headerSlideDown;
  animation-name: headerSlideDown;
}

.headroom--pinned.hide-topbar.header-magic-line .header-inner {
  background: #000;
}

.headroom--pinned.hide-topbar.header-magic-line .header-magic-line-inner {
  border-bottom: none;
}

.headroom--pinned.hide-topbar.header-magic-line .header-inner #header-menu-magic-line li a {
  color: #fff;
}

.hide-topbar.headroom--pinned.header-magic-line .user-login li a i {
  color: #fff;
}

.headroom--pinned.hide-topbar.header-magic-line .header-inner #header-menu-magic-line li.menu-item-has-children .sub-menu {
  background: #000;
}

.header-magic-line-inner {
  border-bottom: 1px solid #f60038;
}

.headroom--pinned.hide-topbar .header-magic-line-inner {
  border-bottom: 1px solid #fff;
}

#magic-line {
  position: absolute !important;
  bottom: -2px;
  left: 0;
  width: 100px;
  height: 4px;
  background: #f60038;
  padding: 0 !important;
  border-radius: 5px;
}

.header_player.style-fullwidth {
  position: relative;
  height: 80px;
  background: transparent;
  background: #111111;
  height: 100px;
  padding: 22px 0 0;
  height: 158px;
}

.header_player.style-fullwidth #main_player {
  display: none;
}

.header_player.style-fullwidth .current-tracks::after {
  display: none;
}

.header_player.style-fullwidth #nowPlaying {
  padding: 0 0 21px 15px;
  margin-top: -7px;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls {
  width: 80px;
  background: #252525;
  position: absolute;
  height: 80px;
  margin-top: 0;
  top: 0;
  left: 0;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface {
  padding: 26px 0 13px 110px;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  border: 0px solid #fff;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
  max-width: 60%;
  height: 6px;
}

.header_player.style-fullwidth #nowPlaying .track-name {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
}

.header_player.style-fullwidth .current-tracks {
  width: 277px;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist {
  height: 80px;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #f60038;
}

.header_player.style-fullwidth .jp-audio .jp-volume-bar .jp-volume-bar-value {
  background: #f60038;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress, .header_player.style-fullwidth .header_player .jp-audio .jp-volume-bar {
  background-color: #323232;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
  width: 140px;
  margin-left: 19px;
}

.header_player.style-fullwidth button#playlist-toggle {
  float: right;
  margin-right: 25px;
}

.header_player.style-fullwidth .jp-audio #playlist-toggle i {
  color: #f60038;
}

.player-container-fullwidth {
  box-shadow: 0px 17px 15.5px 5.5px rgba(0, 0, 0, 0.32);
  background: #181818;
  margin: 0 6.5%;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  font-size: 29px;
  line-height: 80px;
  color: #f60038;
  margin-left: 6px;
}

.header_player.style-fullwidth #nowPlaying {
  color: #424242;
  font-size: 14px;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
  background: #d31014;
}

.header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
  background: #d31014;
}

.banner-five {
  height: 840px;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.banner-five::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  background: url(../../media/background/brushbg.png);
  height: 280px;
  bottom: -48px;
  width: 100%;
}

.banner-five .baneer-five-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -25%);
  -ms-transform: translate(-50%, -25%);
  transform: translate(-50%, -25%);
  width: 100%;
  z-index: 99;
}

.banner-five .baneer-five-content h3 {
  color: #fff;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 3px;
  margin-top: 126px;
  font-family: "Roboto", sans-serif;
  position: relative;
}

.banner-five .baneer-five-content .tim-slide-btn {
  border: 2px dashed #ff6c00;
  color: #ff6c00;
  margin-top: 75px;
  padding: 15px 60px;
}

.banner-five .baneer-five-content .tim-slide-btn:hover {
  background: transparent;
}

@media (max-width: 1440px) {
  #header-menu-magic-line li {
    margin: 0 14px;
  }
}

@media (max-width: 1199px) {
  #header-menu-magic-line > li::before {
    top: 28px;
  }
  #header-menu-magic-line > li::after {
    bottom: 28px;
  }
  #header-menu-magic-line li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #header-menu-magic-line li a {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  #header-menu-magic-line li {
    padding: 25px 0;
    margin: 0 10px;
  }
  #header-menu-magic-line li a {
    font-size: 14px;
  }
}

/*--------------------------------------------------------------
  ##   Offsetmenu two
  --------------------------------------------------------------*/
.offset-menu-two {
  position: fixed;
  right: -380px;
  top: 0;
  width: 359px;
  background: rgba(0, 0, 0, 0.7);
  background-image: url("../../media/background/offset-menu-bg.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: auto;
  height: 100%;
  z-index: 999999;
  padding: 40px;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
  -webkit-transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
  padding-top: 415px;
}

.offset-menu-two .offset-social-two {
  margin-top: 40px;
}

.offset-menu-two .offset-social-two span {
  font-size: 20px;
  color: #fff;
  font-family: "Changa", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
}

.offset-menu-two .offset-social-two ul li {
  display: inline-block;
  border: 1px solid #f60038;
  width: 33px;
  height: 33px;
  text-align: center;
  transition: all 0.15s ease-in-out;
}

.offset-menu-two .offset-social-two ul li a {
  color: #f60038;
  font-size: 15px;
  display: block;
  height: 100%;
  line-height: 29px;
}

.offset-menu-two .offset-social-two > a {
  padding-bottom: 20px;
  border-bottom: 1px solid #f60038;
  margin-bottom: 50px;
  display: block;
  position: relative;
}

.offset-menu-two .offset-social-two > a:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 30px;
  height: 3px;
  background: #f60038;
}

.offset-menu-two.open {
  right: 0;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
}

.offset-menu-two > a {
  margin-bottom: 25px;
  display: block;
  position: absolute;
  right: 30px;
  top: 17px;
}

.offset-menu-two .footer-about .footer-logo {
  max-width: 120px;
  margin-bottom: 30px;
  width: 100%;
  display: inline-block;
  margin-bottom: 15px;
}

.offset-menu-two .footer-about .footer-logo img {
  width: 100%;
}

.offset-menu-two .footer-about > p {
  color: #c8c6c6;
  line-height: 25px;
  margin-bottom: 25px;
}

.offset-menu-two .footer-contact .contact-details i {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 26px;
  color: #f60038;
}

.offset-menu-two .footer-contact .contact-details p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
}

.offset-menu-two h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 22px;
}

.offset-menu-two .footer-about {
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.offset-menu-two .footer-blog-inner .footer-blog {
  border-bottom: 1px solid #26325c;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.offset-menu-two .footer-blog-inner .footer-blog:last-child {
  border-bottom: 0;
}

.offset-menu-two .footer-blog-inner .footer-blog .fea-image {
  float: left;
  position: relative;
}

.offset-menu-two .footer-blog-inner .footer-blog .fea-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}

.offset-menu-two .footer-blog-inner .footer-blog .content {
  margin-left: 116px;
  padding-top: 5px;
}

.offset-menu-two .footer-blog-inner .footer-blog .content h3 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

.offset-menu-two .footer-blog-inner .footer-blog .content h3 a {
  color: #fff;
  font-size: 16px;
}

.offset-menu-two .footer-blog-inner .footer-blog .content h3 a:hover {
  color: #e43a90;
}

.offset-menu-two .footer-blog-inner .footer-blog .content .meta {
  color: #e43a90;
  font-size: 14px;
}

.offset-menu-two .footer-blog-inner .footer-blog .content .meta:hover {
  color: #e43a90;
}

.offset-menu-two .offset-social-two ul li a:hover {
  color: #fff;
}

.offset-menu-two .offset-social-two ul li:hover {
  border: 1px solid #fff;
}

/*--------------------------------------------------------------
  ##   Banner five
  --------------------------------------------------------------*/
.banner-five figure {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  padding-top: 250px;
  text-align: center;
}

.banner-five svg {
  width: 90vw;
  width: 700px;
}

.banner-five svg > * {
  fill: #1C1C1C;
  stroke: #1C1C1C;
  stroke-width: 0.85px;
}

#canvas {
  width: 100%;
  position: absolute;
  left: 100px;
  opacity: .4;
  -webkit-transform: rotate(45deg) scale(1.5);
  -ms-transform: rotate(45deg) scale(1.5);
  transform: rotate(45deg) scale(1.5);
  height: 1300px;
  bottom: -333px;
}

.banner-five .baneer-five-content h3::after {
  position: absolute;
  content: '';
  left: 50%;
  top: 5px;
  height: 1px;
  width: 36%;
  background: white;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f60038 14%, #f60038 85%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-50%);
}

.banner-five .baneer-five-content h3::before {
  position: absolute;
  content: '';
  left: 50%;
  bottom: 5px;
  height: 1px;
  width: 36%;
  background: white;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #f60038 14%, #f60038 85%, rgba(255, 255, 255, 0) 100%);
  transform: translateX(-50%);
}

/*--------------- Banner five text animation ------------*/
.sp-container {
  position: fixed;
  top: -135px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.sp-content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 1000;
}

.sp-container h2 {
  position: absolute;
  top: 50%;
  line-height: 100px;
  height: 90px;
  margin-top: -50px;
  font-size: 180px;
  width: 100%;
  text-align: center;
  color: transparent;
  text-shadow: 0px 0px 1px #110006;
  font-family: 'intro_inlineregular';
}

.sp-container h2.frame-1 {
  font-size: 180px;
  -webkit-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
  -moz-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
  -ms-animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation: animation-1 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation-duration: 20s;
}

.sp-container h2.frame-2 {
  -webkit-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
  -moz-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
  -ms-animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation: animation-2 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation-duration: 20s;
}

.sp-container h2.frame-3 {
  font-size: 180px;
  -webkit-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
  -moz-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
  -ms-animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation: animation-3 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation-duration: 20s;
}

.sp-container h2.frame-4 {
  font-size: 180px;
  -webkit-animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
  -moz-animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
  -ms-animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation: animation-4 cubic-bezier(0, 1.09, 1, 1) infinite;
  animation-duration: 20s;
}

.loader *:after {
  animation-play-state: paused !important;
}

.paralax {
  position: absolute;
  left: 0;
  top: -20px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@keyframes animation-1 {
  0% {
    opacity: .5;
    transform: scale(1.3);
    text-shadow: 0px 0px 40px #000;
    color: transparent;
  }
  5% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  20% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  30% {
    opacity: 0;
    transform: scale(0);
    text-shadow: 0px 0px 50px #000;
    color: transparent;
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes animation-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
    transform: scale(1.3);
    text-shadow: 0px 0px 40px #000;
    color: transparent;
  }
  30% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  40% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  60% {
    opacity: 0;
    transform: scale(0);
    text-shadow: 0px 0px 50px #000;
    color: transparent;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animation-3 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
    transform: scale(1.3);
    text-shadow: 0px 0px 40px #000;
    color: transparent;
  }
  60% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  70% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  80% {
    opacity: 0;
    transform: scale(0);
    text-shadow: 0px 0px 50px #000;
    color: transparent;
  }
  100% {
    opacity: 0;
  }
}

@keyframes animation-4 {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
    transform: scale(1.3);
    text-shadow: 0px 0px 40px #000;
    color: transparent;
  }
  80% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  95% {
    opacity: .8;
    transform: scale(1);
    text-shadow: 0px 0px 0px #000;
    color: #000;
  }
  100% {
    opacity: 0;
    transform: scale(0.5);
    text-shadow: 0px 0px 50px #000;
    color: transparent;
  }
}

@media (min-width: 1501px) and (max-width: 1700px) {
  .header_player.style-fullwidth .current-tracks {
    width: 200px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 55%;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .header_player.style-fullwidth .current-tracks {
    width: 200px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 45%;
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .sp-container h2.frame-2, .sp-container h2.frame-3,
  .sp-container h2.frame-4 {
    font-size: 120px;
  }
  .banner-five .baneer-five-content h3::after {
    width: 55%;
  }
  .banner-five .baneer-five-content h3::before {
    width: 55%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .sp-container h2.frame-2, .sp-container h2.frame-3,
  .sp-container h2.frame-4 {
    font-size: 100px;
  }
  .banner-five .baneer-five-content h3::after {
    width: 70%;
  }
  .banner-five .baneer-five-content h3::before {
    width: 70%;
  }
  .header_player.style-fullwidth .current-tracks {
    width: 200px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 36%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sp-container h2.frame-2, .sp-container h2.frame-3,
  .sp-container h2.frame-4 {
    font-size: 80px;
  }
  .sp-container h2.frame-1 {
    font-size: 130px;
  }
  .banner-five .baneer-five-content h3::after {
    width: 70%;
  }
  .banner-five .baneer-five-content h3::before {
    width: 70%;
  }
  #canvas {
    width: 100%;
    left: 0;
    height: 800px;
    bottom: 0;
  }
  .paralax {
    top: 77px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .sp-container h2.frame-2, .sp-container h2.frame-3,
  .sp-container h2.frame-4 {
    font-size: 66px;
  }
  .sp-container h2.frame-1 {
    font-size: 66px;
  }
  #canvas {
    left: -24px;
    transform: rotate(35deg) scale(1.5);
  }
  .banner-five {
    height: 760px;
  }
  .paralax {
    top: 22%;
  }
  .banner-five .baneer-five-content h3::after {
    width: 77%;
  }
  .banner-five .baneer-five-content h3::before {
    width: 77%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .sp-container h2.frame-2, .sp-container h2.frame-3,
  .sp-container h2.frame-4 {
    font-size: 30px;
  }
  .sp-container h2.frame-1 {
    font-size: 30px;
  }
  #canvas {
    left: -124px;
    transform: rotate(35deg) scale(1.5);
  }
  .banner-five {
    height: 660px;
  }
  .banner-five .baneer-five-content h3 {
    font-size: 14px;
    margin-top: 40px;
  }
  .banner-five .baneer-five-content h3::before,
  .banner-five .baneer-five-content h3::after {
    display: none;
  }
  .banner-five .baneer-five-content .tim-slide-btn {
    padding: 7px 17px;
    font-size: 14px;
    max-width: 104px;
    margin-top: 35px;
  }
  .banner-five::after {
    height: 63px;
    bottom: -23px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .banner-five {
    height: 460px;
  }
  .sp-container {
    top: -28px;
  }
  .paralax {
    left: -72px;
    top: 25%;
  }
  #canvas {
    transform: rotate(0deg) scale(1.5);
    height: 400px;
    top: 0;
    left: 0;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
    height: 75px;
    width: 60px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 60px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .vel-wrap {
    display: none;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 100%;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface {
    padding: 26px 0 13px 80px;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-duration {
    display: none;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
    font-size: 22px;
  }
}

@media (min-width: 400px) and (max-width: 575px) {
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 40%;
  }
  .header_player.style-fullwidth .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    width: 80px;
    display: block;
  }
}

/*--------------------------------------------------------------
  ##  Banner
  --------------------------------------------------------------*/
.banner-slider-one {
  height: 100vh;
}

.banner-slider-one .swiper-slide {
  background-size: cover;
}

.banner-slider-one .swiper-slide .slider-content {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -25%);
  width: 100%;
}

.banner-slider-one .swiper-slide .slider-content h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 40px;
}

.banner-slider-one .swiper-slide .slider-content h2 {
  color: #fff;
  font-size: 70px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 70px;
  margin-bottom: 20px;
}

.banner-slider-one .swiper-slide .slider-content p {
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 40px;
}

.tim-slide-btn {
  padding: 18px 40px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #fff;
  font-size: 17px;
  line-height: 18px;
  display: inline-block;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 2px;
}

.tim-slide-btn i {
  margin-right: 5px;
}

.tim-slide-btn:hover {
  color: #fff;
  background: rgba(109, 18, 66, 0.73);
}

.banner-slider-two {
  height: 100vh;
}

.banner-slider-two .swiper-slide {
  background-size: cover;
}

.banner-slider-two .swiper-slide .slider-content-two {
  position: absolute;
  top: 65%;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  left: 10%;
}

.banner-slider-two .swiper-slide .slider-content-two img {
  float: left;
  transform: translateY(-100px);
}

.banner-slider-two .swiper-slide .slider-content-two h3 {
  font-size: 40px;
  color: #fff;
  line-height: 40px;
  font-weight: 600;
}

.banner-slider-two .swiper-slide .slider-content-two h2 {
  color: #fff;
  font-size: 80px;
  font-weight: 700;
  line-height: 70px;
  margin-bottom: 20px;
}

.banner-slider-two .swiper-slide .slider-content-two p {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 30px;
}

.banner-slider-two .swiper-slide .slider-content-two .tim-btn {
  padding: 13px 37px;
  font-size: 16px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  text-transform: uppercase;
  margin-right: 20px;
}

.banner-slider-two .swiper-slide .slider-content-two .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three {
  width: 80%;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
  font-size: 40px;
  font-weight: 500;
  color: #e43a90;
  line-height: 40px;
}

.banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
  font-size: 70px;
  line-height: 70px;
}

.slider-nav-prev, .slider-nav-next {
  width: 60px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 35px;
}

.slider-nav-next {
  right: 0;
}

/* Music Player */
.header_player {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 111;
}

.header_player button {
  background: transparent;
  border: 0;
  outline: 0;
}

.header_player .player-container {
  max-width: 1200px;
  margin: 0 auto;
}

.header_player .jp-audio {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.header_player .jp-audio .jp-type-playlist {
  display: block;
  position: relative;
  height: 100%;
}

.header_player .jp-audio .jp-type-playlist .jp-interface {
  padding: 36px 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface > div {
  float: left;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
  text-align: center;
  width: 170px;
  padding-top: 0;
  margin-top: -15px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button {
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 0 3px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button i {
  width: 20px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  line-height: 18px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  height: 60px;
  width: 60px;
  border: 2px solid #fff;
  border-radius: 50%;
  text-align: center;
  padding-left: 2px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  font-size: 20px;
  line-height: 40px;
  color: #e43a90;
  margin-left: 6px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
  display: none;
  position: absolute;
  bottom: 100px;
  right: 0;
  width: 100%;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.95);
  padding: 15px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul {
  padding: 0;
  margin: 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
  display: block;
  border-bottom: 1px solid #333;
  padding: 5px 30px;
  transition: all 0.2s linear;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:last-child {
  border: none;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
  background: #e43a90;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div {
  display: block;
  position: relative;
  padding-right: 20px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div .jp-free-media {
  display: block;
  float: left;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  color: #333333;
  text-decoration: none;
  outline: 0;
  padding: 10px 0;
  padding-right: 10px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div .jp-free-media a {
  color: #fff;
  text-decoration: none;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #fff;
  text-decoration: none;
  outline: 0;
  padding: 14px 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a.jp-playlist-item-remove {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  right: 0;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a .jp-artist {
  font-style: normal;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
  background: #e43a90;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current div > a {
  color: #fff;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
  z-index: 30;
  margin-top: 10px;
  overflow: hidden;
  height: 8px;
  max-width: 32%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar {
  width: 0px;
  height: 100%;
  cursor: pointer;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #e43a90;
  width: 0px;
  height: 100%;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .jp-duration {
  color: #fff;
  font-family: "Changa", sans-serif;
  margin-left: 10px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
  width: 140px;
  margin-left: 20px;
}

.header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap .jp-mute {
  color: #fff;
  width: 20px;
  text-align: left;
}

.header_player .jp-audio .jp-type-playlist .jp-no-solution {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  padding: 10px;
  background: #ffffff;
}

.header_player .jp-audio .jp-type-playlist .jp-no-solution span {
  font-weight: 700;
  color: #e43a90;
}

.header_player .jp-audio .jp-volume-bar {
  display: inline-block;
  position: relative;
  width: 65%;
  height: 5px;
  background: #fff;
  margin-bottom: 4px;
  cursor: pointer;
}

.header_player .jp-audio .jp-volume-bar .jp-volume-bar-value {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: #e43a90;
}

.header_player .jp-audio.jp-state-playing .jp-play i:before {
  content: "\f04c" !important;
}

.header_player .jp-audio.jp-state-muted .jp-mute i {
  color: #fff;
}

.header_player .jp-audio.jp-state-muted .jp-mute i:before {
  content: "\f026" !important;
}

.header_player .jp-audio.single-album-player {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #e6e6e6;
}

.header_player .jp-audio.single-album-player .jp-type-playlist {
  display: block;
  height: auto;
  background: #ffffff;
}

.header_player .jp-audio #playlist-toggle i {
  color: #fff;
}

.header_player .jp-audio #playlist-toggle.close-icon i:before {
  content: "\f00d";
}

.header_player #nowPlaying {
  overflow: hidden;
  font-style: normal;
  color: #ffffff;
  padding: 21px 0 21px 15px;
}

.header_player #nowPlaying .track-name {
  font-size: 26px;
  font-family: "Changa", sans-serif;
  color: #fff;
  margin: 0;
  font-weight: 700;
}

.current-tracks {
  float: left;
  width: 365px;
  position: relative;
}

.current-tracks:after {
  position: absolute;
  top: 20px;
  right: 0;
  content: '';
  height: 60px;
  width: 1px;
  background: rgba(255, 255, 255, 0.5);
}

#main_player {
  width: 100px !important;
  height: 100px !important;
  float: left;
  transform: translateY(-25px);
}

#main_player img {
  width: 100% !important;
  height: 100% !important;
}

@media (max-width: 1440px) {
  .banner-slider-two .swiper-slide .slider-content-two {
    max-width: 80%;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 380px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 60px;
    line-height: 55px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p br {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 30px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 55px;
    line-height: 60px;
  }
}

@media (max-width: 1280px) {
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 21%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    width: 115px;
  }
  .header_player #nowPlaying .track-name {
    font-size: 20px;
  }
  .current-tracks {
    width: 320px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 50px;
    line-height: 55px;
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 300px;
  }
}

@media (max-width: 1024px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 40px;
    line-height: 40px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 55px;
    line-height: 55px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    top: 60%;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 50px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 50px;
  }
}

@media (max-width: 992px) {
  .header_player .player-container {
    max-width: 500px;
    margin: 0 auto;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    top: 45%;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 280px;
    transform: translateY(-10px);
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 10px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h3 {
    font-size: 22px;
    margin-bottom: 0;
    line-height: 22px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p {
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    padding: 7px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 7px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two i {
    font-size: 18px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two i:after {
    height: 15px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 145px;
    margin-top: -8px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
    height: 40px;
    width: 40px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
    line-height: 38px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 35%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    width: 70px;
  }
  .current-tracks {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 200px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 25px;
    line-height: 30px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 35px;
    margin-bottom: 10px;
    line-height: 40px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three p {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    padding: 6px 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn i {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 28px;
    line-height: 28px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 35px;
    line-height: 35px;
  }
  .banner-slider-one .swiper-slide .slider-content p {
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .banner-slider-one .swiper-slide .slider-content p br {
    display: none;
  }
  .tim-slide-btn {
    padding: 13px 15px;
    font-size: 14px;
  }
  .slider-nav-prev, .slider-nav-next {
    width: 30px;
    height: 100px;
    line-height: 100px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 90%;
    left: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 180px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .banner-slider-two .swiper-slide .slider-content-two p {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    margin-right: 8px;
    margin-top: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn, .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 10px 14px;
    font-size: 14px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
    width: 400px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 30%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 140px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    transform: translateY(-65%);
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    margin-bottom: 10px;
    padding: 5px 16px;
    font-size: 14px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn i {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .banner-slider-one .swiper-slide .slider-content h3 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .banner-slider-one .swiper-slide .slider-content h2 {
    font-size: 25px;
    line-height: 20px;
  }
  .banner-slider-one .swiper-slide .slider-content p {
    max-width: 300px;
    font-size: 16px;
    margin: 0 auto 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    transform: translateY(-65%);
  }
  .banner-slider-two .swiper-slide .slider-content-two.text-left {
    text-align: center !important;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 150px;
  }
  .banner-slider-two .swiper-slide .slider-content-two h2 {
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
  }
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two, .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    width: 150px;
    padding: 10px;
    text-align: center;
    margin-right: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn {
    margin-top: 10px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three h2 {
    font-size: 26px;
    line-height: 40px;
    margin-bottom: 20px;
  }
  .banner-slider-two .swiper-slide .slider-content-two.content-three .store-btn {
    margin-bottom: 10px;
    margin-left: 0 !important;
    padding: 5px 15px;
  }
  .tim-slide-btn {
    display: block;
    max-width: 200px;
    margin: 0 auto 10px;
  }
  .slider-nav-prev, .slider-nav-next {
    width: 30px;
    height: 100px;
    line-height: 100px;
    font-size: 28px;
  }
  .banner-slider-two {
    text-align: center !important;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    float: none;
    margin: 0 auto;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface {
    padding: 20px 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
    width: 100%;
    bottom: 70px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
    padding: 0 15px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
    font-size: 12px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    width: 29%;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    width: 120px;
    top: -4px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
    height: 35px;
    width: 35px;
    margin: 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
    font-size: 15px;
    line-height: 32px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button {
    margin: 0;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls button i {
    font-size: 15px;
  }
  .header_player .jp-audio .jp-type-playlist .vel-wrap {
    display: none;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 100%;
    left: 10%;
  }
}

@media (max-width: 378px) {
  .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    margin-top: 10px;
  }
}

@media (min-width: 501px) and (max-width: 555px) {
  .banner-slider-two .swiper-slide .slider-content-two .tim-btn, .banner-slider-two .swiper-slide .slider-content-two .video-btn-two {
    padding: 7px 5px;
    font-size: 14px;
  }
  .banner-slider-two .swiper-slide .slider-content-two {
    width: 100%;
    left: 0;
  }
  .banner-slider-two .swiper-slide .slider-content-two img {
    max-width: 170px;
  }
}

.header_player.style-two {
  background: rgba(9, 14, 61, 0.6);
  left: 0;
}

.header_player.style-two .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  color: #d20f14;
}

.header_player.style-two .jp-audio .jp-volume-bar .jp-volume-bar-value {
  background: #d20f14;
}

.header_player.style-two .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #d31014;
}

.header_player.style-two .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  background: #fff;
}

.banner-slider-five .swiper-slide .slider-content-two .tim-btn {
  color: #636363;
  background: #fff;
}

/*--------------------------------------------------------------
  ##  Tranding Album
  --------------------------------------------------------------*/
#tranding-album, #tranding-album-two {
  padding: 120px 0 90px;
}

#tranding-album-two {
  padding: 120px 0 70px;
}

.album-box {
  position: relative;
  margin-bottom: 30px;
  overflow: hidden;
  max-width: 530px;
}

.album-box .box-thumb:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.album-box img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
  transform-origin: 100% 100%;
}

.album-box .album-details {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  bottom: 0;
  padding: 22px 40px;
  width: 100%;
  transition: all 0.3s ease-in-out;
  z-index: 222;
}

.album-box .album-details .content {
  display: inline-block;
  float: left;
}

.album-box .album-details .content p {
  color: #fff;
  font-size: 16px;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: 0;
}

.album-box .album-details .content .album-name {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  padding-bottom: 8px;
  line-height: 24px;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.album-box .album-details .content .album-name a {
  color: #fff;
}

.album-box .album-details .content .album-name a:hover {
  color: rgba(255, 255, 255, 0.8);
}

.album-box .album-details .content .album-name:before, .album-box .album-details .content .album-name:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  background: #e43a90;
  transition: all 0.3s ease-in-out;
}

.album-box .album-details .content .album-name:before {
  width: 100px;
  height: 1px;
}

.album-box .album-details .content .album-name:after {
  width: 30px;
  height: 3px;
  bottom: -1px;
  left: 15px;
}

.album-box .album-details .icon {
  text-align: right;
  height: 50px;
  position: relative;
  margin-top: 3px;
  transition: all 0.3s ease-in-out;
  padding-left: 15px;
}

.album-box .album-details .icon i {
  font-size: 50px;
  line-height: 42px;
  color: #e43a90;
  transition: all 0.3s ease-in-out;
}

.album-box:hover .box-thumb:before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

.album-box:hover img {
  transform: scale(1.05);
}

.album-box:hover .album-details {
  background: rgba(228, 58, 144, 0.97);
}

.album-box:hover .album-details .content .album-name:before, .album-box:hover .album-details .content .album-name:after {
  background: #fff;
}

.album-box:hover .album-details .icon {
  border-color: #fff;
}

.album-box:hover .album-details .icon i {
  color: #fff;
}

.album-box.album-box-two .box-thumb {
  position: relative;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  border-radius: 4px;
  overflow: hidden;
}

.album-box.album-box-two .box-thumb img {
  width: 100%;
}

.album-box.album-box-two .box-thumb .icon {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.album-box.album-box-two .box-thumb .icon i {
  font-size: 30px;
  color: #fff;
}

.album-box.album-box-two .album-details {
  position: static;
  background: transparent !important;
  padding: 20px 0;
}

.album-box.album-box-two .album-details .content h3 a {
  color: #444;
}

.album-box.album-box-two .album-details .content h3 a:hover {
  color: #e43a90;
}

.album-box.album-box-two .album-details .content h3:before, .album-box.album-box-two .album-details .content h3:after {
  background: #000;
}

.album-box.album-box-two .album-details .content p {
  color: #444;
}

.album-box.album-box-two:hover {
  background: transparent;
}

.album-box.album-box-two:hover .content .album-name:before, .album-box.album-box-two:hover .content .album-name:after {
  background: #e43a90 !important;
}

.album-box.album-box-two:hover .content p {
  color: #444;
}

.tab-top-music {
  background: url(../../media/background/13.jpg);
}

.hover14 figure::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.hover14 figure:hover::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

@media (max-width: 1280px) {
  .album-box .album-details {
    padding: 15px 30px;
  }
  .album-box .album-details .icon i {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  #tranding-album-two {
    padding: 50px 0 20px;
  }
  #tranding-album {
    padding: 50px 0 20px;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  .album-box .album-details {
    padding: 10px 15px;
  }
  .album-box .album-details .content .album-name {
    font-size: 18px;
    font-weight: 600;
  }
  .album-box .album-details .content p {
    font-size: 14px;
  }
  .album-box .album-details .icon {
    height: 35px;
    margin-top: 10px;
    padding-left: 10px;
  }
  .album-box .album-details .icon i {
    font-size: 35px;
    line-height: 30px;
  }
}

@media (max-width: 500px) {
  .album-box .album-details .content .album-name {
    font-size: 17px;
    font-weight: 400;
  }
}

/*--------------------------------------------------------------
  ##  Artist
  --------------------------------------------------------------*/
.artist-image img {
  border-radius: 10px;
  max-width: 100%;
  height: auto;
}

.artist-details {
  margin-top: 50px;
}

.artist-details .artist-name {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 26px;
}

.artist-details .band-name {
  color: #fff;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 30px;
}

.artist-details .details h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.artist-details .details p {
  color: #fff;
}

.artist-details .details .sng {
  margin-top: 20px;
}

.artist-details-two {
  margin-top: 45px;
}

@media (max-width: 1200px) {
  .artist-details {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  .artist-details {
    margin-top: 30px;
  }
  .artist-details-two {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ##  Upcomiong Concerts
  --------------------------------------------------------------*/
.concerts {
  background: #fff;
  box-shadow: 0px 0px 46px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 35px 0;
  margin-bottom: 40px;
}

.concerts:last-child {
  margin-bottom: 0;
}

.concerts-count {
  width: 26%;
  height: 75px;
  padding: 0 50px;
}

.concerts {
  display: flex;
  align-items: center;
}

.concerts .countdown {
  background: #e43a90;
  border-radius: 5px;
  height: 75px;
  padding: 15px 0;
}

.concerts .countdown .CountdownContent {
  width: 25%;
  display: inline-block;
  text-align: center;
  font-size: 24px;
  color: #fff;
  font-weight: 500;
  position: relative;
}

.concerts .countdown .CountdownContent:after {
  content: ':';
  position: absolute;
  right: -5px;
  color: #fff;
  top: 0;
}

.concerts .countdown .CountdownContent:last-child:after {
  display: none;
}

.concerts .countdown .CountdownContent .CountdownLabel {
  display: block;
  font-size: 12px;
  font-weight: 300;
}

.concerts .concert-details {
  width: 40%;
  padding: 0 65px;
  border-left: 1px solid #e1e1e1;
  border-right: 1px solid #e1e1e1;
}

.concerts .concert-details h3 {
  font-size: 18px;
  font-weight: 500;
  color: #363636;
}

.concerts .concert-details h2 {
  color: #393838;
  font-size: 24px;
  margin: 0;
}

.concerts .concert-details p {
  font-size: 16px;
  color: #363636;
  margin-bottom: 0;
}

.concerts .ticket {
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: center;
  width: 34%;
}

.concerts .ticket .tic-btn {
  padding: 10px 24px;
  color: #343434;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 30px;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  border: 1px solid #a2a2a2;
}

.concerts .ticket .tic-btn:focus {
  outline: none;
}

.concerts .ticket .tic-btn:last-child {
  margin-right: 0;
}

.concerts .ticket .tic-btn:hover {
  background: #444;
  color: #fff;
}

.concerts .ticket .tic-btn.tic-btn-bg {
  background: #e43a90;
  color: #fff;
  border: transparent;
}

.concerts .ticket .tic-btn.tic-btn-bg:hover {
  background: #444;
  color: #fff;
}

.concerts.concerts-two {
  background: transparent;
  box-shadow: none;
  border: 1px dashed #d9d9d9;
  transition: all 0.3s ease-in-out;
}

.concerts.concerts-two .concerts-count {
  width: 25%;
  padding: 0 50px 0 0;
}

.concerts.concerts-two .concerts-count .countdown {
  background: transparent;
  border: 1px dashed #cacaca;
  padding: 8px 0;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent {
  color: #343434;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent:after {
  color: #343434;
}

.concerts.concerts-two .concerts-count .countdown .CountdownContent .CountdownLabel {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
}

.concerts.concerts-two .concert-details {
  padding: 0 30px;
  border: 0;
  display: flex;
  align-items: center;
  width: 40%;
}

.concerts.concerts-two .concert-details img {
  width: 125px;
  height: 125px;
  box-shadow: 0px 7px 17.43px 3.57px rgba(88, 88, 88, 0.14);
  border-radius: 4px;
}

.concerts.concerts-two .concert-details .content {
  margin-left: 30px;
}

.concerts.concerts-two .ticket {
  width: 35%;
}

.concerts.concerts-two:hover {
  background: rgba(255, 255, 255, 0.25);
}

.concerts.concerts-two:hover .concerts-count .countdown {
  background: transparent;
}

/* Upcomming Concert Three */
.upc-con-three {
  text-align: center;
  border-radius: 10px;
  position: relative;
}

.upc-con-three .cons-feature-image {
  position: relative;
}

.upc-con-three .cons-feature-image img {
  max-width: 100%;
}

.upc-con-three .content-wrap {
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 900;
  text-align: center;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  transition: all .4s ease-in-out;
}

.upc-con-three .content-wrap .content {
  padding: 30px;
}

.upc-con-three .content-wrap .content h4 {
  font-size: 14px;
  font-weight: 500;
}

.upc-con-three .content-wrap .content h3 {
  font-size: 20px;
  font-weight: 700;
  color: #e43a90;
  text-transform: uppercase;
}

.upc-con-three .content-wrap .content p {
  margin: 0;
}

.upc-con-three .upc-count-wrap {
  background: #363636;
  padding: 150px 0;
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 1000;
  -webkit-transform: rotateY(-180deg);
  -webkit-transform--moz-transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  -webkit-transform-style--moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -webkit-backface-visibility--moz-backface-visibility: hidden;
  transition: all .4s ease-in-out;
  width: 100%;
  height: 100%;
}

.upc-con-three .upc-count-wrap .countdown {
  margin-bottom: 20px;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent {
  display: inline-block;
  color: #fff;
  font-size: 22px;
  font-family: "Changa", sans-serif;
  padding: 0 12px;
  position: relative;
  line-height: 20px;
  font-weight: 500;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent:after {
  position: absolute;
  content: '';
  height: 25px;
  width: 1px;
  right: 0;
  top: 7px;
  background: #fff;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent:last-child:after {
  display: none;
}

.upc-con-three .upc-count-wrap .countdown .CountdownContent .CountdownLabel {
  display: block;
  font-size: 14px;
  font-weight: 400;
}

.upc-con-three .upc-count-wrap .tic-btn {
  padding: 14px 22px;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  display: inline-block;
  border: 1px solid #fff;
}

.upc-con-three .upc-count-wrap .tic-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.upc-con-three .upc-count-wrap .tic-btn.tic-btn-bg {
  margin-left: 15px;
}

.upc-con-three {
  margin: 0 auto;
  height: 420px;
  position: relative;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
}

.upc-con-three .content-wrap,
.upc-con-three .upc-count-wrap {
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 7px 34.2px 1.8px rgba(0, 0, 0, 0.1);
}

.upc-con-three.flip .content-wrap {
  z-index: 900;
  transform: rotateY(180deg);
}

.upc-con-three.flip .upc-count-wrap {
  z-index: 1000;
  -webkit-transform: rotateX(0deg) rotateY(0deg);
  -moz-transform: rotateX(0deg) rotateY(0deg);
}

.upc-con-three .content-wrap {
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 900;
  text-align: center;
  transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

.upc-con-three .upc-count-wrap {
  background: #363636;
  padding: 150px 0;
  height: inherit;
  position: absolute;
  top: 0;
  z-index: 1000;
  transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -webkit-transform: rotateY(-180deg);
  transition: all .4s ease-in-out;
  width: 100%;
  height: 100%;
  opacity: .5;
}

.upc-con-three:hover .content-wrap {
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}

.upc-con-three:hover .upc-count-wrap {
  transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -webkit-transform: rotateY(0deg);
  opacity: 1;
}

@media (max-width: 1440px) {
  .concerts-count {
    padding: 0 30px;
  }
  .concerts .concert-details {
    padding: 0 40px;
  }
  .concerts .concert-details h2 {
    font-size: 20px;
  }
  .concerts .ticket .tic-btn {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .concerts.concerts-two .concerts-count {
    padding: 0 0 0 30px;
  }
  .concerts .ticket {
    padding: 0;
  }
  .concerts .ticket .tic-btn {
    padding: 8px 15px;
    font-size: 15px;
  }
  .concerts.concerts-two {
    padding: 15px 0;
  }
  .concerts.concerts-two .concerts-count {
    padding: 0 15px 0 0;
    width: 30%;
  }
  .concerts.concerts-two .ticket {
    width: 27%;
  }
  .concerts.concerts-two .concert-details {
    width: 58%;
    padding: 0 15px;
  }
  .concerts .ticket .tic-btn {
    margin-right: 9px;
  }
}

@media (max-width: 991px) {
  .concerts {
    display: block;
  }
  .concerts.concerts-two .concerts-count, .concerts.concerts-two .concert-details, .concerts.concerts-two .ticket {
    width: 100%;
    max-width: 500px;
    margin: 0 auto 30px;
  }
  .concerts.concerts-two .concerts-count {
    padding: 0 20px;
    margin-top: 20px;
  }
  .concerts.concerts-two .ticket {
    margin-bottom: 0;
  }
  .upc-con-three {
    margin-bottom: 30px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .concerts-count {
    padding: 0 15px;
  }
  .concerts .countdown .CountdownContent {
    font-size: 20px;
  }
  .concerts .countdown .CountdownContent:after {
    right: -3px;
  }
  .concerts .countdown .CountdownContent .CountdownLabel {
    font-size: 10px;
  }
  .concerts .concert-details {
    padding: 0 20px;
  }
  .concerts .concert-details h3 {
    font-size: 14px;
  }
  .concerts .concert-details h2 {
    font-size: 16px;
  }
  .concerts .ticket .tic-btn {
    padding: 8px 14px;
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .section-padding {
    padding: 50px 0;
  }
  .concerts {
    display: block !important;
  }
  .concerts .concerts-count, .concerts .concert-details, .concerts .ticket {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
  }
  .concerts .concerts-count {
    margin: 0 auto 20px;
    padding: 0 15px;
    width: 100%;
  }
  .concerts .concert-details {
    padding: 20px 15px;
    border-left: 0;
    border-right: 0;
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    text-align: center;
  }
  .concerts .ticket {
    margin: 20px auto 0;
  }
  .concerts.concerts-two .concerts-count {
    margin: 20px auto 0px;
  }
  .upc-con-three .upc-count-wrap .tic-btn {
    padding: 14px 11px;
  }
}

@media (min-width: 500px) and (max-width: 576px) {
  .upc-con-three {
    height: 480px;
  }
}

@media (max-width: 500px) {
  .concerts.concerts-two .concert-details {
    padding: 0;
    display: block;
  }
  .concerts.concerts-two .concert-details .content {
    margin: 10px 0 0 0;
  }
  .concerts.concerts-two .ticket {
    margin: 50px 0;
  }
  .concerts .ticket .tic-btn {
    padding: 10px 14px;
    font-size: 15px;
  }
  .upc-con-three {
    height: 430px;
  }
  .concerts.concerts-two .concerts-count .countdown {
    border: none;
  }
}

/*--------------------------------------------------------------
  ##  Ticket
  --------------------------------------------------------------*/
#ticket {
  background-size: cover;
  background-position: center center;
  padding: 120px 0 120px;
}

.live-ticket h2 {
  font-size: 40px;
  color: #fff;
  line-height: 46px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  margin-bottom: 0px;
  padding-bottom: 20px;
}

.live-ticket h2 span {
  color: #e43a90;
}

.live-ticket h2:after {
  position: absolute;
  content: '';
  background-image: url(../img/dm2l.png);
  height: 15px;
  max-width: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  left: 0;
  bottom: -8px;
  display: none;
}

.live-ticket h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 18px;
}

.live-ticket h4 span {
  color: #e43a90;
  font-weight: 700;
}

.live-ticket p {
  color: #fff;
  line-height: 26px;
  font-size: 16px;
  margin: 0;
}

.live-ticket .tim-btn {
  color: #fff;
  border-color: #fff;
}

.live-ticket .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.ticket-thumb img {
  max-width: 100%;
  max-width: 100%;
  grid-column: 1 / span 1;
  clip-path: polygon(0 0, 0 100%, 93% 100%, 100% 0);
}

.live-ticket-count {
  margin: 45px 0 45px;
}

.live-ticket-count .countdown {
  max-width: 500px;
}

.live-ticket-count .countdown .CountdownContent {
  display: inline-block;
  height: 93px;
  width: 104px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  font-family: "Changa", sans-serif;
  text-align: center;
  position: relative;
  background-image: url("../../media/background/1.png");
  background-size: contain;
  margin-right: 20px;
  background-repeat: no-repeat;
  padding: 24px 0;
}

.live-ticket-count .countdown .CountdownContent .CountdownLabel {
  display: block;
  text-align: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

@media (min-width: 1200px) and (max-width: 1440px) {
  .live-ticket h2 {
    font-size: 30px;
    line-height: 35px;
  }
  .live-ticket h4 {
    margin-bottom: 10px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket .tim-btn {
    padding: 8px 25px;
  }
  .live-ticket-count {
    margin: 25px 0 15px;
  }
  .live-ticket-count .countdown .CountdownContent {
    width: 85px;
    font-size: 30px;
    padding: 13px 0;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 12px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .live-ticket h2 {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .live-ticket h4 {
    margin-bottom: 10px;
  }
  .live-ticket p {
    font-size: 14px;
    line-height: 22px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket .tim-btn {
    padding: 5px 22px;
  }
  .live-ticket-count {
    margin: 20px 0 15px;
  }
  .live-ticket-count .countdown .CountdownContent {
    width: 70px;
    font-size: 25px;
    padding: 12px 0;
    height: 62px;
    line-height: 20px;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .live-ticket {
    padding: 30px;
  }
  .ticket-thumb img {
    clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0);
  }
}

@media (max-width: 768px) {
  .live-ticket {
    padding: 30px 15px;
    text-align: center;
  }
  .live-ticket h2 {
    font-size: 35px;
    line-height: 40px;
  }
  .live-ticket p br {
    display: none;
  }
  .live-ticket-count .countdown {
    max-width: 100%;
  }
  .live-ticket-count .countdown .CountdownContent {
    height: 90px;
    width: 100px;
    font-size: 25px;
    padding: 18px 0;
    margin-right: 10px;
    background-size: cover;
    margin-bottom: 15px;
  }
  .live-ticket-count .countdown .CountdownContent .CountdownLabel {
    font-size: 16px;
  }
  #ticket {
    padding: 50px 0 50px;
  }
}

@media (max-width: 500px) {
  .live-ticket h2 {
    font-size: 30px;
  }
  .live-ticket h4 {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------
  ##  Ultimate Tabs
  --------------------------------------------------------------*/
.tabs-wrap {
  background: #f6f6f6;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.music-tabs-items {
  flex-wrap: wrap;
  display: flex;
}

.tabs-item {
  flex: 0 0 25%;
  padding: 0 10px;
  max-width: 25%;
  -webkit-box-flex: 0;
}

.tabs-item-two {
  flex: 0 0 33.33%;
  padding: 0 10px;
  max-width: 33.33%;
  -webkit-box-flex: 0;
}

.music-tabs {
  background-size: cover;
  background-position: center center;
}

.tab-pd-left {
  padding: 80px 80px 60px 135px;
  background-size: cover;
}

.tab-pd-right {
  padding: 17px 135px 0 80px;
}

.music-tab {
  position: relative;
  border: 1px solid #b7b7b7;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
}

.music-tab:before {
  position: absolute;
  content: '';
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.music-tab img {
  width: 100%;
  height: auto;
}

.music-tab > a {
  position: absolute;
  right: 20px;
  top: 15px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.music-tab > a span {
  color: #e43a90;
}

.music-tab .content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 20px 15px 20px;
  width: 100%;
}

.music-tab .content h3 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 0;
}

.music-tab .content h3 a {
  color: #fff;
}

.music-tab .content h3 a:hover {
  color: #e43a90;
}

.music-tab .content .ratting {
  list-style: none;
}

.music-tab .content .ratting li {
  display: inline-block;
  color: #e43a90;
  font-size: 14px;
}

.music-tab.music-tab-two {
  background: #fff;
  border-radius: 4px;
  padding: 29px 20px;
  position: relative;
  min-height: 145px;
}

.music-tab.music-tab-two:before {
  display: none;
}

.music-tab.music-tab-two .bg-icon {
  font-size: 50px;
  font-family: Flaticon;
  position: absolute;
  bottom: 47px;
  right: 15px;
}

.music-tab.music-tab-two .bg-icon i {
  font-size: 60px;
  opacity: 0.09;
}

.music-tab.music-tab-two i {
  font-size: 22px;
}

.music-tab.music-tab-two .content {
  position: relative;
  padding: 0;
}

.music-tab.music-tab-two .content h3 {
  color: #444;
  font-family: "Changa", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.music-tab.music-tab-two .content h3 a {
  position: relative;
  color: #444;
  right: auto;
  top: auto;
}

.music-tab.music-tab-two .content h3 a:hover {
  color: #e43a90;
}

.music-tab.music-tab-two .content .ratting li i {
  font-size: 14px;
}

.music-tab.music-tab-two .view-btn {
  position: relative;
  color: #444;
  right: auto;
}

.music-tab.music-tab-two .view-btn:hover {
  color: #e43a90;
}

@media (max-width: 1600px) {
  .tab-pd-right {
    padding: 30px 80px;
  }
  .tab-pd-left {
    padding-left: 70px;
  }
}

@media (min-width: 1280px) and (max-width: 1600px) {
  .tabs-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1279px) {
  .tabs-item, .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 768px) {
  .music-tabs-items {
    padding: 50px 5% 30px;
  }
  .tabs-item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .tabs-item-two {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 600px) {
  .tabs-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 500px) {
  .tabs-item-two {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .music-tab.music-tab-two .content h3 {
    font-size: 15px;
    font-weight: 400;
  }
}

/*--------------------------------------------------------------
  ##  Intro Video
  --------------------------------------------------------------*/
#video-intro {
  padding: 250px 0;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.intro-video {
  text-align: center;
}

.video-btn {
  height: 85px;
  width: 85px;
  border: 2px solid #fff;
  border-radius: 50%;
  color: #fff;
  line-height: 82px;
  display: block;
  margin: 0 auto;
  font-size: 26px;
  margin-bottom: 20px;
}

.video-btn i {
  margin-left: 3px;
  color: #fff;
}

.video-btn:hover {
  background: #e43a90;
  border-color: #e43a90;
}

.video-btn:hover i {
  color: #fff;
}

.intro-video h2 {
  font-size: 70px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  letter-spacing: 5px;
}

.intro-video h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 20px;
}

.intro-video p {
  font-size: 18px;
  color: #fff;
  margin-bottom: 0px;
  font-family: "Changa", sans-serif;
  font-weight: 400;
}

.intro-video span {
  display: block;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

/* Watch Video */
#watch {
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  padding: 150px 0;
}

.watch-inner .tim-btn {
  color: #fff;
  border-color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 500;
}

.watch-inner .tim-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.watch-inner .tim-btn.tim-btn-bg {
  margin-left: 20px;
  background: #e43a90;
  border-color: #e43a90;
}

.watch-inner .tim-btn.tim-btn-bg:hover {
  color: #363636;
  background: #fff;
  border-color: #fff;
}

@media (max-width: 768px) {
  #video-intro {
    padding: 150px 0;
  }
  .intro-video h2 {
    font-size: 30px;
  }
  .intro-video p {
    font-size: 18px;
  }
  .watch-inner .tim-btn.tim-btn-bg {
    margin-top: 10px;
    margin-left: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Blog
  --------------------------------------------------------------*/
#blog-grid {
  background: #f8f8f8;
}

#blog-grid.section-padding {
  padding-bottom: 90px;
}

.blog-post-grid {
  background: #FFF;
  max-width: 400px;
  margin: 0 auto 30px;
}

.blog-post-grid img {
  max-width: 100%;
  height: auto;
}

.blog-post-grid .entry-date {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  background: #e43a90;
  padding: 10px;
  text-align: center;
  height: 80px;
  width: 80px;
  display: inline-block;
  transform: translate(-10px, -90px);
  position: absolute;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
}

.blog-post-grid .entry-date span {
  font-size: 16px;
  font-style: normal;
}

.blog-post-grid .share-link {
  float: left;
  list-style: none;
  text-align: center;
  padding: 0 20px;
}

.blog-post-grid .share-link li a {
  color: #363636;
  font-size: 17px;
  line-height: 28px;
}

.blog-post-grid .entry-content {
  padding: 30px 25px;
}

.blog-post-grid .entry-content .entry-header .entry-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 10px;
}

.blog-post-grid .entry-content .entry-header .entry-title a {
  color: #363636;
}

.blog-post-grid .entry-content .entry-header .entry-title a:hover {
  color: #e43a90;
}

.blog-post-grid .entry-content .read-btn {
  font-family: "Changa", sans-serif;
  color: #434343;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding: 8px 15px 8px 0;
}

.blog-post-grid .entry-content .read-btn:hover {
  color: #e43a90;
}

.blog-post-grid .entry-meta {
  border-top: 1px solid #e4e4e4;
  padding: 10px 20px;
}

.blog-post-grid .entry-meta a {
  color: #5d5a5a;
  font-size: 16px;
}

.blog-post-grid .entry-meta a i {
  margin-right: 5px;
  color: #5d5a5a;
}

.blog-post-grid .entry-meta a span {
  color: #5d5a5a;
}

.blog-post-grid .entry-meta a:hover {
  color: #e43a90;
}

.blog-post-grid .entry-meta .comments {
  margin-left: 30px;
  position: relative;
  float: right;
}

.blog-post-grid .post-type-icon {
  height: 50px;
  width: 50px;
  text-align: center;
  display: block;
  position: relative;
  z-index: 111;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  line-height: 48px;
  transform: translate(7px, -70px);
  position: absolute;
  background: #e43a90;
}

.blog-post-grid .post-type-icon span {
  display: block;
  font-size: 16px;
}

.blog-post-grid .post-type-icon:before, .blog-post-grid .post-type-icon:after {
  display: none;
}

.post-type-icon {
  height: 65px;
  width: 65px;
  background-color: transparent;
  transform: translate(27px, -50px);
  font-size: 25px;
  line-height: 60px;
  display: inline-block;
  text-align: center;
  color: #fff;
  position: absolute;
  z-index: 11;
}

.post-type-icon:before {
  position: absolute;
  content: '';
  height: 65px;
  width: 65px;
  background: #e43a90;
  left: 0;
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-radius: 4px;
  z-index: -1;
}

.post-type-icon:after {
  position: absolute;
  content: '';
  border: 2px solid #fff;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  left: 0;
  top: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Blog List */
.blog-posts {
  padding: 100px 0;
}

.blog-post {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  margin-bottom: 30px;
  overflow: hidden;
}

.blog-post .post-thumbnail a {
  display: block;
}

.blog-post .post-thumbnail a img {
  max-width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.blog-post.blog-grid .entry-content-wrapper {
  padding: 25px 30px;
}

.blog-post .tim-btn-bgt.read-more-btn {
  border-color: #e4e4e4;
  color: #363636;
}

.blog-post .tim-btn-bgt.read-more-btn:hover {
  color: #e43a90;
}

.entry-content-wrapper {
  padding: 30px;
  position: relative;
  background: #fff;
}

.entry-content-wrapper ul {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
  display: block;
  clear: both;
}

.entry-content-wrapper ul li {
  float: left;
  width: 50%;
  padding-left: 20px;
  position: relative;
  line-height: 30px;
}

.entry-content-wrapper ul li:before {
  position: absolute;
  content: '';
  left: 0;
  top: 9px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: #464646;
}

.entry-content-wrapper .entry-meta ul.meta {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-content-wrapper .entry-meta ul.meta li {
  display: inline-block;
  margin-right: 5px;
  font-family: "Changa", sans-serif;
  width: auto;
  padding-left: 0;
  float: none;
}

.entry-content-wrapper .entry-meta ul.meta li:before {
  display: none;
}

.entry-content-wrapper .entry-meta ul.meta li a {
  color: #717171;
}

.entry-content-wrapper .entry-meta ul.meta li a:hover {
  color: #e43a90;
}

.entry-content-wrapper .entry-meta ul.meta li .author a {
  color: #e43a90;
}

.entry-content-wrapper .entry-header .entry-title {
  font-size: 26px;
  font-weight: 500;
}

.entry-content-wrapper .entry-header .entry-title a {
  color: #363636;
}

.entry-content-wrapper .entry-header .entry-title a:hover {
  color: #e43a90;
}

.entry-content-wrapper .blog-footer {
  border-top: 1px dashed #e0e0e0;
  padding-top: 30px;
  margin-top: 80px;
}

.entry-content-wrapper .blog-footer .tag a {
  padding: 0 12px;
  background: #363636;
  font-size: 15px;
  line-height: 28px;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  margin-right: 5px;
}

.entry-content-wrapper .blog-footer .tag a:hover {
  background: #e43a90;
  color: #fff;
}

.entry-content-wrapper .entry-content {
  margin-bottom: 30px;
}

.post-on {
  position: absolute;
  height: 65px;
  width: 65px;
  text-align: center;
  transform: translate(27px, -49px);
  padding: 12px 0;
  line-height: 20px;
}

.post-on:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.9);
  border: 3px solid #e2dfe2;
  border-radius: 4px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: rotate(-45deg);
}

.post-on a {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  font-family: "Changa", sans-serif;
  height: 65px;
  width: 65px;
  z-index: 1;
  position: relative;
  color: #fff;
}

.post-on a span {
  display: block;
  font-size: 15px;
}

ul.shared-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
  clear: inherit;
}

ul.shared-link li {
  height: 40px;
  width: 40px !important;
  position: relative;
  z-index: 1;
  text-align: center;
  line-height: 40px;
  float: none;
  width: auto;
  padding-left: 0;
  display: inline-block;
  margin-right: 15px;
}

ul.shared-link li:before {
  content: '';
  position: absolute;
  height: 40px;
  width: 40px;
  border: 4px solid #ebebeb;
  z-index: -1;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
  box-shadow: 0px 5px 17.22px 3.78px rgba(180, 180, 180, 0.21);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
}

ul.shared-link li a {
  color: #717171;
  display: block;
  line-height: 40px;
}

ul.shared-link li:hover:before {
  background: #e43a90;
  border-color: #e43a90;
}

ul.shared-link li:hover a {
  color: #fff;
}

blockquote {
  color: #474747;
  background: #f6f6f6;
  padding: 30px 30px 30px 60px;
  font-size: 20px;
  font-style: italic;
  position: relative;
  font-weight: 500;
}

blockquote .quote-icon {
  position: absolute;
  left: 35px;
  top: 30px;
  color: #c5c5c5;
}

.blog-count-details {
  padding: 7px 0;
}

.blog-count-details a {
  color: #666;
  margin-right: 15px;
  position: relative;
  font-weight: 600;
}

.blog-count-details a:after {
  position: absolute;
  content: '';
  height: 12px;
  width: 1px;
  background: #bbb;
  right: -10px;
  top: 5px;
}

.blog-count-details a:last-child:after {
  display: none;
}

.blog-count-details a i {
  margin-right: 5px;
  color: #e43a90;
}

.blog-post-list {
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 30px;
}

/* Comment */
.comments-area ul {
  margin: 0;
  list-style: none;
}

.comments-area ul ul {
  margin-left: 50px;
}

.comment-inner {
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 25px;
  padding: 60px 40px;
  margin-bottom: 30px;
}

.reply-title h3, .comment-reply-title {
  font-size: 26px;
  font-weight: 600;
  color: #3c3c3c;
  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
}

ul.comment-list .comment-body {
  position: relative;
  margin-bottom: 40px;
}

ul.comment-list .comment-body .comment-author a {
  font-size: 24px;
  color: #e43a90;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

ul.comment-list .comment-body .comment-author .author-thumb {
  height: 110px;
  width: 110px;
  border-radius: 50%;
  border: 6px solid #ffffff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  float: left;
}

ul.comment-list .comment-body .comment-author .author-thumb img {
  max-width: 100%;
  border-radius: 50%;
}

ul.comment-list .comment-body .comment-author .comment-metadata a {
  font-size: 15px;
  color: #979696;
}

ul.comment-list .comment-body .author-name {
  margin-bottom: 10px;
}

ul.comment-list .comment-body .comment-content p {
  margin-bottom: 0;
}

ul.comment-list .comment-body .author-name, ul.comment-list .comment-body .comment-content {
  margin-left: 130px;
}

ul.comment-list .comment-body .reply {
  position: absolute;
  top: 0;
  right: 0;
}

ul.comment-list .comment-body .reply a {
  color: #363636;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
}

ul.comment-list .comment-body .reply a i {
  margin: 5px;
}

ul.comment-list .comment-body .reply a:hover {
  color: #e43a90;
}

.comment-respond {
  background: #fff;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 25px;
  padding: 60px 40px;
}

.comment-form:after {
  content: '';
  clear: both;
  display: block;
}

.comment-form p.comment-form-comment {
  width: 100%;
}

.comment-form p.comment-form-comment textarea {
  width: 100%;
  background: #fafafa;
  height: 170px;
  border: 1px solid #ededed;
  box-shadow: none;
  border-radius: 25px;
  padding: 20px;
  outline: 0;
  transition: all 0.5s ease-in-out;
}

.comment-form p.comment-form-comment textarea:hover, .comment-form p.comment-form-comment textarea:focus {
  border-color: #a59a9a;
}

.comment-form p {
  width: 33.33%;
  float: left;
  padding-right: 20px;
}

.comment-form p input {
  width: 100%;
  height: 50px;
  border-radius: 15px;
  padding: 10px 20px;
  border: 1px solid #ededed;
  outline: 0;
  transition: all 0.5s ease-in-out;
}

.comment-form p input:hover, .comment-form p input:focus {
  border-color: #a59a9a;
}

.comment-form p input::placeholder {
  color: #838383;
}

.comment-form .form-submit {
  width: 100%;
  padding-right: 0;
  text-align: center;
  margin-top: 30px;
}

.comment-form .form-submit .submit {
  border: 1px solid #e4e4e4;
  padding: 10px 20px;
  color: #363636;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  max-width: 200px;
  text-transform: uppercase;
  font-family: "Changa", sans-serif;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  background: transparent;
}

.comment-form .form-submit .submit:hover {
  color: #e43a90;
}

/* Post Navigation */
.posts-navigation ul {
  margin: 60px 0 0;
  padding: 0;
  list-style: none;
}

.posts-navigation ul li {
  height: 35px;
  width: 35px;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  display: inline-block;
  vertical-align: middle;
  line-height: 34px;
}

.posts-navigation ul li a {
  font-size: 18px;
  font-weight: 500;
  color: #a5a4a4;
  line-height: 18px;
}

.posts-navigation ul li:hover a, .posts-navigation ul li.active a {
  color: #e43a90;
}

.posts-navigation ul li:first-child, .posts-navigation ul li:last-child {
  height: auto;
  width: auto;
  padding: 7px 20px;
  line-height: 18px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .blog-post-grid {
    max-width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .blog-post.blog-grid .entry-content-wrapper {
    padding: 25px 20px;
  }
  .blog-post.blog-grid .entry-content-wrapper .entry-header .entry-title {
    font-size: 20px;
  }
  .widget {
    padding: 20px 15px;
  }
}

@media (max-width: 768px) {
  .blog-post-grid .share-link {
    padding: 0 10px;
  }
  .blog-post-grid .entry-content {
    padding: 30px 15px;
  }
  .blog-post-grid .entry-meta {
    padding: 10px 15px;
  }
  .comment-form p {
    width: 100%;
    padding-right: 0;
  }
  .comment-respond {
    margin-bottom: 30px;
  }
  .widget_popular_entries .widgets-popular-post li {
    max-width: 350px;
  }
  .blog-posts {
    padding: 50px 0;
  }
}

@media (max-width: 500px) {
  .blog-post .post-type-icon {
    height: 50px;
    width: 50px;
    line-height: 48px;
    font-size: 22px;
    transform: translate(24px, -33px);
  }
  .blog-post .post-type-icon:before {
    height: 50px;
    width: 50px;
  }
  .entry-content-wrapper {
    padding: 30px 20px;
  }
  .entry-content-wrapper .blog-footer {
    text-align: center;
  }
  .entry-content-wrapper ul li {
    width: 100%;
    line-height: 28px;
  }
  .blog-count-details.float-left {
    float: none !important;
    margin-bottom: 10px;
  }
  ul.shared-link {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 20px;
  }
  ul.shared-link li {
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 0;
  }
  blockquote {
    padding: 30px 30px 30px 45px;
  }
  blockquote .quote-icon {
    left: 15px;
  }
  ul.shared-link {
    margin: 0;
  }
  ul.shared-link.float-right {
    float: none !important;
  }
  ul.comment-list .comment-body {
    text-align: center;
  }
  ul.comment-list .comment-body .comment-author .author-thumb {
    float: none;
    margin: 0 auto;
  }
  ul.comment-list .comment-body .author-name, ul.comment-list .comment-body .comment-content {
    margin-left: 0;
  }
  ul.comment-list .comment-body .reply {
    position: static;
  }
  #blog-grid.section-padding {
    padding: 50px 0 20px;
  }
  #blog-grid.section-padding {
    padding-bottom: 20px !important;
  }
  .posts-navigation ul li {
    margin-top: 15px;
  }
}

/*--------------------------------------------------------------
  ##  Widgets
  --------------------------------------------------------------*/
.widget {
  background: #fff;
  padding: 40px 30px;
  text-align: center;
  box-shadow: 0px 3px 21px 0px rgba(204, 204, 204, 0.35);
  border-radius: 15px;
  margin-bottom: 60px;
}

.widget.widget-shop {
  text-align: left !important;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.widget.widget-shop .widget-title-shop {
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 600;
  color: #363636;
  margin-bottom: 20px;
  font-family: "Changa", sans-serif;
}

.widget.widget-shop .widget-title-shop span {
  color: #e43a90;
}

.widget .widget-title {
  font-size: 26px;
  color: #3c3c3c;
  padding: 7px 0;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin-bottom: 30px;
  font-family: "Changa", sans-serif;
  position: relative;
}

.widget .widget-title:before, .widget .widget-title:after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #e43a90;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.widget .widget-title:before {
  width: 60px;
  height: 1px;
}

.widget .widget-title:after {
  height: 3px;
  bottom: -1px;
  width: 25px;
  left: 50%;
}

.widget .widget-title span {
  color: #e43a90;
}

.widget p {
  color: #676767;
}

.widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
}

.widget ul li {
  line-height: 45px;
  border-bottom: 1px dashed #e0e0e0;
}

.widget ul li:last-child {
  border-bottom: 0;
}

.widget ul li a {
  color: #585858;
  font-size: 16px;
  position: relative;
  display: block;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
}

.widget ul li a span {
  position: absolute;
  right: 0;
}

.widget ul li a:hover {
  color: #e43a90;
}

/* Widget Newsletter */
.widgit_newsletter {
  position: relative;
}

.widgit_newsletter:after {
  position: absolute;
  content: '';
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(../../media/background/12.jpg);
  max-width: 250px;
  width: 100%;
  height: 213px;
  z-index: 1;
}

.widgit_newsletter .newsletter-form {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  z-index: 222;
  background: rgba(255, 255, 255, 0.7);
}

.widgit_newsletter .newsletter-form input {
  height: 60px;
  border: 0;
  border: 1px solid #dcdcdc;
  border-bottom: 0;
  border-radius: 4px 4px 0  0;
  background: transparent;
}

.widgit_newsletter .newsletter-form input::placeholder {
  color: #a2a2a2;
}

.widgit_newsletter .newsletter-form .tim-btn {
  width: 100%;
  display: block;
  border-radius: 0;
  padding: 14px 0;
  text-align: center;
  background: #e4e4e4;
  color: #363636;
  text-transform: uppercase;
}

.widgit_newsletter .newsletter-form .tim-btn:hover {
  background: #e43a90;
}

.widgit_newsletter p {
  color: #676767;
  margin-bottom: 25px;
  position: relative;
  z-index: 22;
}

/* Widget About Me */
.ab-thumbnails {
  margin-bottom: 20px;
}

.ab-thumbnails img {
  max-width: 100%;
  height: auto;
}

/* Popular Post */
.widgets-popular-post li {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.widgets-popular-post li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.widgets-popular-post li .content {
  float: left;
  max-width: 65%;
}

.widgets-popular-post li .content .entry-meta {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
}

.widgets-popular-post li .content .entry-meta.cat a {
  color: #e43a90;
}

.widgets-popular-post li .content h3 {
  font-size: 18px;
}

.widgets-popular-post li .feature-img {
  float: right;
  width: 35%;
  display: block;
}

.widgets-popular-post li .feature-img img {
  max-width: 100%;
}

/* Product Price Filter */
#slider-range {
  background-color: #363636;
}

#range-slider p {
  line-height: 1.6875em;
  color: #363636;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
}

#range-slider p #amount {
  border: 0;
  background-color: transparent;
}

.ui-slider-horizontal {
  height: 4px;
}

.ui-slider-horizontal .ui-slider-range {
  background-color: #e43a90;
}

.ui-slider-horizontal .ui-slider-handle {
  top: -10px;
}

.ui-widget.ui-widget-content {
  border: 0;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  background: #e43a90;
}

.ui-slider .ui-slider-handle {
  box-shadow: inset 0px 6px 3.68px 0.32px rgba(0, 0, 0, 0.18);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background: #e43a90;
  border: 0;
  outline: 0;
}

.fil-btn {
  padding: 10px 25px;
  color: #363636;
  border: 1px solid #e4e4e4;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  margin-top: 30px;
  font-size: 17px;
  line-height: 20px;
  border-radius: 4px;
}

.fil-btn:hover {
  color: #e43a90;
}

/* Best Sales */
.best-sales .sales-item {
  display: inline-block;
  position: relative;
  margin: 5px;
  width: 29%;
}

.best-sales .sales-item:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(228, 58, 144, 0.9);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.best-sales .sales-item img {
  max-width: 100%;
}

.best-sales .sales-item .content {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 15px;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.best-sales .sales-item .content a {
  color: #696969;
  position: relative;
  z-index: 2;
  text-align: center;
  line-height: 28px;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-bottom: 10px;
}

.best-sales .sales-item .content a i {
  font-size: 14px;
  margin-left: -2px;
}

.best-sales .sales-item .content a:before {
  position: absolute;
  content: '';
  height: 30px;
  width: 30px;
  background: #fff;
  border-radius: 4px;
  transform: rotate(-45deg);
  z-index: -1;
  top: 0;
  left: 0;
}

.best-sales .sales-item .content a:hover {
  color: #e43a90;
}

.best-sales .sales-item .content .star-rating {
  margin: 0 auto;
  text-align: center;
  width: 4.7em;
}

.best-sales .sales-item .content .star-rating:before {
  font-size: 14px;
}

.best-sales .sales-item .content .star-rating span:before {
  color: #fff;
  font-size: 14px;
}

.best-sales .sales-item .content .price {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  line-height: 20px;
}

.best-sales .sales-item .content .price span {
  line-height: 20px;
}

.best-sales .sales-item:hover:before {
  opacity: 1;
}

.best-sales .sales-item:hover .content {
  opacity: 1;
}

@media (min-width: 768px) and (max-width: 1320px) {
  .best-sales .sales-item {
    width: 44%;
  }
}

@media (max-width: 767px) {
  .best-sales .sales-item {
    width: auto;
  }
}

/* Adds */
.adds-thumbnails a {
  display: block;
}

.adds-thumbnails a img {
  max-width: 100%;
}

@media (min-width: 768px) and (max-width: 1280px) {
  .widget {
    padding: 20px 15px;
  }
  .widget ul li a {
    font-size: 14px;
  }
  #range-slider p {
    font-size: 15px;
  }
}

@media (max-width: 768px) {
  .widgit_newsletter:after {
    bottom: 10px;
  }
}

/*--------------------------------------------------------------
  ##  Logo Carousel
  --------------------------------------------------------------*/
#logo-carousel {
  padding: 65px 0;
}

.tim-logo-carousel .swiper-wrapper {
  display: flex;
  align-items: center;
}

.tim-logo-carousel .brand-logo {
  text-align: center;
}

.tim-logo-carousel .brand-logo img {
  opacity: 0.4;
  transition: all 0.3s ease-in-out;
}

.tim-logo-carousel .brand-logo:hover img {
  opacity: 1;
}

@media (max-width: 768px) {
  #logo-carousel {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##  lesson
  --------------------------------------------------------------*/
#lesson {
  padding: 120px 0 90px;
}

.lesson-box {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  max-width: 400px;
  margin: 0 auto 30px;
}

.lesson-box:before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 11;
}

.lesson-box img {
  border-radius: 8px;
  vertical-align: top;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  transition: all 0.3s ease-in-out;
  max-width: 100%;
}

.lesson-box i {
  color: #fff;
  font-size: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 22;
}

.lesson-box .content {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  width: 100%;
  text-align: center;
  z-index: 22;
}

.lesson-box .content h3 {
  color: #fff;
  font-weight: 700;
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.lesson-box .content h3 a {
  color: #fff;
}

.lesson-box .content h3 a:hover {
  color: #e43a90;
}

.lesson-box .content p {
  color: #c5c5c5;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.05s;
}

.lesson-box:hover:before {
  opacity: 1;
}

.lesson-box:hover .content h3, .lesson-box:hover .content p {
  transform: translateY(-50px);
}

.lesson-box:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media (max-width: 767px) {
  #lesson {
    padding: 50px 0 20px;
  }
}

/*--------------------------------------------------------------
  ##  About
  --------------------------------------------------------------*/
.about-details img {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.about-details h3 {
  font-size: 34px;
  font-weight: 500;
}

.about-details h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 30px;
}

.about-details p {
  margin-bottom: 50px;
}

.artist-details-two .artist-name {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

.artist-details-two .band-name {
  font-size: 18px;
  font-weight: 400;
  color: #e43a90;
  margin-bottom: 40px;
}

.artist-details-two .band-name span {
  color: #fff;
}

.artist-details-two .details {
  margin-bottom: 20px;
}

.artist-details-two .details p {
  color: #fff;
  margin-bottom: 50px;
}

.artist-music-inner {
  padding: 20px 0;
  border-top: 2px solid #d9d9d9;
  display: flex;
  align-items: center;
}

.artist-music-inner .aritist-music {
  padding: 0 30px;
  border-right: 2px solid #d9d9d9;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.artist-music-inner .aritist-music .icon {
  float: left;
  margin-right: 20px;
}

.artist-music-inner .aritist-music .icon i {
  color: #e43a90;
  font-size: 50px;
  line-height: 50px;
}

.artist-music-inner .aritist-music .content {
  margin-left: 70px;
}

.artist-music-inner .aritist-music .content p {
  color: #494848;
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  margin-bottom: 5px;
}

.artist-music-inner .aritist-music .content span {
  font-size: 20px;
  color: #494848;
  font-family: "Changa", sans-serif;
}

.artist-music-inner .aritist-music:last-child {
  border-right: 0;
}

.artist-music-inner .aritist-music:first-child {
  padding-left: 0;
}

.artist-music-inner.artist-music-inner-two {
  border-top: 2px dashed #717171;
  border-bottom: 2px dashed #717171;
  margin-bottom: 40px;
}

.artist-music-inner.artist-music-inner-two .aritist-music {
  border-color: #333;
  padding: 0 15px;
}

.artist-music-inner.artist-music-inner-two .aritist-music .icon {
  margin-right: 0;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content {
  margin-left: 60px;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content p, .artist-music-inner.artist-music-inner-two .aritist-music .content span {
  color: #fff;
}

.artist-music-inner.artist-music-inner-two .aritist-music .content span {
  display: block;
  line-height: 20px;
}

.album-cover-img {
  position: relative;
}

.album-cover-img img {
  max-width: 100%;
  margin-bottom: 30px;
}

.album-cover-img .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  border: 2px solid #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 46px;
  color: #fff;
  font-size: 20px;
  transform: translate(-50%, -50%);
  outline: 0;
}

.artist-social-link {
  margin: 0;
  padding: 0;
  list-style: none;
}

.artist-social-link li {
  display: inline-block;
  margin-right: 5px;
}

.artist-social-link li a {
  display: inline-block;
  height: 40px;
  width: 40px;
  color: #fff;
  border: 1px dashed #fff;
  border-radius: 4px;
  text-align: center;
  line-height: 38px;
}

.artist-social-link li a:hover {
  border-color: #e43a90;
  color: #e43a90;
}

/* Music Playlist One */
.hit-plalist {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #f5f5f5;
}

.hit-plalist li {
  padding: 16px 0;
  border-bottom: 1px solid #f5f5f5;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
}

.hit-plalist li:last-child {
  border-bottom: 0;
}

.hit-plalist li .sm2_button {
  width: 7%;
  display: inline-block;
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 18px;
  text-align: center;
}

.hit-plalist li .song-name {
  width: 63%;
  text-align: left;
  display: inline-block;
}

.hit-plalist li span {
  width: 10%;
  display: inline-block;
  text-align: center;
  font-family: "Changa", sans-serif;
}

.hit-plalist li .video-song, .hit-plalist li .song-download {
  width: 10%;
  display: inline-block;
  text-align: center;
  color: #363636;
}

.hit-plalist li .video-song:hover, .hit-plalist li .song-download:hover {
  color: #e43a90;
}

.hit-plalist li.active {
  background: #e43a90;
}

.hit-plalist li.active .sm2_button, .hit-plalist li.active .video-song, .hit-plalist li.active .song-download, .hit-plalist li.active span {
  color: #fff;
}

.hit-plalist li.active .video-song:hover, .hit-plalist li.active .song-download:hover {
  color: #751043;
}

/* About Two */
.artist-about .artist-name {
  font-size: 34px;
  font-weight: 500;
  margin-top: 0;
}

.artist-about .artist-name a {
  color: #363636;
}

.artist-about .artist-name a:hover {
  color: #e43a90;
}

.artist-about h6 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}

.artist-about span {
  display: block;
  margin-bottom: 10px;
  color: #828282;
}

.artist-about .tim-btn {
  padding: 7px 20px;
  font-size: 16px;
  margin-bottom: 20px;
  position: absolute;
  right: 30px;
  top: 0;
}

.artist-about .content {
  padding-bottom: 15px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
}

.artist-about .alb-title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.alb-single {
  display: flex;
  flex-wrap: wrap;
}

.alb-single .single-items {
  display: inline-block;
  width: 20%;
  padding-right: 5px;
}

.alb-single .single-items img {
  max-width: 100%;
}

.album-feature {
  position: relative;
}

.album-feature img {
  max-width: 100%;
}

.album-feature .artist-music-inner {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(228, 58, 144, 0.8);
  border-top: 0;
  padding-left: 30px;
}

.album-feature .artist-music-inner .aritist-music {
  border-right: 1px solid rgba(255, 255, 255, 0.5);
}

.album-feature .artist-music-inner .aritist-music .icon i {
  color: #fff;
}

.album-feature .artist-music-inner .aritist-music .content p, .album-feature .artist-music-inner .aritist-music .content span {
  color: #fff;
}

.sm2_button {
  height: 30px;
  width: 30px;
  position: relative;
  background: transparent;
}

.sm2_button i:after {
  content: "\f04b";
  font: normal normal normal 14px/1 FontAwesome;
}

.sm2_button.sm2_playing i:after {
  content: "\f04c";
  font: normal normal normal 14px/1 FontAwesome;
}

.artist-about.pt_70 .tim-btn {
  top: 70px;
}

@media (max-width: 1600px) {
  .artist-about {
    margin-top: 0;
  }
}

@media (max-width: 1440px) {
  .alb-single {
    margin-bottom: 20px;
  }
  .artist-music-inner .aritist-music {
    padding: 0 15px;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 55px;
  }
  .artist-music-inner .aritist-music .content p {
    font-size: 26px;
  }
  .artist-music-inner .aritist-music .content span {
    font-size: 14px;
    display: block;
    line-height: 16px;
  }
  .artist-music-inner .aritist-music .icon {
    margin-right: 0;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 40px;
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .artist-music-inner .aritist-music {
    padding: 0 5px;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 30px;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 35px;
  }
  .artist-music-inner .aritist-music .content p {
    margin: 0;
  }
  .artist-about.pt_70 {
    padding-top: 0;
  }
  .artist-about.pt_70 .tim-btn {
    top: 0;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .artist-about .artist-name {
    font-size: 26px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .artist-music-inner.artist-music-inner-two .aritist-music .content {
    margin-left: 40px;
  }
  .artist-details-two .band-name {
    margin-bottom: 20px;
  }
  .artist-details-two .details p {
    margin-bottom: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .artist-profile .profoile-image {
    max-width: 190px !important;
  }
  .artist-profile {
    padding: 30px 25px;
  }
  .artist-profile .content {
    margin-left: 210px !important;
  }
}

@media (max-width: 767px) {
  .artist-profile .profoile-image {
    float: none !important;
    margin: 0 auto;
  }
  .artist-profile .content {
    margin-left: 0 !important;
    text-align: center;
  }
}

@media (max-width: 991px) {
  .album-feature {
    margin-bottom: 30px;
  }
  .artist-about.pl__30 {
    padding-left: 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .artist-music-inner .aritist-music {
    border-right: 0;
  }
}

@media (max-width: 768px) {
  .artist-music-inner .aritist-music {
    padding: 0 10px;
  }
  .album-feature .artist-music-inner .aritist-music {
    border-right: 0;
  }
}

@media (max-width: 580px) {
  .album-cover-img {
    margin: 0 auto 20px;
  }
  .artist-music-inner {
    display: block;
  }
  .artist-music-inner .aritist-music {
    border-right: 0;
    padding-left: 0;
    width: 50%;
    float: left;
    margin-bottom: 10px;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .artist-music-inner .aritist-music .content {
    margin-left: 30px;
  }
  .artist-music-inner .aritist-music .content p {
    font-size: 21px;
  }
  .artist-music-inner .aritist-music .icon i {
    font-size: 20px;
    line-height: 30px;
  }
  .artist-about .tim-btn {
    position: relative;
    right: 0;
    top: 5px;
  }
  .artist-about.pt_70 .tim-btn {
    top: 6px;
  }
}

/*--------------------------------------------------------------
  ##  Event About
  --------------------------------------------------------------*/
#event-about {
  padding: 100px 0;
}

.event-thumb img {
  max-width: 100%;
}

.event-content h2 {
  font-size: 34px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.event-content h2 span {
  color: #e43a90;
}

.event-content h2 em {
  font-style: normal;
  font-weight: 400;
}

.event-content p {
  margin-bottom: 0;
}

.event-content .event-details {
  margin: 30px 0;
}

.event-content .event-details p {
  margin: 0;
}

.event-content .event-details p span {
  font-weight: 600;
  font-size: 18px;
  font-family: "Changa", sans-serif;
}

.event-content h4 {
  font-size: 18px;
  font-weight: 600;
}

.event-content .tim-btn {
  padding: 12px 27px;
  text-transform: uppercase;
  margin-top: 30px;
  font-size: 16px;
  line-height: 20px;
}

@media (max-width: 991px) {
  .event-content {
    margin-top: 20px;
  }
}

@media (max-width: 768px) {
  #event-about {
    padding: 50px 0;
  }
  .event-content h2 {
    font-size: 26px;
  }
}

/*--------------------------------------------------------------
  ##  Sale Album
  --------------------------------------------------------------*/
#sale-album {
  background: #f7f7f7;
}

.gallery-tab .swiper-slide .slider-tab {
  float: right;
}

.slider-content h3 {
  font-size: 24px;
  font-weight: 500;
}

.slider-content p {
  font-size: 16px;
}

.slider-content ul {
  margin-bottom: 20px;
  list-style: none;
}

.slider-content ul li {
  line-height: 30px;
  font-size: 16px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 7px;
}

.slider-content ul li:before {
  position: absolute;
  content: "\f105";
  font: normal normal normal 14px/1 FontAwesome;
  left: 0;
  top: 5px;
  color: #e43a90;
  border: 1px solid #cecece;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 18px;
  border-radius: 50%;
  padding-left: 2px;
}

.sale-tabs {
  position: relative;
  max-width: 1072px;
  margin: 0 auto;
  text-align: center;
}

.sale-tabs li {
  width: 25%;
}

.sale-tabs li a {
  color: #5b5b5b;
  font-size: 16px;
  margin-bottom: 20px;
  margin-right: 30px;
  position: relative;
  font-family: "Changa", sans-serif;
}

.sale-tabs li a:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  border: 1px solid  #cdcdcd;
  background: #fff;
  border-radius: 50%;
  left: 50%;
  bottom: -7px;
  transform: translate(-50%);
}

.sale-tabs li a.active:after {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  background: #e43a90;
  border-radius: 50%;
  left: 50%;
  bottom: -4px;
  transform: translate(-50%);
}

/* Tab Nav */
.tabnav-wrap {
  position: relative;
}

.tabnav-wrap:before {
  content: '';
  width: 100%;
  height: 1px;
  background: #cdcdcd;
  position: absolute;
  bottom: 20px;
}

.tab-content .tab-pane {
  padding: 50px 15px 0;
}

.tab-content .tab-pane.active {
  animation: fadeIn .75s ease forwards;
}

.tim-music-player {
  width: 100%;
  margin: 0;
}

.tim-music-player .player {
  width: 100%;
  float: left;
}

/* Track Info */
.tim-music-player .track-info p {
  font-family: "Changa", sans-serif;
  color: #363636;
}

.tim-music-player .track-info p.title {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
}

.tim-music-player .track-info p.artist-outer {
  font-size: 16px;
}

.tim-music-player .player .album-cover img {
  max-width: 100%;
}

.tim-music-player .album-cover {
  position: relative;
}

.tim-music-player .album-cover .track-info {
  position: absolute;
  left: 15px;
  bottom: 60px;
}

.tim-music-player .album-cover .track-info .title {
  color: #fff;
  font-family: "Changa", sans-serif;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.tim-music-player .album-cover .track-info .artist-outer {
  color: #fff;
}

.tim-music-player .album-cover .player-controls {
  position: absolute;
  width: 100%;
  height: 60px;
  bottom: 0;
}

.tim-music-player .album-cover .player-controls > div {
  cursor: pointer;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tim-music-player .album-cover .player-controls .main .play-control {
  height: 35px;
  width: 35px;
  line-height: 32px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-right: 5px;
}

.tim-music-player .album-cover .player-controls .main .jp-stop {
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 22px;
  border-radius: 50%;
  border: 1px solid #fff;
  margin-right: 10px;
}

.tim-music-player .album-cover .player-controls .main .jp-stop:before {
  content: "\f04d";
  color: #fff;
  font-size: 12px;
  font: normal normal normal 14px/1 FontAwesome;
}

.tim-music-player .album-cover .player-controls .jp-time-holder {
  margin-right: 10px;
}

.tim-music-player .album-cover .player-controls .jp-duration {
  margin-right: 10px;
  color: #fff;
}

.tim-music-player .progress-wrapper {
  height: 9px;
  max-width: 140px;
  width: 100%;
  margin: 7px 10px 5px 0;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

.tim-music-player .progress {
  height: 9px;
  width: 135px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.7);
  background-image: none;
  border-radius: 2px;
  -webkit-border-radius: 2px;
}

.tim-music-player .elapsed {
  height: 9px;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  width: 60%;
  background: #e43a90;
}

.tim-music-player .description {
  clear: both;
  margin: 30px 0 0 0;
  font-size: 12px;
  color: #999;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.6);
}

.tim-music-player .description.showing {
  margin: 30px 0;
}

.tim-music-player .player {
  width: 40%;
  float: left;
  padding-right: 30px;
}

.tim-music-player .tracklist .title {
  max-width: 60%;
  width: 100%;
  display: inline-block;
  padding: 0 0 0 14px;
  cursor: pointer;
}

.tim-music-player .jp-volume-controls button {
  display: block;
  position: absolute;
  overflow: hidden;
  text-indent: -9999px;
  border: none;
  cursor: pointer;
}

.tim-music-player .jp-volume-bar {
  overflow: hidden;
  background: #fff;
  width: 46px;
  height: 5px;
  cursor: pointer;
}

.tim-music-player .jp-volume-bar-value {
  width: 0;
  height: 5px;
  background: #e43a90;
  display: block;
}

.tim-music-player .jp-mute {
  width: 20px;
  height: 20px;
  line-height: 18px;
  position: relative;
}

.tim-music-player .jp-mute:before {
  content: "\f028";
  font: normal normal normal 14px/1 FontAwesome;
  color: #fff;
  font-size: 12px;
}

.tim-music-player .jp-current-time {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.jp-state-muted .jp-unmute {
  width: 20px;
  height: 20px;
  line-height: 18px;
  position: relative;
}

.jp-state-muted .jp-unmute:after {
  content: "\f026";
  font: normal normal normal 14px/1 FontAwesome;
  color: #fff;
  font-size: 12px;
}

.tim-music-player .rating {
  height: 16px;
  margin: 20px 0 20px 0;
}

.tim-music-player .rating-star {
  width: 15px;
  height: 16px;
  display: block;
  float: left;
  cursor: pointer;
}

.tim-music-player .rating-star:before {
  font-family: FontAwesome;
  content: "\f005";
  font-size: 15px;
  color: #333;
}

.tim-music-player .rating-bar:before {
  font-family: FontAwesome;
  content: "\f005";
  font-size: 11px !important;
  color: #333;
}

.hover:before,
.on:before {
  color: #FFFF00 !important;
}

/* Player */
.progress {
  float: left;
}

.tim-music-player .previous, .tim-music-player .next {
  color: #fff;
}

.tim-music-player .previous:hover:before, .tim-music-player .next:hover:before {
  color: #e43a90;
}

div.jp-seeking-bg {
  background: url("../img/jplayer_seeking.gif") !important;
}

.tim-music-player .play {
  color: #fff;
}

.tim-music-player .play:before {
  content: "\f102";
}

.tim-music-player .pause:before {
  content: "\f101";
}

.tim-music-player .play:before, .tim-music-player .pause:before {
  font-family: tim-icon;
  font-size: 14px;
  color: #f5f5f5;
  margin-left: 1px;
}

.tim-music-player .pause {
  color: #fff;
}

/** Track List **/
.tim-music-player .tracklist {
  padding: 0;
  border: 1px solid #ededed;
  overflow: hidden;
}

.tim-music-player .tracklist ol {
  margin: 0;
  padding: 0;
}

.tim-music-player .tracklist li {
  padding: 11px 0 11px 20px;
  list-style-position: inside;
  position: relative;
  border-bottom: 1px solid #ededed;
  transition: all 0.2s ease-in-out;
  font-size: 18px;
  font-family: "Changa", sans-serif;
}

.tim-music-player .tracklist li:hover {
  color: #fff;
  background: #e43a90;
}

.tim-music-player li:last-child {
  border-bottom: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.tim-music-player .show-more-button li:last-child {
  background: transparent url(../img/tracklist-item-bg.png) repeat-x bottom left scroll;
}

.tim-music-player li:nth-child(1n + 10) .title {
  padding-left: 7px;
}

.tim-music-player li.playing {
  color: #fff !important;
  background: #e43a90;
}

.tim-music-player .tracklist .rating {
  margin-left: 30px;
  width: 66px;
  display: block;
  position: absolute;
  right: 92px;
  top: -15px;
}

.tim-music-player .rating-bar {
  width: 13px;
  display: block;
  float: left;
  cursor: pointer;
}

.tim-music-player .buy {
  height: 18px;
  display: inline-block;
  font-size: 16px;
  text-align: center;
  line-height: 18px;
  width: 8%;
  color: #363636;
}

.tim-music-player .video {
  color: #363636;
  width: 16%;
  text-align: center;
  display: block;
}

.tim-music-player li.track:hover .buy,
.tim-music-player li .playing .buy, .tim-music-player li.track:hover .video {
  color: #fff;
}

.tim-music-player .more {
  font-size: 14px;
  padding: 5px 0;
  cursor: pointer;
  text-align: center;
  background: #fff;
  position: relative;
}

.tim-music-player .more:hover {
  color: #fff;
  background: #e43a90;
}

.tim-music-player .more,
.tim-music-player .album-cover .highlight,
.tim-music-player .tracklist li,
.tim-music-player .buy {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.tim-music-player .tracklist li span {
  display: inline-block;
}

.tim-music-player .player:after {
  clear: both;
  content: ' ';
  display: block;
  font-size: 0;
  line-height: 0;
  visibility: hidden;
  width: 0;
  height: 0;
}

.music-single .tracklist {
  width: 94%;
  margin-top: 20px;
}

.music-single .player {
  width: 100%;
  float: none;
  display: block;
}

.music-single .track-info {
  width: 67%;
}

.music-single .player-controls {
  width: 67%;
}

.music-single .player-controls div {
  width: 79%;
}

.wide-mp3 .player-controls {
  width: 54%;
}

.wide-mp3 .progress-wrapper {
  width: 173px;
}

/* Best Music 2018 */
#the-sorcerers-wrath-container {
  margin-top: 30px;
}

#the-sorcerers-wrath-container .jp-audio {
  max-width: 100%;
  margin-bottom: 60px;
}

#the-sorcerers-wrath-container .jp-controls {
  float: left;
  width: 95px;
  min-height: 42px;
  margin-bottom: 30px;
}

#the-sorcerers-wrath-container .jp-controls a {
  float: left;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border: 2px solid #434343;
  border-radius: 50%;
  color: #434343;
  outline: 0;
}

#the-sorcerers-wrath-container .jp-controls a i {
  font-size: 16px;
}

#the-sorcerers-wrath-container .jp-controls a.jp-play {
  height: 50px;
  width: 50px;
  margin-right: 5px;
  margin-top: -5px;
}

#the-sorcerers-wrath-container .jp-controls a.jp-play i {
  font-size: 22px;
  line-height: 46px;
  margin-left: 3px;
}

#the-sorcerers-wrath-container .jp-controls a:hover {
  background: transparent;
}

#the-sorcerers-wrath-container .jp-seek-bar {
  background: #ededed;
  cursor: pointer;
}

#the-sorcerers-wrath-container .jp-play-bar {
  background: #e43a90;
}

#the-sorcerers-wrath-container .jp-middle {
  position: relative;
  width: -webkit-calc( 100% - 95px);
  width: calc( 100% - 95px);
  min-height: 42px;
  float: left;
  padding: 0 0 0 22px;
}

#the-sorcerers-wrath-container .jp-progress {
  width: 100%;
  height: 8px;
  margin-bottom: 4px;
}

#the-sorcerers-wrath-container .jp-seek-bar {
  width: 0;
  height: 100%;
}

#the-sorcerers-wrath-container .jp-play-bar {
  width: 0;
  height: 8px;
  position: relative;
}

#the-sorcerers-wrath-container .jp-title {
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 6px;
}

#the-sorcerers-wrath-container .jp-current-time {
  float: left;
}

#the-sorcerers-wrath-container .jp-duration {
  float: right;
}

#the-sorcerers-wrath-container .jp-volume-max {
  display: block;
  width: 24px;
  min-height: 42px;
  float: left;
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
}

#the-sorcerers-wrath-container .jp-volume-bar {
  width: 59%;
  height: 8px;
  float: left;
  margin-top: 17px;
}

#the-sorcerers-wrath-container .jp-volume-bar-value {
  width: 0;
  height: 8px;
  position: relative;
}

#the-sorcerers-wrath-container .jp-playlist {
  clear: both;
}

#the-sorcerers-wrath-container .jp-playlist ul {
  margin: 0;
  padding: 0;
  list-style: none;
  border: 1px solid #ededed;
}

#the-sorcerers-wrath-container .jp-playlist ul li {
  position: relative;
  border-bottom: 1px solid #ededed;
}

#the-sorcerers-wrath-container .jp-playlist ul li:last-child {
  border-bottom: 0;
}

#the-sorcerers-wrath-container .jp-playlist ul li a {
  display: block;
  position: relative;
  min-height: 50px;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 1px;
  padding: 0 20px;
  color: #494949;
  font-family: "Changa", sans-serif;
  font-size: 16px;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media {
  position: absolute;
  display: inline;
  top: 0;
  right: 20px;
  font-size: 65%;
  font-weight: normal;
  letter-spacing: 4px;
  text-transform: uppercase;
  z-index: 1;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media a {
  height: auto;
  margin: 0;
  padding: 0;
  display: inline;
  font-weight: normal;
}

#the-sorcerers-wrath-container .jp-playlist ul li.jp-free-media a:hover {
  box-shadow: none;
}

#the-sorcerers-wrath-container .jp-playlist ul li:hover, #the-sorcerers-wrath-container .jp-playlist ul li.jp-playlist-current {
  background: #e43a90;
}

#the-sorcerers-wrath-container .jp-playlist ul li:hover a, #the-sorcerers-wrath-container .jp-playlist ul li.jp-playlist-current a {
  outline: 0;
  color: #fff;
}

#the-sorcerers-wrath-container .track-byline {
  font-weight: normal;
}

.jp-controls a {
  color: #e43a90;
}

.jp-state-playing .jp-play i:before {
  content: "\f04c";
}

/* Bast Sale Album */
.best-sale-album-cover {
  width: 45%;
  float: left;
}

.best-sale-album-cover img {
  max-width: 100%;
}

.music-playlist-two {
  margin-left: 47%;
}

.music-playlist-two .hit-plalist .sm2_button {
  width: 10%;
}

.music-playlist-two .hit-plalist .song-name {
  width: 60%;
}

@media (max-width: 1280px) {
  .tim-music-player .progress-wrapper {
    width: 110px;
  }
}

@media (min-width: 991px) and (max-width: 1279px) {
  .tim-music-player .tracklist .title {
    width: 55%;
  }
  .tim-music-player .player {
    padding-right: 10px;
  }
  .slider-content p {
    font-size: 14px;
  }
  .slider-content ul li {
    font-size: 14px;
  }
}

@media (max-width: 1024px) {
  .tim-music-player .tracklist .title {
    width: 55%;
  }
}

@media (max-width: 768px) {
  .tim-music-player .player {
    max-width: 400px;
    float: none;
    width: 100%;
    margin: 0 auto 30px;
  }
  .best-sale-album-cover {
    width: 100%;
    float: none;
  }
  .music-playlist-two {
    margin-left: 0px;
  }
  .hit-plalist li span {
    width: 15%;
  }
  .hit-plalist li .song-name {
    width: 50%;
  }
  .hit-plalist li .video-song {
    width: 14%;
  }
  .music-playlist-two .hit-plalist .song-name {
    width: 47%;
  }
}

@media (max-width: 500px) {
  .tim-music-player .tracklist .title {
    width: 52%;
  }
  .tim-music-player .tracklist li {
    padding: 10px 0 11px 8px;
  }
  .tim-music-player .tracklist li span {
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .slider-content ul li {
    font-size: 15px;
    float: left;
    padding-right: 7px;
    width: 50%;
  }
  .best-sale-album {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ##   Album
  --------------------------------------------------------------*/
#album {
  padding: 120px 0;
}

#album-two {
  padding: 120px 0 110px;
}

.album-filter-button {
  margin: 0 auto 50px;
  padding: 0;
  list-style: none;
  max-width: 1000px;
  text-align: center;
  position: relative;
}

.album-filter-button:after {
  position: absolute;
  content: '';
  height: 1px;
  border-bottom: 1px dashed #dedede;
  width: 90%;
  top: 50%;
  z-index: -1;
  left: 27px;
}

.album-filter-button li {
  display: inline-block;
  height: 60px;
  width: 60px;
  line-height: 58px;
  text-align: center;
  border: 1px dashed #dedede;
  border-radius: 50%;
  margin-left: 160px;
  background: #fff;
}

.album-filter-button li a {
  display: block;
  text-transform: uppercase;
  color: #363636;
  font-weight: 500;
}

.album-filter-button li:first-child {
  margin-left: 0 !important;
}

.album-filter-button li:hover, .album-filter-button li.current {
  background: #e43a90;
  border-color: #e43a90;
  border-style: solid;
}

.album-filter-button li:hover a, .album-filter-button li.current a {
  color: #fff;
}

.album-filter-button.album-filter-button-two {
  max-width: 760px;
}

.album-filter-button.album-filter-button-two:after {
  width: 90%;
  left: 0;
}

.album-filter-button.album-filter-button-two li {
  height: auto;
  width: auto;
  line-height: 20px;
  padding: 10px 15px;
  border-radius: 8px;
  border: 1px dashed #dedede;
  margin-left: 90px;
  transition: all 0.3s ease-in-out;
}

.album-filter-button.album-filter-button-two li:hover, .album-filter-button.album-filter-button-two li.current {
  background: #fff;
  border-color: #e43a90;
  border-style: solid;
  border: 1px dashed #e43a90;
}

.album-filter-button.album-filter-button-two li:hover a, .album-filter-button.album-filter-button-two li.current a {
  color: #e43a90;
}

.tim-album-items {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tim-album-items .grid-item {
  width: 24.5%;
  padding: 15px;
}

.tim-album-items .grid-item:before {
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
}

.tim-isotope {
  position: relative;
}

.tim-isotope.tim-isotope-1 .tim-album-item {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  position: relative;
  position: relative;
  overflow: hidden;
}

.tim-isotope.tim-isotope-1 .tim-album-item img {
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  max-width: 100%;
  height: auto;
}

.tim-isotope.tim-isotope-1 .tim-album-item:before {
  position: absolute;
  content: '';
  left: 15px;
  top: 15px;
  right: 15px;
  bottom: 15px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap {
  position: absolute;
  left: 0;
  bottom: 45px;
  width: 100%;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:before, .tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:after {
  content: '';
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:before {
  width: 30px;
  height: 1px;
  left: 50%;
  bottom: 0;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h4:after {
  width: 30px;
  height: 5px;
  bottom: -2px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap h5 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 10px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap .tim-btn {
  color: #fff;
  padding: 5px 30px;
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 15px;
}

.tim-isotope.tim-isotope-1 .tim-album-item .album_details_wrap .tim-btn:hover {
  background: #cf1c77;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover:before {
  opacity: 1;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover .album_details_wrap {
  visibility: visible;
  opacity: 1;
}

.tim-isotope.tim-isotope-1 .tim-album-item:hover .album_details_wrap h4:before {
  width: 100px;
}

.tim-isotope.tim-isotope-2 .tim-album-item {
  position: relative;
}

.tim-isotope.tim-isotope-2 .tim-album-item:before {
  position: absolute;
  content: '';
  left: 15px;
  bottom: 15px;
  right: 15px;
  top: 15px;
  border-radius: 8px;
  background: rgba(228, 58, 144, 0.58);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item .tim-isotope-grid__img {
  border-radius: 8px;
  overflow: hidden;
}

.tim-isotope.tim-isotope-2 .tim-album-item .tim-isotope-grid__img img {
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-2 .tim-album-item .popup-modal {
  position: absolute;
  top: 40px;
  left: 40px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap {
  text-align: left;
  position: absolute;
  left: 40px;
  bottom: 40px;
  z-index: 2;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info {
  opacity: 0;
  visibility: hidden;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info h4 {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  transform: translateY(-15px);
  transition: all 0.3s ease-in-out;
  transition-delay: 100ms;
  opacity: 0;
}

.tim-isotope.tim-isotope-2 .tim-album-item .gallery_details_wrap .gallery-info h5 {
  font-size: 15px;
  color: #fff;
  margin: 0;
  transform: translateY(-15px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover:before {
  opacity: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .tim-isotope-grid__img img {
  transform: scale(1.1);
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .popup-modal, .tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info {
  visibility: visible;
  opacity: 1;
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .popup-modal {
  transform: scale(1);
}

.tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info h4, .tim-isotope.tim-isotope-2 .tim-album-item:hover .gallery-info h5 {
  transform: translateY(0);
  opacity: 1;
}

.grid-item, .grid-sizer {
  width: 25%;
}

/* Exclusive Album */
.exclusive-album-items {
  padding: 0;
}

.exclusive-album-items .album-item {
  position: relative;
  margin: 0 5px 10px;
}

.exclusive-album-items .album-item:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(228, 58, 144, 0.5);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.exclusive-album-items .album-item .album-image img {
  max-width: 100%;
}

.exclusive-album-items .album-item .album-image .exse-album-name {
  position: absolute;
  top: 0;
  left: 0;
}

.exclusive-album-items .album-item .album-image .exse-album-name .icon {
  height: 40px;
  width: 45px;
  line-height: 40px;
  background: #e43a90;
  text-align: center;
  margin-right: 5px;
  display: inline-block;
  color: #fff;
  font-size: 22px;
  vertical-align: top;
}

.exclusive-album-items .album-item .album-image .exse-album-name h4 {
  color: #fff;
  background: #e43a90;
  font-size: 20px;
  font-weight: 600;
  padding: 10px 20px;
  display: inline-block;
  line-height: 20px;
  margin: 0;
  vertical-align: top;
  text-transform: uppercase;
}

.exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
  top: auto;
  left: auto;
  bottom: 0;
  right: 0;
}

.exclusive-album-items .album-item .content {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px;
}

.exclusive-album-items .album-item .content h4 {
  font-size: 26px;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  line-height: 26px;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.exclusive-album-items .album-item .content span {
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
  transform: translateY(15px);
  transition: all 0.3s ease-in-out;
  transition-delay: 100ms;
  opacity: 0;
  display: inline-block;
}

.exclusive-album-items .album-item .content .icon {
  position: absolute;
  right: 20px;
  top: 23px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.exclusive-album-items .album-item .content .icon a {
  color: #fff;
  font-size: 25px;
}

.exclusive-album-items .album-item .content .icon a:hover {
  color: #e43a90;
}

.exclusive-album-items .album-item:hover:before {
  opacity: 1;
}

.exclusive-album-items .album-item:hover h4, .exclusive-album-items .album-item:hover span {
  transform: translateY(0);
  opacity: 1;
}

.exclusive-album-items .album-item:hover .icon {
  opacity: 1;
}

@media (max-width: 1280px) {
  .album-filter-button {
    max-width: 600px;
  }
  .album-filter-button li {
    margin-left: 70px;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 760px;
  }
  .album-filter-button:after {
    width: 90%;
    left: 0;
  }
  .tim-album-items .grid-item, .tim-album-items .grid-sizer {
    width: 33%;
  }
}

@media (max-width: 1024px) {
  .album-filter-button {
    max-width: 600px;
  }
  .album-filter-button.album-filter-button-two li {
    margin-left: 60px;
  }
  .album-filter-button.album-filter-button-two:after {
    left: 30px;
    transform: translateX(0);
    width: 90%;
  }
}

@media (max-width: 992px) {
  .album-filter-button {
    max-width: 450px;
  }
  .album-filter-button li {
    height: 50px;
    width: 50px;
    line-height: 46px;
    margin-left: 45px;
  }
}

@media (max-width: 768px) {
  #album {
    padding: 50px 0;
  }
  .tim-album-items .grid-item, .tim-album-items .grid-sizer {
    width: 50%;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 425px;
  }
  .album-filter-button.album-filter-button-two:after {
    display: none;
  }
  .album-filter-button.album-filter-button-two li {
    padding: 8px 12px;
    margin-left: 25px;
  }
  .album-filter-button.album-filter-button-two li a {
    font-size: 13px;
  }
  #album-two {
    padding: 60px 0 50px;
  }
  .exclusive-album-items .album-item .album-image .exse-album-name.exse-album-bottom {
    top: 0;
    left: 0;
  }
  #gallery .section-title h2 {
    margin-bottom: 0;
  }
  #gallery .album-filter-button {
    margin: 0 auto 20px;
  }
  .album-filter-button {
    margin: 0 auto 20px;
  }
}

@media (max-width: 580px) {
  .album-filter-button {
    max-width: 330px;
  }
  .album-filter-button:after {
    left: 40px;
    width: 76%;
  }
  .album-filter-button li {
    margin-left: 9px;
    height: 40px;
    width: 40px;
    line-height: 37px;
  }
  .album-filter-button li a {
    font-size: 10px;
  }
  .album-filter-button.album-filter-button-two {
    max-width: 500px;
  }
}

@media (max-width: 500px) {
  .tim-album-items .grid-item {
    width: 100%;
  }
  .album-filter-button.album-filter-button-two li {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

/*--------------- Single Album  ----------------*/
.single-album-info {
  box-shadow: 0px 1px 17.85px 3.15px rgba(23, 28, 62, 0.05);
}

.single-album-info .padding-remove {
  padding: 0;
}

.single-album-info .single-album-details {
  padding: 70px 90px 70px 60px;
}

.single-album-info .single-album-details .details-top {
  border-bottom: 2px solid #f8f8f8;
  padding-bottom: 20px;
  position: relative;
  padding-bottom: 10px;
}

.single-album-info .single-album-details .details-top:after {
  content: '';
  position: absolute;
  width: 200px;
  height: 2px;
  background: #f2adc3;
  bottom: -2px;
  left: 0;
}

.single-album-info .single-album-details h6 {
  font-size: 26px;
  color: #333333;
  font-weight: 600;
}

.single-album-info .single-album-details p {
  font-size: 16px;
  color: #585757;
  font-family: "Changa", sans-serif;
}

.single-album-info .single-album-details ul {
  padding-top: 46px;
  width: 79%;
  padding-bottom: 60px;
  font-family: "Changa", sans-serif;
}

.single-album-info .single-album-details ul li {
  list-style: none;
  font-size: 16px;
  color: #585757;
  font-weight: 500;
  margin-bottom: 12px;
}

.single-album-info .single-album-details ul li span {
  font-weight: 400;
  float: right;
  width: 250px;
}

.single-album-info .single-album-details .single-album-description h6 {
  font-size: 20px;
  color: #333333;
  position: relative;
  padding-bottom: 11px;
  margin-bottom: 25px;
}

.single-album-info .single-album-details .single-album-description h6:after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #979797;
  width: 100px;
  height: 2px;
  left: 0;
}

.single-album-info .single-album-details .single-album-description p {
  font-size: 16px;
  color: #585757;
  font-family: "Changa", sans-serif;
}

.single-album-info .single-album-details .single-album-price {
  margin-top: 43px;
}

.single-album-info .single-album-details .single-album-price .left {
  float: left;
  display: inline-block;
  margin-right: 55px;
}

.single-album-info .single-album-details .single-album-price .left span {
  font-size: 20px;
  color: #e43a90;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.single-album-info .single-album-details .single-album-price .left p {
  color: #333333;
  font-size: 16px;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
}

.single-album-info .single-album-details .single-album-price a {
  padding: 9px 29px;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  margin-right: 30px;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  background: #e43a90;
  color: #fff;
  border: transparent;
  display: inline-block;
}

.album-info-wrapper {
  position: relative;
}

.album-info-wrapper:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  background: #edf5fb;
  height: 100%;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
  display: block !important;
  position: relative;
  bottom: 100px;
  right: 0;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #fff;
  padding: 15px;
  margin-top: 163px;
}

.single-album-player .current-tracks {
  z-index: 5;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
  padding: 36px 0;
  border: 5px solid #ebebeb;
  background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -webkit-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -ms-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
}

.single-album-player .header_player {
  background: transparent;
  position: relative;
}

.single-album-player .header_player .player-container {
  max-width: 100%;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li > div > a {
  color: #333333;
  font-family: "Changa", sans-serif;
}

.single-album-player .artist-name {
  font-size: 14px;
}

.single-album-player #main_player {
  width: 0 !important;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
  padding-top: 34px;
  padding-bottom: 60px;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  font-size: 20px;
  line-height: 37px;
  color: #e43a90;
  margin-left: 8px;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  height: 40px;
  width: 40px;
  padding-left: 2px;
  background: #fff;
}

.single-album-player .header_player #nowPlaying {
  padding: 30px 0 0 38px;
}

.single-album-player .current-tracks::after {
  top: 23px;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
  padding-top: 8px;
}

.single-album-player .header_player #nowPlaying .track-name {
  font-size: 18px;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
  height: auto;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li.jp-playlist-current {
  background: transparent;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li {
  display: block;
  border-bottom: 1px solid #ebebeb;
}

.single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover {
  background: transparent;
}

.single-album-player .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist ul li:hover > div > a {
  color: #e43a90;
  font-family: "Changa", sans-serif;
}

.single-album-player .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  margin-left: 6px;
}

.single-album-player .music-playlist-one {
  width: 100%;
}

.single-album-player #playlist-toggle {
  display: none;
}

.related-album-single .swiper-slide {
  text-align: center;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .single-album-info .single-album-details {
    padding: 70px 10px 70px 30px;
  }
  .single-album-info .single-album-details ul li span {
    width: 187px;
  }
  .album-info-wrapper::before {
    display: none;
  }
  .single-album-player .current-tracks {
    display: block;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    padding-top: 3px;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-playlist {
    bottom: 145px;
  }
  .single-album-player .current-tracks::after {
    right: 60px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-album-info .single-album-details {
    padding: 70px 10px 70px 30px;
  }
  .single-album-info .single-album-details ul li span {
    width: 187px;
  }
  .album-info-wrapper::before {
    display: none;
  }
  .single-album-info .single-album-details ul li {
    font-size: 14px;
  }
  .single-album-info .single-album-details ul {
    width: 100%;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
    padding-top: 35px;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    padding-top: 2px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 30%;
  }
  .single-album-player .current-tracks {
    display: block;
    width: 220px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .single-album-info .single-album-details {
    padding: 70px 10px 70px 30px;
  }
  .single-album-info .single-album-details ul li span {
    width: 187px;
  }
  .album-info-wrapper::before {
    display: none;
  }
  .single-album-info .single-album-details ul li {
    font-size: 14px;
  }
  .single-album-info .single-album-details ul {
    width: 100%;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface {
    padding-top: 35px;
  }
  .single-album-player .header_player .jp-audio .jp-type-playlist .jp-interface .jp-controls {
    padding-top: 2px;
  }
  .header_player .jp-audio .jp-type-playlist .jp-interface .jp-progress {
    max-width: 55%;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .single-album-info .single-album-details .single-album-price .left {
    margin-right: 26px;
  }
  .single-album-info .single-album-details ul li span {
    width: 160px;
  }
  .related-album-single .section-title {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
  ##   Artist Profiles
  --------------------------------------------------------------*/
.artist-profile {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.22);
  padding: 65px 40px;
}

.artist-profile.artist-profile-details {
  padding: 65px 40px 40px;
}

.artist-profile .profoile-image {
  float: left;
  max-width: 250px;
  text-align: center;
}

.artist-profile .profoile-image img {
  max-width: 100%;
  border-radius: 50%;
}

.artist-profile .profoile-image .artist-social-link {
  margin-top: 10px;
}

.artist-profile .profoile-image .artist-social-link li {
  margin-right: 5px;
  height: auto;
  width: auto;
  border: 0;
}

.artist-profile .profoile-image .artist-social-link li a {
  color: #868686;
  height: auto;
  width: auto;
  border: 0;
}

.artist-profile .profoile-image .artist-social-link li a:hover {
  color: #e43a90;
  border: 0;
}

.artist-profile .content {
  margin-left: 280px;
}

.artist-profile .content h3 {
  font-size: 34px;
  font-weight: 500;
}

.artist-profile .content h3 a {
  color: #363636;
}

.artist-profile .content h3 a:hover {
  color: #e43a90;
}

.artist-profile .content span {
  display: block;
  margin-bottom: 10px;
  color: #828282;
}

.artist-profile .content p {
  margin-bottom: 30px;
}

.artist-profile .content .tim-btn {
  font-size: 16px;
  text-transform: uppercase;
  padding: 7px 20px;
}

.artist-profile .recent-concert {
  margin-top: 35px;
}

.artist-profile .recent-concert .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 12px;
}

.artist-profile .recent-concert p {
  margin-bottom: 30px;
}

.artist-profile .video-inner {
  position: relative;
}

.artist-profile .video-inner .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  border: 2px solid #fff;
  text-align: center;
  line-height: 46px;
  border-radius: 50%;
  font-size: 20px;
  transform: translate(-50%, -50%);
  outline: 0;
}

.artist-profile {
  margin-bottom: 30px;
}

.artist-profile .video-inner img {
  max-width: 100%;
}

/*--------------------------------------------------------------
  ##   Featured Album
  --------------------------------------------------------------*/
#feature-album {
  padding: 100px 0 70px;
}

.feature-albums .tim-album-item {
  text-align: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto 30px;
}

.feature-albums .tim-album-item:before {
  position: absolute;
  content: '';
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 8px;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.feature-albums .tim-album-item .tim-feature-image {
  border-radius: 8px;
}

.feature-albums .tim-album-item .tim-feature-image img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0px 5px 21px 0px rgba(119, 119, 119, 0.35);
}

.feature-albums .tim-album-item .album_details_wrap {
  position: absolute;
  left: 0;
  bottom: 45px;
  width: 100%;
  visibility: hidden;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 0;
}

.feature-albums .tim-album-item .album_details_wrap h4 {
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.feature-albums .tim-album-item .album_details_wrap h4:before, .feature-albums .tim-album-item .album_details_wrap h4:after {
  content: '';
  position: absolute;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
}

.feature-albums .tim-album-item .album_details_wrap h4:before {
  width: 30px;
  height: 1px;
  left: 50%;
  bottom: 0;
}

.feature-albums .tim-album-item .album_details_wrap h4:after {
  width: 30px;
  height: 5px;
  bottom: -2px;
}

.feature-albums .tim-album-item .album_details_wrap h5 {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
}

.feature-albums .tim-album-item .album_details_wrap .tim-btn {
  color: #fff;
  border-color: #fff;
  padding: 5px 30px;
  font-size: 16px;
  margin-top: 15px;
  text-transform: uppercase;
}

.feature-albums .tim-album-item .album_details_wrap .tim-btn:hover {
  color: #fff;
  background: #e43a90;
  border-color: #e43a90;
}

.feature-albums .tim-album-item:hover:before {
  opacity: 1;
}

.feature-albums .tim-album-item:hover .album_details_wrap {
  opacity: 1;
  visibility: visible;
}

.feature-albums .tim-album-item:hover .album_details_wrap h4:before {
  width: 100px;
}

@media (max-width: 768px) {
  #feature-album {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Newsletter
  --------------------------------------------------------------*/
#newsletter {
  padding: 90px 0;
  background: #e8e8e8;
}

.newsletter h2 {
  font-size: 36px;
  font-weight: 600;
}

.newsletter h4 {
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
}

.section-dark h2, .section-dark h4 {
  color: #fff;
}

.newsletter-inner {
  margin-top: 7px;
}

.newsletter-inner .newsletter-form {
  display: flex;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
}

.newsletter-inner .newsletter-form input {
  height: 60px;
  background: #fff;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 15px 20px;
}

.newsletter-inner .newsletter-form input::placeholder {
  color: #363636;
  font-family: "Changa", sans-serif;
}

.newsletter-inner .newsletter-form input:hover, .newsletter-inner .newsletter-form input:focus {
  outline: 0;
}

.newsletter-inner .newsletter-form .tim-btn {
  padding: 10px 23px;
  border-radius: 0;
  text-transform: uppercase;
  border: 0;
}

.newsletter-inner .newsletter-form .tim-btn:hover {
  background: #e12483;
  color: #fff;
}

.newsletter-form > button > i {
  display: none;
}

/* Newsletter Two */
.newsletter-wrap-two {
  position: relative;
  padding: 90px 0;
}

.newsletter-wrap-two:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.5);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.newsletter-wrap-two .newsletter-inner .newsletter-form input {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.newsletter-wrap-two .newsletter-inner .newsletter-form .tim-btn {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

@media (max-width: 575px) {
  .newsletter-inner .newsletter-form .tim-btn {
    position: relative;
    left: 50%;
    margin-left: -70px;
    margin-top: 20px;
  }
  .newsletter-wrap-two .newsletter-inner .newsletter-form input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .newsletter-wrap-two .newsletter-inner .newsletter-form .tim-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .newsletter-inner .newsletter-form .tim-btn {
    padding: 7px 23px;
  }
  .newsletter-inner .newsletter-form input {
    height: 45px;
  }
  .newsletter-inner .newsletter-form {
    display: block;
  }
}

@media (max-width: 768px) {
  .newsletter {
    text-align: center;
    margin-bottom: 30px;
  }
  #newsletter {
    padding: 50px 0;
  }
  .newsletter h2 {
    font-size: 28px;
  }
  .newsletter-wrap-two {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Sticky Audio Player
  --------------------------------------------------------------*/
.app-player {
  background: rgba(228, 58, 144, 0.8);
}

/*--------------------------------------------------------------
  ##   Event Schedule
  --------------------------------------------------------------*/
#event-schedule {
  display: flex;
  padding-bottom: 100px;
}

.schedule-ticket {
  position: relative;
  max-width: 32%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.schedule-ticket img {
  max-width: 100%;
}

.schedule-ticket .content {
  position: absolute;
  top: 50%;
  left: 20%;
  transform: translateY(-50%);
}

.schedule-ticket .content p {
  font-size: 18px;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  color: #fff;
  margin: 0;
}

.schedule-ticket .content h3 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.schedule-ticket .content .tim-btn {
  padding: 10px 30px;
  text-transform: uppercase;
  color: #fff;
  border-color: #fff;
}

.schedule-ticket .content .tim-btn:hover {
  color: #e43a90;
}

.schedule {
  width: 68%;
  float: left;
}

.schedule .schedule-item {
  text-align: center;
  padding: 41px 0;
  border: 1px solid #ededed;
  border-left: 0;
}

.schedule .schedule-item .schedule-thumb {
  height: 125px;
  width: 125px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
}

.schedule .schedule-item .schedule-thumb img {
  width: 100%;
  border-radius: 50%;
}

.schedule .schedule-item .sch-time {
  font-size: 24px;
  color: #666666;
  font-weight: 600;
}

.schedule .schedule-item .band-name {
  font-size: 18px;
  font-weight: 600;
  color: #e43a90;
  position: relative;
  padding-top: 10px;
}

.schedule .schedule-item .band-name:before {
  position: absolute;
  content: '';
  top: 0;
  left: 50%;
  width: 50px;
  height: 2px;
  background: #e43a90;
  transform: translateX(-50%);
}

.schedule .schedule-item p {
  color: #747373;
  margin: 0;
}

@media (min-width: 1280px) and (max-width: 1440px) {
  .schedule .schedule-item p {
    font-size: 14px;
  }
  .schedule-ticket .content {
    left: 10%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .schedule-ticket .content {
    left: 10%;
  }
}

@media (max-width: 1024px) {
  #event-schedule {
    display: block;
    background: #f3f3f3;
  }
  .schedule {
    width: 100%;
  }
  .schedule-ticket {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  #event-schedule {
    padding-bottom: 50px;
  }
}

@media (max-width: 350px) {
  .schedule-ticket .content {
    top: 7px;
    left: 19px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .schedule-ticket .content h3 {
    font-size: 25px;
    margin-bottom: 8px;
  }
}

@media (max-width: 500px) {
  .schedule-ticket .content h3 {
    font-size: 30px;
  }
}

/*--------------------------------------------------------------
  ##   Recent Performance
  --------------------------------------------------------------*/
#recent-performance {
  padding: 100px 0;
  background: #f8f8f8;
}

.performace {
  position: relative;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-width: 400px;
  margin: 0 auto 30px;
}

.performace:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(228, 58, 144, 0.58);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.performace .per-thumb img {
  max-width: 100%;
  transition: all 0.3s ease-in-out;
}

.performace .content {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 78%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(60, 60, 60, 0.85);
  padding: 15px 0;
  transition: all 0.3s ease-in-out;
  z-index: 2;
}

.performace .content:before {
  position: absolute;
  content: '';
  left: -7px;
  right: -7px;
  top: -7px;
  bottom: -7px;
  border: 3px solid rgba(60, 60, 60, 0.85);
  transition: all 0.3s ease-in-out;
}

.performace .content h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 26px;
}

.performace .content h5 {
  font-size: 16px;
  color: #fff;
}

.performace:hover:before {
  opacity: 1;
}

.performace:hover .per-thumb img {
  transform: scale(1.05);
}

.performace:hover .content {
  background: rgba(255, 255, 255, 0.8);
}

.performace:hover .content h3, .performace:hover .content h5 {
  color: #e43a90;
}

.performace:hover .content:before {
  border-color: rgba(255, 255, 255, 0.8);
}

@media (max-width: 1400px) {
  #recent-performance {
    padding: 50px 0;
  }
}

/*--------------------------------------------------------------
  ##   Pricing
  --------------------------------------------------------------*/
#pricing {
  padding: 100px 0;
}

.pricing-table {
  text-align: center;
  background-repeat: no-repeat;
  padding: 60px 0;
  background-size: cover;
  position: relative;
  max-width: 365px;
  margin: 0 auto 30px;
}

.pricing-table .pricbg, .pricing-table .pricbg-bottom {
  position: absolute;
  filter: drop-shadow(-1px -11px 5px rgba(0, 0, 0, 0.1));
}

.pricing-table .pricbg path, .pricing-table .pricbg-bottom path {
  fill: #fff;
}

.pricing-table .pricbg {
  top: 0;
  left: 0;
}

.pricing-table .pricbg path {
  fill: #f1f1f1;
  transition: all 0.3s ease-in-out;
}

.pricing-table .pricbg-bottom {
  bottom: 0;
  left: 0;
  z-index: -1;
  filter: drop-shadow(0px 8px 5px rgba(0, 0, 0, 0.1));
}

.pricing-table .pricing-head {
  padding-bottom: 33px;
  position: relative;
}

.pricing-table .pricing-head .price-title {
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}

.pricing-table .price-inner {
  box-shadow: 0 -15px 20px 0 rgba(0, 0, 0, 0.1);
}

.pricing-table .price {
  font-size: 55px;
  font-weight: 600;
  color: #e43a90;
  line-height: 55px;
  padding: 40px 0 20px;
}

.pricing-table .price-feature {
  margin: 0;
  list-style: none;
  padding: 0 70px 40px;
}

.pricing-table .price-feature li {
  line-height: 40px;
  border-bottom: 1px dashed #d7d7d7;
}

.pricing-table .tim-btn {
  padding: 12px 30px;
  background: transparent;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  text-transform: uppercase;
  color: #696868;
  font-size: 20px;
  display: inline-block;
}

.pricing-table .tim-btn:hover {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

.pricing-table:hover .pricbg path {
  fill: #e43a90;
}

.pricing-table:hover .pricing-head .price-title {
  color: #fff;
}

@media (max-width: 1400px) {
  .pricing-table .pricing-head {
    padding-bottom: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1280px) {
  .pricing-table {
    padding: 45px 0;
  }
  .pricing-table .pricing-head {
    padding-bottom: 0;
  }
  .pricing-table .pricing-head .price-title {
    font-size: 28px;
  }
  .pricing-table .price-feature {
    padding: 0 30px 20px;
  }
  .pricing-table .price {
    padding: 50px 0 10px;
    font-size: 45px;
    margin-bottom: 0;
  }
  .container.con-pd {
    padding: 0 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .pricing-table {
    padding: 50px 0;
  }
  .pricing-table .pricing-head {
    padding-bottom: 0;
  }
  .pricing-table .pricing-head .price-title {
    font-size: 30px;
  }
  .pricing-table .price {
    font-size: 45px;
    padding: 50px 0 0;
  }
  .pricing-table .price-feature {
    padding: 0 30px 20px;
  }
  .pricing-table .tim-btn {
    padding: 8px 25px;
    font-size: 16px;
  }
}

@media (max-width: 1400px) {
  #pricing {
    padding: 50px 0;
  }
}

@media (max-width: 767px) {
  #pricing .container.con-pd {
    padding: 0;
  }
}

/*--------------------------------------------------------------
  ##   Tabs
  --------------------------------------------------------------*/
.tabs-filter-button {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #313131;
  padding: 37px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-align: center;
}

.tabs-filter-button li {
  position: relative;
  padding-left: 20px;
}

.tabs-filter-button li:after {
  content: '';
  position: absolute;
  right: -60px;
  top: 6px;
  height: 16px;
  width: 2px;
  background: #4e4e4e;
}

.tabs-filter-button li:last-child:after {
  display: none;
}

.tabs-filter-button li a {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.tabs-filter-button li a:hover {
  color: #e43a90;
}

.tabs-filter-button li.current a {
  color: #e43a90;
}

.tab-details .details {
  margin: 0;
  padding: 30px 40px;
  list-style: none;
  box-shadow: 0px 5px 21px 0px rgba(142, 142, 142, 0.23);
  background: rgba(255, 255, 255, 0.5);
}

.tab-details .details li {
  font-family: "Changa", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #585757;
  position: relative;
  padding-bottom: 5px;
  display: inline-block;
  text-align: left;
  width: 16%;
}

.tab-details .details li:before, .tab-details .details li:after {
  content: '';
  position: absolute;
  background: #adadad;
}

.tab-details .details li:before {
  bottom: 0;
  width: 25%;
  height: 1px;
  left: 25px;
  transform: translateX(-50%);
}

.tab-details .details li:after {
  left: 16px;
  width: 15px;
  height: 4px;
  bottom: -1px;
}

.tab-details.tab-dark ul {
  background: #313131;
}

.tab-details.tab-dark ul li {
  color: #fff;
}

.songs-details {
  margin: 0;
  padding: 0;
  list-style: none;
}

.songs-details li {
  display: inline-block;
  width: 16%;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.songs-details li a {
  color: #585757;
}

.songs-details li a:hover {
  color: #e43a90;
}

.songs-details li span {
  color: #e43a90;
}

.songs-details li i {
  margin-right: 5px;
}

.tabs-filter {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tab-filter-wraper {
  background-image: url(../../media/background/13.jpg);
  padding-bottom: 30px;
}

.tab-filter-wraper.top-music-wrap {
  box-shadow: 0px 5px 21px 0px rgba(184, 184, 184, 0.23);
}

.tim-isotope-3 {
  padding: 0 40px;
}

.tim-songs-items.grid-item {
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid #d1d1d1;
}

.tim-songs-items.grid-item:last-child {
  border-bottom: 0;
}

.tim-songs-items.tranding-song {
  padding: 16px 0;
  border-bottom: 1px solid #d1d1d1;
}

.tim-songs-items.tranding-song:before {
  clear: both;
  content: '';
  display: block;
}

/* Top Music */
#top-music {
  padding: 120px 0;
  background: #fbfbfb;
}

.circle-player {
  background: #f5f5f5;
  padding: 25px;
}

#jp_container_1 .jp-type-playlist {
  background: #dea23c;
  padding: 80px;
}

#jp_container_1 .jp-gui {
  margin: 0 auto;
  height: 410px;
  width: 410px;
  border-radius: 50%;
  background: #FFF;
  padding: 80px;
  position: relative;
}

#jp_container_1 .jp-gui .jp-controls {
  background: #dea23c;
  border-radius: 50%;
  text-align: center;
  height: 100%;
  width: 100%;
  padding: 65px 0;
}

#jp_container_1 .jp-gui .jp-controls button {
  border: 0;
  background: transparent;
}

#jp_container_1 .jp-gui .jp-next, #jp_container_1 .jp-gui .jp-previous {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#jp_container_1 .jp-gui .jp-next {
  right: 25px;
}

#jp_container_1 .jp-gui .jp-previous {
  left: 25px;
}

#jp_container_1 .jp-gui .jp-volume-controls {
  border-radius: 50%;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  align-items: center;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-volume-bar {
  height: 5px;
  background: #fff;
  width: 70px;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-volume-bar .jp-volume-bar-value {
  height: 5px;
  background: #4c4c4c;
}

#jp_container_1 .jp-gui .jp-volume-controls .jp-mute {
  margin-right: 5px;
}

#jp_container_1 .jp-gui .jp-progress {
  width: 150px;
  margin: 0 auto;
}

#jp_container_1 .jp-gui .jp-progress .jp-seek-bar {
  height: 5px;
  background: #fff;
}

#jp_container_1 .jp-gui .jp-progress .jp-seek-bar .jp-play-bar {
  height: 5px;
  background: #4c4c4c;
}

#jp_container_1 .jp-gui .jp-progress .jp-current-time {
  float: left;
}

#jp_container_1 .jp-gui .jp-progress .jp-duration {
  float: right;
}

.tab-filter-wrapper {
  padding: 0 40px;
}

@media (max-width: 1200px) {
  .tabs-filter-button li:after {
    right: -44px;
  }
}

@media (max-width: 1200px) {
  .tabs-filter-button li:after {
    right: -30px;
  }
}

@media (max-width: 1199px) {
  .tab-pd-left, .tab-pd-right {
    padding: 70px 35px;
  }
}

@media (max-width: 768px) {
  .tabs-filter-button li {
    width: 25%;
  }
  .tabs-filter-button li:after {
    right: -17px;
  }
  .tim-isotope-3 {
    padding: 0;
  }
  .tab-details .details {
    display: none;
  }
  .songs-details li {
    display: block;
    width: 100%;
    float: right;
    padding: 15px;
    border-bottom: 1px solid #b1b1b1;
  }
  .songs-details li:last-child {
    border-bottom: 0;
  }
  .songs-details li:before {
    width: 30%;
    float: left;
    font-weight: 600;
    text-align: left;
  }
  .songs-details li:nth-of-type(1):before {
    content: "Artist";
  }
  .songs-details li:nth-of-type(2):before {
    content: "Title";
  }
  .songs-details li:nth-of-type(3):before {
    content: "Type";
  }
  .songs-details li:nth-of-type(4):before {
    content: "Rating";
  }
  .songs-details li:nth-of-type(5):before {
    content: "Published";
  }
  .songs-details li:nth-of-type(6):before {
    content: "View";
  }
  .tim-songs-items.grid-item {
    padding: 15px 0;
    border-bottom: 2px solid #000;
  }
  .tim-songs-items.tranding-song {
    padding: 0;
    border-bottom: 2px solid #211f1f;
  }
  .tab-filter-wrapper {
    padding: 0;
    overflow: hidden;
  }
  #top-music {
    padding: 50px 0;
  }
}

@media (max-width: 575px) {
  .tabs-filter-button li {
    width: 50%;
  }
  .tab-pd-left, .tab-pd-right {
    padding: 30px 15px 15px;
  }
}

/*--------------------------------------------------------------
  ##  Tab single page
  --------------------------------------------------------------*/
/*--------------------   Tab single page header  --------------*/
.header-search {
  width: 670px;
  margin: 0 auto;
  text-align: left;
  padding-bottom: 170px;
}

.header-search h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.header-search input {
  border: 1px solid #fff;
  border-radius: 5px;
  width: 40%;
  background: rgba(255, 255, 255, 0.09);
  padding-left: 15px;
  height: 47px;
  color: #fff;
  margin-right: 9px;
}

.header-search select {
  border: 1px solid #fff;
  width: 40%;
  height: 47px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  color: #7b7b7b;
  padding-left: 15px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 25px;
  background: rgba(255, 255, 255, 0.09);
  border-radius: 5px;
  margin-right: 9px;
  background-image: url("../../assets/img/arrow.png") no-repeat scroll 94% 47%;
}

.header-search .tim-cart-btn {
  background: #e43a90;
  padding: 10px 23px;
  color: #FFF;
  border-radius: 6px;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  font-family: "Changa", sans-serif;
  cursor: pointer;
}

.page-header.tab-header {
  padding: 330px 0 30px;
  background-position: center;
  background-size: cover;
  /*--------------------   Tab single page Inner  --------------*/
}

.tab-left .tab-img {
  box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
  border: 14px solid #fff;
}

.tab-left h6 {
  font-size: 30px;
  color: #585757;
  margin-top: 30px;
}

.tab-left p {
  font-size: 18px;
  color: #585757;
}

.tab-left p a {
  color: #f84196;
}

.tab-right {
  padding-top: 170px;
  padding-left: 50px;
  padding-right: 110px;
}

.tab-right h6 {
  font-size: 20px;
  color: #fff;
  font-family: "Changa", sans-serif;
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.tab-right p {
  font-size: 16px;
  color: #fff;
}

.tab-right ul {
  margin-top: 42px;
  position: relative;
}

.tab-right ul:after {
  content: '';
  position: absolute;
  left: 0;
  top: 38px;
  background: #e39ac8;
  width: 100%;
  height: 1px;
}

.tab-right ul li {
  display: inline-block;
  text-align: left;
  padding-right: 27px;
  font-family: "Changa", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
}

.tab-right ul li i {
  margin-right: 5px;
}

.tab-right ul li span {
  display: block;
  font-family: "Changa", sans-serif;
  font-weight: 400;
  font-size: 15px;
  padding-top: 24px;
  color: #fff;
}

.tab-right ul:last-child {
  padding-right: 15px;
}

.tab-right::after {
  content: '';
  width: 720px;
  height: 500px;
  position: absolute;
  left: -200px;
  top: 100px;
  z-index: -1;
  background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -webkit-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -ms-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
}

.tab-right h6::before, .tab-right h6::after {
  position: absolute;
  content: '';
  bottom: 0;
  background: #fff;
}

.tab-right h6::before {
  width: 80px;
  height: 1px;
}

.tab-right h6::after {
  height: 3px;
  bottom: -1px;
  width: 30px;
  left: 0;
}

.tab-right ul li {
  display: inline-block;
  text-align: left;
  padding-right: 27px;
  font-family: "Changa", sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
}

.tab-right ul li:last-child {
  padding-right: 0;
}

.single-tab-details {
  text-align: left;
}

.single-tab-details .section-title-small h2 {
  font-size: 22px;
  line-height: 26px;
  color: #444;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 20px;
  position: relative;
  display: inline-block;
}

.single-tab-details .section-title-small h2 span {
  color: #e43a90;
}

.single-tab-details .section-title-small h2::before, .single-tab-details .section-title-small h2::after {
  position: absolute;
  content: '';
  background: #e43a90;
}

.section-title-small h2::before {
  width: 100px;
  height: 1px;
  left: 0;
  bottom: 0;
}

.section-title-small h2::after {
  height: 3px;
  bottom: -1px;
  width: 30px;
  left: 10px;
}

.lyrics-note p {
  font-size: 16px;
  font-family: "Changa", sans-serif;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
  display: block;
}

.lyrics-note ul {
  list-style: none;
}

.lyrics-note ul li {
  padding-bottom: 10px;
  font-size: 16px;
  color: #585757;
  font-weight: 500;
}

.lyrics-note ul span {
  font-weight: 400;
}

.tab-details {
  background: #fcfafb;
}

.tab-details .section-title-small {
  padding-bottom: 70px;
}

.lyrics-note {
  margin-top: 66px;
}

.lyrics-note > span {
  font-size: 24px;
  color: #585757;
  font-weight: 600;
  font-family: "Changa", sans-serif;
  margin-bottom: 18px;
  display: inline-block;
}

.lyrics-note ul {
  margin-top: 20px;
}

.header_player.tab_page {
  position: relative;
  background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -webkit-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -ms-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  border: 12px solid #fff;
  box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
}

.header_player.tab_page .current-tracks {
  float: none;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play i {
  font-size: 17px;
  line-height: 37px;
  color: #d20f14;
  margin-left: 6px;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls .jp-play {
  height: 40px;
  width: 40px;
  padding-left: 2px;
  background: #fff;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-controls {
  width: 140px;
  margin-top: -6px;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
  width: 125px;
  margin-left: 20px;
}

.header_player.tab_page .current-tracks::after {
  display: none;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
  padding: 0px 0 28px;
}

.header_player.tab_page #nowPlaying .track-name {
  font-size: 20px;
}

.header_player.tab_page #nowPlaying {
  padding: 21px 0 40px 15px;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress {
  border: 1px dashed #ec89c3;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress .jp-seek-bar .jp-play-bar {
  background: #fff;
}

.header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .jp-progress {
  background-color: transparent;
}

.tab-video {
  position: relative;
  margin-top: 80px;
}

.tab-video .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #d21d20;
  font-size: 96px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}

.tab-video .popup-video-btn::after {
  content: '';
  background: #fff;
  width: 46px;
  height: 46px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: -1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.related-album-single .section-title h2::before {
  left: 13px;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  width: 100px;
}

.related-album-single .section-title h2::after {
  height: 3px;
  bottom: -1px;
  width: 30px;
  left: 13px;
}

.related-album-wrapper {
  list-style: none;
  padding: 0;
}

.single-related-album {
  box-shadow: 0px 1px 29.75px 5.25px rgba(23, 28, 62, 0.1);
  list-style: none;
  margin: 30px 8px;
  display: inline-block;
}

.single-related-album .single-related-prod-bottom {
  font-family: "Changa", sans-serif;
  padding: 15px 15px;
  width: 100%;
  overflow: hidden;
}

.single-related-album .single-related-prod-bottom .left {
  float: left;
  display: inline-block;
}

.single-related-album .single-related-prod-bottom .left a {
  font-size: 16px;
  color: #333333;
  font-weight: 600;
}

.single-related-album .single-related-prod-bottom .left p {
  font-size: 16px;
  color: #585757;
  line-height: 16px;
}

.single-related-album .single-related-prod-bottom .play-bottom {
  float: right;
  width: 46px;
  height: 46px;
  background: #fae4ee;
  text-align: center;
  border-radius: 50px;
  line-height: 48px;
  position: relative;
}

.single-related-album .single-related-prod-bottom .play-bottom i {
  color: #fff;
  width: 36px;
  height: 36px;
  background: #333333;
  border-radius: 50px;
  line-height: 36px;
  transition: all 0.15s ease-in-out;
}

.single-related-album:hover .left a {
  color: #f84196;
}

.single-related-album:hover .play-bottom i {
  background: #f84196;
  transition: all 0.15s ease-in-out;
}

.tab_page #playlist-toggle {
  display: none;
}

.header_player.tab_page .player-container {
  padding-bottom: 30px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .tab-right::after {
    width: 520px;
    height: 380px;
    left: -30px;
    top: 68px;
  }
  .tab-right {
    padding-top: 102px;
    padding-left: 15px;
    padding-right: 30px;
  }
  .header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
    padding: 22px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .tab-right {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 110px;
    background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
    padding-bottom: 50px;
  }
  .tab-right::after {
    display: none;
  }
  .header_player.tab_page .player-container {
    max-width: 600px;
    margin-top: 32px;
  }
  .header_player.tab_page {
    margin-top: 50px;
  }
  .tab-video {
    margin-top: 70px;
  }
  .tab-left .tab-img {
    text-align: center;
  }
  .header_player.tab_page {
    margin-top: 132px;
  }
  .header_player.tab_page .jp-audio .jp-type-playlist .jp-interface .vel-wrap {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .tab-right {
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 110px;
    background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
    padding-bottom: 50px;
  }
  .tab-right::after {
    display: none;
  }
  .header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
    padding: 20px 0 40px;
  }
  .tab-details .section-title-small {
    padding-bottom: 20px;
  }
  .header_player.tab_page {
    margin-top: 25px;
  }
  .tab-video {
    margin-top: 55px;
  }
  .header_player.tab_page .player-container {
    padding-bottom: 11px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .tab-right {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
    padding-bottom: 20px;
  }
  .tab-right::after {
    display: none;
  }
  .header_player.tab_page .jp-audio .jp-type-playlist .jp-interface {
    padding: 20px 0 40px;
  }
  .tab-details .section-title-small {
    padding-bottom: 20px;
  }
  .header_player.tab_page {
    margin-top: 25px;
  }
  .tab-video {
    margin-top: 55px;
  }
  .header_player.tab_page .player-container {
    padding-bottom: 6px;
  }
  .header_player.tab_page {
    border: none;
  }
  .header_player.tab_page .tim-container {
    padding: 0;
  }
  .tab-right ul::after {
    display: none;
  }
  .tab-right ul li span {
    padding-top: 10px;
  }
  .tab-right ul li {
    margin-bottom: 30px;
  }
}

/*--------------------------------------------------------------
  ##   Gallery
  --------------------------------------------------------------*/
/*--------------------------------------------------------------
  ##   Event
  --------------------------------------------------------------*/
.event-details .event-details {
  font-family: "Changa", sans-serif;
}

.event-details .event-details .event-brief {
  border: 10px solid #f5f5f5;
  margin-bottom: 45px;
}

.event-details .event-details .event-brief .brief-bottom {
  display: inline-block;
  padding: 30px;
  width: 100%;
}

.event-details .event-details .event-brief .brief-bottom h6 {
  float: left;
  width: 50%;
  display: inline-block;
  font-size: 26px;
  color: #333333;
  padding-right: 50px;
  position: relative;
}

.event-details .event-details .event-brief .brief-bottom h6:after {
  content: '';
  position: absolute;
  height: 80px;
  width: 1px;
  background: #ebebeb;
  right: 0;
  top: 0;
}

.event-details .event-details .event-brief .brief-bottom p {
  overflow: hidden;
  margin-bottom: 5px;
  padding-left: 30px;
}

.event-details .event-details > p {
  color: #585757;
}

.event-details .event-details ul {
  list-style: none;
  margin-bottom: 33px;
}

.event-details .event-details ul li i {
  margin-right: 15px;
  color: #e43a90;
}

.event-details .event-details > span {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 18px;
  display: inline-block;
}

.event-time .event-video {
  position: relative;
  width: 380px;
  float: right;
  margin-right: 30px;
  margin-top: 25px;
}

.event-time .event-video .video-btn {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  line-height: 75px;
  display: block;
  margin: 0 auto;
  font-size: 26px;
  margin-bottom: 20px;
  background: #fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.event-time .event-video .video-btn i {
  color: #e43a90;
}

.event-time .event-video .video-btn::after {
  position: absolute;
  content: '';
  top: -17px;
  left: -17px;
  width: 105px;
  height: 105px;
  background: #fff;
  opacity: 0.2;
  z-index: -1;
  border-radius: 50%;
}

.event-time .event-video .video-btn:hover {
  border-color: #fff;
}

.single-event-schedule .img-box {
  float: left;
  border-radius: 6px;
}

.single-event-schedule h6 {
  font-size: 18px;
  color: #333333;
  margin-bottom: 0;
}

.single-event-schedule span {
  font-size: 16px;
  color: #565555;
}

.single-event-schedule .schedule-time {
  float: right;
}

.single-event-schedule .schedule-time span {
  font-size: 24px;
  display: block;
  color: #333333;
  font-weight: 600;
}

.single-event-schedule:hover h6 {
  color: #e43a90;
}

.single-event-schedule:hover .schedule-time span {
  color: #e43a90;
}

.event-schedule-inner {
  width: 50%;
  float: left;
}

.single-event-schedule {
  padding-bottom: 22px;
  border-bottom: 1px solid #f7f7f7;
  padding-top: 30px;
}

.single-event-schedule .schedule-mid {
  overflow: hidden;
  display: inline-block;
  padding-left: 26px;
}

.single-event-schedule .event-time {
  margin-top: 25px;
}

.single-event-list-widget {
  font-family: "Changa", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
}

.single-event-list-widget .sle-img {
  float: left;
  position: relative;
}

.single-event-list-widget .sle-img span {
  position: absolute;
  background: rgba(18, 25, 58, 0.87);
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  width: 100%;
  text-align: center;
}

.single-event-list-widget .right h6 {
  font-size: 18px;
  color: #333333;
  margin: 0;
}

.single-event-list-widget .right a {
  font-size: 15px;
  color: #5e5c5c;
  display: block;
}

.single-event-list-widget .right a i {
  margin-right: 5px;
}

.single-event-list-widget .right .mid-box {
  float: left;
  text-align: left;
  padding-left: 15px;
  padding-top: 17px;
  padding-right: 25px;
}

.single-event-list-widget .right .price {
  display: inline-block;
  padding-top: 28px;
}

.single-event-list-widget .right .price span {
  font-size: 18px;
  font-weight: 500;
}

.single-event-list-widget .right .price a {
  font-size: 18px;
  color: #e43a90;
  font-weight: 500;
  line-height: 15px;
}

.widget.widget-event-list {
  padding: 40px 10px 20px;
  box-shadow: none;
  border: 10px solid #f5f5f5;
  border-radius: 0;
}

.widget.widget-sponser ul {
  overflow: hidden;
}

.widget.widget-sponser ul li {
  padding: 17px 10px;
  float: left;
  height: 100px;
  overflow: hidden;
  width: 32%;
}

.widget.widget-sponser ul li:nth-child(1),
.widget.widget-sponser ul li:nth-child(2) {
  border-bottom: 1px solid #f4f4f4;
  border-right: 1px solid #f4f4f4;
}

.widget.widget-sponser ul li:nth-child(3) {
  border-bottom: 1px solid #f4f4f4;
}

.widget.widget-sponser ul li:nth-child(4),
.widget.widget-sponser ul li:nth-child(5) {
  border-bottom: none;
  border-right: 1px solid #f4f4f4;
}

.single-event-list-widget:hover {
  background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -webkit-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  background-image-background-image: -ms-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
  transition: all .3s;
}

.single-event-list-widget:hover {
  font-family: "Changa", sans-serif;
  margin-bottom: 10px;
  display: inline-block;
  width: 100%;
  box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
}

.single-event-list-widget:hover .sle-img span {
  color: #fff;
  transition: all .3s;
}

.single-event-list-widget:hover .right h6 {
  color: #fff;
  transition: all .3s;
}

.single-event-list-widget:hover .right a {
  color: #fff;
}

.single-event-list-widget:hover .right a a {
  color: #fff;
}

.single-event-list-widget:hover .right .price span {
  color: #fff;
  transition: all .3s;
}

/*--------------------------------------------------------------
  ##   Page Header
  --------------------------------------------------------------*/
.page-header {
  padding: 280px 0 30px;
  background-position: center;
  background-size: cover;
}

.page-header.event-header {
  padding: 300px 0 30px;
}

.page-header.event-header .page-header-title {
  padding-bottom: 150px;
}

.page-header .page-header-title {
  padding-bottom: 40px;
}

.page-header .page-header-title h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 600;
  text-transform: uppercase;
}

.page-header .page-header-title h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.page-header .page-header-title.event-page-header h2 {
  font-size: 36px;
}

.page-header .page-header-title.event-page-header h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.page-header .live-ticket-count {
  margin-bottom: 50px;
  text-align: center;
}

.page-header .live-ticket-count .countdown {
  margin: 0 auto;
}

.page-header .tim-btn.tim-btn-bgt {
  border: 1px solid #e4e4e4;
  color: #fff;
}

.page-header .tim-btn.tim-btn-bgt:hover {
  color: #e43a90;
}

.breadcrumbs a, .breadcrumbs span {
  color: #fff;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
}

.breadcrumbs a {
  color: #b4b4b4;
}

.breadcrumbs a:hover {
  color: #e43a90;
}

@media (max-width: 992px) {
  .page-header {
    padding-top: 155px !important;
  }
}

@media (max-width: 768px) {
  .page-header .page-header-title h2 {
    font-size: 40px;
    line-height: 40px;
  }
  .page-header .live-ticket-count {
    margin-bottom: 20px;
    text-align: center;
  }
}

/*--------------------------------------------------------------
  ##   Page Header Magazine
  --------------------------------------------------------------*/
.mag-header-inner h2 {
  font-size: 60px;
  color: #fff;
}

.mag-header-inner h2 span {
  color: #db9a0a;
}

.mag-bn {
  position: relative;
  width: auto;
  z-index: 1;
  padding: 13px 35px;
  text-align: center;
  display: inline-block;
  margin-left: 15px;
}

.mag-bn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-15deg);
  background: #313130;
  z-index: -1;
}

.mag-bn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 3%;
  width: 94%;
  height: 100%;
  -webkit-transform: skewX(-15deg);
  -ms-transform: skewX(-15deg);
  transform: skewX(-15deg);
  z-index: -1;
  border-left: 7px solid #fff;
  border-right: 7px solid #fff;
}

.mag-bn p {
  font-size: 24px;
  color: #2e2e2e;
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
}

.mag-bn p span {
  color: #fff;
}

.mag-header-inner {
  max-width: 1350px;
  margin: 0 auto;
}

.mag-social ul {
  padding: 0;
  list-style: none;
}

.mag-social ul li {
  display: inline;
}

.mheader-top span {
  float: left;
}

.mheader-top p {
  float: left;
}

.mheader-top .mag-social {
  float: right;
}

.mheader-top {
  display: block;
  overflow: hidden;
  background: #1d1d1d;
  padding: 16px 15px 16px;
  border-radius: 5px 5px 0px 0px;
}

.mag-header-inner .mag-bn::before {
  background: #dc9c0a;
}

.mheader-top span {
  float: left;
  color: #fff;
  background: #dc9c0a;
  padding: 6px 13px;
  overflow: hidden;
  display: block;
  border-radius: 5px 0px 0px 5px;
  margin-right: 15px;
}

.mheader-top p {
  float: left;
  padding-top: 6px;
  margin: 0px;
  color: #adadad;
  font-size: 14px;
}

.mag-social ul li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  margin-right: 8px;
  text-align: center;
  color: #fff;
  border-radius: 50px;
  line-height: 35px;
}

.mag-social ul li a.fb {
  background: #071662;
}

.mag-social ul li a.tt {
  background: #4d91e5;
}

.mag-social ul li a.ins {
  background: #9d1973;
}

.mag-social ul li a.gp {
  background: #cd0630;
}

.mag-header-inner img {
  max-width: 100%;
}

.headr-post {
  width: 25%;
  float: left;
  position: relative;
  z-index: 1;
}

.mheader-post {
  display: block;
  overflow: hidden;
}

.hp-content {
  position: absolute;
  bottom: 0;
  left: 16px;
  z-index: 999;
}

.hp-content p a {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 24px;
}

.hp-content a {
  color: #da9a0a;
  font-size: 18px;
  font-weight: 400;
}

.hp-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.magazine-tab-area .tab-content {
  overflow: hidden;
  width: 100%;
}

.page-header.magazine-header {
  padding-bottom: 0;
}

/*--------------------------------------------------------------
  ##   Woocommerce
  --------------------------------------------------------------*/
#product.section-padding {
  padding-bottom: 65px;
}

.product-top-menu {
  margin-bottom: 30px;
  overflow: hidden;
  width: 100%;
}

.product-top-menu span {
  font-family: "Changa", sans-serif;
  margin-right: 5px;
}

.product-top-menu #product-show {
  padding: 0 22px 0 6px;
  margin-right: 20px;
}

.product-top-menu .product-select {
  border: 1px solid #ebebeb;
  background: transparent;
  font-family: "Changa", sans-serif;
  font-size: 16px;
  line-height: 16px;
  color: #7a7979;
  outline: 0;
  padding: 0 10px;
}

.product-top-menu .product-menu-wrapper {
  width: 450px;
  float: right;
}

.tim-product-btn-vw input {
  display: none;
}

.tim-product-btn-vw label {
  cursor: pointer;
}

/* Product */
ul.products li.product {
  text-align: left;
}

ul.products li.product .product-thumb {
  position: relative;
  overflow: hidden;
  margin: 0 auto 20px;
}

ul.products li.product .product-thumb img {
  max-width: 100%;
  margin-bottom: 0;
}

ul.products li.product .product-thumb .new {
  position: absolute;
  top: 10px;
  right: -10px;
  background: #e43a90;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 17px;
}

ul.products li.product .product-thumb:before {
  position: absolute;
  content: '';
  background: rgba(228, 58, 144, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

ul.products li.product .product-thumb .add_to_cart_button {
  padding: 8px 25px;
  border: 1px solid #fff;
  font-family: "Changa", sans-serif;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  display: inline-block;
  position: absolute;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
}

ul.products li.product .product-thumb .add_to_cart_button:hover {
  background: #434343;
  color: #fff;
  border-color: #434343;
}

ul.products li.product .product-thumb:hover .add_to_cart_button {
  bottom: 20px;
}

ul.products li.product .product-thumb:hover:before {
  opacity: 1;
}

ul.products li.product .woocommerce-loop-product__title {
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

ul.products li.product .woocommerce-loop-product__title a {
  font-family: "Changa", sans-serif;
  color: #363636;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}

ul.products li.product .woocommerce-loop-product__title a:hover {
  color: #e43a90;
}

ul.products li.product .woocommerce-loop-product__title:hover {
  color: #e43a90;
}

ul.products li.product .price {
  display: block;
  float: right;
  margin: 0;
}

ul.products li.product .price .woocommerce-Price-amount {
  font-size: 24px;
  font-weight: 500;
  color: #e43a90;
  font-family: "Changa", sans-serif;
}

ul.products li.product-two {
  text-align: center;
}

ul.products li.product-two .woocommerce-loop-product__title {
  font-size: 22px;
}

ul.products li.product-two .star-rating {
  float: right;
}

ul.products li.product-two .product-thumb {
  position: relative;
}

ul.products li.product-two .product-thumb:before {
  position: absolute;
  content: '';
  background: rgba(0, 0, 0, 0.3);
}

ul.products li.product-two .product-thumb .product-details {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px;
  background: #fff;
  box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.08);
  border-radius: 5px;
  width: 160px;
  opacity: 0;
  visibility: visible;
  transition: all 0.3s ease-in-out;
}

ul.products li.product-two .product-thumb .product-details a {
  width: 40px;
  display: inline-block;
  text-align: center;
}

ul.products li.product-two .product-thumb .product-details a i {
  color: #636363;
  transition: all 0.3s ease-in-out;
  font-size: 20px;
}

ul.products li.product-two .product-thumb .product-details a:nth-child(2) {
  border-right: 1px solid #d1d1d1;
  border-left: 1px solid #d1d1d1;
}

ul.products li.product-two .product-thumb .product-details a:hover i {
  color: #e43a90;
}

ul.products li.product-two .product-thumb .new {
  left: 0;
  right: auto;
  font-family: "Changa", sans-serif;
}

ul.products li.product-two .product-thumb .new.sale {
  right: 0;
  left: auto;
  background: #d2d40b;
}

ul.products li.product-two .product-thumb:hover .product-details {
  opacity: 1;
}

ul.products li.product-two .product-thumb:hover .share-link {
  opacity: 1;
  visibility: visible;
}

ul.products li.product-two .product-thumb:hover .share-link li {
  transform: scale(1);
}

ul.products li.product-two .star-rating {
  font-size: 14px;
}

ul.products li.product-two .star-rating span:before {
  font-size: 14px;
  color: #e43a90;
}

ul.products li.product-two .price {
  float: none;
}

ul.products li.product-two .price ins {
  margin-left: 0;
  text-decoration: none;
}

ul.products li.product-two .price del .woocommerce-Price-amount {
  font-size: 18px;
  color: #898989;
  margin-left: 10px;
}

ul.products li.product-two .product-description {
  display: none;
}

ul.products li.product-two .share-link {
  position: absolute;
  bottom: 50px;
  text-align: center;
  width: 100%;
  list-style: none;
  opacity: 0;
  visibility: hidden;
}

ul.products li.product-two .share-link li {
  display: inline-block;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  text-align: center;
  line-height: 35px;
  transform: scale(0);
  transition: all 0.3s ease-in-out;
}

ul.products li.product-two .share-link li:before {
  position: absolute;
  height: 30px;
  width: 30px;
  background: #fff;
  content: '';
  transform: rotate(45deg);
  z-index: 0;
  left: 3px;
  box-shadow: 0px 2px 12.09px 0.91px rgba(105, 104, 104, 0.23);
  border-radius: 4px;
  transition: all 265ms ease-in;
}

ul.products li.product-two .share-link li a {
  display: block;
  position: relative;
  z-index: 1;
  line-height: 30px;
}

ul.products li.product-two .share-link li:nth-child(2) {
  transition-delay: 0.01s;
}

ul.products li.product-two .share-link li:nth-child(3) {
  transition-delay: 0.05s;
}

ul.products li.product-two .share-link li:nth-child(3) {
  transition-delay: 0.07s;
}

ul.products li.product-two .share-link li:nth-child(4) {
  transition-delay: 0.09s;
}

ul.products li.product-two .share-link li:hover:before {
  transform: rotate(0deg);
  border-radius: 50%;
}

ul.products li.product-two .share-link li.fa-col a {
  color: #4868ab;
}

ul.products li.product-two .share-link li.tw-col a {
  color: #1da2f2;
}

ul.products li.product-two .share-link li.ins-col a {
  color: #f23751;
}

ul.products li.product-two .share-link li.dri-col a {
  color: #ee1370;
}

ul.products li.product-two .ajax_add_to_cart {
  border-radius: 10px;
  padding: 10px 18px;
  position: relative;
  font-size: 16px;
}

ul.products li.product-two .ajax_add_to_cart:before {
  position: absolute;
  content: '';
  left: 20px;
  height: 20px;
  width: 2px;
  background: rgba(255, 255, 255, 0.5);
  top: 12px;
  left: 43px;
}

ul.products li.product-two .ajax_add_to_cart i {
  margin-right: 20px;
}

/* Product 3 Column */
.site-main .columns-3 ul.products li.product {
  width: 33.33%;
  padding: 0 15px;
  margin: 0 0 40px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two {
  width: 98% !important;
  text-align: left;
  border: 1px solid #ebebeb;
  padding: 0;
  display: flex;
  align-items: center;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
  max-width: 31%;
  margin-bottom: 0;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
  padding: 0 20px;
  width: 69%;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .woocommerce-loop-product__title {
  font-size: 30px;
  margin-bottom: 20px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .product-description {
  display: block;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .price {
  margin-bottom: 20px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
  margin-bottom: 30px;
  font-size: 16px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button {
  display: inline-block;
  padding: 15px 35px;
  border: 1px solid #e4e4e4;
  color: #363636;
  font-size: 18px;
  line-height: 18px;
  border-radius: 4px;
  font-family: "Changa", sans-serif;
  font-weight: 500;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button i {
  margin-right: 10px;
}

.site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button:hover {
  background: #e43a90;
  border-color: #e43a90;
  color: #fff;
}

/* Product 4 Column */
.site-main .columns-4 ul.products li.product {
  margin-right: 0;
  width: 25%;
  padding: 0 15px;
  margin-bottom: 50px;
}

.quickview-slider swiper-slide img {
  width: 100%;
}

.quickview-slider .slider-nav .slick-slide {
  padding: 0 3px;
}

.product-cart {
  margin: 40px 0 60px;
}

.product-cart .quantity {
  position: relative;
  background: #FFF;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.09);
  width: 90px;
  height: 50px;
  border: none;
  border-radius: 5px;
  float: left;
  padding: 11px 12px;
  margin-right: 10px;
}

.product-cart .quantity .minus, .product-cart .quantity .plus {
  cursor: pointer;
  color: #363636;
  font-size: 12px;
}

.product-cart .quantity .minus:focus, .product-cart .quantity .plus:focus {
  background: transparent;
  color: #363636;
}

.product-cart .quantity input {
  width: 51%;
  margin: 0 auto;
  border: none;
  padding: 0;
  text-align: center;
  font-size: 16px;
}

.product-cart .tim-cart-btn {
  background: #e43a90;
  padding: 10px 17px;
  color: #FFF;
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  font-weight: 500;
  border: 0;
  font-family: "Changa", sans-serif;
  cursor: pointer;
}

.product-cart .tim-cart-btn i {
  margin-right: 5px;
}

.product-cart .tim-cart-btn:hover {
  background: #333;
  color: #FFF;
}

.product-details .close-menu {
  right: 15px;
  top: 0px;
  padding: 0;
  height: auto;
  width: auto;
  line-height: 1;
  cursor: pointer;
}

.product-details .product-title {
  font-size: 26px;
}

.product-details .product-sub-title {
  font-size: 20px;
}

.product-details .price {
  margin-bottom: 30px;
}

.product-details .price ins {
  margin-right: 10px;
  text-decoration: none;
}

.product-details .price ins, .product-details .price del {
  font-size: 20px;
  font-weight: 600;
}

.product-details .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

.product_meta .sku_wrapper {
  margin: 10px 0;
}

.product_meta .posted_in {
  margin: 10px 0;
}

.product_meta .tagcloud a {
  font-size: 14px;
  padding: 3px 15px;
  border-radius: 10px;
}

.product_meta .posted_in a {
  color: #333;
  font-size: 14px;
  margin: 3px;
}

.product_meta .posted_in a:hover {
  color: #e43a90;
}

.woocommerce-product-rating .star-rating {
  display: inline-block;
  margin-right: 10px;
  font-size: 14px;
  height: 19px;
}

.woocommerce-product-rating .star-rating:before {
  font-size: 14px;
}

.share-wrap {
  margin-top: 20px;
}

.share-wrap h3 {
  font-size: 18px;
  display: inline-block;
  margin-right: 10px;
}

.product-share-link {
  margin: 0;
  padding: 0;
  list-style: none;
  display: inline-block;
}

.product-share-link li {
  display: inline-block;
  margin-right: 5px;
}

.product-share-link li a {
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  text-align: center;
  line-height: 35px;
  color: #fff;
  transition: all .3s ease-in-out;
}

.product-share-link li a.facebook-bg {
  background: #305fb3;
}

.product-share-link li a.twitter-bg {
  background: #5eaade;
}

.product-share-link li a.google-plus-bg {
  background: #d7482a;
}

.product-share-link li a.pinterest-bg {
  background: #bd081c;
}

.product-share-link li a:hover {
  background: #e43a90;
}

/* Shop Page */
.shop {
  padding: 100px 0;
}

.single-product {
  padding: 100px 0 60px;
}

.single-product-details .product-title {
  font-size: 32px;
  line-height: 30px;
  margin-bottom: 20px;
}

.single-product-details .price {
  margin-bottom: 50px;
}

.single-product-details .price ins {
  margin-right: 10px;
  text-decoration: none;
}

.single-product-details .price ins, .single-product-details .price del {
  font-size: 26px;
  font-weight: 600;
}

.single-product-details .star-rating span:before {
  color: #e43a90;
}

.single-product-details .review-link {
  color: #363636;
}

.single-product-details .woocommerce-product-details__short-description {
  margin-bottom: 30px;
}

/* Quick View */
.quickview-wrapper {
  position: fixed;
  max-width: 760px;
  border-radius: 5px;
  top: 50%;
  opacity: 0;
  visibility: hidden;
  backface-visibility: hidden;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.quickview-wrapper .quickview-content {
  background: #fff;
  padding: 15px;
  transition: all 0.5s ease-in-out;
}

.quickview-wrapper.open {
  visibility: visible;
  transform: translate(0, -50%);
  opacity: 1;
}

.quickview-wrapper.open .quickview-content {
  transform: translateY(0);
}

.quickview-slider .slider-for .slick-slide, .quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-for1 .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  outline: 0;
}

.quickview-slider .slider-for .slick-slide img, .quickview-slider .slider-nav .slick-slide img, .quickview-slider .slider-for1 .slick-slide img, .quickview-slider .slider-nav1 .slick-slide img {
  max-width: 100%;
}

.quickview-slider .slider-for, .quickview-slider .slider-for1 {
  margin-bottom: 15px;
}

.quickview-slider .slider-nav .slick-slide, .quickview-slider .slider-nav1 .slick-slide {
  cursor: pointer;
}

/* Product Single */
.product-tab-wrapper .product-tabs {
  margin: 50px 0 30px;
  list-style: none;
  text-align: center;
  border-top: 1px solid #ededed;
  text-align: center;
  display: inherit;
}

.product-tab-wrapper .product-tabs li {
  display: inline-block;
}

.product-tab-wrapper .product-tabs li a {
  padding: 10px 20px;
  text-decoration: none;
  color: #444;
  display: inline-block;
  transition: all 0.3s ease-in-out;
  border-top: 1px solid transparent;
  font-weight: 600;
}

.product-tab-wrapper .product-tabs li a:hover, .product-tab-wrapper .product-tabs li a.active {
  color: #e43a90;
  border-color: #e43a90;
}

.product-tab-wrapper .tab-content .tab-pane {
  padding: 0;
}

.comments-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.review-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.review-list .review-comment {
  margin-bottom: 20px;
}

.review-list .review-comment .user-avatar {
  height: 70px;
  width: 70px;
  float: left;
  border-radius: 50%;
  overflow: hidden;
}

.review-list .review-comment .user-avatar img {
  width: 100%;
  height: auto;
}

.review-list .review-comment .comment-content {
  margin-left: 90px;
}

.review-list .review-comment .comment-content .name {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}

.review-list .review-comment .comment-content .star-rating span:before {
  color: #e43a90;
}

.review-list .review-comment .comment-content .comment-meta {
  margin-bottom: 10px;
}

.review-list .review-comment .comment-content .comment-meta .star-rating li {
  font-size: 12px;
  margin-right: 0;
}

.review-list .review-comment .comment-content .comment-meta .post-date {
  font-size: 12px;
  color: #a7a7a7;
}

.rating-title {
  font-size: 22px;
  font-weight: 600;
}

.review-form textarea, .review-form input {
  border: 1px solid #c9c9c9;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  width: 100%;
  padding: 10px;
}

.review-form textarea {
  height: 150px;
}

.review-form input {
  height: 50px;
  margin-top: 20px;
}

.review-form .gp-btn {
  max-width: 250px;
  margin: 30px 0 0 15px;
  display: inline-block;
  width: 100%;
  text-align: center;
  background: transparent;
  color: #343434;
  border-radius: 4px;
  padding: 12px 0px;
  font-weight: 500;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid #c9c9c9;
}

.review-form .gp-btn:hover {
  background: #e43a90;
  color: #fff;
  border: 1px solid #e43a90;
}

.shop_attributes {
  width: 100%;
}

.shop_attributes tbody {
  border: 1px solid #dcdcdc;
}

.shop_attributes tbody tr {
  height: 40px;
  border-bottom: 1px solid #dcdcdc;
}

.shop_attributes tbody tr th {
  width: 30%;
  border-right: 1px solid #dcdcdc;
  padding-left: 20px;
}

.shop_attributes tbody tr td {
  padding-left: 20px;
}

.shop_attributes tbody tr td p {
  margin-bottom: 0;
}

.related-product {
  padding-top: 30px;
  margin-top: 30px;
  border-top: 1px solid #dcdcdc;
}

.related-product .product-title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  color: #363636;
}

/* Rating Star Widgets Style */
.rating {
  margin-bottom: 20px;
}

.rating li {
  cursor: default;
  display: inline-block;
  font-size: 20px;
  list-style-type: none;
}

.star,
.rating:not(.vote-cast):hover .star:hover ~ .star,
.rating.vote-cast .star.selected ~ .star {
  color: #a5a5a5;
}

.rating:hover .star,
.rating.vote-cast .star {
  color: #e43a90;
}

/* Color Checkbox */
.color-checkboxes {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.color-checkboxes h4 {
  margin-right: 10px;
  font-size: 18px;
  font-weight: 600;
}

.color-checkboxes .col-Blue-label {
  background: #2196f3;
}

.color-checkboxes .col-Green-label {
  background: #8bc34a;
}

.color-checkboxes .col-Yellow-label {
  background: #fdd835;
}

.color-checkboxes .col-Orange-label {
  background: #ff9800;
}

.color-checkboxes .col-Red-label {
  background: #f44336;
}

.color-checkboxes .col-Black-label {
  background: #222222;
}

.color-checkbox {
  width: 20px;
  height: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin-right: 7px;
  position: relative;
  border-radius: 16px;
  -webkit-transition: -webkit-transform 0.1s linear;
  transition: -webkit-transform 0.1s linear;
  transition: transform 0.1s linear;
  transition: transform 0.1s linear, -webkit-transform 0.1s linear;
  cursor: pointer;
}

.color-checkbox.bordered {
  -webkit-box-shadow: 0 0 0 2px #f0f0f0 inset;
  box-shadow: 0 0 0 2px #f0f0f0 inset;
}

.color-checkbox.bordered:after {
  border-color: black;
}

.color-checkbox:after {
  content: '';
  display: block;
  width: 6px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: 7px;
  border: 2px solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  opacity: 0;
  -webkit-transition: opacity 0.1s;
  -o-transition: opacity 0.1s;
  transition: opacity 0.1s;
  text-align: center;
}

.color-checkbox:hover,
.color-checkbox__input:focus + .color-checkbox {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.color-checkbox__input:checked + .color-checkbox:after {
  opacity: 1;
}

.color-checkbox__input {
  visibility: hidden;
  width: 0;
  pointer-events: none;
  position: absolute;
}

/* Product Size */
.options__item {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.options__item h4 {
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 0;
}

.options__item span {
  display: inline-block;
  height: 30px;
  width: 30px;
  border: 1px solid #363636;
  color: #363636;
  border-radius: 3px;
  text-align: center;
  left: 30px;
  margin-right: 5px;
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s ease-in-out;
}

.options__item span:hover, .options__item span.active {
  background: #e43a90;
  color: #fff;
  border-color: #e43a90;
}

/* Cart */
#cart {
  padding: 100px 0;
  background: #f7f7f7;
}

.woocommerce table.shop_table {
  margin-bottom: 60px;
  border: none;
  border-collapse: collapse;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.09);
  margin: 0 -1px 24px 0;
  text-align: left;
  width: 100%;
}

.woocommerce table.shop_table th {
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table td {
  padding: 6px 12px;
  border: 0;
  text-transform: uppercase;
  position: relative;
}

.woocommerce table.shop_table thead tr {
  background: #f7f7f7;
}

.woocommerce table.shop_table thead tr th {
  border-bottom: 1px solid #f7f7f7;
  background: #e9e9e9;
  font-size: 15px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  padding: 15px 30px;
  text-align: center;
}

.woocommerce table.shop_table tbody tr {
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.product-item a {
  color: #202020;
  text-decoration: none;
  font-weight: 600;
}

.woocommerce table.shop_table tbody tr td.product-item a.remove {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  background: #e9e9e9;
  transition: all 0.4s ease-in-out;
  position: absolute;
  left: 20px;
  top: 20px;
  border-radius: 100%;
  color: red !important;
  text-decoration: none;
  border: 0;
}

.woocommerce table.shop_table tbody tr td.product-item img {
  width: 70px;
  height: 70px;
  margin-left: 70px;
  margin-right: 15px;
  border-radius: 3px;
}

.woocommerce table.shop_table tbody tr td.product-price {
  line-height: 28px;
  letter-spacing: 0.1em;
  text-align: center;
  border: 1px solid #f7f7f7;
}

.woocommerce table.shop_table tbody tr td.actions {
  padding: 20px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon {
  float: left;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input {
  margin-bottom: 0;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.input-text {
  margin-right: 10px;
  max-width: 200px;
  padding: 6px 15px;
  border: 2px solid #f7f7f7;
  border-radius: 25px;
  font-size: 14px;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button {
  background: #333;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .coupon input.button:hover {
  background: #191919;
  color: #FFF;
}

.woocommerce table.shop_table tbody tr td.actions .button {
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap {
  padding: 10px 20px;
  background: #fff;
  border-radius: 15px;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul {
  margin: 0;
  padding: 0;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li {
  list-style: none;
  padding: 10px 0;
  border-bottom: 1px solid #f7f7f7;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li .title {
  display: inline-block;
  width: 59%;
  font-size: 12px;
  font-weight: 900;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

.woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Subtotal"], .woocommerce .cart-collaterals .cart_totals .grand-total .total-wrap > ul > li span[data-title="Total"] {
  display: inline-block;
  width: 39%;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 24px;
  letter-spacing: 0.1em;
  text-align: right;
}

.woocommerce .grand-total h4 {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.woocommerce .wc-proceed-to-checkout {
  padding-top: 20px;
}

.woocommerce .wc-proceed-to-checkout .gp-btn {
  padding: 12px 35px;
  color: #FFF;
  font-size: 14px;
  display: inline-block;
  margin: auto;
  float: none;
  text-align: center;
  border-radius: 25px;
  background: #e43a90;
  font-size: 14px;
  border: none;
  box-shadow: 0 15px 34px rgba(0, 0, 0, 0.18);
}

.text-active, .active > .text, .active > .auto .text {
  display: none !important;
}

.active > .text-active, .active > .auto .text-active {
  display: inline-block !important;
}

/* Product Responsive */
@media (min-width: 1200px) {
  .quickview-wrapper {
    min-height: 600px;
  }
}

@media (max-width: 1380px) {
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    max-width: 45%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    width: 55%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .add_to_cart_button {
    padding: 12px 20px;
  }
}

@media (max-width: 1280px) {
  .site-main .columns-4 ul.products li.product {
    width: 33.33%;
  }
  .site-main .columns-3 ul.products {
    width: 100%;
  }
  .site-main .columns-3 ul.products li.product {
    width: 50%;
    float: left;
  }
}

@media (max-width: 1024px) {
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    float: none;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    margin-left: 0;
  }
}

@media (max-width: 992px) {
  .site-main .columns-4 ul.products li.product {
    width: 50%;
    float: left;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .woocommerce-loop-product__title {
    margin-bottom: 5px;
    font-size: 22px;
    margin-bottom: 10px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content .price {
    margin-bottom: 5px;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content p {
    margin-bottom: 5px;
    margin-bottom: 15px;
  }
  .widget.widget-shop .widget-title-shop {
    font-size: 20px;
  }
  #range-slider p #amount {
    width: 104px;
  }
  .fil-btn {
    padding: 6px 12px;
    font-size: 15px;
  }
}

@media (max-width: 576px) {
  .quickview-wrapper {
    width: 60%;
  }
  .quickview-wrapper.open {
    transform: translate(0, 3%);
  }
  .product-details {
    margin-top: 20px;
  }
}

@media (max-width: 576px) {
  .single-product-details .product-title {
    margin-top: 20px;
  }
  ul.products li.product .product-thumb .add_to_cart_button {
    padding: 8px 11px;
  }
  .product-top-menu .product-menu-wrapper {
    width: auto;
    float: none;
  }
}

@media (max-width: 500px) {
  .site-main .columns-4 ul.products li.product {
    width: 100%;
  }
  .site-main .columns-3 ul.products {
    width: 100%;
  }
  .site-main .columns-3 ul.products li.product {
    width: 100%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two {
    display: inherit;
    width: 300px !important;
    margin: 0 auto 30px;
    float: none;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-thumb {
    max-width: 100%;
  }
  .site-main .columns-3 ul.tim-product-view--list li.product-two .product-details-content {
    width: 100%;
    padding: 30px 20px 35px;
  }
  .quickview-wrapper {
    width: 90%;
  }
  .product-top-menu span:first-child {
    display: none;
  }
  .product-top-menu #product-show {
    display: none;
  }
  .product-top-menu .product-select {
    width: 110px;
  }
  .shop.site-main {
    padding: 50px 0;
  }
  .color-checkboxes h4 {
    font-size: 16px;
  }
}

@media (min-width: 501px) and (max-width: 575px) {
  ul.products li.product .woocommerce-loop-product__title a {
    font-size: 21px;
  }
  ul.products li.product .price .woocommerce-Price-amount {
    font-size: 21px;
  }
  ul.products li.product .product-thumb .add_to_cart_button {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  #product.section-padding {
    padding-bottom: 0px;
  }
  .quickview-wrapper {
    top: 5%;
  }
  .single-product {
    padding: 50px 0 50px;
  }
  .woocommerce-product-rating.float-right {
    float: left !important;
    margin-bottom: 17px;
  }
  .single-product-details .product-title {
    font-size: 23px;
  }
  .single-product-details .price {
    margin-bottom: 27px;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .single-product-details .price ins, .single-product-details .price del {
    font-size: 20px;
    font-weight: 600;
  }
}

/*--------------------------------------------------------------
  ##   Contact
  --------------------------------------------------------------*/
.gmap3-area {
  height: 425px;
}

.sin-cont-info {
  text-align: center;
  color: #525252;
  height: 410px;
  box-shadow: 0px 3px 9px 0px rgba(108, 108, 108, 0.35);
}

.sin-cont-info i {
  font-size: 45px;
  color: #3a3a3a;
  padding-bottom: 6px;
  display: inline-block;
}

.sin-cont-info h3 {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.sin-cont-info p {
  font-size: 15px;
  font-weight: 700;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sin-cont-info a {
  font-size: 15px;
  font-weight: 400;
  color: #797878;
}

.no-pad {
  padding: 0px;
}

.center-wrap {
  margin: 0 auto;
}

.office-location {
  background: #2c2c2c;
  position: relative;
  z-index: 6;
}

.office-location h3 {
  color: #fff;
}

.office-location p {
  color: #fff;
}

.office-location a {
  color: #fff;
}

.sin-cont-info.office-location i {
  color: #fff;
}

.office-location::before {
  content: '\f041';
  position: absolute;
  left: 0;
  font-family: Fontawesome;
  font-size: 250px;
  top: 44%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -25%);
}

.con-bottom-inner h4 {
  font-size: 30px;
  color: #3c3c3c;
}

.con-bottom-inner .per-social ul li {
  display: inline;
  margin: 0 10px;
}

.con-bottom-inner h4 span {
  font-size: 30px;
  color: #f18636;
}

.con-bottom-inner {
  text-align: center;
}

.con-bottom-inner h4 {
  font-size: 30px;
  color: #3c3c3c;
  padding-bottom: 15px;
}

.con-bottom-inner .per-social {
  padding-bottom: 30px;
}

.con-bottom-inner .per-social li {
  margin: 0 10px;
}

.con-bottom-inner .per-social ul li a i {
  text-shadow: none;
}

.con-bottom-inner .per-social ul li a {
  color: #3c3c3c;
}

.con-bottom-inner .per-social ul li a:hover {
  color: #e43a90;
}

.con-page-form {
  padding-top: 32px;
  display: inline-block;
}

.con-page-form textarea {
  width: 100%;
  border: 1px solid #e7e6e6;
  border-radius: 4px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  padding: 15px;
  height: 180px;
}

.con-page-form input {
  width: 49%;
  float: left;
  border: 1px solid #e7e6e6;
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  border-radius: 4px;
  height: 55px;
  padding: 0px 20px;
}

.Stock {
  border-width: 1px;
  border-color: #e7e6e6;
  border-style: solid;
  border-radius: 12px;
  position: absolute;
  left: 550px;
  top: 1323px;
  width: 818px;
  height: 178px;
  z-index: 64;
}

.mar-r {
  margin-right: 2%;
}

.con-page-form input[type="submit"] {
  background-color: transparent;
  color: #363636;
  padding: 16px 40px;
  border-color: #e7e6e6;
  border-radius: 4px;
  cursor: pointer;
  width: auto;
  margin-top: 10px;
  text-transform: uppercase;
  margin-top: 30px;
  -webkit-box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  box-shadow: 0px 0px 2px 0px rgba(108, 108, 108, 0.25);
  height: 55px;
  line-height: 0;
}

.con-page-form input[type="submit"]:hover {
  color: #e43a90;
}

#googleMap {
  height: 500px;
}

@media only screen and (max-width: 575px) {
  .con-page-form input {
    width: 100%;
    margin-bottom: 20px;
  }
  .con-page-form input[type="submit"] {
    margin-top: 0;
  }
}

/*--------------------------------------------------------------
  ##   Footer
  --------------------------------------------------------------*/
#footer {
  background: #1b1b1b;
}

#footer .footer-inner {
  padding: 80px 0 40px;
}

.footer-about .footer-logo {
  max-width: 120px;
  margin-bottom: 30px;
  width: 100%;
  display: inline-block;
}

.footer-about .footer-logo img {
  width: 100%;
}

.footer-about > p {
  color: #c8c6c6;
  line-height: 25px;
  margin-bottom: 20px;
}

.footer-contact .contact-details i {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 26px;
}

.footer-contact .contact-details p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
}

.footer-title {
  color: #fff;
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-top: 20px;
}

/* Footer Latest Post */
.footer-blog-inner .footer-blog {
  border-bottom: 1px solid #4c4c4c;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.footer-blog-inner .footer-blog:last-child {
  border-bottom: 0;
}

.footer-blog-inner .footer-blog .fea-image {
  float: left;
  position: relative;
}

.footer-blog-inner .footer-blog .fea-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}

.footer-blog-inner .footer-blog .content {
  margin-left: 140px;
}

.footer-blog-inner .footer-blog .content h3 {
  font-size: 15px;
  line-height: 24px;
}

.footer-blog-inner .footer-blog .content h3 a {
  color: #fff;
}

.footer-blog-inner .footer-blog .content h3 a:hover {
  color: #e43a90;
}

.footer-blog-inner .footer-blog .content .meta {
  color: #b4b3b3;
}

.footer-blog-inner .footer-blog .content .meta:hover {
  color: #e43a90;
}

/* Tags */
.tagcloud a {
  display: inline-block;
  padding: 10px 28px;
  font-size: 16px;
  color: #fff;
  background: #636363;
  margin: 0 5px 10px 0;
  font-family: "Changa", sans-serif;
}

.tagcloud a:hover {
  background: #e43a90;
}

/* Widget Newsletter */
.footer-newsletter p {
  color: #fff;
}

.footer-newsletter #widget-newsletter {
  display: flex;
}

.footer-newsletter #widget-newsletter input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding: 10px 20px;
  outline: 0;
  border: 0;
}

.footer-newsletter #widget-newsletter input::placeholder {
  color: #afafaf;
}

.footer-newsletter #widget-newsletter .submit {
  background: #e43a90;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 0;
  padding: 0 17px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.footer-newsletter #widget-newsletter .submit:hover {
  background: #444;
}

/*  Instagram Widget */
.instagram-feed a {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 5px;
}

.instagram-feed a img {
  max-width: 100%;
}

/* Footer Social Link */
.footer-social-link {
  height: 60px;
}

.footer-social-link li {
  display: inline-block;
  width: 25%;
  background: #000;
  margin-left: -4px;
  text-align: center;
  padding: 16px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.footer-social-link li:before {
  position: absolute;
  content: '';
  height: 61px;
  width: 100%;
  top: -61px;
  background: #e43a90;
  z-index: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.footer-social-link li a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  z-index: 2;
}

.footer-social-link li.fb-bg {
  background: #4868ab;
}

.footer-social-link li.yotube-bg {
  background: #ff2500;
}

.footer-social-link li.tw-bg {
  background: #1da2f2;
}

.footer-social-link li.pin-bg {
  background: #bd1a1c;
}

.footer-social-link li:hover:before {
  top: 0;
}

.copyright-text {
  padding: 28px 0;
}

.copyright-text p {
  margin: 0;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.copyright-text p a {
  color: #e43a90;
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .tagcloud a {
    padding: 10px 23px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 85%;
  }
}

@media (max-width: 576px) {
  .footer-social-link li {
    padding: 12px 0;
  }
  .footer-social-link li a {
    font-size: 16px;
  }
  .copyright-text p {
    text-align: center;
  }
  #footer .footer-inner {
    padding: 50px 0 40px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 83%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .instagram-feed a {
    max-width: 107px;
  }
  .tagcloud a {
    padding: 10px 20px;
  }
}

/*--------------------------------------------------------------
  ##   Footer Two
  --------------------------------------------------------------*/
#footer-2 {
  background: #030825;
}

#footer-2 .footer-inner {
  padding: 80px 0 40px;
}

#footer-2 .footer-title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 70px;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

#footer-2 .footer-title:after {
  content: '';
  position: absolute;
  left: 32px;
  top: -5px;
  width: 39px;
  height: 39px;
  background-image: url(../../media/blog/square.png);
  background-repeat: no-repeat;
  z-index: -1;
}

#footer-2 .footer-about .footer-logo {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 66px;
  margin-top: 17px;
}

#footer-2 .footer-about .footer-logo img {
  width: 100%;
}

#footer-2 .footer-about > p {
  color: #c8c6c6;
  line-height: 25px;
  margin-bottom: 25px;
}

#footer-2 .footer-contact .contact-details i {
  float: left;
  width: 30px;
  height: 40px;
  line-height: 26px;
}

#footer-2 .footer-contact .contact-details p {
  color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
  margin-left: 30px;
}

#footer-2 h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 22px;
}

/* Footer Latest Post */
#footer-2 .footer-blog-inner .footer-blog {
  border-bottom: 1px solid #151a35;
  margin-bottom: 20px;
  padding-bottom: 40px;
}

#footer-2 .footer-blog-inner .footer-blog:last-child {
  border-bottom: 0;
}

#footer-2 .footer-blog-inner .footer-blog .fea-image {
  float: left;
  position: relative;
}

#footer-2 .footer-blog-inner .footer-blog .fea-image i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  color: #fff;
}

#footer-2 .footer-blog-inner .footer-blog .content {
  margin-left: 116px;
  padding-top: 5px;
}

#footer-2 .footer-blog-inner .footer-blog .content h3 {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
}

#footer-2 .footer-blog-inner .footer-blog .content h3 a {
  color: #fff;
  font-size: 16px;
}

#footer-2 .footer-blog-inner .footer-blog .content h3 a:hover {
  color: #e43a90;
}

#footer-2 .footer-blog-inner .footer-blog .content .meta {
  color: #e43a90;
  font-size: 14px;
}

#footer-2 .footer-blog-inner .footer-blog .content .meta:hover {
  color: #e43a90;
}

#footer-2 .footer-menu ul li {
  list-style: none;
  padding-bottom: 10px;
  border-bottom: 1px solid #151a35;
  margin-bottom: 19px;
  position: relative;
  padding-left: 17px;
}

#footer-2 .footer-menu ul li:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 4px 0 4px 6px;
  border-color: transparent transparent transparent #f84196;
  left: 0;
  top: 9px;
}

#footer-2 .footer-menu ul li a {
  font-size: 16px;
  color: #fff;
}

/* Tags */
#footer-2 .tagcloud a {
  display: inline-block;
  padding: 6px 28px;
  font-size: 16px;
  color: #fff;
  background: transparent;
  margin: 0 5px 10px 0;
  font-family: "Changa", sans-serif;
  border: 2px solid #262969;
  border-radius: 5px;
}

#footer-2 .tagcloud a:hover {
  background: #e43a90;
  border: 2px solid #e43a90;
}

/* Widget Newsletter */
#footer-2 .footer-newsletter p {
  color: #fff;
}

#footer-2 .footer-newsletter #widget-newsletter {
  display: flex;
}

#footer-2 .footer-newsletter #widget-newsletter input {
  width: 100%;
  height: 45px;
  background: #fff;
  padding: 10px 20px;
  outline: 0;
  border: 0;
}

#footer-2 .footer-newsletter #widget-newsletter input::placeholder {
  color: #afafaf;
}

#footer-2 .footer-newsletter #widget-newsletter .submit {
  background: #e43a90;
  height: 45px;
  line-height: 45px;
  text-align: center;
  border: 0;
  padding: 0 17px;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

#footer-2 .footer-newsletter #widget-newsletter .submit:hover {
  background: #444;
}

/*  Instagram Widget */
#footer-2 .instagram-feed a {
  max-width: 120px;
  width: 100%;
  display: inline-block;
  margin-bottom: 10px;
  padding-right: 5px;
}

#footer-2 .instagram-feed a img {
  max-width: 100%;
}

/* Footer Social Link */
#footer-2 .footer-social-link {
  height: 60px;
}

#footer-2 .footer-social-link li {
  display: inline-block;
  width: 25%;
  background: #000;
  margin-left: -4px;
  text-align: center;
  padding: 16px 0;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

#footer-2 .footer-social-link li:before {
  position: absolute;
  content: '';
  height: 61px;
  width: 100%;
  top: -61px;
  background: #e43a90;
  z-index: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

#footer-2 .footer-social-link li a {
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  position: relative;
  z-index: 2;
}

#footer-2 .footer-social-link li.fb-bg {
  background: #4868ab;
}

#footer-2 .footer-social-link li.yotube-bg {
  background: #ff2500;
}

#footer-2 .footer-social-link li.tw-bg {
  background: #1da2f2;
}

#footer-2 .footer-social-link li.pin-bg {
  background: #bd1a1c;
}

#footer-2 .footer-social-link li:hover:before {
  top: 0;
}

#footer-2 .copyright-text {
  padding: 28px 0;
  background: #000310;
}

#footer-2 .copyright-text p {
  margin: 0;
  color: #fff;
  font-family: "Changa", sans-serif;
  padding-top: 6px;
}

#footer-2 .copyright-text p a {
  color: #e43a90;
}

#footer-2 .footer-social-2 ul {
  float: right;
}

#footer-2 .footer-social-2 ul li {
  display: inline;
  margin-left: 5px;
}

#footer-2 .footer-social-2 ul li a {
  color: #fff;
  position: relative;
  background: url(../../media/blog/square.png);
  background-repeat: no-repeat;
  width: 36px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 41px;
  background-position: 5px center;
}

#footer-2 .footer-social-2 ul li:after {
  position: absolute;
  left: 0;
  top: 0;
}

@media (min-width: 1200px) and (max-width: 1450px) {
  .tagcloud a {
    padding: 10px 23px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 85%;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  #footer-2 .tagcloud a {
    padding: 5px 16px;
  }
}

@media (max-width: 576px) {
  .footer-social-link li {
    padding: 12px 0;
  }
  .footer-social-link li a {
    font-size: 16px;
  }
  .copyright-text p {
    text-align: center;
  }
  #footer .footer-inner {
    padding: 50px 0 40px;
  }
  .footer-newsletter #widget-newsletter input {
    width: 83%;
  }
  #footer-2 .footer-social-2 ul {
    float: none;
    text-align: center;
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .instagram-feed a {
    max-width: 107px;
  }
  .tagcloud a {
    padding: 10px 20px;
  }
}

/*--------------------------------------------------------------
  ##   Footer Three
  --------------------------------------------------------------*/
#footer-3 {
  background-image: url(../../media/background/footer-bg.jpg);
  background-size: 100%;
  background-postion: center;
  padding: 260px 0 180px;
  background-repeat: no-repeat;
  background-size: cover;
}

#footer-3 .section-title.style-four {
  margin-bottom: 20px;
}

#footer-3 .section-title.style-four h2 {
  color: #fff;
}

.footer-feed {
  padding-bottom: 40px;
  overflow: hidden;
  border-bottom: 1px solid #000;
}

.footer-feed ul li {
  float: left;
  width: 12.5%;
  position: relative;
}

.footer-feed ul li a:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .5;
  top: 0;
  left: 0;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.footer-feed ul li a:hover:before {
  opacity: 0;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.footer-three-bottom {
  padding-top: 50px;
  border-top: 1px solid #1e1e1e;
}

.footer-three-bottom .footer-three-left {
  width: 60%;
  float: left;
}

.footer-three-bottom .footer-three-left > a {
  padding-bottom: 30px;
  display: inline-block;
}

.footer-three-bottom .footer-three-left p {
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.56;
  font-weight: 300;
  color: #6d6d6d;
}

.footer-three-bottom .footer-three-right {
  float: right;
  width: 40%;
  text-align: right;
}

.footer-three-bottom .footer-social-three ul li {
  display: inline-block;
  margin-left: 11px;
}

.footer-three-bottom .footer-social-three ul li a {
  color: #5e5e5e;
  font-size: 14px;
}

.footer-three-bottom .footer-social-three ul li a:hover {
  color: #f60038;
}

.footer-three-bottom .footer-three-menu {
  padding-bottom: 20px;
}

.footer-three-bottom .footer-three-menu li {
  line-height: 22px;
}

.footer-three-bottom .footer-three-menu li a {
  color: #f60038;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2.8px;
  text-decoration: underline;
  font-family: "Changa", sans-serif;
  text-transform: uppercase;
}

.footer-three-bottom .footer-three-menu li a:hover {
  color: #fff;
}

@media (min-width: 576px) and (max-width: 991px) {
  #footer-3 {
    padding: 150px 0 80px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  #footer-3 {
    padding: 150px 0 80px;
  }
  .footer-three-bottom .footer-three-right {
    width: 100%;
    text-align: left;
  }
  .footer-three-bottom .footer-three-left {
    width: 100%;
  }
}

/*--------------------------------------------------------------
  ##  Magazine
  --------------------------------------------------------------*/
.single-social-widget {
  width: 100%;
  background: #242e8a;
  height: 70px;
  margin-bottom: 10px;
}

.single-social-widget a {
  float: left;
  background: #19227a;
  color: #fff;
  display: block;
  width: 60px;
  height: 70px;
  text-align: center;
  line-height: 70px;
  font-size: 27px;
}

.single-social-widget p {
  color: #fff;
  margin: 0;
  padding-left: 80px;
  padding-top: 17px;
  font-family: "Changa", sans-serif;
  font-size: 16px;
  line-height: 16px;
}

.single-social-widget span {
  color: #fff;
  margin: 0;
  padding-left: 81px;
  display: block;
  font-family: "Changa", sans-serif;
  font-size: 16px;
}

.single-social-widget p:first-child {
  padding-top: 10px;
}

.facebook-widget {
  background: #242e8a;
}

.facebook-widget a {
  background: #19227a;
}

.twitter-widget {
  background: #2196f0;
}

.twitter-widget a {
  background: #1487df;
}

.pint-widget {
  background: #cf0f21;
}

.pint-widget a {
  background: #b30919;
}

.magazine-post-widget > a {
  float: left;
}

.mag-post-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.mag-post-content h2 a {
  font-size: 18px;
  color: #363636;
}

.magazine-post-widget {
  overflow: hidden;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
}

.mag-post-content h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.mag-post-content span {
  line-height: 17px;
  padding: 5px 20px;
}

.mag-post-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.mag-show-content h2 {
  line-height: 17px;
  padding: 5px 20px;
  margin-bottom: 0;
}

.mag-show-content span {
  line-height: 16px;
  padding: 5px 20px;
  font-size: 16px;
}

.mag-show-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.mag-show-content > a {
  color: #dc9c0a;
  float: right;
  margin-right: 50px;
}

.mag-show-content h2 a {
  font-size: 20px;
  color: #363636;
}

.magazine-show-widget {
  margin-bottom: 20px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
}

.mag-show-widget .magazine-show-widget:last-child {
  margin-bottom: 0px;
}

.magazine-tab-area .magazine-show-widget {
  margin-bottom: 30px;
}

.mag-show-content {
  background: #fff;
  padding: 18px 0 27px;
}

.magazine-widget .mag-bn::before {
  background: #313130;
}

.magazine-widget .mag-bn p {
  color: #fff;
  font-weight: 700;
}

.magazine-sidebar {
  padding-top: 60px;
}

.magazine-widget .mag-bn {
  margin-bottom: 30px;
}

.magazine-widget {
  margin-bottom: 60px;
}

.mag-tag-widget ul li {
  position: relative;
  width: auto;
  z-index: 1;
  text-align: center;
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 15px;
}

.mag-tag-widget ul li:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  transform: skewX(-20deg);
  background: #313130;
  z-index: -1;
}

.mag-tag-widget ul li a {
  color: #fff;
  padding: 12px 35px;
  display: block;
  text-transform: uppercase;
  font-weight: 500;
}

.mag-tag-widget ul li:hover::before {
  background: #ddb013;
  transition: all 0.5s ease;
}

.mag-twitter-widget .single-twitter {
  border-bottom: 1px dashed #ddd;
  margin-top: 1rem;
}

.mag-twitter-widget .single-twitter p {
  font-size: 16px;
  color: #525252;
  margin-top: 10px;
}

.mag-twitter-widget .single-twitter .twt-top i {
  color: #313130;
  font-size: 27px;
  margin-right: 18px;
  margin-top: -1px;
  float: left;
  font-style: italic;
}

.mag-twitter-widget .single-twitter .twt-top span {
  color: #ddb013;
}

.magazine-blog {
  padding-top: 60px;
}

.magazine-blog .mag-bn p {
  color: #fff;
  font-weight: 700;
}

.magazine-post {
  position: relative;
  z-index: 1;
  margin-bottom: 30px;
  overflow: hidden;
}

.magazine-post:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.magazine-post img {
  transition: all 0.3s ease-in-out;
}

.magazine-show-widget a {
  display: block;
  overflow: hidden;
}

.magazine-show-widget:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.magazine-show-widget img {
  transition: all 0.3s ease-in-out;
}

.mags-post-content {
  position: absolute;
  bottom: 6px;
  left: 24px;
  z-index: 999;
}

.mags-post-content h4 a {
  color: #fff;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 42px;
  padding-right: 105px;
  display: inline-block;
}

.mags-post-content > a {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  background: #da9a0a;
  border-radius: 3px;
  padding: 8px 18px;
  display: inline-block;
  margin-bottom: 13px;
  font-family: "Changa", sans-serif;
}

.mag-post-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.mags-post-content p {
  font-size: 16px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.mags-post-content p a {
  color: #ddb013;
}

.mid .mags-post-content h4 a {
  font-size: 24px;
  line-height: 32px;
  padding-right: 0;
}

.mid .mags-post-content > a {
  font-size: 15px;
  margin-bottom: 7px;
  padding: 5px 13px;
}

.mid .mags-post-content h4 {
  margin-bottom: 0;
}

.magazine-post.mid:first-child {
  margin-bottom: 20px;
}

.magazine-post-small {
  overflow: hidden;
  margin-bottom: 22px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
}

.magazine-post-small:last-child {
  margin-bottom: 0;
}

.magazine-post-small > a {
  float: left;
}

.mag-post-content-small {
  position: relative;
  display: block;
  overflow: hidden;
}

.mag-post-content-small h2 a {
  font-size: 18px;
  color: #363636;
}

.mag-post-content-small h2 a:hover {
  color: #da9a0a;
}

.mag-post-content-small h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.mag-post-content-small span {
  line-height: 17px;
  padding: 5px 20px;
}

.mag-post-content-small span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-blog .mag-bn {
  margin-bottom: 30px;
}

.magazine-tab-area .mag-bn {
  float: left;
  margin-bottom: 0;
}

.magazine-tab-btn {
  float: right;
  margin-top: 7px;
}

.magazine-tab-btn .nav-item a {
  font-size: 18px;
  color: #474646;
  font-family: "Changa", sans-serif;
}

.magazine-tab-btn .nav-item a:hover, .magazine-tab-btn .nav-item a.active, .mag-post-content h2 a:hover, .mag-show-content h2 a:hover,
.magazine-post-small-video .mag-post-content-small h2 a:hover, .single-concert .sin-concert-content h4 a:hover, .magazine-next-cons-content h2 a:hover,
.mag-hot-post h2 a:hover, .concer-sin-content h2 a:hover {
  color: #da9a0a;
}

.magazine-tab-area .tab-content .tab-pane {
  padding: 30px 15px 0;
}

.magazine-artist-info .aritist-music .icon {
  float: none;
  margin-right: 0;
  text-align: center;
}

.magazine-artist-info .aritist-music .content {
  margin-left: 0;
  text-align: center;
}

.magazine-artist-info .aritist-music .icon i {
  color: #fff;
  font-size: 40px;
  line-height: 50px;
}

.magazine-artist-info .aritist-music .content p {
  color: #fff;
  font-size: 30px;
  margin: 0;
  font-weight: 600;
  font-family: "Changa", sans-serif;
}

.magazine-artist-info .aritist-music .content span {
  font-size: 20px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.magazine-artist-info {
  position: absolute;
  top: 0;
  height: 100%;
  background: rgba(58, 57, 58, 0.67);
  width: 102px;
  z-index: 6;
  padding-top: 20px;
}

.magazine-artist {
  position: relative;
  margin-bottom: 30px;
}

.magazine-artist::before {
  position: absolute;
  content: '';
  height: 100%;
  width: 100%;
  background: rgba(216, 198, 0, 0.4);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.magazine-artist:hover:before {
  opacity: 1;
}

.mag-artist-content {
  position: absolute;
  left: 0;
  bottom: 31px;
  left: 131px;
  z-index: 6;
}

.mag-artist-content p {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
  margin-bottom: 0;
}

.mag-artist-content span {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.mag-gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#000), to(transparent));
  background: -o-linear-gradient(top, #000, transparent);
  background: linear-gradient(to bottom, #000, transparent);
  background: -webkit-linear-gradient(bottom, #000, transparent);
  opacity: 0.7;
  z-index: 1;
  top: 0;
}

.aritist-music {
  margin-bottom: 20px;
}

.magazine-artist:hover .magazine-artist-info {
  background: rgba(220, 156, 10, 0.67);
  transition: all 0.5s ease;
}

.magazine-video {
  position: relative;
  margin-bottom: 30px;
}

.magazine-video .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #d21d20;
  font-size: 96px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}

.magazine-video .popup-video-btn::after {
  content: '';
  background: #fff;
  width: 46px;
  height: 46px;
  top: 50%;
  left: 50%;
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.magazine-post-small-video {
  overflow: hidden;
  margin-bottom: 18px;
  box-shadow: 0px 6px 20px 0px rgba(83, 83, 83, 0.11);
  position: relative;
}

.magazine-post-small-video:last-child {
  margin-bottom: 0;
}

.magazine-post-small-video > img {
  float: left;
}

.magazine-post-small-video .mag-post-content-small {
  position: relative;
  display: block;
  overflow: hidden;
}

.magazine-post-small-video .mag-post-content-small h2 a {
  font-size: 18px;
  color: #363636;
}

.magazine-post-small-video .mag-post-content-small h2 {
  line-height: 17px;
  padding: 5px 0 5px 20px;
  margin-bottom: 0;
}

.magazine-post-small-video .mag-post-content-small span {
  line-height: 17px;
  padding: 5px 20px;
}

.magazine-post-small-video .mag-post-content-small span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-post-small-video .popup-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  color: #d21d20;
  font-size: 25px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  outline: 0;
}

.mag-post-vid-img {
  float: left;
  position: relative;
}

.magazine-post-small-video .popup-video-btn::after {
  content: '';
  background: #fff;
  width: 10px;
  height: 13px;
  top: 8px;
  left: 9px;
  position: absolute;
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.single-concert {
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
}

.single-concert > a.tag {
  position: absolute;
  top: 25px;
  left: 25px;
  background: #dc9c0a;
  border-radius: 3px;
  padding: 3px 10px;
  color: #fff;
}

.single-concert .sin-concert-content span {
  color: #707070;
  font-size: 16px;
  font-family: "Changa", sans-serif;
}

.single-concert .sin-concert-content span a {
  color: #ddb80a;
}

.single-concert .sin-concert-content h4 a {
  font-family: "Changa", sans-serif;
  font-size: 22px;
  color: #353535;
}

.single-concert .sin-concert-content p {
  font-size: 14px;
  color: #363636;
}

.single-concert a.imag {
  display: inline-block;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.single-concert a.imag img {
  transition: all 0.3s ease-in-out;
}

.single-concert:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.sin-concert-content {
  padding: 30px 15px 30px 25px;
}

.area-wrap {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.concert-single img {
  float: left;
}

.concer-sin-content {
  position: relative;
  display: block;
  overflow: hidden;
}

.concer-sin-content h2 a {
  font-size: 18px;
  color: #363636;
  font-family: "Changa", sans-serif;
}

.concert-single {
  overflow: hidden;
  padding-bottom: 20px;
  padding: 27px 15px 27px 25px;
  border-top: 1px dashed #ddd;
}

.concer-sin-content h2 {
  line-height: 17px;
  padding: 0 0 0 20px;
  margin-bottom: 0;
}

.concer-sin-content span {
  line-height: 17px;
  padding: 5px 20px;
  font-size: 14px;
  color: #ddb80a;
}

.concer-sin-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.concer-sin-content > a {
  background: #8a2467;
  color: #fff;
  padding: 3px 11px;
  border-radius: 5px;
  margin-top: 4px;
  display: inline;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 18px;
}

.magazine-next-cons-content h2 {
  line-height: 17px;
  padding: 5px 20px;
  margin-bottom: 0;
}

.magazine-next-cons-content span {
  line-height: 16px;
  padding: 5px 20px;
  font-size: 16px;
}

.magazine-next-cons-content span i {
  color: #dc9c0a;
  font-size: 14px;
  margin-right: 5px;
}

.magazine-next-cons-content > a {
  color: #dc9c0a;
  float: right;
  margin-right: 50px;
}

.magazine-next-cons-content h2 a {
  font-size: 20px;
  color: #363636;
}

.magazine-next-cons-content {
  background: #fff;
  padding: 12px 0 12px;
}

.magazine-next-cons > a {
  display: inline-block;
  overflow: hidden;
}

.magazine-next-cons img {
  transition: all 0.3s ease-in-out;
}

.magazine-next-cons:hover img {
  transform: scale(1.1);
  transition: all 0.3s ease-in-out;
}

.mag-hot-post-long {
  box-shadow: 0px 6px 21px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  margin-bottom: 30px;
}

.mag-hot-post-long img {
  float: left;
  width: 50%;
}

.mag-hot-post-long.img-fullwidth > img {
  width: 100%;
}

.mag-hot-post .mhp-icon i {
  color: #fff;
  background: #2a2a2a;
  padding: 7px;
  border-radius: 51px;
  width: 32px;
  height: 32px;
  text-align: center;
  padding-left: 11px;
}

.mag-hot-post .mhp-icon > a {
  background: #dc9c0a;
  color: #fff;
  padding: 3px 11px;
  border-radius: 5px;
  margin-top: 4px;
  display: inline;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 10px;
}

.mag-hot-post h2 {
  margin-top: 20px;
  line-height: 32px;
}

.mag-hot-post h2 a {
  font-size: 22px;
  color: #363636;
  font-family: "Changa", sans-serif;
  line-height: 32px;
}

.mag-hot-post p {
  font-size: 16px;
  color: #363636;
  font-family: "Changa", sans-serif;
}

.mag-hot-post .mhp-footer img {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  margin-right: 20px;
}

.mag-hot-post .mhp-footer span {
  font-size: 16px;
  color: #696969;
  font-family: "Changa", sans-serif;
}

.mag-hot-post .mhp-footer i {
  color: #ddb80a;
  background: none;
}

.mag-hot-post {
  overflow: hidden;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 15px;
}

.mhp-footer {
  border-top: 1px dashed #ddd;
  padding-top: 24px;
  margin-right: 30px;
  padding-bottom: 23px;
}

.bg-two {
  background: #e43a90 !important;
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .magazine-post-widget > a {
    float: left;
    width: 84px;
  }
  .mag-post-content h2 {
    line-height: 15px;
    padding: 0px 0 5px 20px;
  }
  .magazine-widget .mag-bn p {
    color: #fff;
    font-weight: 700;
    font-size: 18px;
  }
  .mag-tag-widget ul li a {
    color: #fff;
    padding: 4px 11px;
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }
  .hp-content p a {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
  }
  .mag-show-content h2 a {
    font-size: 16px;
  }
  .mag-show-content {
    padding: 9px 0 17px;
  }
  .mag-show-content span {
    font-size: 14px;
  }
  .mags-post-content > a {
    font-size: 16px;
    padding: 2px 8px;
  }
  .mags-post-content h4 a {
    font-size: 20px;
    line-height: 28px;
    padding-right: 50px;
  }
  .magazine-post.mid .mags-post-content {
    left: 10px;
  }
  .mid .mags-post-content > a {
    font-size: 15px;
    padding: 4px 10px;
  }
  .mid .mags-post-content h4 a {
    font-size: 18px;
    line-height: 28px;
  }
  .magazine-post-small > a {
    display: none;
  }
  .mag-post-content-small h2 a {
    font-size: 16px;
  }
  .tab-content .mag-show-content > a {
    margin-right: 18px;
  }
  .tab-content .mag-show-content h2 {
    padding: 5px 8px;
  }
  .tab-content .mag-show-content span {
    padding: 5px 9px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .mag-artist-content {
    left: 115px;
  }
  .mag-post-vid-img {
    display: none;
  }
  .magazine-post-small-video .mag-post-content-small {
    padding-bottom: 4px;
  }
  .concert-single img {
    display: none;
  }
  .single-concert .sin-concert-content span {
    font-size: 13px;
  }
  .single-concert .sin-concert-content h4 a {
    font-size: 16px;
    line-height: 16px;
  }
  .sin-concert-content {
    padding: 14px 10px 0px 15px;
  }
  .concert-single {
    padding: 13px 12px 10px 1px;
  }
  .concer-sin-content > a {
    padding: 2px 8px;
    font-size: 12px;
  }
  .magazine-next-cons-content h2 a {
    font-size: 16px;
  }
  .magazine-next-cons-content span {
    font-size: 14px;
  }
  .concer-sin-content h2 {
    line-height: 16px;
  }
  .concer-sin-content h2 a {
    font-size: 16px;
  }
  .magazine-next-cons {
    margin-bottom: 20px;
  }
  .magazine-post-small {
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
  .magazine-post-small-video .mag-post-content-small h2 a {
    font-size: 16px;
  }
  .mag-hot-post-long.big-one img {
    display: none;
  }
  .mag-hot-post h2 {
    margin-top: 10px;
    line-height: 1.2rem;
  }
  .mag-hot-post h2 a {
    font-size: 16px;
    line-height: 16px;
  }
  .mag-hot-post .mhp-icon i {
    padding: 4px;
    width: 21px;
    height: 21px;
    padding-left: 7px;
    font-size: 13px;
  }
  .mag-hot-post .mhp-footer img {
    display: none;
  }
  .mhp-footer {
    padding-top: 10px;
    padding-bottom: 15px;
  }
  .mag-post-content h2 a {
    font-size: 16px;
  }
  .mag-twitter-widget .single-twitter p {
    font-size: 14px;
    line-height: 26px;
  }
  .magazine-widget {
    margin-bottom: 30px;
  }
  .magazine-post-small-video .mag-post-content-small span {
    padding: 5px 14px;
    font-size: 14px;
  }
  .magazine-post-small-video .mag-post-content-small h2 {
    padding: 5px 0 5px 12px;
  }
  .mag-post-content-small span {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mheader-top p {
    padding-top: 0px;
    width: 60%;
    line-height: 21px;
  }
  .hp-content p {
    line-height: 20px;
  }
  .hp-content p a {
    font-size: 15px;
  }
  .hp-content a {
    font-size: 14px;
  }
  .hp-content {
    top: 50px;
  }
  .magazine-post-small img {
    width: 93px;
  }
  .mag-show-content > a {
    margin-right: 20px;
  }
  .mag-show-content h2 a {
    font-size: 16px;
  }
  .mag-show-content span {
    font-size: 14px;
  }
  .mag-show-content {
    padding: 5px 0 10px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .mag-bn p {
    font-size: 20px;
  }
  .mag-bn {
    padding: 8px 33px;
  }
  .magazine-post-small-video:last-child {
    display: none;
  }
  .mag-post-vid-img img {
    width: 102px;
  }
  .concert-single img {
    display: none;
  }
  .single-concert .sin-concert-content span {
    font-size: 14px;
  }
  .single-concert .sin-concert-content h4 a {
    font-size: 17px;
  }
  .concert-single {
    padding: 13px 15px 8px 0px;
  }
  .sin-concert-content {
    padding: 14px 15px 0px 20px;
  }
  .magazine-next-cons-content span {
    font-size: 14px;
  }
  .magazine-next-cons-content h2 a {
    font-size: 17px;
  }
  .magazine-widget {
    width: 50%;
    float: left;
    padding-right: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .mheader-top p {
    font-size: 13px;
    width: 50%;
    margin-top: -8px;
    padding-top: 0;
  }
  .headr-post {
    width: 50%;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 30px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 26px;
  }
  .aritist-music {
    margin-bottom: 0;
  }
  .magazine-artist-info {
    padding-top: 10px;
  }
  .magazine-next-cons {
    width: 50%;
    float: left;
    padding: 15px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .magazine-widget {
    width: 50%;
    float: left;
    padding-right: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .mheader-top p {
    font-size: 14px;
    width: 72%;
    line-height: 24px;
  }
  .mheader-top span {
    padding: 3px 7px;
    border-radius: 5px 0px 0px 5px;
    margin-right: 11px;
    margin-top: 7px;
  }
  .mheader-top .mag-social {
    float: none;
    text-align: center;
    margin-top: 14px;
    display: inline-block;
    width: 100%;
  }
  .headr-post {
    width: 50%;
  }
  .hp-content p a {
    font-size: 16px;
  }
  .hp-content p {
    line-height: 24px;
  }
  .hp-content {
    top: 85px;
  }
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .magazine-artist-info {
    padding-top: 10px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .mag-hot-post-long img {
    float: none;
    width: 100%;
  }
  .mag-show-widget .magazine-show-widget:last-child {
    margin-bottom: 30px;
  }
  .magazine-sidebar .magazine-show-widget {
    width: 45%;
    margin: 0 2%;
    margin-bottom: 30px;
    float: left;
  }
  .magazine-next-cons {
    width: 50%;
    float: left;
    padding: 0 15px;
    margin-bottom: 15px;
  }
  .magazine-post-small:last-child {
    margin-bottom: 22px;
  }
  .magazine-post.mid {
    width: 50%;
    float: left;
  }
}

@media (max-width: 575px) {
  .magazine-artist-info .aritist-music .content span {
    font-size: 16px;
  }
  .magazine-artist-info .aritist-music .content p {
    font-size: 22px;
    line-height: 20px;
  }
  .magazine-artist-info .aritist-music .icon i {
    font-size: 17px;
    line-height: 12px;
  }
  .aritist-music {
    margin-bottom: 4px;
  }
  .magazine-artist-info {
    width: 80px;
  }
  .mag-artist-content {
    left: 90px;
  }
  .magazine-artist-info {
    padding-top: 35px;
  }
  .headr-post {
    width: 50%;
  }
  .hp-content p a {
    font-size: 16px;
  }
  .hp-content p {
    line-height: 24px;
  }
  .hp-content {
    top: 75px;
  }
  .magazine-post-small:last-child {
    margin-bottom: 30px;
  }
  .magazine-post-small-video:last-child {
    margin-bottom: 30px;
  }
  .mag-hot-post-long img {
    float: none;
    width: 100%;
  }
  .mag-show-content h2 {
    padding: 5px 11px;
  }
  .mag-show-content span {
    font-size: 13px;
    padding: 5px 11px;
  }
  .mag-show-content > a {
    margin-right: 23px;
  }
  .mag-header-inner h2 {
    font-size: 45px;
    line-height: 50px;
    margin-top: 9px;
  }
}

@media (min-width: 420px) and (max-width: 575px) {
  .magazine-next-cons {
    width: 80%;
    margin: 0 auto;
  }
  .mags-post-content {
    bottom: 0px;
    left: 13px;
  }
  .concert-single img {
    width: 20%;
  }
}

@media (max-width: 460px) {
  .hp-content {
    top: 7px;
  }
}

/*--------------------------------------------------------------
  ##  Latest release section
  --------------------------------------------------------------*/
.latest-relese {
  background-image: url(../../media/background/3.png);
  background-postion: top;
  background-size: 100%;
  background-repeat: no-repeat;
}

.latest-relese .section-title {
  margin-bottom: 95px;
}

.latest-album-left .cover-img {
  float: left;
  box-shadow: 0px 50px 108.9px 12.1px black;
}

.latest-album-left .albun-details {
  overflow: hidden;
  padding-left: 30px;
}

.latest-album-left .albun-details h6 {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 26px;
  line-height: 11px;
}

.latest-album-left .albun-details p {
  font-size: 14px;
  color: #565656;
  font-family: "Roboto", sans-serif;
  line-height: 21px;
}

.latest-album-left .albun-details p span {
  color: #f60038;
}

.latest-album-right {
  padding-left: 128px;
}

.latest-album-right h6 {
  font-size: 20px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 26px;
  line-height: 11px;
}

.latest-album-btn .sm2_button {
  color: #f60038;
  border: 2px solid #f60038;
  width: 58px;
  height: 58px;
  display: block;
  text-align: center;
  line-height: 60px;
  border-radius: 3px;
}

.latest-album-btn .sm2_button i:after {
  font-size: 27px;
}

.latest-album-btn .sm2_button:hover {
  color: #fff;
}

.player-main {
  text-align: center;
}

.record-player {
  position: relative;
  margin-top: 70px;
  width: 691px;
  margin: 0 auto;
  margin-top: 60px;
}

.record-player .player-main-shade {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  height: 691px;
  width: 691px;
  -webkit-box-shadow: 15px 13px 17px 5px rgba(0, 0, 0, 0.48);
  -moz-box-shadow: 15px 13px 17px 5px rgba(0, 0, 0, 0.48);
  box-shadow: 15px 13px 17px 5px rgba(0, 0, 0, 0.48);
  border-radius: 100%;
  box-shadow: 0px 17px 36.86px 1.14px rgba(0, 0, 0, 0.45);
}

.record-player .record-key {
  position: absolute;
  top: -24px;
  right: -189px;
}

@keyframes color_change {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.player-main {
  animation: color_change 5s linear infinite;
  transition: all 2s;
  animation-play-state: paused;
}

.player-main img {
  transform: rotate(0deg);
  transition: all 2s;
}

.player-main.active {
  animation-play-state: running;
}

.record-player .record-key {
  top: -24px;
  right: -189px;
  transform: rotate(0deg);
  transform-origin: 149px 147px;
  transition: all 1s;
}

.record-player .record-key.active {
  transform: rotate(15deg);
  transform-origin: 149px 147px;
  transition: all 1s;
}

@keyframes move {
  100% {
    transform: translate3d(300px, 100px, 0px);
  }
}

.bubble-wrap {
  position: absolute;
  top: 36%;
  left: 10%;
  -webkit-transform: rotate(39deg);
  -ms-transform: rotate(39deg);
  transform: rotate(47deg);
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
}

.bubble-wrap-right {
  position: absolute;
  top: 53%;
  right: 46%;
  -webkit-transform: rotate(39deg);
  -ms-transform: rotate(39deg);
  transform: rotate(9deg);
  z-index: -1;
  opacity: 0;
}

@keyframes bub_opacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bubble {
  position: absolute;
  position-opacity: 0;
  animation: move 3s infinite reverse;
  background-image: url(../../media/music/1.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.bubble-wrap.active, .bubble-wrap-right.active {
  opacity: 0.2;
  transition: all 0.3s;
}

.bubble.icon-1 {
  background-image: url(../../media/music/1.png);
}

.bubble.icon-2 {
  background-image: url(../../media/music/2.png);
}

.bubble.icon-3 {
  background-image: url(../../media/music/3.png);
}

.bubble.icon-4 {
  background-image: url(../../media/music/4.png);
}

.bubble.icon-5 {
  background-image: url(../../media/music/5.png);
}

.bubble:nth-child(1) {
  height: 12px;
  width: 12px;
  animation-delay: -0.9s;
  transform: translate3d(719px, 274px, 816px);
}

.bubble:nth-child(2) {
  height: 10px;
  width: 10px;
  animation-delay: -1.8s;
  transform: translate3d(81px, 115px, 129px);
}

.bubble:nth-child(3) {
  height: 13px;
  width: 13px;
  animation-delay: -2.7s;
  transform: translate3d(178px, 88px, 1249px);
}

.bubble:nth-child(4) {
  height: 7px;
  width: 7px;
  animation-delay: -3.6s;
  transform: translate3d(967px, 317px, 1995px);
}

.bubble:nth-child(5) {
  height: 4px;
  width: 4px;
  animation-delay: -4.5s;
  transform: translate3d(286px, 981px, 588px);
}

.bubble:nth-child(6) {
  height: 11px;
  width: 11px;
  animation-delay: -5.4s;
  transform: translate3d(22px, 945px, 553px);
}

.bubble:nth-child(7) {
  height: 3px;
  width: 3px;
  animation-delay: -6.3s;
  transform: translate3d(994px, 402px, 312px);
}

.bubble:nth-child(8) {
  height: 7px;
  width: 7px;
  animation-delay: -7.2s;
  transform: translate3d(501px, 789px, 143px);
}

.bubble:nth-child(9) {
  height: 10px;
  width: 10px;
  animation-delay: -8.1s;
  transform: translate3d(717px, 758px, 1469px);
}

.bubble:nth-child(10) {
  height: 7px;
  width: 7px;
  animation-delay: -9s;
  transform: translate3d(68px, 4px, 1308px);
}

.bubble:nth-child(11) {
  height: 16px;
  width: 16px;
  animation-delay: -9.9s;
  transform: translate3d(8px, 118px, 1098px);
}

.bubble:nth-child(12) {
  height: 9px;
  width: 9px;
  animation-delay: -10.8s;
  transform: translate3d(360px, 171px, 931px);
}

.bubble:nth-child(13) {
  height: 9px;
  width: 9px;
  animation-delay: -11.7s;
  transform: translate3d(205px, 591px, 1270px);
}

.bubble:nth-child(14) {
  height: 28px;
  width: 28px;
  animation-delay: -12.6s;
  transform: translate3d(380px, 439px, 1276px);
}

.bubble:nth-child(15) {
  height: 29px;
  width: 29px;
  animation-delay: -13.5s;
  transform: translate3d(131px, 188px, 638px);
}

.bubble:nth-child(16) {
  height: 15px;
  width: 15px;
  animation-delay: -14.4s;
  transform: translate3d(507px, 886px, 674px);
}

.bubble:nth-child(17) {
  height: 17px;
  width: 17px;
  animation-delay: -15.3s;
  transform: translate3d(848px, 377px, 1727px);
}

.bubble:nth-child(18) {
  height: 21px;
  width: 21px;
  animation-delay: -16.2s;
  transform: translate3d(615px, 200px, 1267px);
}

.bubble:nth-child(19) {
  height: 28px;
  width: 28px;
  animation-delay: -17.1s;
  transform: translate3d(156px, 559px, 294px);
}

.bubble:nth-child(20) {
  height: 19px;
  width: 19px;
  animation-delay: -18s;
  transform: translate3d(355px, 306px, 1150px);
}

.bubble:nth-child(21) {
  height: 7px;
  width: 7px;
  animation-delay: -18.9s;
  transform: translate3d(155px, 586px, 1797px);
}

.bubble:nth-child(22) {
  height: 27px;
  width: 27px;
  animation-delay: -19.8s;
  transform: translate3d(931px, 274px, 1140px);
}

.bubble:nth-child(23) {
  height: 3px;
  width: 3px;
  animation-delay: -20.7s;
  transform: translate3d(75px, 611px, 569px);
}

.bubble:nth-child(24) {
  height: 12px;
  width: 12px;
  animation-delay: -21.6s;
  transform: translate3d(941px, 152px, 925px);
}

.bubble:nth-child(25) {
  height: 12px;
  width: 12px;
  animation-delay: -22.5s;
  transform: translate3d(54px, 431px, 291px);
}

.bubble:nth-child(26) {
  height: 22px;
  width: 22px;
  animation-delay: -23.4s;
  transform: translate3d(61px, 85px, 1000px);
}

.bubble:nth-child(27) {
  height: 25px;
  width: 25px;
  animation-delay: -24.3s;
  transform: translate3d(437px, 797px, 1710px);
}

.bubble:nth-child(28) {
  height: 14px;
  width: 14px;
  animation-delay: -25.2s;
  transform: translate3d(220px, 199px, 1455px);
}

.bubble:nth-child(29) {
  height: 9px;
  width: 9px;
  animation-delay: -26.1s;
  transform: translate3d(684px, 144px, 472px);
}

.bubble:nth-child(30) {
  height: 25px;
  width: 25px;
  animation-delay: -27s;
  transform: translate3d(548px, 135px, 197px);
}

.bubble:nth-child(31) {
  height: 25px;
  width: 25px;
  animation-delay: -27.9s;
  transform: translate3d(115px, 465px, 1626px);
}

.bubble:nth-child(32) {
  height: 30px;
  width: 30px;
  animation-delay: -28.8s;
  transform: translate3d(383px, 233px, 986px);
}

.bubble:nth-child(33) {
  height: 4px;
  width: 4px;
  animation-delay: -29.7s;
  transform: translate3d(133px, 812px, 729px);
}

.bubble:nth-child(34) {
  height: 4px;
  width: 4px;
  animation-delay: -30.6s;
  transform: translate3d(459px, 383px, 1174px);
}

.bubble:nth-child(35) {
  height: 27px;
  width: 27px;
  animation-delay: -31.5s;
  transform: translate3d(426px, 721px, 1269px);
}

.bubble:nth-child(36) {
  height: 29px;
  width: 29px;
  animation-delay: -32.4s;
  transform: translate3d(712px, 918px, 834px);
}

.bubble:nth-child(37) {
  height: 17px;
  width: 17px;
  animation-delay: -33.3s;
  transform: translate3d(299px, 302px, 1893px);
}

.bubble:nth-child(38) {
  height: 2px;
  width: 2px;
  animation-delay: -34.2s;
  transform: translate3d(747px, 689px, 146px);
}

.bubble:nth-child(39) {
  height: 8px;
  width: 8px;
  animation-delay: -35.1s;
  transform: translate3d(980px, 62px, 707px);
}

.bubble:nth-child(40) {
  height: 2px;
  width: 2px;
  animation-delay: -36s;
  transform: translate3d(820px, 197px, 1050px);
}

.bubble:nth-child(41) {
  height: 16px;
  width: 16px;
  animation-delay: -36.9s;
  transform: translate3d(527px, 36px, 834px);
}

.bubble:nth-child(42) {
  height: 19px;
  width: 19px;
  animation-delay: -37.8s;
  transform: translate3d(856px, 612px, 876px);
}

.bubble:nth-child(43) {
  height: 20px;
  width: 20px;
  animation-delay: -38.7s;
  transform: translate3d(826px, 626px, 745px);
}

.bubble:nth-child(44) {
  height: 13px;
  width: 13px;
  animation-delay: -39.6s;
  transform: translate3d(874px, 151px, 867px);
}

.bubble:nth-child(45) {
  height: 13px;
  width: 13px;
  animation-delay: -40.5s;
  transform: translate3d(279px, 218px, 487px);
}

.bubble:nth-child(46) {
  height: 27px;
  width: 27px;
  animation-delay: -41.4s;
  transform: translate3d(11px, 882px, 1622px);
}

.bubble:nth-child(47) {
  height: 16px;
  width: 16px;
  animation-delay: -42.3s;
  transform: translate3d(201px, 231px, 1219px);
}

.bubble:nth-child(48) {
  height: 13px;
  width: 13px;
  animation-delay: -43.2s;
  transform: translate3d(23px, 418px, 879px);
}

.bubble:nth-child(49) {
  height: 22px;
  width: 22px;
  animation-delay: -44.1s;
  transform: translate3d(696px, 462px, 1401px);
}

.bubble:nth-child(50) {
  height: 28px;
  width: 28px;
  animation-delay: -45s;
  transform: translate3d(925px, 584px, 484px);
}

.bubble:nth-child(51) {
  height: 20px;
  width: 20px;
  animation-delay: -45.9s;
  transform: translate3d(238px, 963px, 469px);
}

.bubble:nth-child(52) {
  height: 25px;
  width: 25px;
  animation-delay: -46.8s;
  transform: translate3d(433px, 241px, 39px);
}

.bubble:nth-child(53) {
  height: 1px;
  width: 1px;
  animation-delay: -47.7s;
  transform: translate3d(520px, 221px, 980px);
}

.bubble:nth-child(54) {
  height: 13px;
  width: 13px;
  animation-delay: -48.6s;
  transform: translate3d(742px, 581px, 581px);
}

.bubble:nth-child(55) {
  height: 23px;
  width: 23px;
  animation-delay: -49.5s;
  transform: translate3d(290px, 704px, 443px);
}

.bubble:nth-child(56) {
  height: 6px;
  width: 6px;
  animation-delay: -50.4s;
  transform: translate3d(346px, 686px, 1483px);
}

.bubble:nth-child(57) {
  height: 6px;
  width: 6px;
  animation-delay: -51.3s;
  transform: translate3d(859px, 630px, 643px);
}

.bubble:nth-child(58) {
  height: 10px;
  width: 10px;
  animation-delay: -52.2s;
  transform: translate3d(334px, 741px, 1705px);
}

.bubble:nth-child(59) {
  height: 12px;
  width: 12px;
  animation-delay: -53.1s;
  transform: translate3d(899px, 511px, 362px);
}

.bubble:nth-child(60) {
  height: 1px;
  width: 1px;
  animation-delay: -54s;
  transform: translate3d(934px, 191px, 1223px);
}

.bubble:nth-child(61) {
  height: 29px;
  width: 29px;
  animation-delay: -54.9s;
  transform: translate3d(144px, 472px, 973px);
}

.bubble:nth-child(62) {
  height: 13px;
  width: 13px;
  animation-delay: -55.8s;
  transform: translate3d(91px, 83px, 123px);
}

.bubble:nth-child(63) {
  height: 14px;
  width: 14px;
  animation-delay: -56.7s;
  transform: translate3d(645px, 344px, 763px);
}

.bubble:nth-child(64) {
  height: 28px;
  width: 28px;
  animation-delay: -57.6s;
  transform: translate3d(69px, 171px, 903px);
}

.bubble:nth-child(65) {
  height: 3px;
  width: 3px;
  animation-delay: -58.5s;
  transform: translate3d(809px, 426px, 1841px);
}

.bubble:nth-child(66) {
  height: 19px;
  width: 19px;
  animation-delay: -59.4s;
  transform: translate3d(895px, 605px, 457px);
}

.bubble:nth-child(67) {
  height: 27px;
  width: 27px;
  animation-delay: -60.3s;
  transform: translate3d(863px, 527px, 547px);
}

.bubble:nth-child(68) {
  height: 29px;
  width: 29px;
  animation-delay: -61.2s;
  transform: translate3d(25px, 844px, 1366px);
}

.bubble:nth-child(69) {
  height: 28px;
  width: 28px;
  animation-delay: -62.1s;
  transform: translate3d(525px, 386px, 1180px);
}

.bubble:nth-child(70) {
  height: 20px;
  width: 20px;
  animation-delay: -63s;
  transform: translate3d(870px, 333px, 357px);
}

.bubble:nth-child(71) {
  height: 22px;
  width: 22px;
  animation-delay: -63.9s;
  transform: translate3d(88px, 851px, 571px);
}

.bubble:nth-child(72) {
  height: 14px;
  width: 14px;
  animation-delay: -64.8s;
  transform: translate3d(635px, 832px, 1407px);
}

.bubble:nth-child(73) {
  height: 27px;
  width: 27px;
  animation-delay: -65.7s;
  transform: translate3d(454px, 29px, 1366px);
}

.bubble:nth-child(74) {
  height: 11px;
  width: 11px;
  animation-delay: -66.6s;
  transform: translate3d(468px, 500px, 1610px);
}

.bubble:nth-child(75) {
  height: 7px;
  width: 7px;
  animation-delay: -67.5s;
  transform: translate3d(121px, 726px, 1885px);
}

.bubble:nth-child(76) {
  height: 2px;
  width: 2px;
  animation-delay: -68.4s;
  transform: translate3d(953px, 129px, 178px);
}

.bubble:nth-child(77) {
  height: 13px;
  width: 13px;
  animation-delay: -69.3s;
  transform: translate3d(224px, 802px, 1836px);
}

.bubble:nth-child(78) {
  height: 1px;
  width: 1px;
  animation-delay: -70.2s;
  transform: translate3d(839px, 682px, 1453px);
}

.bubble:nth-child(79) {
  height: 24px;
  width: 24px;
  animation-delay: -71.1s;
  transform: translate3d(607px, 279px, 1196px);
}

.bubble:nth-child(80) {
  height: 10px;
  width: 10px;
  animation-delay: -72s;
  transform: translate3d(90px, 689px, 1683px);
}

.bubble:nth-child(81) {
  height: 5px;
  width: 5px;
  animation-delay: -72.9s;
  transform: translate3d(603px, 926px, 100px);
}

.bubble:nth-child(82) {
  height: 28px;
  width: 28px;
  animation-delay: -73.8s;
  transform: translate3d(115px, 242px, 852px);
}

.bubble:nth-child(83) {
  height: 28px;
  width: 28px;
  animation-delay: -74.7s;
  transform: translate3d(411px, 951px, 273px);
}

.bubble:nth-child(84) {
  height: 10px;
  width: 10px;
  animation-delay: -75.6s;
  transform: translate3d(826px, 590px, 1269px);
}

.bubble:nth-child(85) {
  height: 21px;
  width: 21px;
  animation-delay: -76.5s;
  transform: translate3d(566px, 654px, 1365px);
}

.bubble:nth-child(86) {
  height: 18px;
  width: 18px;
  animation-delay: -77.4s;
  transform: translate3d(283px, 754px, 1907px);
}

.bubble:nth-child(87) {
  height: 27px;
  width: 27px;
  animation-delay: -78.3s;
  transform: translate3d(640px, 494px, 586px);
}

.bubble:nth-child(88) {
  height: 18px;
  width: 18px;
  animation-delay: -79.2s;
  transform: translate3d(242px, 241px, 607px);
}

.bubble:nth-child(89) {
  height: 1px;
  width: 1px;
  animation-delay: -80.1s;
  transform: translate3d(552px, 673px, 869px);
}

.bubble:nth-child(90) {
  height: 1px;
  width: 1px;
  animation-delay: -81s;
  transform: translate3d(412px, 687px, 344px);
}

.bubble:nth-child(91) {
  height: 10px;
  width: 10px;
  animation-delay: -81.9s;
  transform: translate3d(21px, 915px, 569px);
}

.bubble:nth-child(92) {
  height: 9px;
  width: 9px;
  animation-delay: -82.8s;
  transform: translate3d(255px, 309px, 345px);
}

.bubble:nth-child(93) {
  height: 4px;
  width: 4px;
  animation-delay: -83.7s;
  transform: translate3d(78px, 462px, 532px);
}

.bubble:nth-child(94) {
  height: 22px;
  width: 22px;
  animation-delay: -84.6s;
  transform: translate3d(534px, 58px, 88px);
}

.bubble:nth-child(95) {
  height: 28px;
  width: 28px;
  animation-delay: -85.5s;
  transform: translate3d(815px, 917px, 1707px);
}

.bubble:nth-child(96) {
  height: 29px;
  width: 29px;
  animation-delay: -86.4s;
  transform: translate3d(306px, 216px, 1174px);
}

.bubble:nth-child(97) {
  height: 14px;
  width: 14px;
  animation-delay: -87.3s;
  transform: translate3d(175px, 861px, 88px);
}

.bubble:nth-child(98) {
  height: 6px;
  width: 6px;
  animation-delay: -88.2s;
  transform: translate3d(40px, 7px, 166px);
}

.bubble:nth-child(99) {
  height: 14px;
  width: 14px;
  animation-delay: -89.1s;
  transform: translate3d(535px, 239px, 1646px);
}

.bubble:nth-child(100) {
  height: 11px;
  width: 11px;
  animation-delay: -90s;
  transform: translate3d(185px, 493px, 1092px);
}

.bubble:nth-child(101) {
  height: 9px;
  width: 9px;
  animation-delay: -90.9s;
  transform: translate3d(750px, 77px, 1256px);
}

.bubble:nth-child(102) {
  height: 7px;
  width: 7px;
  animation-delay: -91.8s;
  transform: translate3d(25px, 853px, 1699px);
}

.bubble:nth-child(103) {
  height: 5px;
  width: 5px;
  animation-delay: -92.7s;
  transform: translate3d(561px, 499px, 1114px);
}

.bubble:nth-child(104) {
  height: 19px;
  width: 19px;
  animation-delay: -93.6s;
  transform: translate3d(735px, 52px, 616px);
}

.bubble:nth-child(105) {
  height: 7px;
  width: 7px;
  animation-delay: -94.5s;
  transform: translate3d(464px, 572px, 231px);
}

.bubble:nth-child(106) {
  height: 3px;
  width: 3px;
  animation-delay: -95.4s;
  transform: translate3d(458px, 506px, 1730px);
}

.bubble:nth-child(107) {
  height: 5px;
  width: 5px;
  animation-delay: -96.3s;
  transform: translate3d(550px, 239px, 855px);
}

.bubble:nth-child(108) {
  height: 19px;
  width: 19px;
  animation-delay: -97.2s;
  transform: translate3d(986px, 293px, 1845px);
}

.bubble:nth-child(109) {
  height: 1px;
  width: 1px;
  animation-delay: -98.1s;
  transform: translate3d(648px, 739px, 1979px);
}

.bubble:nth-child(110) {
  height: 27px;
  width: 27px;
  animation-delay: -99s;
  transform: translate3d(327px, 881px, 1613px);
}

.bubble:nth-child(111) {
  height: 6px;
  width: 6px;
  animation-delay: -99.9s;
  transform: translate3d(374px, 357px, 1010px);
}

.bubble:nth-child(112) {
  height: 1px;
  width: 1px;
  animation-delay: -100.8s;
  transform: translate3d(160px, 838px, 883px);
}

.bubble:nth-child(113) {
  height: 23px;
  width: 23px;
  animation-delay: -101.7s;
  transform: translate3d(486px, 777px, 276px);
}

.bubble:nth-child(114) {
  height: 2px;
  width: 2px;
  animation-delay: -102.6s;
  transform: translate3d(72px, 228px, 1909px);
}

.bubble:nth-child(115) {
  height: 29px;
  width: 29px;
  animation-delay: -103.5s;
  transform: translate3d(17px, 528px, 1574px);
}

.bubble:nth-child(116) {
  height: 8px;
  width: 8px;
  animation-delay: -104.4s;
  transform: translate3d(238px, 792px, 1963px);
}

.bubble:nth-child(117) {
  height: 5px;
  width: 5px;
  animation-delay: -105.3s;
  transform: translate3d(534px, 144px, 789px);
}

.bubble:nth-child(118) {
  height: 15px;
  width: 15px;
  animation-delay: -106.2s;
  transform: translate3d(124px, 475px, 1825px);
}

.bubble:nth-child(119) {
  height: 11px;
  width: 11px;
  animation-delay: -107.1s;
  transform: translate3d(126px, 655px, 236px);
}

.bubble:nth-child(120) {
  height: 26px;
  width: 26px;
  animation-delay: -108s;
  transform: translate3d(125px, 97px, 68px);
}

.bubble:nth-child(121) {
  height: 12px;
  width: 12px;
  animation-delay: -108.9s;
  transform: translate3d(353px, 203px, 169px);
}

.bubble:nth-child(122) {
  height: 29px;
  width: 29px;
  animation-delay: -109.8s;
  transform: translate3d(298px, 91px, 322px);
}

.bubble:nth-child(123) {
  height: 23px;
  width: 23px;
  animation-delay: -110.7s;
  transform: translate3d(679px, 22px, 1041px);
}

.bubble:nth-child(124) {
  height: 2px;
  width: 2px;
  animation-delay: -111.6s;
  transform: translate3d(916px, 407px, 1174px);
}

.bubble:nth-child(125) {
  height: 18px;
  width: 18px;
  animation-delay: -112.5s;
  transform: translate3d(398px, 436px, 1151px);
}

.bubble:nth-child(126) {
  height: 18px;
  width: 18px;
  animation-delay: -113.4s;
  transform: translate3d(960px, 462px, 527px);
}

.bubble:nth-child(127) {
  height: 29px;
  width: 29px;
  animation-delay: -114.3s;
  transform: translate3d(781px, 431px, 1453px);
}

.bubble:nth-child(128) {
  height: 21px;
  width: 21px;
  animation-delay: -115.2s;
  transform: translate3d(432px, 765px, 654px);
}

.bubble:nth-child(129) {
  height: 13px;
  width: 13px;
  animation-delay: -116.1s;
  transform: translate3d(762px, 773px, 191px);
}

.bubble:nth-child(130) {
  height: 17px;
  width: 17px;
  animation-delay: -117s;
  transform: translate3d(184px, 347px, 1251px);
}

.bubble:nth-child(131) {
  height: 12px;
  width: 12px;
  animation-delay: -117.9s;
  transform: translate3d(876px, 225px, 1440px);
}

.bubble:nth-child(132) {
  height: 23px;
  width: 23px;
  animation-delay: -118.8s;
  transform: translate3d(963px, 91px, 1768px);
}

.bubble:nth-child(133) {
  height: 23px;
  width: 23px;
  animation-delay: -119.7s;
  transform: translate3d(171px, 920px, 1179px);
}

.bubble:nth-child(134) {
  height: 14px;
  width: 14px;
  animation-delay: -120.6s;
  transform: translate3d(183px, 205px, 496px);
}

.bubble:nth-child(135) {
  height: 18px;
  width: 18px;
  animation-delay: -121.5s;
  transform: translate3d(975px, 450px, 1101px);
}

.bubble:nth-child(136) {
  height: 21px;
  width: 21px;
  animation-delay: -122.4s;
  transform: translate3d(791px, 42px, 1151px);
}

.bubble:nth-child(137) {
  height: 22px;
  width: 22px;
  animation-delay: -123.3s;
  transform: translate3d(574px, 256px, 605px);
}

.bubble:nth-child(138) {
  height: 8px;
  width: 8px;
  animation-delay: -124.2s;
  transform: translate3d(820px, 511px, 1230px);
}

.bubble:nth-child(139) {
  height: 30px;
  width: 30px;
  animation-delay: -125.1s;
  transform: translate3d(657px, 200px, 511px);
}

.bubble:nth-child(140) {
  height: 1px;
  width: 1px;
  animation-delay: -126s;
  transform: translate3d(978px, 35px, 498px);
}

.bubble:nth-child(141) {
  height: 6px;
  width: 6px;
  animation-delay: -126.9s;
  transform: translate3d(446px, 122px, 1671px);
}

.bubble:nth-child(142) {
  height: 17px;
  width: 17px;
  animation-delay: -127.8s;
  transform: translate3d(710px, 812px, 1508px);
}

.bubble:nth-child(143) {
  height: 14px;
  width: 14px;
  animation-delay: -128.7s;
  transform: translate3d(921px, 315px, 1904px);
}

.bubble:nth-child(144) {
  height: 21px;
  width: 21px;
  animation-delay: -129.6s;
  transform: translate3d(626px, 745px, 1008px);
}

.bubble:nth-child(145) {
  height: 17px;
  width: 17px;
  animation-delay: -130.5s;
  transform: translate3d(200px, 868px, 341px);
}

.bubble:nth-child(146) {
  height: 24px;
  width: 24px;
  animation-delay: -131.4s;
  transform: translate3d(586px, 924px, 523px);
}

.bubble:nth-child(147) {
  height: 16px;
  width: 16px;
  animation-delay: -132.3s;
  transform: translate3d(4px, 750px, 1356px);
}

.bubble:nth-child(148) {
  height: 26px;
  width: 26px;
  animation-delay: -133.2s;
  transform: translate3d(558px, 875px, 161px);
}

.bubble:nth-child(149) {
  height: 5px;
  width: 5px;
  animation-delay: -134.1s;
  transform: translate3d(151px, 587px, 1329px);
}

.bubble:nth-child(150) {
  height: 10px;
  width: 10px;
  animation-delay: -135s;
  transform: translate3d(811px, 206px, 1817px);
}

.bubble:nth-child(151) {
  height: 3px;
  width: 3px;
  animation-delay: -135.9s;
  transform: translate3d(277px, 253px, 1706px);
}

.bubble:nth-child(152) {
  height: 13px;
  width: 13px;
  animation-delay: -136.8s;
  transform: translate3d(900px, 234px, 1956px);
}

.bubble:nth-child(153) {
  height: 2px;
  width: 2px;
  animation-delay: -137.7s;
  transform: translate3d(222px, 668px, 896px);
}

.bubble:nth-child(154) {
  height: 3px;
  width: 3px;
  animation-delay: -138.6s;
  transform: translate3d(166px, 836px, 1810px);
}

.bubble:nth-child(155) {
  height: 5px;
  width: 5px;
  animation-delay: -139.5s;
  transform: translate3d(515px, 368px, 361px);
}

.bubble:nth-child(156) {
  height: 27px;
  width: 27px;
  animation-delay: -140.4s;
  transform: translate3d(832px, 852px, 948px);
}

.bubble:nth-child(157) {
  height: 24px;
  width: 24px;
  animation-delay: -141.3s;
  transform: translate3d(674px, 901px, 1145px);
}

.bubble:nth-child(158) {
  height: 14px;
  width: 14px;
  animation-delay: -142.2s;
  transform: translate3d(825px, 555px, 1554px);
}

.bubble:nth-child(159) {
  height: 19px;
  width: 19px;
  animation-delay: -143.1s;
  transform: translate3d(964px, 917px, 1443px);
}

.bubble:nth-child(160) {
  height: 30px;
  width: 30px;
  animation-delay: -144s;
  transform: translate3d(231px, 777px, 1619px);
}

.bubble:nth-child(161) {
  height: 2px;
  width: 2px;
  animation-delay: -144.9s;
  transform: translate3d(516px, 801px, 883px);
}

.bubble:nth-child(162) {
  height: 20px;
  width: 20px;
  animation-delay: -145.8s;
  transform: translate3d(88px, 485px, 1227px);
}

.bubble:nth-child(163) {
  height: 8px;
  width: 8px;
  animation-delay: -146.7s;
  transform: translate3d(628px, 304px, 1429px);
}

.bubble:nth-child(164) {
  height: 27px;
  width: 27px;
  animation-delay: -147.6s;
  transform: translate3d(638px, 795px, 1801px);
}

.bubble:nth-child(165) {
  height: 24px;
  width: 24px;
  animation-delay: -148.5s;
  transform: translate3d(935px, 433px, 1992px);
}

.bubble:nth-child(166) {
  height: 29px;
  width: 29px;
  animation-delay: -149.4s;
  transform: translate3d(411px, 905px, 489px);
}

.bubble:nth-child(167) {
  height: 11px;
  width: 11px;
  animation-delay: -150.3s;
  transform: translate3d(179px, 942px, 1880px);
}

.bubble:nth-child(168) {
  height: 12px;
  width: 12px;
  animation-delay: -151.2s;
  transform: translate3d(488px, 132px, 1112px);
}

.bubble:nth-child(169) {
  height: 17px;
  width: 17px;
  animation-delay: -152.1s;
  transform: translate3d(406px, 610px, 450px);
}

.bubble:nth-child(170) {
  height: 25px;
  width: 25px;
  animation-delay: -153s;
  transform: translate3d(47px, 398px, 1359px);
}

.bubble:nth-child(171) {
  height: 29px;
  width: 29px;
  animation-delay: -153.9s;
  transform: translate3d(247px, 628px, 1448px);
}

.bubble:nth-child(172) {
  height: 2px;
  width: 2px;
  animation-delay: -154.8s;
  transform: translate3d(166px, 384px, 1915px);
}

.bubble:nth-child(173) {
  height: 19px;
  width: 19px;
  animation-delay: -155.7s;
  transform: translate3d(451px, 629px, 516px);
}

.bubble:nth-child(174) {
  height: 17px;
  width: 17px;
  animation-delay: -156.6s;
  transform: translate3d(318px, 767px, 1215px);
}

.bubble:nth-child(175) {
  height: 23px;
  width: 23px;
  animation-delay: -157.5s;
  transform: translate3d(75px, 178px, 387px);
}

.bubble:nth-child(176) {
  height: 5px;
  width: 5px;
  animation-delay: -158.4s;
  transform: translate3d(594px, 620px, 1755px);
}

.bubble:nth-child(177) {
  height: 20px;
  width: 20px;
  animation-delay: -159.3s;
  transform: translate3d(167px, 733px, 1165px);
}

.bubble:nth-child(178) {
  height: 10px;
  width: 10px;
  animation-delay: -160.2s;
  transform: translate3d(798px, 151px, 184px);
}

.bubble:nth-child(179) {
  height: 22px;
  width: 22px;
  animation-delay: -161.1s;
  transform: translate3d(725px, 914px, 1171px);
}

.bubble:nth-child(180) {
  height: 2px;
  width: 2px;
  animation-delay: -162s;
  transform: translate3d(913px, 846px, 1507px);
}

.bubble:nth-child(181) {
  height: 2px;
  width: 2px;
  animation-delay: -162.9s;
  transform: translate3d(101px, 859px, 355px);
}

.bubble:nth-child(182) {
  height: 2px;
  width: 2px;
  animation-delay: -163.8s;
  transform: translate3d(748px, 706px, 54px);
}

.bubble:nth-child(183) {
  height: 3px;
  width: 3px;
  animation-delay: -164.7s;
  transform: translate3d(365px, 567px, 1082px);
}

.bubble:nth-child(184) {
  height: 24px;
  width: 24px;
  animation-delay: -165.6s;
  transform: translate3d(981px, 222px, 10px);
}

.bubble:nth-child(185) {
  height: 18px;
  width: 18px;
  animation-delay: -166.5s;
  transform: translate3d(48px, 929px, 976px);
}

.bubble:nth-child(186) {
  height: 17px;
  width: 17px;
  animation-delay: -167.4s;
  transform: translate3d(285px, 64px, 1679px);
}

.bubble:nth-child(187) {
  height: 3px;
  width: 3px;
  animation-delay: -168.3s;
  transform: translate3d(540px, 831px, 1075px);
}

.bubble:nth-child(188) {
  height: 28px;
  width: 28px;
  animation-delay: -169.2s;
  transform: translate3d(383px, 821px, 1358px);
}

.bubble:nth-child(189) {
  height: 30px;
  width: 30px;
  animation-delay: -170.1s;
  transform: translate3d(535px, 698px, 848px);
}

.bubble:nth-child(190) {
  height: 1px;
  width: 1px;
  animation-delay: -171s;
  transform: translate3d(260px, 596px, 419px);
}

.bubble:nth-child(191) {
  height: 28px;
  width: 28px;
  animation-delay: -171.9s;
  transform: translate3d(230px, 745px, 1126px);
}

.bubble:nth-child(192) {
  height: 14px;
  width: 14px;
  animation-delay: -172.8s;
  transform: translate3d(528px, 419px, 201px);
}

.bubble:nth-child(193) {
  height: 14px;
  width: 14px;
  animation-delay: -173.7s;
  transform: translate3d(781px, 713px, 1466px);
}

.bubble:nth-child(194) {
  height: 15px;
  width: 15px;
  animation-delay: -174.6s;
  transform: translate3d(227px, 487px, 1683px);
}

.bubble:nth-child(195) {
  height: 21px;
  width: 21px;
  animation-delay: -175.5s;
  transform: translate3d(675px, 925px, 288px);
}

.bubble:nth-child(196) {
  height: 20px;
  width: 20px;
  animation-delay: -176.4s;
  transform: translate3d(599px, 435px, 1154px);
}

.bubble:nth-child(197) {
  height: 15px;
  width: 15px;
  animation-delay: -177.3s;
  transform: translate3d(984px, 22px, 1367px);
}

.bubble:nth-child(198) {
  height: 13px;
  width: 13px;
  animation-delay: -178.2s;
  transform: translate3d(920px, 334px, 476px);
}

.bubble:nth-child(199) {
  height: 5px;
  width: 5px;
  animation-delay: -179.1s;
  transform: translate3d(426px, 261px, 1329px);
}

.bubble:nth-child(200) {
  height: 26px;
  width: 26px;
  animation-delay: -180s;
  transform: translate3d(984px, 104px, 1117px);
}

.bubble:nth-child(201) {
  height: 23px;
  width: 23px;
  animation-delay: -180.9s;
  transform: translate3d(205px, 871px, 1528px);
}

.bubble:nth-child(202) {
  height: 10px;
  width: 10px;
  animation-delay: -181.8s;
  transform: translate3d(350px, 883px, 183px);
}

.bubble:nth-child(203) {
  height: 29px;
  width: 29px;
  animation-delay: -182.7s;
  transform: translate3d(723px, 370px, 799px);
}

.bubble:nth-child(204) {
  height: 29px;
  width: 29px;
  animation-delay: -183.6s;
  transform: translate3d(694px, 938px, 1320px);
}

.bubble:nth-child(205) {
  height: 20px;
  width: 20px;
  animation-delay: -184.5s;
  transform: translate3d(901px, 635px, 1064px);
}

.bubble:nth-child(206) {
  height: 26px;
  width: 26px;
  animation-delay: -185.4s;
  transform: translate3d(452px, 26px, 1034px);
}

.bubble:nth-child(207) {
  height: 1px;
  width: 1px;
  animation-delay: -186.3s;
  transform: translate3d(530px, 772px, 1756px);
}

.bubble:nth-child(208) {
  height: 2px;
  width: 2px;
  animation-delay: -187.2s;
  transform: translate3d(716px, 158px, 1541px);
}

.bubble:nth-child(209) {
  height: 22px;
  width: 22px;
  animation-delay: -188.1s;
  transform: translate3d(846px, 389px, 444px);
}

.bubble:nth-child(210) {
  height: 19px;
  width: 19px;
  animation-delay: -189s;
  transform: translate3d(558px, 18px, 555px);
}

.bubble:nth-child(211) {
  height: 3px;
  width: 3px;
  animation-delay: -189.9s;
  transform: translate3d(737px, 621px, 609px);
}

.bubble:nth-child(212) {
  height: 1px;
  width: 1px;
  animation-delay: -190.8s;
  transform: translate3d(329px, 978px, 761px);
}

.bubble:nth-child(213) {
  height: 11px;
  width: 11px;
  animation-delay: -191.7s;
  transform: translate3d(60px, 704px, 1988px);
}

.bubble:nth-child(214) {
  height: 5px;
  width: 5px;
  animation-delay: -192.6s;
  transform: translate3d(766px, 228px, 295px);
}

.bubble:nth-child(215) {
  height: 4px;
  width: 4px;
  animation-delay: -193.5s;
  transform: translate3d(207px, 508px, 1064px);
}

.bubble:nth-child(216) {
  height: 10px;
  width: 10px;
  animation-delay: -194.4s;
  transform: translate3d(882px, 781px, 1520px);
}

.bubble:nth-child(217) {
  height: 17px;
  width: 17px;
  animation-delay: -195.3s;
  transform: translate3d(933px, 976px, 876px);
}

.bubble:nth-child(218) {
  height: 20px;
  width: 20px;
  animation-delay: -196.2s;
  transform: translate3d(621px, 178px, 495px);
}

.bubble:nth-child(219) {
  height: 3px;
  width: 3px;
  animation-delay: -197.1s;
  transform: translate3d(285px, 234px, 616px);
}

.bubble:nth-child(220) {
  height: 1px;
  width: 1px;
  animation-delay: -198s;
  transform: translate3d(851px, 362px, 1042px);
}

.bubble:nth-child(221) {
  height: 28px;
  width: 28px;
  animation-delay: -198.9s;
  transform: translate3d(149px, 444px, 1105px);
}

.bubble:nth-child(222) {
  height: 7px;
  width: 7px;
  animation-delay: -199.8s;
  transform: translate3d(224px, 550px, 1824px);
}

.bubble:nth-child(223) {
  height: 19px;
  width: 19px;
  animation-delay: -200.7s;
  transform: translate3d(565px, 173px, 1414px);
}

.bubble:nth-child(224) {
  height: 27px;
  width: 27px;
  animation-delay: -201.6s;
  transform: translate3d(681px, 263px, 1778px);
}

.bubble:nth-child(225) {
  height: 13px;
  width: 13px;
  animation-delay: -202.5s;
  transform: translate3d(272px, 272px, 862px);
}

.bubble:nth-child(226) {
  height: 25px;
  width: 25px;
  animation-delay: -203.4s;
  transform: translate3d(579px, 309px, 617px);
}

.bubble:nth-child(227) {
  height: 17px;
  width: 17px;
  animation-delay: -204.3s;
  transform: translate3d(573px, 323px, 1451px);
}

.bubble:nth-child(228) {
  height: 20px;
  width: 20px;
  animation-delay: -205.2s;
  transform: translate3d(997px, 200px, 443px);
}

.bubble:nth-child(229) {
  height: 8px;
  width: 8px;
  animation-delay: -206.1s;
  transform: translate3d(597px, 453px, 1808px);
}

.bubble:nth-child(230) {
  height: 9px;
  width: 9px;
  animation-delay: -207s;
  transform: translate3d(537px, 74px, 533px);
}

.bubble:nth-child(231) {
  height: 5px;
  width: 5px;
  animation-delay: -207.9s;
  transform: translate3d(839px, 710px, 734px);
}

.bubble:nth-child(232) {
  height: 22px;
  width: 22px;
  animation-delay: -208.8s;
  transform: translate3d(312px, 234px, 1017px);
}

.bubble:nth-child(233) {
  height: 14px;
  width: 14px;
  animation-delay: -209.7s;
  transform: translate3d(380px, 719px, 622px);
}

.bubble:nth-child(234) {
  height: 13px;
  width: 13px;
  animation-delay: -210.6s;
  transform: translate3d(132px, 780px, 1064px);
}

.bubble:nth-child(235) {
  height: 25px;
  width: 25px;
  animation-delay: -211.5s;
  transform: translate3d(520px, 542px, 1015px);
}

.bubble:nth-child(236) {
  height: 23px;
  width: 23px;
  animation-delay: -212.4s;
  transform: translate3d(294px, 29px, 1800px);
}

.bubble:nth-child(237) {
  height: 18px;
  width: 18px;
  animation-delay: -213.3s;
  transform: translate3d(375px, 72px, 1632px);
}

.bubble:nth-child(238) {
  height: 6px;
  width: 6px;
  animation-delay: -214.2s;
  transform: translate3d(387px, 477px, 1335px);
}

.bubble:nth-child(239) {
  height: 26px;
  width: 26px;
  animation-delay: -215.1s;
  transform: translate3d(328px, 858px, 1247px);
}

.bubble:nth-child(240) {
  height: 27px;
  width: 27px;
  animation-delay: -216s;
  transform: translate3d(829px, 993px, 1849px);
}

.bubble:nth-child(241) {
  height: 28px;
  width: 28px;
  animation-delay: -216.9s;
  transform: translate3d(423px, 403px, 844px);
}

.bubble:nth-child(242) {
  height: 22px;
  width: 22px;
  animation-delay: -217.8s;
  transform: translate3d(276px, 769px, 519px);
}

.bubble:nth-child(243) {
  height: 7px;
  width: 7px;
  animation-delay: -218.7s;
  transform: translate3d(481px, 859px, 559px);
}

.bubble:nth-child(244) {
  height: 15px;
  width: 15px;
  animation-delay: -219.6s;
  transform: translate3d(205px, 800px, 1014px);
}

.bubble:nth-child(245) {
  height: 13px;
  width: 13px;
  animation-delay: -220.5s;
  transform: translate3d(959px, 706px, 1993px);
}

.bubble:nth-child(246) {
  height: 12px;
  width: 12px;
  animation-delay: -221.4s;
  transform: translate3d(15px, 803px, 953px);
}

.bubble:nth-child(247) {
  height: 30px;
  width: 30px;
  animation-delay: -222.3s;
  transform: translate3d(561px, 426px, 130px);
}

.bubble:nth-child(248) {
  height: 20px;
  width: 20px;
  animation-delay: -223.2s;
  transform: translate3d(9px, 678px, 268px);
}

.bubble:nth-child(249) {
  height: 2px;
  width: 2px;
  animation-delay: -224.1s;
  transform: translate3d(696px, 249px, 1688px);
}

.bubble:nth-child(250) {
  height: 17px;
  width: 17px;
  animation-delay: -225s;
  transform: translate3d(983px, 355px, 141px);
}

.bubble:nth-child(251) {
  height: 2px;
  width: 2px;
  animation-delay: -225.9s;
  transform: translate3d(822px, 628px, 1812px);
}

.bubble:nth-child(252) {
  height: 5px;
  width: 5px;
  animation-delay: -226.8s;
  transform: translate3d(761px, 926px, 820px);
}

.bubble:nth-child(253) {
  height: 3px;
  width: 3px;
  animation-delay: -227.7s;
  transform: translate3d(23px, 267px, 1446px);
}

.bubble:nth-child(254) {
  height: 3px;
  width: 3px;
  animation-delay: -228.6s;
  transform: translate3d(30px, 601px, 1728px);
}

.bubble:nth-child(255) {
  height: 7px;
  width: 7px;
  animation-delay: -229.5s;
  transform: translate3d(527px, 977px, 673px);
}

.bubble:nth-child(256) {
  height: 1px;
  width: 1px;
  animation-delay: -230.4s;
  transform: translate3d(798px, 864px, 1189px);
}

.bubble:nth-child(257) {
  height: 16px;
  width: 16px;
  animation-delay: -231.3s;
  transform: translate3d(286px, 772px, 1061px);
}

.bubble:nth-child(258) {
  height: 14px;
  width: 14px;
  animation-delay: -232.2s;
  transform: translate3d(391px, 428px, 280px);
}

.bubble:nth-child(259) {
  height: 22px;
  width: 22px;
  animation-delay: -233.1s;
  transform: translate3d(163px, 206px, 171px);
}

.bubble:nth-child(260) {
  height: 16px;
  width: 16px;
  animation-delay: -234s;
  transform: translate3d(48px, 362px, 1460px);
}

.bubble:nth-child(261) {
  height: 14px;
  width: 14px;
  animation-delay: -234.9s;
  transform: translate3d(502px, 554px, 1172px);
}

.bubble:nth-child(262) {
  height: 5px;
  width: 5px;
  animation-delay: -235.8s;
  transform: translate3d(661px, 574px, 936px);
}

.bubble:nth-child(263) {
  height: 2px;
  width: 2px;
  animation-delay: -236.7s;
  transform: translate3d(543px, 976px, 234px);
}

.bubble:nth-child(264) {
  height: 3px;
  width: 3px;
  animation-delay: -237.6s;
  transform: translate3d(951px, 92px, 977px);
}

.bubble:nth-child(265) {
  height: 28px;
  width: 28px;
  animation-delay: -238.5s;
  transform: translate3d(578px, 936px, 1198px);
}

.bubble:nth-child(266) {
  height: 2px;
  width: 2px;
  animation-delay: -239.4s;
  transform: translate3d(753px, 40px, 239px);
}

.bubble:nth-child(267) {
  height: 24px;
  width: 24px;
  animation-delay: -240.3s;
  transform: translate3d(872px, 168px, 898px);
}

.bubble:nth-child(268) {
  height: 11px;
  width: 11px;
  animation-delay: -241.2s;
  transform: translate3d(646px, 788px, 572px);
}

.bubble:nth-child(269) {
  height: 10px;
  width: 10px;
  animation-delay: -242.1s;
  transform: translate3d(688px, 585px, 1297px);
}

.bubble:nth-child(270) {
  height: 18px;
  width: 18px;
  animation-delay: -243s;
  transform: translate3d(509px, 701px, 919px);
}

.bubble:nth-child(271) {
  height: 14px;
  width: 14px;
  animation-delay: -243.9s;
  transform: translate3d(732px, 580px, 1464px);
}

.bubble:nth-child(272) {
  height: 9px;
  width: 9px;
  animation-delay: -244.8s;
  transform: translate3d(341px, 958px, 1599px);
}

.bubble:nth-child(273) {
  height: 28px;
  width: 28px;
  animation-delay: -245.7s;
  transform: translate3d(92px, 572px, 949px);
}

.bubble:nth-child(274) {
  height: 25px;
  width: 25px;
  animation-delay: -246.6s;
  transform: translate3d(842px, 824px, 1144px);
}

.bubble:nth-child(275) {
  height: 25px;
  width: 25px;
  animation-delay: -247.5s;
  transform: translate3d(220px, 465px, 475px);
}

.bubble:nth-child(276) {
  height: 24px;
  width: 24px;
  animation-delay: -248.4s;
  transform: translate3d(392px, 420px, 1487px);
}

.bubble:nth-child(277) {
  height: 16px;
  width: 16px;
  animation-delay: -249.3s;
  transform: translate3d(554px, 259px, 415px);
}

.bubble:nth-child(278) {
  height: 3px;
  width: 3px;
  animation-delay: -250.2s;
  transform: translate3d(986px, 664px, 1460px);
}

.bubble:nth-child(279) {
  height: 5px;
  width: 5px;
  animation-delay: -251.1s;
  transform: translate3d(414px, 5px, 1452px);
}

.bubble:nth-child(280) {
  height: 19px;
  width: 19px;
  animation-delay: -252s;
  transform: translate3d(557px, 66px, 1489px);
}

.bubble:nth-child(281) {
  height: 2px;
  width: 2px;
  animation-delay: -252.9s;
  transform: translate3d(213px, 380px, 1869px);
}

.bubble:nth-child(282) {
  height: 9px;
  width: 9px;
  animation-delay: -253.8s;
  transform: translate3d(468px, 771px, 1543px);
}

.bubble:nth-child(283) {
  height: 16px;
  width: 16px;
  animation-delay: -254.7s;
  transform: translate3d(887px, 227px, 258px);
}

.bubble:nth-child(284) {
  height: 25px;
  width: 25px;
  animation-delay: -255.6s;
  transform: translate3d(87px, 437px, 1222px);
}

.bubble:nth-child(285) {
  height: 5px;
  width: 5px;
  animation-delay: -256.5s;
  transform: translate3d(460px, 189px, 1786px);
}

.bubble:nth-child(286) {
  height: 16px;
  width: 16px;
  animation-delay: -257.4s;
  transform: translate3d(545px, 1px, 1638px);
}

.bubble:nth-child(287) {
  height: 26px;
  width: 26px;
  animation-delay: -258.3s;
  transform: translate3d(413px, 827px, 1504px);
}

.bubble:nth-child(288) {
  height: 7px;
  width: 7px;
  animation-delay: -259.2s;
  transform: translate3d(654px, 513px, 205px);
}

.bubble:nth-child(289) {
  height: 18px;
  width: 18px;
  animation-delay: -260.1s;
  transform: translate3d(618px, 976px, 1160px);
}

.bubble:nth-child(290) {
  height: 24px;
  width: 24px;
  animation-delay: -261s;
  transform: translate3d(201px, 424px, 1276px);
}

.bubble:nth-child(291) {
  height: 15px;
  width: 15px;
  animation-delay: -261.9s;
  transform: translate3d(433px, 826px, 1371px);
}

.bubble:nth-child(292) {
  height: 4px;
  width: 4px;
  animation-delay: -262.8s;
  transform: translate3d(715px, 851px, 1241px);
}

.bubble:nth-child(293) {
  height: 21px;
  width: 21px;
  animation-delay: -263.7s;
  transform: translate3d(4px, 262px, 700px);
}

.bubble:nth-child(294) {
  height: 6px;
  width: 6px;
  animation-delay: -264.6s;
  transform: translate3d(270px, 609px, 1335px);
}

.bubble:nth-child(295) {
  height: 14px;
  width: 14px;
  animation-delay: -265.5s;
  transform: translate3d(965px, 260px, 356px);
}

.bubble:nth-child(296) {
  height: 24px;
  width: 24px;
  animation-delay: -266.4s;
  transform: translate3d(595px, 669px, 368px);
}

.bubble:nth-child(297) {
  height: 11px;
  width: 11px;
  animation-delay: -267.3s;
  transform: translate3d(730px, 686px, 1301px);
}

.bubble:nth-child(298) {
  height: 20px;
  width: 20px;
  animation-delay: -268.2s;
  transform: translate3d(456px, 441px, 1278px);
}

.bubble:nth-child(299) {
  height: 17px;
  width: 17px;
  animation-delay: -269.1s;
  transform: translate3d(363px, 836px, 684px);
}

.bubble:nth-child(300) {
  height: 8px;
  width: 8px;
  animation-delay: -270s;
  transform: translate3d(998px, 175px, 1012px);
}

.bubble:nth-child(301) {
  height: 12px;
  width: 12px;
  animation-delay: -270.9s;
  transform: translate3d(35px, 263px, 1007px);
}

.bubble:nth-child(302) {
  height: 16px;
  width: 16px;
  animation-delay: -271.8s;
  transform: translate3d(67px, 167px, 1995px);
}

.bubble:nth-child(303) {
  height: 8px;
  width: 8px;
  animation-delay: -272.7s;
  transform: translate3d(185px, 175px, 1351px);
}

.bubble:nth-child(304) {
  height: 27px;
  width: 27px;
  animation-delay: -273.6s;
  transform: translate3d(193px, 787px, 473px);
}

.bubble:nth-child(305) {
  height: 19px;
  width: 19px;
  animation-delay: -274.5s;
  transform: translate3d(143px, 138px, 1921px);
}

.bubble:nth-child(306) {
  height: 1px;
  width: 1px;
  animation-delay: -275.4s;
  transform: translate3d(107px, 471px, 828px);
}

.bubble:nth-child(307) {
  height: 9px;
  width: 9px;
  animation-delay: -276.3s;
  transform: translate3d(842px, 21px, 1376px);
}

.bubble:nth-child(308) {
  height: 8px;
  width: 8px;
  animation-delay: -277.2s;
  transform: translate3d(999px, 298px, 1650px);
}

.bubble:nth-child(309) {
  height: 14px;
  width: 14px;
  animation-delay: -278.1s;
  transform: translate3d(828px, 177px, 433px);
}

.bubble:nth-child(310) {
  height: 23px;
  width: 23px;
  animation-delay: -279s;
  transform: translate3d(695px, 103px, 106px);
}

.bubble:nth-child(311) {
  height: 25px;
  width: 25px;
  animation-delay: -279.9s;
  transform: translate3d(170px, 41px, 1829px);
}

.bubble:nth-child(312) {
  height: 28px;
  width: 28px;
  animation-delay: -280.8s;
  transform: translate3d(281px, 406px, 1648px);
}

.bubble:nth-child(313) {
  height: 2px;
  width: 2px;
  animation-delay: -281.7s;
  transform: translate3d(24px, 944px, 126px);
}

.bubble:nth-child(314) {
  height: 7px;
  width: 7px;
  animation-delay: -282.6s;
  transform: translate3d(794px, 149px, 1266px);
}

.bubble:nth-child(315) {
  height: 3px;
  width: 3px;
  animation-delay: -283.5s;
  transform: translate3d(362px, 299px, 937px);
}

.bubble:nth-child(316) {
  height: 3px;
  width: 3px;
  animation-delay: -284.4s;
  transform: translate3d(111px, 482px, 315px);
}

.bubble:nth-child(317) {
  height: 3px;
  width: 3px;
  animation-delay: -285.3s;
  transform: translate3d(989px, 485px, 1351px);
}

.bubble:nth-child(318) {
  height: 21px;
  width: 21px;
  animation-delay: -286.2s;
  transform: translate3d(738px, 567px, 110px);
}

.bubble:nth-child(319) {
  height: 10px;
  width: 10px;
  animation-delay: -287.1s;
  transform: translate3d(851px, 832px, 1739px);
}

.bubble:nth-child(320) {
  height: 8px;
  width: 8px;
  animation-delay: -288s;
  transform: translate3d(185px, 940px, 185px);
}

.bubble:nth-child(321) {
  height: 9px;
  width: 9px;
  animation-delay: -288.9s;
  transform: translate3d(741px, 809px, 1423px);
}

.bubble:nth-child(322) {
  height: 27px;
  width: 27px;
  animation-delay: -289.8s;
  transform: translate3d(430px, 42px, 1618px);
}

.bubble:nth-child(323) {
  height: 10px;
  width: 10px;
  animation-delay: -290.7s;
  transform: translate3d(155px, 741px, 474px);
}

.bubble:nth-child(324) {
  height: 14px;
  width: 14px;
  animation-delay: -291.6s;
  transform: translate3d(528px, 557px, 1412px);
}

.bubble:nth-child(325) {
  height: 12px;
  width: 12px;
  animation-delay: -292.5s;
  transform: translate3d(460px, 697px, 625px);
}

.bubble:nth-child(326) {
  height: 9px;
  width: 9px;
  animation-delay: -293.4s;
  transform: translate3d(267px, 611px, 1051px);
}

.bubble:nth-child(327) {
  height: 9px;
  width: 9px;
  animation-delay: -294.3s;
  transform: translate3d(87px, 158px, 670px);
}

.bubble:nth-child(328) {
  height: 4px;
  width: 4px;
  animation-delay: -295.2s;
  transform: translate3d(552px, 294px, 1869px);
}

.bubble:nth-child(329) {
  height: 6px;
  width: 6px;
  animation-delay: -296.1s;
  transform: translate3d(806px, 958px, 637px);
}

.bubble:nth-child(330) {
  height: 25px;
  width: 25px;
  animation-delay: -297s;
  transform: translate3d(934px, 864px, 1635px);
}

.bubble:nth-child(331) {
  height: 13px;
  width: 13px;
  animation-delay: -297.9s;
  transform: translate3d(629px, 28px, 405px);
}

.bubble:nth-child(332) {
  height: 20px;
  width: 20px;
  animation-delay: -298.8s;
  transform: translate3d(781px, 595px, 1186px);
}

.bubble:nth-child(333) {
  height: 14px;
  width: 14px;
  animation-delay: -299.7s;
  transform: translate3d(310px, 567px, 1420px);
}

.bubble:nth-child(334) {
  height: 2px;
  width: 2px;
  animation-delay: -300.6s;
  transform: translate3d(315px, 447px, 481px);
}

.bubble:nth-child(335) {
  height: 26px;
  width: 26px;
  animation-delay: -301.5s;
  transform: translate3d(787px, 334px, 857px);
}

.bubble:nth-child(336) {
  height: 13px;
  width: 13px;
  animation-delay: -302.4s;
  transform: translate3d(845px, 11px, 613px);
}

.bubble:nth-child(337) {
  height: 28px;
  width: 28px;
  animation-delay: -303.3s;
  transform: translate3d(419px, 216px, 367px);
}

.bubble:nth-child(338) {
  height: 25px;
  width: 25px;
  animation-delay: -304.2s;
  transform: translate3d(720px, 868px, 1805px);
}

.bubble:nth-child(339) {
  height: 6px;
  width: 6px;
  animation-delay: -305.1s;
  transform: translate3d(80px, 533px, 1327px);
}

.bubble:nth-child(340) {
  height: 16px;
  width: 16px;
  animation-delay: -306s;
  transform: translate3d(235px, 665px, 24px);
}

.bubble:nth-child(341) {
  height: 29px;
  width: 29px;
  animation-delay: -306.9s;
  transform: translate3d(593px, 283px, 1673px);
}

.bubble:nth-child(342) {
  height: 2px;
  width: 2px;
  animation-delay: -307.8s;
  transform: translate3d(737px, 126px, 1008px);
}

.bubble:nth-child(343) {
  height: 23px;
  width: 23px;
  animation-delay: -308.7s;
  transform: translate3d(413px, 447px, 1068px);
}

.bubble:nth-child(344) {
  height: 16px;
  width: 16px;
  animation-delay: -309.6s;
  transform: translate3d(271px, 317px, 1948px);
}

.bubble:nth-child(345) {
  height: 25px;
  width: 25px;
  animation-delay: -310.5s;
  transform: translate3d(725px, 99px, 571px);
}

.bubble:nth-child(346) {
  height: 13px;
  width: 13px;
  animation-delay: -311.4s;
  transform: translate3d(388px, 32px, 547px);
}

.bubble:nth-child(347) {
  height: 24px;
  width: 24px;
  animation-delay: -312.3s;
  transform: translate3d(581px, 739px, 1323px);
}

.bubble:nth-child(348) {
  height: 21px;
  width: 21px;
  animation-delay: -313.2s;
  transform: translate3d(987px, 80px, 44px);
}

.bubble:nth-child(349) {
  height: 28px;
  width: 28px;
  animation-delay: -314.1s;
  transform: translate3d(210px, 728px, 434px);
}

.bubble:nth-child(350) {
  height: 22px;
  width: 22px;
  animation-delay: -315s;
  transform: translate3d(123px, 136px, 1709px);
}

.bubble:nth-child(351) {
  height: 16px;
  width: 16px;
  animation-delay: -315.9s;
  transform: translate3d(930px, 345px, 1161px);
}

.bubble:nth-child(352) {
  height: 5px;
  width: 5px;
  animation-delay: -316.8s;
  transform: translate3d(1000px, 598px, 587px);
}

.bubble:nth-child(353) {
  height: 9px;
  width: 9px;
  animation-delay: -317.7s;
  transform: translate3d(583px, 25px, 1224px);
}

.bubble:nth-child(354) {
  height: 5px;
  width: 5px;
  animation-delay: -318.6s;
  transform: translate3d(411px, 257px, 576px);
}

.bubble:nth-child(355) {
  height: 9px;
  width: 9px;
  animation-delay: -319.5s;
  transform: translate3d(379px, 966px, 318px);
}

.bubble:nth-child(356) {
  height: 7px;
  width: 7px;
  animation-delay: -320.4s;
  transform: translate3d(54px, 704px, 554px);
}

.bubble:nth-child(357) {
  height: 6px;
  width: 6px;
  animation-delay: -321.3s;
  transform: translate3d(820px, 737px, 1427px);
}

.bubble:nth-child(358) {
  height: 8px;
  width: 8px;
  animation-delay: -322.2s;
  transform: translate3d(930px, 62px, 663px);
}

.bubble:nth-child(359) {
  height: 15px;
  width: 15px;
  animation-delay: -323.1s;
  transform: translate3d(477px, 3px, 661px);
}

.bubble:nth-child(360) {
  height: 1px;
  width: 1px;
  animation-delay: -324s;
  transform: translate3d(934px, 756px, 54px);
}

.bubble:nth-child(361) {
  height: 1px;
  width: 1px;
  animation-delay: -324.9s;
  transform: translate3d(121px, 347px, 254px);
}

.bubble:nth-child(362) {
  height: 14px;
  width: 14px;
  animation-delay: -325.8s;
  transform: translate3d(186px, 269px, 268px);
}

.bubble:nth-child(363) {
  height: 26px;
  width: 26px;
  animation-delay: -326.7s;
  transform: translate3d(770px, 28px, 475px);
}

.bubble:nth-child(364) {
  height: 23px;
  width: 23px;
  animation-delay: -327.6s;
  transform: translate3d(39px, 712px, 1470px);
}

.bubble:nth-child(365) {
  height: 29px;
  width: 29px;
  animation-delay: -328.5s;
  transform: translate3d(894px, 374px, 338px);
}

.bubble:nth-child(366) {
  height: 6px;
  width: 6px;
  animation-delay: -329.4s;
  transform: translate3d(546px, 411px, 650px);
}

.bubble:nth-child(367) {
  height: 24px;
  width: 24px;
  animation-delay: -330.3s;
  transform: translate3d(539px, 340px, 1124px);
}

.bubble:nth-child(368) {
  height: 2px;
  width: 2px;
  animation-delay: -331.2s;
  transform: translate3d(692px, 810px, 1109px);
}

.bubble:nth-child(369) {
  height: 3px;
  width: 3px;
  animation-delay: -332.1s;
  transform: translate3d(745px, 158px, 579px);
}

.bubble:nth-child(370) {
  height: 28px;
  width: 28px;
  animation-delay: -333s;
  transform: translate3d(802px, 719px, 971px);
}

.bubble:nth-child(371) {
  height: 18px;
  width: 18px;
  animation-delay: -333.9s;
  transform: translate3d(51px, 641px, 517px);
}

.bubble:nth-child(372) {
  height: 2px;
  width: 2px;
  animation-delay: -334.8s;
  transform: translate3d(585px, 392px, 1385px);
}

.bubble:nth-child(373) {
  height: 2px;
  width: 2px;
  animation-delay: -335.7s;
  transform: translate3d(711px, 446px, 1135px);
}

.bubble:nth-child(374) {
  height: 12px;
  width: 12px;
  animation-delay: -336.6s;
  transform: translate3d(17px, 685px, 156px);
}

.bubble:nth-child(375) {
  height: 12px;
  width: 12px;
  animation-delay: -337.5s;
  transform: translate3d(500px, 867px, 113px);
}

.bubble:nth-child(376) {
  height: 24px;
  width: 24px;
  animation-delay: -338.4s;
  transform: translate3d(28px, 718px, 39px);
}

.bubble:nth-child(377) {
  height: 18px;
  width: 18px;
  animation-delay: -339.3s;
  transform: translate3d(828px, 877px, 303px);
}

.bubble:nth-child(378) {
  height: 22px;
  width: 22px;
  animation-delay: -340.2s;
  transform: translate3d(879px, 780px, 1583px);
}

.bubble:nth-child(379) {
  height: 7px;
  width: 7px;
  animation-delay: -341.1s;
  transform: translate3d(54px, 984px, 1637px);
}

.bubble:nth-child(380) {
  height: 23px;
  width: 23px;
  animation-delay: -342s;
  transform: translate3d(137px, 104px, 109px);
}

.bubble:nth-child(381) {
  height: 15px;
  width: 15px;
  animation-delay: -342.9s;
  transform: translate3d(752px, 249px, 1185px);
}

.bubble:nth-child(382) {
  height: 1px;
  width: 1px;
  animation-delay: -343.8s;
  transform: translate3d(43px, 467px, 274px);
}

.bubble:nth-child(383) {
  height: 11px;
  width: 11px;
  animation-delay: -344.7s;
  transform: translate3d(396px, 187px, 1856px);
}

.bubble:nth-child(384) {
  height: 25px;
  width: 25px;
  animation-delay: -345.6s;
  transform: translate3d(144px, 510px, 831px);
}

.bubble:nth-child(385) {
  height: 26px;
  width: 26px;
  animation-delay: -346.5s;
  transform: translate3d(100px, 222px, 185px);
}

.bubble:nth-child(386) {
  height: 12px;
  width: 12px;
  animation-delay: -347.4s;
  transform: translate3d(986px, 196px, 838px);
}

.bubble:nth-child(387) {
  height: 14px;
  width: 14px;
  animation-delay: -348.3s;
  transform: translate3d(236px, 997px, 1507px);
}

.bubble:nth-child(388) {
  height: 26px;
  width: 26px;
  animation-delay: -349.2s;
  transform: translate3d(867px, 814px, 1608px);
}

.bubble:nth-child(389) {
  height: 16px;
  width: 16px;
  animation-delay: -350.1s;
  transform: translate3d(985px, 637px, 1813px);
}

.bubble:nth-child(390) {
  height: 23px;
  width: 23px;
  animation-delay: -351s;
  transform: translate3d(897px, 873px, 1566px);
}

.bubble:nth-child(391) {
  height: 26px;
  width: 26px;
  animation-delay: -351.9s;
  transform: translate3d(886px, 920px, 1884px);
}

.bubble:nth-child(392) {
  height: 2px;
  width: 2px;
  animation-delay: -352.8s;
  transform: translate3d(592px, 96px, 9px);
}

.bubble:nth-child(393) {
  height: 30px;
  width: 30px;
  animation-delay: -353.7s;
  transform: translate3d(64px, 625px, 841px);
}

.bubble:nth-child(394) {
  height: 10px;
  width: 10px;
  animation-delay: -354.6s;
  transform: translate3d(95px, 864px, 336px);
}

.bubble:nth-child(395) {
  height: 9px;
  width: 9px;
  animation-delay: -355.5s;
  transform: translate3d(17px, 661px, 617px);
}

.bubble:nth-child(396) {
  height: 29px;
  width: 29px;
  animation-delay: -356.4s;
  transform: translate3d(112px, 159px, 1791px);
}

.bubble:nth-child(397) {
  height: 30px;
  width: 30px;
  animation-delay: -357.3s;
  transform: translate3d(580px, 321px, 1273px);
}

.bubble:nth-child(398) {
  height: 16px;
  width: 16px;
  animation-delay: -358.2s;
  transform: translate3d(172px, 426px, 1294px);
}

.bubble:nth-child(399) {
  height: 10px;
  width: 10px;
  animation-delay: -359.1s;
  transform: translate3d(396px, 87px, 730px);
}

.bubble:nth-child(400) {
  height: 13px;
  width: 13px;
  animation-delay: -360s;
  transform: translate3d(365px, 352px, 977px);
}

.bubble:nth-child(401) {
  height: 15px;
  width: 15px;
  animation-delay: -360.9s;
  transform: translate3d(163px, 420px, 1304px);
}

.bubble:nth-child(402) {
  height: 17px;
  width: 17px;
  animation-delay: -361.8s;
  transform: translate3d(283px, 279px, 1473px);
}

.bubble:nth-child(403) {
  height: 18px;
  width: 18px;
  animation-delay: -362.7s;
  transform: translate3d(697px, 676px, 1135px);
}

.bubble:nth-child(404) {
  height: 19px;
  width: 19px;
  animation-delay: -363.6s;
  transform: translate3d(620px, 173px, 1222px);
}

.bubble:nth-child(405) {
  height: 28px;
  width: 28px;
  animation-delay: -364.5s;
  transform: translate3d(822px, 640px, 1107px);
}

.bubble:nth-child(406) {
  height: 7px;
  width: 7px;
  animation-delay: -365.4s;
  transform: translate3d(112px, 231px, 945px);
}

.bubble:nth-child(407) {
  height: 10px;
  width: 10px;
  animation-delay: -366.3s;
  transform: translate3d(482px, 985px, 1042px);
}

.bubble:nth-child(408) {
  height: 10px;
  width: 10px;
  animation-delay: -367.2s;
  transform: translate3d(729px, 632px, 398px);
}

.bubble:nth-child(409) {
  height: 16px;
  width: 16px;
  animation-delay: -368.1s;
  transform: translate3d(567px, 3px, 516px);
}

.bubble:nth-child(410) {
  height: 24px;
  width: 24px;
  animation-delay: -369s;
  transform: translate3d(513px, 823px, 1935px);
}

.bubble:nth-child(411) {
  height: 29px;
  width: 29px;
  animation-delay: -369.9s;
  transform: translate3d(586px, 650px, 357px);
}

.bubble:nth-child(412) {
  height: 5px;
  width: 5px;
  animation-delay: -370.8s;
  transform: translate3d(734px, 336px, 931px);
}

.bubble:nth-child(413) {
  height: 28px;
  width: 28px;
  animation-delay: -371.7s;
  transform: translate3d(48px, 781px, 1541px);
}

.bubble:nth-child(414) {
  height: 30px;
  width: 30px;
  animation-delay: -372.6s;
  transform: translate3d(492px, 453px, 797px);
}

.bubble:nth-child(415) {
  height: 19px;
  width: 19px;
  animation-delay: -373.5s;
  transform: translate3d(343px, 919px, 600px);
}

.bubble:nth-child(416) {
  height: 19px;
  width: 19px;
  animation-delay: -374.4s;
  transform: translate3d(833px, 423px, 50px);
}

.bubble:nth-child(417) {
  height: 10px;
  width: 10px;
  animation-delay: -375.3s;
  transform: translate3d(783px, 624px, 826px);
}

.bubble:nth-child(418) {
  height: 24px;
  width: 24px;
  animation-delay: -376.2s;
  transform: translate3d(282px, 942px, 411px);
}

.bubble:nth-child(419) {
  height: 18px;
  width: 18px;
  animation-delay: -377.1s;
  transform: translate3d(432px, 73px, 1567px);
}

.bubble:nth-child(420) {
  height: 28px;
  width: 28px;
  animation-delay: -378s;
  transform: translate3d(73px, 990px, 879px);
}

.bubble:nth-child(421) {
  height: 8px;
  width: 8px;
  animation-delay: -378.9s;
  transform: translate3d(766px, 195px, 1354px);
}

.bubble:nth-child(422) {
  height: 15px;
  width: 15px;
  animation-delay: -379.8s;
  transform: translate3d(342px, 121px, 1278px);
}

.bubble:nth-child(423) {
  height: 13px;
  width: 13px;
  animation-delay: -380.7s;
  transform: translate3d(348px, 366px, 1196px);
}

.bubble:nth-child(424) {
  height: 8px;
  width: 8px;
  animation-delay: -381.6s;
  transform: translate3d(727px, 932px, 1280px);
}

.bubble:nth-child(425) {
  height: 29px;
  width: 29px;
  animation-delay: -382.5s;
  transform: translate3d(754px, 533px, 1245px);
}

.bubble:nth-child(426) {
  height: 17px;
  width: 17px;
  animation-delay: -383.4s;
  transform: translate3d(915px, 148px, 309px);
}

.bubble:nth-child(427) {
  height: 2px;
  width: 2px;
  animation-delay: -384.3s;
  transform: translate3d(860px, 240px, 1044px);
}

.bubble:nth-child(428) {
  height: 18px;
  width: 18px;
  animation-delay: -385.2s;
  transform: translate3d(213px, 198px, 1009px);
}

.bubble:nth-child(429) {
  height: 19px;
  width: 19px;
  animation-delay: -386.1s;
  transform: translate3d(550px, 346px, 33px);
}

.bubble:nth-child(430) {
  height: 1px;
  width: 1px;
  animation-delay: -387s;
  transform: translate3d(275px, 756px, 997px);
}

.bubble:nth-child(431) {
  height: 4px;
  width: 4px;
  animation-delay: -387.9s;
  transform: translate3d(519px, 627px, 456px);
}

.bubble:nth-child(432) {
  height: 22px;
  width: 22px;
  animation-delay: -388.8s;
  transform: translate3d(633px, 29px, 261px);
}

.bubble:nth-child(433) {
  height: 20px;
  width: 20px;
  animation-delay: -389.7s;
  transform: translate3d(780px, 315px, 91px);
}

.bubble:nth-child(434) {
  height: 20px;
  width: 20px;
  animation-delay: -390.6s;
  transform: translate3d(838px, 766px, 763px);
}

.bubble:nth-child(435) {
  height: 22px;
  width: 22px;
  animation-delay: -391.5s;
  transform: translate3d(501px, 452px, 1102px);
}

.bubble:nth-child(436) {
  height: 25px;
  width: 25px;
  animation-delay: -392.4s;
  transform: translate3d(999px, 569px, 913px);
}

.bubble:nth-child(437) {
  height: 13px;
  width: 13px;
  animation-delay: -393.3s;
  transform: translate3d(568px, 979px, 1723px);
}

.bubble:nth-child(438) {
  height: 12px;
  width: 12px;
  animation-delay: -394.2s;
  transform: translate3d(258px, 129px, 1489px);
}

.bubble:nth-child(439) {
  height: 14px;
  width: 14px;
  animation-delay: -395.1s;
  transform: translate3d(874px, 787px, 1356px);
}

.bubble:nth-child(440) {
  height: 18px;
  width: 18px;
  animation-delay: -396s;
  transform: translate3d(88px, 288px, 206px);
}

.bubble:nth-child(441) {
  height: 19px;
  width: 19px;
  animation-delay: -396.9s;
  transform: translate3d(604px, 297px, 551px);
}

.bubble:nth-child(442) {
  height: 17px;
  width: 17px;
  animation-delay: -397.8s;
  transform: translate3d(764px, 524px, 980px);
}

.bubble:nth-child(443) {
  height: 23px;
  width: 23px;
  animation-delay: -398.7s;
  transform: translate3d(100px, 250px, 761px);
}

.bubble:nth-child(444) {
  height: 8px;
  width: 8px;
  animation-delay: -399.6s;
  transform: translate3d(650px, 683px, 779px);
}

.bubble:nth-child(445) {
  height: 10px;
  width: 10px;
  animation-delay: -400.5s;
  transform: translate3d(648px, 489px, 256px);
}

.bubble:nth-child(446) {
  height: 18px;
  width: 18px;
  animation-delay: -401.4s;
  transform: translate3d(571px, 176px, 1069px);
}

.bubble:nth-child(447) {
  height: 18px;
  width: 18px;
  animation-delay: -402.3s;
  transform: translate3d(280px, 680px, 38px);
}

.bubble:nth-child(448) {
  height: 11px;
  width: 11px;
  animation-delay: -403.2s;
  transform: translate3d(864px, 885px, 1664px);
}

.bubble:nth-child(449) {
  height: 5px;
  width: 5px;
  animation-delay: -404.1s;
  transform: translate3d(631px, 798px, 1132px);
}

.bubble:nth-child(450) {
  height: 4px;
  width: 4px;
  animation-delay: -405s;
  transform: translate3d(346px, 905px, 1115px);
}

.bubble:nth-child(451) {
  height: 16px;
  width: 16px;
  animation-delay: -405.9s;
  transform: translate3d(927px, 804px, 1761px);
}

.bubble:nth-child(452) {
  height: 28px;
  width: 28px;
  animation-delay: -406.8s;
  transform: translate3d(183px, 197px, 124px);
}

.bubble:nth-child(453) {
  height: 14px;
  width: 14px;
  animation-delay: -407.7s;
  transform: translate3d(634px, 515px, 1271px);
}

.bubble:nth-child(454) {
  height: 26px;
  width: 26px;
  animation-delay: -408.6s;
  transform: translate3d(961px, 800px, 1998px);
}

.bubble:nth-child(455) {
  height: 11px;
  width: 11px;
  animation-delay: -409.5s;
  transform: translate3d(495px, 737px, 821px);
}

.bubble:nth-child(456) {
  height: 18px;
  width: 18px;
  animation-delay: -410.4s;
  transform: translate3d(820px, 861px, 717px);
}

.bubble:nth-child(457) {
  height: 13px;
  width: 13px;
  animation-delay: -411.3s;
  transform: translate3d(83px, 142px, 934px);
}

.bubble:nth-child(458) {
  height: 5px;
  width: 5px;
  animation-delay: -412.2s;
  transform: translate3d(309px, 519px, 1081px);
}

.bubble:nth-child(459) {
  height: 17px;
  width: 17px;
  animation-delay: -413.1s;
  transform: translate3d(911px, 29px, 1778px);
}

.bubble:nth-child(460) {
  height: 23px;
  width: 23px;
  animation-delay: -414s;
  transform: translate3d(765px, 630px, 1575px);
}

.bubble:nth-child(461) {
  height: 23px;
  width: 23px;
  animation-delay: -414.9s;
  transform: translate3d(722px, 441px, 1639px);
}

.bubble:nth-child(462) {
  height: 5px;
  width: 5px;
  animation-delay: -415.8s;
  transform: translate3d(707px, 222px, 1317px);
}

.bubble:nth-child(463) {
  height: 25px;
  width: 25px;
  animation-delay: -416.7s;
  transform: translate3d(883px, 446px, 1317px);
}

.bubble:nth-child(464) {
  height: 29px;
  width: 29px;
  animation-delay: -417.6s;
  transform: translate3d(47px, 682px, 544px);
}

.bubble:nth-child(465) {
  height: 6px;
  width: 6px;
  animation-delay: -418.5s;
  transform: translate3d(212px, 504px, 1275px);
}

.bubble:nth-child(466) {
  height: 23px;
  width: 23px;
  animation-delay: -419.4s;
  transform: translate3d(785px, 749px, 1763px);
}

.bubble:nth-child(467) {
  height: 23px;
  width: 23px;
  animation-delay: -420.3s;
  transform: translate3d(171px, 937px, 166px);
}

.bubble:nth-child(468) {
  height: 3px;
  width: 3px;
  animation-delay: -421.2s;
  transform: translate3d(697px, 947px, 844px);
}

.bubble:nth-child(469) {
  height: 15px;
  width: 15px;
  animation-delay: -422.1s;
  transform: translate3d(766px, 928px, 1901px);
}

.bubble:nth-child(470) {
  height: 6px;
  width: 6px;
  animation-delay: -423s;
  transform: translate3d(687px, 182px, 931px);
}

.bubble:nth-child(471) {
  height: 5px;
  width: 5px;
  animation-delay: -423.9s;
  transform: translate3d(924px, 307px, 276px);
}

.bubble:nth-child(472) {
  height: 29px;
  width: 29px;
  animation-delay: -424.8s;
  transform: translate3d(889px, 673px, 1401px);
}

.bubble:nth-child(473) {
  height: 8px;
  width: 8px;
  animation-delay: -425.7s;
  transform: translate3d(356px, 379px, 1899px);
}

.bubble:nth-child(474) {
  height: 17px;
  width: 17px;
  animation-delay: -426.6s;
  transform: translate3d(431px, 518px, 989px);
}

.bubble:nth-child(475) {
  height: 10px;
  width: 10px;
  animation-delay: -427.5s;
  transform: translate3d(121px, 444px, 1689px);
}

.bubble:nth-child(476) {
  height: 10px;
  width: 10px;
  animation-delay: -428.4s;
  transform: translate3d(416px, 879px, 1957px);
}

.bubble:nth-child(477) {
  height: 19px;
  width: 19px;
  animation-delay: -429.3s;
  transform: translate3d(941px, 396px, 325px);
}

.bubble:nth-child(478) {
  height: 20px;
  width: 20px;
  animation-delay: -430.2s;
  transform: translate3d(608px, 847px, 233px);
}

.bubble:nth-child(479) {
  height: 3px;
  width: 3px;
  animation-delay: -431.1s;
  transform: translate3d(691px, 58px, 1707px);
}

.bubble:nth-child(480) {
  height: 5px;
  width: 5px;
  animation-delay: -432s;
  transform: translate3d(674px, 956px, 1653px);
}

.bubble:nth-child(481) {
  height: 18px;
  width: 18px;
  animation-delay: -432.9s;
  transform: translate3d(650px, 235px, 435px);
}

.bubble:nth-child(482) {
  height: 23px;
  width: 23px;
  animation-delay: -433.8s;
  transform: translate3d(705px, 200px, 1631px);
}

.bubble:nth-child(483) {
  height: 30px;
  width: 30px;
  animation-delay: -434.7s;
  transform: translate3d(531px, 329px, 1005px);
}

.bubble:nth-child(484) {
  height: 7px;
  width: 7px;
  animation-delay: -435.6s;
  transform: translate3d(979px, 774px, 1961px);
}

.bubble:nth-child(485) {
  height: 4px;
  width: 4px;
  animation-delay: -436.5s;
  transform: translate3d(522px, 793px, 871px);
}

.bubble:nth-child(486) {
  height: 12px;
  width: 12px;
  animation-delay: -437.4s;
  transform: translate3d(33px, 932px, 828px);
}

.bubble:nth-child(487) {
  height: 6px;
  width: 6px;
  animation-delay: -438.3s;
  transform: translate3d(521px, 173px, 1253px);
}

.bubble:nth-child(488) {
  height: 21px;
  width: 21px;
  animation-delay: -439.2s;
  transform: translate3d(224px, 45px, 1916px);
}

.bubble:nth-child(489) {
  height: 2px;
  width: 2px;
  animation-delay: -440.1s;
  transform: translate3d(321px, 525px, 1755px);
}

.bubble:nth-child(490) {
  height: 27px;
  width: 27px;
  animation-delay: -441s;
  transform: translate3d(12px, 319px, 1226px);
}

.bubble:nth-child(491) {
  height: 4px;
  width: 4px;
  animation-delay: -441.9s;
  transform: translate3d(66px, 276px, 515px);
}

.bubble:nth-child(492) {
  height: 1px;
  width: 1px;
  animation-delay: -442.8s;
  transform: translate3d(442px, 213px, 1179px);
}

.bubble:nth-child(493) {
  height: 17px;
  width: 17px;
  animation-delay: -443.7s;
  transform: translate3d(758px, 170px, 1138px);
}

.bubble:nth-child(494) {
  height: 26px;
  width: 26px;
  animation-delay: -444.6s;
  transform: translate3d(507px, 252px, 1111px);
}

.bubble:nth-child(495) {
  height: 2px;
  width: 2px;
  animation-delay: -445.5s;
  transform: translate3d(33px, 14px, 565px);
}

.bubble:nth-child(496) {
  height: 14px;
  width: 14px;
  animation-delay: -446.4s;
  transform: translate3d(938px, 113px, 122px);
}

.bubble:nth-child(497) {
  height: 22px;
  width: 22px;
  animation-delay: -447.3s;
  transform: translate3d(243px, 225px, 1760px);
}

.bubble:nth-child(498) {
  height: 2px;
  width: 2px;
  animation-delay: -448.2s;
  transform: translate3d(818px, 999px, 294px);
}

.bubble:nth-child(499) {
  height: 30px;
  width: 30px;
  animation-delay: -449.1s;
  transform: translate3d(163px, 989px, 200px);
}

.bubble:nth-child(500) {
  height: 1px;
  width: 1px;
  animation-delay: -450s;
  transform: translate3d(854px, 312px, 1117px);
}

@media (min-width: 768px) and (max-width: 1199px) {
  .record-player .record-key {
    top: -24px;
    right: -117px;
    width: 169px;
    transform-origin: 95px 95px;
  }
  .record-player .player-main-shade {
    height: 400px;
    width: 400px;
  }
  .record-player {
    width: 400px;
  }
  .bubble-wrap {
    left: 21%;
  }
  .bubble-wrap-right {
    right: 62%;
  }
  .record-player .record-key.active {
    transform-origin: 95px 95px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .latest-album-right {
    padding-left: 0;
  }
  .latest-relese .section-title {
    margin-bottom: 30px;
  }
  .latest-album-left .albun-details p {
    font-size: 12px;
    line-height: 19px;
  }
  .latest-album-left .albun-details h6 {
    margin-bottom: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .record-player .record-key {
    top: -24px;
    right: -117px;
    width: 169px;
    transform-origin: 95px 95px;
  }
  .record-player .player-main-shade {
    height: 380px;
    width: 380px;
  }
  .record-player {
    width: 380px;
  }
  .bubble-wrap {
    left: 21%;
  }
  .bubble-wrap-right {
    right: 62%;
  }
  .record-player .record-key.active {
    transform-origin: 95px 95px;
  }
  .latest-album-right {
    padding-left: 0;
  }
  .latest-relese .section-title {
    margin-bottom: 30px;
  }
  .latest-album-left .albun-details p {
    font-size: 12px;
    line-height: 19px;
  }
  .latest-album-left .albun-details h6 {
    margin-bottom: 15px;
  }
  .cover-img {
    display: none;
  }
  .bubble-wrap, .bubble-wrap-right {
    display: none;
  }
  .latest-relese {
    background-size: cover;
    background-position: 45% -124px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .record-player .record-key {
    top: -4px;
    right: -65px;
    width: 80px;
    transform-origin: 45px 45px;
  }
  .record-player .player-main-shade {
    height: 200px;
    width: 200px;
  }
  .record-player {
    width: 200px;
  }
  .bubble-wrap {
    left: 21%;
  }
  .bubble-wrap-right {
    right: 62%;
  }
  .record-player .record-key.active {
    transform-origin: 45px 45px;
  }
  .latest-album-right {
    padding-left: 0;
  }
  .latest-relese .section-title {
    margin-bottom: 30px;
  }
  .latest-album-left .albun-details p {
    font-size: 12px;
    line-height: 19px;
  }
  .latest-album-left .albun-details h6 {
    margin-bottom: 15px;
  }
  .cover-img {
    display: none;
  }
  .bubble-wrap, .bubble-wrap-right {
    display: none;
  }
  .latest-relese {
    background-size: cover;
    background-position: 55% -85px;
  }
  .latest-album-left .albun-details {
    padding-left: 0;
    margin-bottom: 10px;
  }
  .record-player {
    margin-top: 10px;
  }
  .section-title.style-five {
    margin-bottom: 30px;
  }
  .latest-album-btn .sm2_button {
    width: 42px;
    height: 42px;
    line-height: 47px;
  }
}

/*--------------------------------------------------------------
  ##  Artist lineup
  --------------------------------------------------------------*/
.artist-lineup {
  background: #111111;
}

.artist-lineup .swiper-button-prev {
  background-image: none;
  left: 0px;
  right: auto;
  height: 107%;
  background-size: cover;
  top: 7px;
  width: 200px;
  background: linear-gradient(90deg, #111111 0%, #111111 13%, rgba(255, 255, 255, 0) 87%, rgba(255, 255, 255, 0) 100%);
}

.artist-lineup .swiper-button-next {
  background-image: none;
  right: 0;
  left: auto;
  height: 107%;
  background-size: cover;
  top: 7px;
  width: 200px;
  background: linear-gradient(-90deg, #111111 0%, #111111 13%, rgba(255, 255, 255, 0) 87%, rgba(255, 255, 255, 0) 100%);
}

.artist-lineup .swiper-container {
  margin-top: 65px;
}

.artist-single {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.artist-single:before {
  content: "";
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #f60038 100%);
  position: absolute;
  left: 0;
  top: 27px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-repeat: repeat-x;
  opacity: 0;
  transition: all 0.3s;
}

.artist-single img {
  -webkit-transition: opacity 1s, -webkit-transform 1s;
  transition: opacity 1s, transform 1s;
  width: 100%;
}

.artist-single .artist-single-content {
  position: absolute;
  left: 31px;
  bottom: 15px;
  z-index: 5;
}

.artist-single .artist-single-content ul {
  border-bottom: 1px solid #f60038;
  display: inline-block;
  padding-right: 25px;
  padding-bottom: 6px;
  margin-bottom: 10px;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
  opacity: 0;
  -webkit-transition-delay: 0.03s;
  transition-delay: 0.03s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-opacity: 0.15s;
}

.artist-single .artist-single-content ul li {
  display: inline-block;
  margin-right: 12px;
}

.artist-single .artist-single-content ul li a {
  color: #fff;
}

.artist-single .artist-single-content ul li a:hover {
  color: #000;
}

.artist-single .artist-single-content h6 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
  -webkit-transition-delay: 0.03s;
  transition-delay: 0.03s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-opacity: 0.15s;
  opacity: 0;
}

.artist-single .artist-single-content p {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #fff;
  line-height: 14px;
  -webkit-transform: translate3d(0, 40px, 0);
  transform: translate3d(0, 40px, 0);
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  opacity: 0;
}

.artist-single:hover:before {
  opacity: 1;
  transition: all 0.3s;
}

.artist-single:hover ul {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  opacity: 1;
}

.artist-single:hover h6 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0.03s;
  transition-delay: 0.03s;
  -webkit-transition-duration: 0.25s;
  transition-duration: 0.25s;
  transition-opacity: 0.15s;
  opacity: 1;
}

.artist-single:hover p {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0.05s;
  transition-delay: 0.05s;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  opacity: 1;
}

.artist-single:hover img {
  -webkit-transform: scale3d(1.1, 1.1, 1) rotate(2deg);
  transform: scale3d(1.1, 1.1, 1) rotate(2deg);
}

@media (min-width: 991px) and (max-width: 1300px) {
  .section-title.style-four p {
    padding: 0px 15%;
  }
}

@media (max-width: 991px) {
  .artist-single .artist-single-content {
    left: 17px;
    bottom: 10px;
  }
  .artist-lineup .swiper-button-next {
    background-repeat: no-repeat;
    background-image: url("../../assets/img/car-arrowr.png");
    right: 0;
    height: 30px;
    top: 50%;
    background-size: contain;
    width: 30px;
    background-position: center;
  }
  .artist-lineup .swiper-button-prev {
    background-image: url("../../assets/img/car-arrowl.png");
    left: 0;
    right: auto;
    height: 30px;
    background-size: contain;
    top: 50%;
    width: 30px;
    background-repeat: no-repeat;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .artist-lineup .swiper-container {
    margin-top: 30px;
  }
}

/*--------------------------------------------------------------
  ## 3D Album
  --------------------------------------------------------------*/
.three-d-album {
  padding: 55px 0 0;
}

.single-3d {
  flex: 0 0 16%;
  max-width: 16%;
  padding: 15px;
}

.single-3d img {
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.22), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
  z-index: 1;
  position: relative;
}

.threed-container-wrapper {
  perspective: 2950px;
}

.threed-container-inner {
  transform: translate3d(-11%, -25%, 0) scale(1) rotateX(45deg) rotateY(-5deg) rotateZ(25deg);
  display: flex;
  flex-wrap: wrap;
}

.three-d-album-width {
  width: 122%;
  margin-top: 300px;
}

.single-3d:hover img {
  transform: scale(1.5);
  box-shadow: 0px 36px 67.9px 2.1px rgba(0, 0, 0, 0.5), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
  transition: all .6s ease-in-out;
  position: relative;
  z-index: 5;
}

.three-d-album {
  background-image: url(../../media/background/play_bg.png);
  background-position: left 93%;
  background-repeat: no-repeat;
  background-size: auto;
}

.three-d-album .more-album {
  position: absolute;
  right: 350px;
  margin-top: 54px;
}

.three-d-album .more-album a {
  font-size: 14px;
  color: #959595;
  font-family: "Changa", sans-serif;
  font-weight: 500;
}

.three-d-album .more-album a:hover i {
  margin-left: 20px;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.three-d-album .more-album a i {
  color: #f60038;
  display: block;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
  margin-left: 0;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .single-3d {
    padding: 5px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .threed-container-inner {
    transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  .single-3d {
    width: 20%;
    padding: 7px;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .three-d-album-width {
    width: 100%;
    margin-top: 170px;
    padding: 0 21px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-3d.empty-space {
    display: none;
  }
  .three-d-album {
    padding-bottom: 180px;
  }
}

@media (min-width: 576px) and (max-width: 776px) {
  .threed-container-inner {
    transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  .single-3d {
    width: 25%;
    padding: 7px;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .three-d-album-width {
    width: 100%;
    margin-top: 154px;
    padding: 0 15px;
  }
  .empty-space {
    display: none;
  }
  .banner-five .baneer-five-content h3 {
    font-size: 22px;
    margin-top: 53px;
  }
  .banner-five .baneer-five-content .tim-slide-btn {
    margin-top: 20px;
    padding: 12px 35px;
  }
  .three-d-album .more-album {
    margin-top: 0;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .threed-container-inner {
    transform: translate3d(0, 0, 0) scale(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  .three-d-album-width {
    width: 100%;
    margin-top: 170px;
    padding: 0 15px;
  }
  .three-d-album {
    background-image: none;
  }
  .single-3d {
    -ms-flex: 0 0 46%;
    flex: 0 0 46%;
    max-width: 46%;
    overflow: hidden;
    padding: 0;
    margin: 5px;
  }
  .empty-space {
    display: none;
  }
  .three-d-album-width {
    margin-top: 100px;
  }
  .three-d-album .more-album {
    left: 10px;
    margin-top: 0;
    right: 0;
  }
}

/*--------------------------------------------------------------
  ## Show Archicve
  --------------------------------------------------------------*/
.show-archive {
  padding-bottom: 50px;
}

.show-archive-wrapper {
  width: 78%;
  float: right;
  margin-top: -140px;
}

.show-archive-wrapper div {
  padding: 0;
}

.video-btn-three {
  height: 60px;
  width: 60px;
  border: 2px solid #f60038;
  border-radius: 4px;
  color: #f60038;
  line-height: 65px;
  display: block;
  text-align: center;
}

.video-btn-three i {
  margin-left: 3px;
  color: #f60038;
  font-size: 30px;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.video-btn-three:hover i {
  color: #fff;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.single-show-archive {
  position: relative;
}

.single-show-archive > a {
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-14px, 55px, 0);
  font-size: 21px;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
  opacity: 0;
}

.single-show-archive .video-player {
  position: absolute;
  left: 0px;
  bottom: 40px;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
  transform: translate3d(-25px, 0, 0);
}

.single-show-archive .video-player .text {
  position: absolute;
  left: 80px;
  top: 2px;
  width: 255px;
}

.single-show-archive .video-player .text p {
  font-size: 20px;
  color: #fff;
  margin: 0;
  font-family: "Changa", sans-serif;
}

.single-show-archive .video-player .text span {
  font-size: 14px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.single-show-archive:hover > a {
  transform: translate3d(-14px, -14px, 0);
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
  opacity: 1;
  delay: .5s;
}

.video-btn-shade {
  height: 60px;
  width: 60px;
  border: 2px solid #000;
  border-radius: 4px;
  color: #f60038;
  line-height: 65px;
  display: block;
  text-align: center;
  position: absolute;
  left: -7px;
  top: 7px;
  z-index: -1;
  filter: blur(2.6px);
  opacity: 0.4;
}

.video-btn-shade i {
  color: #000;
  font-size: 25px;
}

.single-show-archive::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f60038;
  opacity: 0;
  transition-duration: 0.7s;
}

.single-show-archive:hover:before {
  opacity: .8;
  transition-duration: 0.7s;
}

.single-show-archive:hover .video-player {
  left: 0;
  transform: translate3d(40px, 0, 0);
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.single-show-archive.video-archive:before {
  display: none;
}

@media (min-width: 768px) and (max-width: 991px) {
  .show-archive-wrapper {
    width: 90%;
    float: right;
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .show-archive-wrapper {
    width: 100%;
    float: right;
    margin-top: -15px;
  }
  .single-show-archive .video-player {
    transform: translate3d(15px, 0, 0);
  }
  .single-show-archive:hover .video-player {
    transform: translate3d(20px, 0, 0);
  }
  .show-archive {
    padding-top: 50px;
  }
}

/*--------------------------------------------------------------
  ## Show Ticket
  --------------------------------------------------------------*/
.single-show-ticket {
  transition: all .2s;
  border: solid 6px transparent;
  padding: 28px 17px 13px;
  margin-bottom: 16px;
}

.single-show-ticket h6 {
  font-size: 30px;
  color: #000;
  line-height: 16px;
  margin-bottom: 0;
  line-height: 34px;
  font-weight: 700;
}

.single-show-ticket h5 {
  font-size: 20px;
  color: #000;
  font-weight: 400;
}

.single-show-ticket p {
  font-size: 14px;
  color: #000;
}

.single-show-ticket a {
  font-size: 14px;
  letter-spacing: 2.8px;
  color: #000;
  border-radius: 4px;
  text-align: center;
  font-weight: 500;
  z-index: 99;
  position: relative;
  height: 60px;
  text-align: center;
  width: 100%;
  display: block;
  overflow: hidden;
  padding-top: 17px;
}

.single-show-ticket a:hover {
  color: #f60038;
}

.single-show-ticket:hover {
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
  transition: all .5s;
  border: solid 6px #f60038;
}

.single-show-ticket:hover h6 {
  color: #f60038;
}

.single-show-ticket:hover h5 {
  color: #f60038;
}

.path {
  fill: transparent;
  stroke: #f60038;
  stroke-miterlimit: 10;
  stroke-width: 2px;
  opacity: 0;
}

.st0 {
  fill: transparent;
  stroke: #ddd;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.a_hover:hover .path {
  stroke-dasharray: 1000;
  animation: dash 2s linear;
  opacity: 1;
  stroke-width: 2px;
  stroke: #f60038;
}

.a_hover {
  position: relative;
  margin-top: 0;
  width: 144px;
}

.a_hover svg {
  position: absolute;
  left: 0;
  top: 0;
}

.a_hover i {
  position: absolute;
  bottom: 0;
  left: 5px;
  color: #f60038;
  font-size: 13px;
  opacity: 0;
}

.a_hover:hover i {
  left: 24px;
  transition-duration: 1s;
  transition-delay: .5s;
  opacity: 1;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.date-time {
  width: 72%;
  position: relative;
}

.date-time:after {
  content: '';
  position: absolute;
  right: 0;
  background: #d7d7d7;
  height: 85px;
  width: 1px;
  top: -13px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .date-time {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .a_hover {
    margin: 0 auto;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .single-show-ticket p {
    font-size: 15px;
  }
  .date-time {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .date-time::after {
    display: none;
  }
  .a_hover {
    float: left;
    margin-left: -6px;
  }
  .single-show-ticket {
    border: solid 1px transparent;
    margin-bottom: 40px;
    box-shadow: 0px -1px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
  }
  .single-show-ticket:hover {
    border: solid 1px #f60038;
    box-shadow: 0px -1px 36.9px 1.1px rgba(0, 0, 0, 0.16), inset -0.5px -0.9px 0 0 rgba(255, 255, 255, 0.75);
  }
  .single-show-ticket h5 {
    color: #f60038;
  }
}

/*--------------------------------------------------------------
  ## Spotlight
  --------------------------------------------------------------*/
.video-btn-four {
  height: 60px;
  width: 60px;
  border: 2px solid #f60038;
  border-radius: 4px;
  color: #f60038;
  line-height: 65px;
  display: block;
  text-align: center;
}

.video-btn-four i {
  margin-left: 3px;
  color: #f60038;
  font-size: 30px;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
}

.video-btn-four:hover i {
  color: #fff;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
}

.spotlight-inner .video-player-three {
  position: absolute;
  right: 40px;
  bottom: 40px;
  -webkit-transition: all 1s ease-in;
  -o-transition: all 1s ease-in;
  transition: all 1s ease-in;
  -webkit-transform: translate3d(-25px, 0, 0);
  transform: translate3d(0px, 0, 0);
}

.spotlight-inner .video-player-three .text {
  position: absolute;
  right: 76px;
  top: 2px;
  width: 255px;
  text-align: right;
}

.spotlight-inner .video-player-three .text p {
  font-size: 20px;
  color: #fff;
  margin: 0;
  font-family: "Changa", sans-serif;
}

.spotlight-inner .video-player-three .text span {
  font-size: 14px;
  color: #fff;
  font-family: "Changa", sans-serif;
}

.video-player-three .video-btn-shade {
  height: 60px;
  width: 60px;
  border: 2px solid #000;
  border-radius: 4px;
  color: #f60038;
  line-height: 65px;
  display: block;
  text-align: center;
  position: absolute;
  left: -7px;
  top: 7px;
  z-index: -1;
  filter: blur(2.6px);
  opacity: 0.4;
}

.video-player-three .video-btn-shade i {
  color: #000;
  font-size: 25px;
}

.spotlight-inn:hover .video-player-three {
  left: 0;
  transform: translate3d(40px, 0, 0);
  transition: all 1s ease-in;
}

.grid-60 {
  width: 60%;
  float: left;
  padding: 0 15px;
}

.grid-40 {
  width: 40%;
  float: left;
  padding: 0 15px;
}

.spotlight-inner {
  position: relative;
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
}

.subscribe-two {
  position: relative;
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
  padding: 50px 40px;
  background: #fff;
}

.subscribe-two input {
  background: #f7f7f7;
  width: 100%;
  border: 0px solid;
  height: 60px;
  padding-left: 19px;
  margin-bottom: 19px;
}

.subscribe-two button {
  border: 2px solid #000;
  border-radius: 4px;
  color: #000;
  padding: 7px 33px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 2.8px;
  cursor: pointer;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.subscribe-two button:hover {
  border: 2px solid #f60038;
  color: #f60038;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.subscribe-two .section-title {
  margin-bottom: 21px;
}

.wide_line_bg {
  position: relative;
  background-image: url(../../media/background/play_bg_right.png);
  background-position: right 93%;
  background-repeat: no-repeat;
  background-size: auto;
}

.wide_line_bg:after {
  position: absolute;
  content: '';
  left: -8%;
  top: 28%;
  width: 117%;
  height: 325px;
  z-index: -1;
  background-repeat: no-repeat;
  background: #f60038;
  transform: rotate(160deg);
}

@media (min-width: 992px) and (max-width: 1199px) {
  .wide_line_bg::after {
    left: -8%;
    top: 40%;
    width: 117%;
    height: 230px;
  }
}

@media (min-width: 768px) and (max-width: 1300px) {
  .subscribe-two {
    padding: 25px 25px;
  }
  .subscribe-two .section-title.style-five p {
    width: auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .wide_line_bg::after {
    display: none;
  }
  .wide_line_bg {
    display: none;
  }
  .section-title.style-five p {
    width: auto;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .subscribe-two {
    padding: 30px;
  }
  .section-title.style-five p {
    width: auto;
  }
  .grid-60, .grid-40 {
    width: 100%;
  }
  .spotlight-inner {
    margin-bottom: 30px;
  }
  .wide_line_bg::after {
    display: none;
  }
  .wide_line_bg {
    background-image: none;
  }
}

/*--------------------------------------------------------------
  ## Stores
  --------------------------------------------------------------*/
#store-pagination {
  text-align: left;
  left: 35px;
  bottom: 40px;
}

#store-pagination .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background: #d7d7d7;
  border-radius: 0;
  opacity: 1;
}

#store-pagination .swiper-pagination-bullet-active {
  background: #f60038;
  opacity: 1;
}

.brand-shop-carousel {
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
}

.soundcloud-wrapper {
  padding: 40px;
  box-shadow: 0px 36px 36.9px 1.1px rgba(0, 0, 0, 0.1);
  border: solid 1px #f8f8f8;
}

.soundcloud-wrapper iframe {
  height: 120px;
  width: 100%;
}

.soundcloud-wrapper h6 {
  font-size: 20px;
  color: #000;
  padding-top: 13px;
  padding-bottom: 17px;
}

.soundcloud-wrapper footer {
  border-top: 1px solid #ebebeb;
  padding-top: 5px;
  overflow: hidden;
}

.soundcloud-wrapper footer a {
  font-size: 14px;
  color: #959595;
  letter-spacing: 1.7px;
  float: left;
  font-weight: 500;
  font-family: "Changa", sans-serif;
}

.soundcloud-wrapper footer a i {
  color: red;
  width: 100%;
  display: inline;
  float: left;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
  margin-left: 0;
}

.soundcloud-wrapper footer p {
  font-size: 14px;
  color: #494848;
  float: right;
}

.soundcloud-wrapper footer p span {
  color: #949494;
}

.soundcloud-wrapper footer a:hover i {
  margin-left: 15px;
  transition: all 0.5s cubic-bezier(0, 1.09, 1, 1);
}

@media (min-width: 992px) and (max-width: 1400px) {
  .soundcloud-wrapper {
    padding: 20px 30px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .soundcloud-wrapper h6 {
    font-size: 17px;
    padding-top: 13px;
    padding-bottom: 10px;
  }
  .soundcloud-wrapper {
    padding: 20px;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .brand-shop-carousel {
    max-width: 435px;
    margin: 0 auto;
    margin-bottom: 100px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .soundcloud-wrapper {
    padding: 15px;
  }
}

/*--------------------------------------------------------------
  ## Stores
  --------------------------------------------------------------*/
.partners-area {
  padding: 60px 0 16px;
}

.partner-swipper .swiper-button-next {
  background-image: url("../img/right-arrow.png");
  background-size: auto;
}

.partner-swipper .swiper-button-prev {
  background-image: url("../img/left-arrow.png");
  background-size: auto;
}

.partner-swipper {
  margin-top: 50px;
}

.partner-swipper .swiper-slide {
  text-align: center;
}

.partner-swipper .swiper-button-next, .partner-swipper .swiper-button-prev {
  top: 60%;
  opacity: 0;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}

.partner-swipper:hover .swiper-button-next, .partner-swipper:hover .swiper-button-prev {
  opacity: 1;
  transition: all 0.8s cubic-bezier(0, 1.09, 1, 1);
}
