.event-details {


	.event-details {
		font-family: $font_heading;
		.event-brief {
			border: 10px solid #f5f5f5;
			margin-bottom: 45px;
			.brief-bottom {
				display: inline-block;
				padding: 30px;
				width: 100%;
				h6 {
					float: left;
					width: 50%;
					display: inline-block;
					font-size: 26px;
					color: #333333;
					padding-right: 50px;
					position: relative;
					&:after {
						content: '';
						position: absolute;
						height: 80px;
						width: 1px;
						background: #ebebeb;
						right: 0;
						top: 0; } }
				p {
					overflow: hidden;
					margin-bottom: 5px;
					padding-left: 30px; } } }
		> p {
			color: #585757; }

		ul {
			list-style: none;
			margin-bottom: 33px;
			li {
				i {
					margin-right: 15px;
					color: $color_primary; } } }

		> span {
			color: #333333;
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 18px;
			display: inline-block; } } }


.event-time {
	.event-video {
		position: relative;
		width: 380px;
		float: right;
		margin-right: 30px;
		margin-top: 25px;
		.video-btn {
			height: 75px;
			width: 75px;
			border-radius: 50%;
			line-height: 75px;
			display: block;
			margin: 0 auto;
			font-size: 26px;
			margin-bottom: 20px;
			background: #fff;
			text-align: center;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			position: absolute;
			border: {}
			i {
				color: $color_primary; } } } }


.event-time .event-video .video-btn::after {
	position: absolute;
	content: '';
	top: -17px;
	left: -17px;
	width: 105px;
	height: 105px;
	background: #fff;
	opacity: 0.2;
	z-index: -1;
	border-radius: 50%; }

.event-time .event-video .video-btn:hover {
	border-color: #fff; }





.single-event-schedule {
	.img-box {
		float: left;
		border-radius: 6px; }

	h6 {
		font-size: 18px;
		color: #333333;
		margin-bottom: 0; }
	span {
		font-size: 16px;
		color: #565555; }
	.schedule-time {
		float: right;
		span {
			font-size: 24px;
			display: block;
			color: #333333;
			font-weight: 600; } } }


.single-event-schedule:hover {


	h6 {
		color: $color_primary; }
	.schedule-time {
		span {
			color: $color_primary; } } }



.event-schedule-inner {
	width: 50%;
	float: left; }


.single-event-schedule {
	padding-bottom: 22px;
	border-bottom: 1px solid #f7f7f7;
	padding-top: 30px;

	.schedule-mid {
		overflow: hidden;
		display: inline-block;
		padding-left: 26px; }
	.event-time {
		margin-top: 25px; } }


.single-event-list-widget {
	font-family: $font_heading;
	margin-bottom: 10px;
	display: inline-block;
	width: 100%;
	box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
	.sle-img {
		float: left;
		position: relative;
		span {
			position: absolute;
			background: rgba(18, 25, 58, 0.87);
			left: 0;
			bottom: 0;
			color: #fff;
			font-size: 16px;
			width: 100%;
			text-align: center; } }
	.right {
		h6 {
			font-size: 18px;
			color: #333333;
			margin: 0; }
		a {
			font-size: 15px;
			color: #5e5c5c;
			display: block;
			i {
				margin-right: 5px; } }
		.mid-box {
			float: left;
			text-align: left;
			padding-left: 15px;
			padding-top: 17px;
			padding-right: 25px; }
		.price {
			display: inline-block;
			padding-top: 28px;
			span {
				font-size: 18px;
				font-weight: 500; }


			a {
				font-size: 18px;
				color: $color_primary;
				font-weight: 500;
				line-height: 15px; } } } }


.widget.widget-event-list {
	padding: 40px 10px 20px;
	box-shadow: none;
	border: 10px solid #f5f5f5;
	border-radius: 0; }


.widget.widget-sponser {
	ul {
		overflow: hidden;
		li {
			padding: 17px 10px;
			float: left;
			height: 100px;
			overflow: hidden;
			width: 32%; } } }


.widget.widget-sponser ul li:nth-child(1),
.widget.widget-sponser ul li:nth-child(2) {
	border-bottom: 1px solid #f4f4f4;
	border-right: 1px solid #f4f4f4; }

.widget.widget-sponser ul li:nth-child(3) {
	border-bottom: 1px solid #f4f4f4; }

.widget.widget-sponser ul li:nth-child(4),
.widget.widget-sponser ul li:nth-child(5) {
	border-bottom: none;
	border-right: 1px solid #f4f4f4; }


.single-event-list-widget:hover {

	background-image: -moz-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
	background-image-background-image: -webkit-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
	background-image-background-image: -ms-linear-gradient(90deg, #ee658f 0%, #a33cbf 100%);
	transition: all .3s; }




.single-event-list-widget:hover {
	font-family: $font_heading;
	margin-bottom: 10px;
	display: inline-block;
	width: 100%;
	box-shadow: 0px 3px 12.35px 0.65px rgba(4, 56, 115, 0.09);
	.sle-img {
		span {
			color: #fff;
			transition: all .3s; } }
	.right {
		h6 {
			color: #fff;
			transition: all .3s; }
		a {
			color: #fff;
			a {
				color: #fff; } } }
	.right .price span {
		color: #fff;
		transition: all .3s; } }
